import React, {
  CSSProperties,
  Dispatch,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';

import { fetchFpsForMap } from 'api/fps';
import { MapClusterDTO } from 'api/commonTypes';
import { getConfigState } from 'config/duck';
import AdvertisingModal from 'commons/AdvertisingModal';
import { InternalApiState } from 'api/duck';
import services from 'commons/services';
import { Point } from '@cvfm-front/tefps-types';
import { FpsMapSearchResultDTO } from 'api/fps/types';
import { DrawingType, MapId } from 'commons/services/MapService';

import { DISPLAY_PATROL_ZONES, DISPLAY_ZONES } from '../ControlStrategy';

import FpsMapFilters from './FpsMapFilters';
import { MapFilters, StrategyProps } from './types';
import { getStrategyFiltersState, strategyFiltersChanged } from './duck';
import { filtersToRequest } from './utils';

const { _tg } = window.loadTranslations(__filename);

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  width: '100%',
  height: '100%',
};

const STYLE_LIST_WRAPPER: CSSProperties = {
  backgroundColor: 'white',
  width: '100%',
  margin: '0 auto',
};

export type Marker = {
  status?: string | null | undefined;
  position: Point;
  count?: number;
  id?: string;
  title?: string;
};

const titleFormatter = (count: number) => `${count} ${_tg('commons.fps')}`;

const Strategy = ({
  config,
  filters,
  onFilterChange,
  history,
}: StrategyProps) => {
  const refMap = useRef<HTMLDivElement>(null);

  const [markers, setMarkers] = useState<Array<Marker>>([]);
  const [result, setResult] = useState<FpsMapSearchResultDTO | null>(null);
  const [displayZones, setDisplayZones] = useState<boolean>(false);
  const [displayPatrolZones, setDisplayPatrolZones] = useState<boolean>(false);

  const totalHits = useMemo(() => result?.totalHits || 0, [result]);

  const handleClickOnFps = (fpsId: string) => {
    history.push({ pathname: `/fps/detail/${fpsId}` });
  };

  const toggleDisplayZones = (_id: string, newFilter: Set<string>): void => {
    // zones
    const newDisplayZones = newFilter.has(DISPLAY_ZONES);
    if (displayZones !== newDisplayZones) {
      setDisplayZones(newDisplayZones);
      if (newDisplayZones) {
        services.zoning.drawZonesOnMap(MapId.FPS_STRATEGY);
      } else {
        services.zoning.clearZonesOnMap(MapId.FPS_STRATEGY);
      }
    }
    // patrol zones
    const newDisplayPatrolZones = newFilter.has(DISPLAY_PATROL_ZONES);
    if (displayPatrolZones !== newDisplayPatrolZones) {
      setDisplayPatrolZones(newDisplayPatrolZones);
      if (newDisplayPatrolZones) {
        services.patrolZoneService.drawPatrolZonesOnMap(MapId.FPS_STRATEGY);
      } else {
        services.patrolZoneService.clearPatrolZonesOnMap(MapId.FPS_STRATEGY);
      }
    }
  };

  const fetchFpses = async (): Promise<Array<Marker>> => {
    try {
      const newResult = await fetchFpsForMap(filtersToRequest(filters));
      let newMarkers: Array<Marker> = [];
      if (newResult && newResult.fpses) {
        newMarkers = newResult.fpses.map(fps => ({
          position: {
            latitude: fps.latitude,
            longitude: fps.longitude,
          },
          id: fps.id,
          count: 1,
        }));
      } else if (newResult && newResult.clusters) {
        newMarkers = newResult.clusters.map((cluster: MapClusterDTO) => ({
          position: {
            latitude: cluster.latitude,
            longitude: cluster.longitude,
          },
          id: cluster.key,
          count: cluster.number,
        }));
      }
      setResult(newResult);
      setMarkers(newMarkers);
      return newMarkers;
    } catch (error) {
      // ignored
    }
    return [];
  };

  useEffect(() => {
    if (refMap?.current) {
      void services.mapService.init(MapId.FPS_STRATEGY, config, refMap);
    }
  }, [refMap]);

  useEffect(() => {
    void fetchFpses();
    const map = services.mapService.get(MapId.FPS_STRATEGY);
    map?.mapListener?.add('bounds_changed', () => {
      const [north, west, south, east] = map.viewPort.get();
      const zoom = map.zoom.get();
      onFilterChange({
        ...filters,
        boundingBoxNorthEast: {
          latitude: north,
          longitude: east,
        },
        boundingSouthWest: {
          latitude: south,
          longitude: west,
        },
        zoom,
      });
    });
    return () => map?.mapListener?.clear('bounds_changed');
  }, [filters]);

  useEffect(() => {
    const map = services.mapService.get(MapId.FPS_STRATEGY);
    map?.markers?.add(
      markers.map(marker => {
        const count = marker.count || 1;
        const listeners = new Map();
        if (count === 1) {
          listeners.set('click', () => {
            handleClickOnFps(marker.id || '');
          });
        }
        return {
          id: marker.id || '',
          type: DrawingType.FPS_STRATEGY,
          point: marker.position,
          title: titleFormatter(count),
          count,
          symbol: '/static/img/map/circles/circle_ko.png',
          listeners,
        };
      })
    );
    map?.markers?.addClusterer({
      type: DrawingType.FPS_STRATEGY,
      titleFormatter: (count: number) => `${count} ${_tg('commons.fps')}`,
    });
    return () => map?.clear?.drawingType(DrawingType.FPS_STRATEGY);
  }, [markers]);

  return (
    <div style={STYLE_CONTENT}>
      <AdvertisingModal module="fps" />
      <FpsMapFilters
        totalHits={totalHits}
        facetings={(result || {}).checkboxFaceting}
        displayZones={displayZones}
        displayPatrolZones={displayPatrolZones}
        toggleDisplayZone={toggleDisplayZones}
      />
      <div style={STYLE_LIST_WRAPPER}>
        <div
          ref={refMap}
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: InternalApiState) => {
  const config = getConfigState(state);
  const filters = getStrategyFiltersState(state);
  return {
    config,
    filters,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<unknown>) => ({
  onFilterChange: (filters: MapFilters) =>
    dispatch(strategyFiltersChanged(filters)),
});

export default connect<unknown, unknown, StrategyProps>(
  mapStateToProps,
  mapDispatchToProps
)(Strategy);
