import React, { CSSProperties, useEffect, useState } from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import Card from 'material-ui/Card';

import SimpleTable from 'commons/SimpleTable';
import ErrorBlock from 'commons/ErrorBlock';
import Content from 'commons/Content';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import AdvertisingModal from 'commons/AdvertisingModal';

import MonthlyAmountHistogram, {
  MonthlyAmountHistogramData,
} from '../commons/MonthlyAmountHistogram';
import { EUR_UNIT } from '../commons/types';

import StatisticService, {
  FormattedSubStatistics,
  DEFAULT_STATISTICS,
} from './service';
import { buildYearColumns } from './utils';
import {
  ACCEPTED_APPLICATION_CONFIG,
  BO_APPLICATION_CONFIG,
  FO_APPLICATION_CONFIG,
  REJECTED_APPLICATION_CONFIG,
  SUBSCRIPTION_PAYMENT_CONFIG,
  TICKET_PAYMENT_CONFIG,
} from './config';

const { _tg } = window.loadTranslations(__filename);

const STYLE_CONTENT: CSSProperties = {
  width: '100%',
  height: '100%',
  margin: '0 auto',
};

const GraphCard = ({
  children,
}: {
  children?: React.ReactNodeArray | React.ReactNode;
}) => <Card style={{ padding: 20 }}>{children}</Card>;

const SubscriptionsStats = (): JSX.Element => {
  const [state, setState] = useState<FormattedSubStatistics>(
    DEFAULT_STATISTICS
  );
  const [error, setError] = useState<Error | null>(null);

  const subsTableCols = buildYearColumns(_tg('field.rights.right'));
  const ticketsTableCols = buildYearColumns('Forfait');

  useEffect(() => {
    const asyncEffect = async () => {
      try {
        setState(await StatisticService.fetchDataFromApiAndFormat());
      } catch (e) {
        setError(e);
      }
    };
    void asyncEffect();
  }, []);

  if (error) {
    return (
      <Content>
        <ErrorBlock
          message={_tg(
            'tefps.dashboard.finance.finance.errorWhileLoadingGraphs'
          )}
          error={error}
        />
      </Content>
    );
  }

  return (
    <Content>
      <AdvertisingModal module="subscribers" />
      <SeparatorWithTitle
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {_tg('tefps.dashboard.subscriptionStats.subscriberData')}
          </div>
        }
        style={{ marginTop: 20 }}
        color="#dd5269"
        titleSize={22}
      />
      <div
        style={{
          padding: '50px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {state && state.apiData === null && <CircularProgress />}

        {state && state.apiData && (
          <div style={STYLE_CONTENT}>
            <div style={{ marginBottom: 50 }}>
              <GraphCard>
                <SeparatorWithTitle
                  title={_tg(
                    'tefps.dashboard.subscriptionStats.subscriptionPerMonth'
                  )}
                  color="#dd5269"
                  titleSize={18}
                  style={{ marginBottom: 20 }}
                />
                <SimpleTable
                  maxHeight={500}
                  cols={subsTableCols}
                  rowHeight={50}
                  header
                  itemsRenderer={i => i}
                  items={state.subscriptionByMonthStatItems}
                />
              </GraphCard>
            </div>
            <div style={{ marginBottom: 50 }}>
              <GraphCard>
                <SeparatorWithTitle
                  title={_tg(
                    'tefps.dashboard.subscriptionStats.ticketsPerMonth'
                  )}
                  color="#dd5269"
                  titleSize={18}
                  style={{ marginBottom: 20 }}
                />
                <SimpleTable
                  maxHeight={500}
                  cols={ticketsTableCols}
                  rowHeight={50}
                  header
                  itemsRenderer={i => i}
                  items={state.ticketByMonthStatItems}
                />
              </GraphCard>
            </div>
            <div style={{ marginBottom: 50 }}>
              <GraphCard>
                <MonthlyAmountHistogram
                  title={_tg('tefps.dashboard.subscriptionStats.demandsOrigin')}
                  configs={[FO_APPLICATION_CONFIG(), BO_APPLICATION_CONFIG()]}
                  data={
                    state.apiData
                      .monthlyApplicationSource as MonthlyAmountHistogramData
                  }
                  unit={undefined}
                />
              </GraphCard>
            </div>
            <div style={{ marginBottom: 50 }}>
              <GraphCard>
                <MonthlyAmountHistogram
                  title={_tg(
                    'tefps.dashboard.subscriptionStats.demandsDecisions'
                  )}
                  configs={[
                    ACCEPTED_APPLICATION_CONFIG(),
                    REJECTED_APPLICATION_CONFIG(),
                  ]}
                  data={
                    state.apiData
                      .monthlyApplicationDecision as MonthlyAmountHistogramData
                  }
                  unit={undefined}
                />
              </GraphCard>
            </div>
            <div style={{ marginBottom: 50 }}>
              <GraphCard>
                <MonthlyAmountHistogram
                  title={_tg('tefps.dashboard.finance.finance.monthlyIncome')}
                  configs={[
                    SUBSCRIPTION_PAYMENT_CONFIG(),
                    TICKET_PAYMENT_CONFIG(),
                  ]}
                  data={
                    state.apiData.monthlyPayments as MonthlyAmountHistogramData
                  }
                  unit={EUR_UNIT}
                />
              </GraphCard>
            </div>
          </div>
        )}
      </div>
    </Content>
  );
};

export default SubscriptionsStats;
