import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import Sidebar from 'commons/SidebarV2';
import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import Dates from 'commons/SidebarV2/Components/Dates';
import Times from 'commons/SidebarV2/Components/Times';
import Input from 'commons/SidebarV2/Components/Input';
import { translateDayOptions } from 'commons/Days';
import { KeyBucketDTO } from 'api/commonTypes';
import { fetchOrganizations } from 'api/organizations';
import { fetchMatchingAgents } from 'api/accounts';
import { AgentIdAndNameDTO } from '@cvfm-front/tefps-types';
import { InternalApiState } from 'api/duck';

import { MapFilters } from './types';
import {
  getStrategyFiltersState,
  initialFilters,
  strategyFiltersChanged as setFilters,
} from './duck';
import {
  DEVICE_TYPE_FILTER_OPTIONS,
  FPS_FROM_CONTROL_FILTER_OPTIONS,
  STATUSES_FILTER_OPTIONS,
} from './utils';

import { DISPLAY_PATROL_ZONES, DISPLAY_ZONES } from '.';

const { _tg } = window.loadTranslations(__filename);

type ReduxStateProps = {
  filters: MapFilters;
};

type ReduxDispatchProps = {
  updateFilters: (newFilters: MapFilters) => void;
};

type Props = {
  totalHits: number;
  facetings:
    | {
        [key: string]: Array<KeyBucketDTO>;
      }
    | null
    | undefined;
  displayZones: boolean;
  displayPatrolZones: boolean;
  toggleDisplayZone: (id: string, filters: Set<string>) => void;
};

const ControlMapFilters = ({
  totalHits,
  facetings,
  displayZones,
  displayPatrolZones,
  toggleDisplayZone,
  filters,
  updateFilters,
}: Props & ReduxStateProps & ReduxDispatchProps): JSX.Element => {
  const [organizationOptions, setOrganizationOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);
  const [agentSearchOptions, setAgentSearchOptions] = useState<
    Array<{ id: string; name: string }>
  >([]);

  const displayZonesFilters = useMemo(() => {
    const dzf = new Set<string>();
    if (displayZones) {
      dzf.add(DISPLAY_ZONES);
    }
    if (displayPatrolZones) {
      dzf.add(DISPLAY_PATROL_ZONES);
    }
    return dzf;
  }, [displayZones, displayPatrolZones]);

  const onChange = (id: string, value: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    updateFilters({ ...filters, [id]: value });
  };

  const fetchOrganizationsAndBuildOptions = async () => {
    const organizations = (await fetchOrganizations(false, 'fps')) || [];
    const organizationIds = new Set();
    const newOrganizationOptions = organizations.map(organization => {
      if (filters.organizationIds.has(organization.organizationId)) {
        organizationIds.add(organization.organizationId);
      }
      return {
        value: organization.organizationId,
        label: organization.name,
      };
    });

    setOrganizationOptions(newOrganizationOptions);
    // Apply all organization ids to the filters
    onChange('organizationIds', organizationIds);
  };

  const fetchAgentsAndGenerateOptions = async (
    agentFilter: string | null | undefined
  ) => {
    let agents: Array<AgentIdAndNameDTO> = [];
    if (agentFilter && agentFilter.length >= 3) {
      agents = (await fetchMatchingAgents(agentFilter)).matchingAgents;
    }

    const newAgentSearchOptions = agents.map(({ id, fullName }) => ({
      id,
      name: fullName,
    }));
    setAgentSearchOptions(newAgentSearchOptions);
  };

  const resetFilters = () => {
    updateFilters({
      ...initialFilters(),
      organizationIds: new Set(
        (organizationOptions || []).map(({ value }) => value)
      ),
      agentId: undefined,
    });
  };

  useEffect(() => {
    void fetchAgentsAndGenerateOptions(null);
    void fetchOrganizationsAndBuildOptions();
  }, []);

  return (
    <Sidebar>
      <BarTitle resetFilters={resetFilters} />
      <HitsCounter hits={totalHits} />
      <Checkboxes
        id="display"
        title={_tg('tefps.filters.strategy.displayZones')}
        options={[
          { value: DISPLAY_ZONES, label: _tg('field.address.zone_plural') },
          {
            value: DISPLAY_PATROL_ZONES,
            label: _tg('field.address.patrolZone_plural'),
          },
        ]}
        filters={displayZonesFilters}
        onChange={toggleDisplayZone}
      />
      <Checkboxes
        id="statementDaysOfWeek"
        title={_tg('tefps.filters.misc.statementDaysOfWeek')}
        options={translateDayOptions()}
        filters={filters.statementDaysOfWeek}
        onChange={onChange}
        faceting={facetings ? facetings.statementDaysOfWeek : null}
      />
      <Checkboxes
        id="organizationIds"
        title={_tg('tefps.filters.misc.organization')}
        options={organizationOptions}
        filters={filters.organizationIds}
        onChange={onChange}
        faceting={facetings ? facetings.organizationIds : null}
      />
      <Checkboxes
        id="controlStatuses"
        title={_tg('tefps.filters.strategy.controlStatus')}
        options={STATUSES_FILTER_OPTIONS()}
        filters={filters.controlStatuses}
        onChange={onChange}
        faceting={facetings ? facetings.controlStatuses : null}
      />
      <Checkboxes
        id="esControlDeviceTypes"
        title={_tg('tefps.filters.strategy.esControlDeviceTypes')}
        options={DEVICE_TYPE_FILTER_OPTIONS()}
        filters={filters.esControlDeviceTypes}
        onChange={onChange}
        faceting={facetings ? facetings.esControlDeviceTypes : null}
      />
      <Checkboxes
        id="fpsCreationStatuses"
        title={_tg('tefps.filters.strategy.fpsCreationStatuses')}
        options={FPS_FROM_CONTROL_FILTER_OPTIONS()}
        filters={filters.fpsCreationStatuses}
        onChange={onChange}
        faceting={facetings ? facetings.fpsCreationStatuses : null}
      />
      <Dates
        id="dates"
        title={_tg('field.date.date_plural')}
        dates={filters.dates}
        onChange={onChange}
        periodSelectionEnabled
      />
      <Times
        id="times"
        title={_tg('field.date.hours')}
        times={filters.times}
        onChange={onChange}
      />
      <Autocomplete
        id="agentId"
        title={_tg('field.agent.idOrName')}
        options={agentSearchOptions}
        onChange={onChange}
        onAutocomplete={fetchAgentsAndGenerateOptions}
        search={filters.agentId}
      />
      <Input
        id="fineId"
        title={_tg('tefps.filters.misc.fpsOrFpsmIds.title')}
        placeholder={_tg('tefps.filters.misc.fpsOrFpsmIds.placeholder')}
        onChange={onChange}
        value={filters.fineId || ''}
      />
      <Input
        id="controlId"
        title={_tg('field.control.controlId')}
        placeholder={_tg('tefps.filters.misc.control.placeholder')}
        onChange={onChange}
        value={filters.controlId || ''}
      />
      <Input
        id="plate"
        title={_tg('field.vehicle.licensePlate')}
        placeholder={_tg('tefps.filters.misc.plates.placeholder')}
        onChange={onChange}
        value={filters.plate || ''}
      />
    </Sidebar>
  );
};

export default connect(
  (state: InternalApiState): ReduxStateProps => {
    const filters = getStrategyFiltersState(state);
    return {
      filters,
    };
  },
  (dispatch): ReduxDispatchProps => ({
    updateFilters: (filters: MapFilters) => dispatch(setFilters(filters)),
  })
)(ControlMapFilters);
