import moment from 'moment';

import { FilterDate } from 'commons/SidebarV2/Components/Dates';
import { SortParameters } from 'api/commonTypes';
import { StatsAgentsTepvSearchFiltersParam } from 'Dashboard/pv/Reports/types';

export const EXPORT_COL = [
  { key: 'name', label: "Nom de l'agent", checked: true },
  { key: 'nbrOfGpv', label: "Nombre d'infractions cas A", checked: true },
  { key: 'lastGpv', label: 'Date de la dernière infraction cas A' },
];

export const EXPORT_COL_ORG = [
  { key: 'name', label: "Nom de l'organisation", checked: true },
  { key: 'count', label: "Nombre d'infractions cas A", checked: true },
  { key: 'lastGpv', label: 'Date de la dernière infraction cas A' },
];

const gpvColumns = ['agentName', 'nbrOfGpv', 'lastGpv'];
const gpvColumnsOrg = ['orgName', 'nbrOfGpv', 'lastGpv'];

function getSortFieldText(sortFieldNumber: number) {
  return gpvColumns[sortFieldNumber];
}

function getSortFieldTextOrg(sortField: number) {
  return gpvColumnsOrg[sortField];
}

export const filtersToRequest = (
  period: FilterDate,
  sort: SortParameters,
  type: string
): StatsAgentsTepvSearchFiltersParam => {
  const { from, to } = period;
  return {
    statementStartDatetime: from ? moment(from).toISOString() : undefined,
    statementEndDatetime: to ? moment(to).toISOString() : undefined,
    sortOrder: sort.increasingOrder ? 'ASC' : 'DESC',
    sortField:
      type === 'agent'
        ? getSortFieldText(sort.sortField)
        : getSortFieldTextOrg(sort.sortField),
  };
};
