import moment from 'moment';

import { ReduxDashboardState } from 'Dashboard/duck';

import { PeriodFilter } from './types';

const OFFICIAL_REPORT_PERIOD_CHANGED = 'OFFICIAL_REPORT_PERIOD_CHANGED';
const PAGE_ID_CHANGED = 'PAGE_ID_CHANGED';

type Action = {
  type: string;
  period?: PeriodFilter;
  pageId?: number;
};

export type State = PeriodFilter & { pageId: number };

const INITIAL_SATE: State = {
  start: moment().startOf('year'),
  end: moment().endOf('year'),
  pageId: 0,
};

export default function reportReducer(
  state: State = INITIAL_SATE,
  action: Action
): State {
  switch (action.type) {
    case OFFICIAL_REPORT_PERIOD_CHANGED: {
      const { period } = action;
      return {
        ...state,
        ...period,
      };
    }
    case PAGE_ID_CHANGED: {
      const { pageId } = action;
      return {
        ...state,
        pageId: pageId as number,
      };
    }
    default:
      return state;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getReportState(state: any): State {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return (state.dashboard as ReduxDashboardState).report;
}

export function officialReportPeriodChanged(
  period: PeriodFilter
): { type: typeof OFFICIAL_REPORT_PERIOD_CHANGED; period: PeriodFilter } {
  return {
    type: OFFICIAL_REPORT_PERIOD_CHANGED,
    period,
  };
}

export function reportPageChanged(
  pageId: number
): {
  type: typeof PAGE_ID_CHANGED;
  pageId: number;
} {
  return {
    type: PAGE_ID_CHANGED,
    pageId,
  };
}
