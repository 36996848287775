import React, { CSSProperties, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Checkbox from 'material-ui/Checkbox';

import { isValidIP, validateMandatoryInput } from 'commons/Validators';
import BoButton from 'facade/BoButton';
import NotificationService from '@cvfm-front/commons-services/build/NotificationService';
import { patchCity } from 'api/cvfm-core-config';
import { InternalApiState } from 'api/duck';
import Content from 'commons/Content';
import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItemWrapper,
  DataBoxWithName,
} from 'commons/DataBox';
import { fetchConfig, getConfigState } from 'config/duck';
import {
  Camera,
  ParkingConfigurationDTO,
  ParkingConfigurationPatchDTO,
} from '@cvfm-front/tefps-types';
import { InteractiveListInput } from '@cvfm-front/tefps-ui';
import {
  getCityCamerasConfig,
  updateCityCamerasConfig,
} from 'api/cvfm-core-parking/CityCamerasConfigurationAPI';
import NumberField from 'commons/NumberField';

import CameraTable from './CameraTable';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_HEADER_LEFT: CSSProperties = {
  fontWeight: 'bold',
  marginLeft: 30,
};

const STYLE_DATABOX = { width: '95%', margin: '20px auto 0' };

interface Props {
  parkingConfigurationDTO: ParkingConfigurationDTO;
  reloadConfig: () => void;
}

const ParkingConfiguration = ({
  parkingConfigurationDTO,
  reloadConfig,
}: Props) => {
  const [dto, setDTO] = useState<ParkingConfigurationPatchDTO>({
    ...parkingConfigurationDTO,
  });
  const [cloneCameraConfigLines, setCloneCameraConfigLines] = useState<
    Array<Camera>
  >([]);
  const [cameraConfigLines, setCameraConfigLines] = useState<Array<Camera>>([]);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  // Compute hasChanges every time dto change
  useEffect(() => {
    setHasChanges(
      dto.billPrice !== parkingConfigurationDTO.billPrice ||
        dto.parkDuration !== parkingConfigurationDTO.parkDuration ||
        dto.parkingRightCreationActivated !==
          parkingConfigurationDTO.parkingRightCreationActivated ||
        dto.mediaUploadActivated !==
          parkingConfigurationDTO.mediaUploadActivated ||
        dto.controlActivated !== parkingConfigurationDTO.controlActivated ||
        dto.demoModeActivated !== parkingConfigurationDTO.demoModeActivated ||
        dto.ipWhiteList !== parkingConfigurationDTO.ipWhiteList ||
        dto.siteCode !== parkingConfigurationDTO.siteCode ||
        cloneCameraConfigLines !== cameraConfigLines
    );
  }, [dto, parkingConfigurationDTO, cameraConfigLines]);

  const onFormChange = (key: keyof ParkingConfigurationPatchDTO) => (
    newValue: number
  ) => {
    setDTO({ ...dto, [key]: newValue });
  };

  const onCheck = (
    event: React.MouseEvent<Record<string, unknown>, MouseEvent>,
    checked: boolean
  ) => {
    const key = event.currentTarget.id as string;

    setDTO({ ...dto, [key]: checked, demoModeActivated: false });
  };

  // check or uncheck demoModeActivated and uncheck other tasks
  const onCheckDemoMode = (
    event: React.MouseEvent<Record<string, unknown>, MouseEvent>,
    checked: boolean
  ) => {
    setDTO({
      ...dto,
      demoModeActivated: checked,
      parkingRightCreationActivated: false,
      mediaUploadActivated: false,
      controlActivated: false,
    });
  };

  const submit = async () => {
    try {
      await patchCity({
        path: '/parkingConfiguration',
        op: 'REPLACE',
        value: { ...dto },
      });
      await updateCityCamerasConfig(cameraConfigLines);
      NotificationService.pushNotification({
        id: 'parking-configuration-success',
        message: _t('notification.success'),
        type: 'success',
      });
      reloadConfig();
    } catch (e) {
      NotificationService.pushNotification({
        id: 'parking-configuration-error',
        message: e.json.message,
        type: 'error',
      });
    }
  };

  const cancel = () => {
    setDTO({
      ...parkingConfigurationDTO,
    });
    setCameraConfigLines(cloneCameraConfigLines);
    setHasChanges(false);
  };

  const getCityConfig = async () => {
    const cameras: Array<Camera> = await getCityCamerasConfig();
    setCloneCameraConfigLines(cameras);
    setCameraConfigLines(cameras);
  };

  const onIPListChange = (newIpList: Array<string>): void => {
    setDTO({ ...dto, ipWhiteList: newIpList });
  };

  const onSiteCodeChange = (newSiteCode: Array<string>): void => {
    setDTO({ ...dto, siteCode: newSiteCode });
  };

  useEffect(() => {
    getCityConfig();
  }, []);

  return (
    <Content>
      <DataBox panel style={STYLE_DATABOX}>
        <DataBoxHeader>
          <div style={STYLE_HEADER_LEFT}>{_t('title')}</div>
        </DataBoxHeader>

        <DataBoxContent>
          {DataBoxWithName({
            name: _t('billPrice'),
            node: (
              <NumberField
                name="billPrice"
                id="billPrice"
                type="number"
                value={dto.billPrice}
                defaultValue={2000}
                onChange={onFormChange('billPrice')}
              />
            ),
          })}

          {DataBoxWithName({
            name: _t('parkDuration'),
            node: (
              <NumberField
                name="parkDuration"
                id="parkDuration"
                type="number"
                value={dto.parkDuration}
                defaultValue={60}
                onChange={onFormChange('parkDuration')}
              />
            ),
          })}

          {DataBoxWithName({
            name: _t('mediaUploadTask'),
            node: (
              <Checkbox
                id="mediaUploadActivated"
                checked={dto.mediaUploadActivated}
                onCheck={onCheck}
              />
            ),
          })}

          {DataBoxWithName({
            name: _t('parkingRightTask'),
            node: (
              <Checkbox
                id="parkingRightCreationActivated"
                checked={dto.parkingRightCreationActivated}
                onCheck={onCheck}
              />
            ),
          })}

          {DataBoxWithName({
            name: _t('controlTask'),
            node: (
              <Checkbox
                id="controlActivated"
                checked={dto.controlActivated}
                onCheck={onCheck}
              />
            ),
          })}

          {DataBoxWithName({
            name: _t('demoModeTask'),
            node: (
              <Checkbox
                id="demoModeActivated"
                checked={dto.demoModeActivated}
                onCheck={onCheckDemoMode}
              />
            ),
          })}

          {DataBoxWithName({
            name: _t('ipWhiteList'),
            node: (
              <InteractiveListInput
                list={dto.ipWhiteList}
                onListChange={onIPListChange}
                validator={isValidIP}
                errorMessage={_t('invalidIp')}
              />
            ),
          })}

          {DataBoxWithName({
            name: _t('siteCode'),
            node: (
              <InteractiveListInput
                list={dto.siteCode}
                onListChange={onSiteCodeChange}
                validator={(value: string) => {
                  return !validateMandatoryInput(value);
                }}
                errorMessage={_t('invalidSiteCode')}
              />
            ),
          })}

          {DataBoxWithName({
            name: _t('camerasConfiguration'),
            node: (
              <CameraTable
                configLines={cameraConfigLines}
                setConfigLines={setCameraConfigLines}
              />
            ),
          })}

          <DataBoxItemWrapper>
            <BoButton
              label={_tg('action.save_1')}
              primary
              disabled={!hasChanges}
              onClick={submit}
            />
            <BoButton
              label={_tg('action.cancel')}
              disabled={!hasChanges}
              onClick={cancel}
              secondary
            />
          </DataBoxItemWrapper>
        </DataBoxContent>
      </DataBox>
    </Content>
  );
};

function mapStateToProps(state: InternalApiState) {
  const { parkingConfigurationDTO } = getConfigState(state);

  return { parkingConfigurationDTO };
}

function mapDispatchToProps(dispatch: any) {
  return { reloadConfig: () => dispatch(fetchConfig()) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ParkingConfiguration);
