import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import {
  Link,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import MissNotFound from 'commons/MissNotFound';
import { isDisplayed } from 'commons/helpers/ModulesConfiguration';
import { BKG_CYAN, TXT_BLACK } from 'theme';
import { getConfigState } from 'config/duck';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import LapiReviewConfiguration from 'Configuration/LapiReviewConfiguration';

import PlanningConfiguration from './PlanningConfiguration';
import Rapo from './Rapo';
import WhiteList from './WhiteList';
import FpsConfiguration from './FpsConfiguration';
import ControlConfiguration from './ControlConfiguration';
import MailTemplateConfiguration from './MailTemplateConfiguration/MailTemplateConfiguration';
import SectorsConfiguration from './SectorsConfiguration';
import ControlViewConfiguration from './ControlViewConfiguration/ControlViewConfiguration';
import ParkingConfiguration from './ParkingConfiguration';
import HVConfiguration from './HV';
import KpiConfiguration from './Kpi';
import SubscriptionConfiguration from './SubscriptionConfiguration';

const { _t } = window.loadTranslations(__filename);

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  flexWrap: 'wrap',
  color: TXT_BLACK,
  width: '60%',
  margin: '0 auto',
};

const STYLE_TITLE: CSSProperties = {
  fontSize: 25,
  margin: 30,
};

const STYLE_MODULE: CSSProperties = {
  width: 500,
  height: 200,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  border: `1px solid ${BKG_CYAN}`,
  backgroundColor: 'white',
  margin: 30,
  fontWeight: 'bold',
  fontSize: 35,
  textAlign: 'center',
};

type ConfigurationHomeProps = {
  isSubscribersDisplayed: boolean;
  isRapoDisplayed: boolean;
  isFpsDisplayed: boolean;
  isPlannerDisplayed: boolean;
  isLapiReviewDisplayed: boolean;
  isControlDisplayed: boolean;
  isControlViewDisplayed: boolean;
  isParkingDisplayed: boolean;
  isFpsRefundDisplayed: boolean;
  isHVDisplayed: boolean;
  whiteListEnabled: boolean;
  userInfo: InternalAgent;
};

const ConfigurationHome = ({
  isSubscribersDisplayed,
  isRapoDisplayed,
  isFpsDisplayed,
  isPlannerDisplayed,
  isLapiReviewDisplayed,
  isControlDisplayed,
  isControlViewDisplayed,
  isParkingDisplayed,
  isFpsRefundDisplayed,
  isHVDisplayed,
  whiteListEnabled,
  userInfo,
}: ConfigurationHomeProps) => (
  <div style={{ height: '100%', fontFamily: 'Roboto' }}>
    <div style={STYLE_CONTAINER}>
      <span style={STYLE_TITLE}>{_t('element.headerText')}</span>
    </div>
    <div style={STYLE_CONTAINER}>
      {userInfo.rights.includes('TEAM_READ') && isPlannerDisplayed && (
        <Link to="/configuration/planning" style={STYLE_MODULE}>
          {_t('element.linkPlanning')}
        </Link>
      )}
      {userInfo.rights.includes('CONTROL_READ') && isControlDisplayed && (
        <Link to="/configuration/control" style={STYLE_MODULE}>
          {_t('element.linkControl')}
        </Link>
      )}
      {userInfo.rights.includes('CONTROL_FOLLOWER_READ') &&
        isControlViewDisplayed && (
          <Link to="/configuration/controlView" style={STYLE_MODULE}>
            {_t('element.linkControlView')}
          </Link>
        )}
      {userInfo.rights.includes('BACKOFFICE_DASHBOARD_GRAPHICS') &&
        isControlDisplayed && (
          // TODO add a new right
          <Link to="/configuration/kpi" style={STYLE_MODULE}>
            {_t('element.linkKPI')}
          </Link>
        )}
      {userInfo.rights.includes('RAPO_ANSWER_ADMIN') && isRapoDisplayed && (
        <Link to="/configuration/rapo" style={STYLE_MODULE}>
          {_t('element.linkRapo')}
        </Link>
      )}
      {whiteListEnabled &&
        userInfo.rights.includes('FPS_ADMIN_WHITELIST') &&
        isFpsDisplayed && (
          <Link to="/configuration/whitelist" style={STYLE_MODULE}>
            {_t('element.linkWhitelist')}
          </Link>
        )}
      {userInfo.rights.includes('FPS_CONFIGURATION_WRITE') && isFpsDisplayed && (
        <Link to="/configuration/fpsconfiguration" style={STYLE_MODULE}>
          {_t('element.linkFpsConfig')}
        </Link>
      )}
      {((userInfo.rights.includes('FPS_CONFIGURATION_WRITE') &&
        isFpsRefundDisplayed) ||
        (userInfo.rights.includes('RAPO_CONFIGURATION_WRITE') &&
          isRapoDisplayed) ||
        (userInfo.rights.includes('SUBSCRIBER_WRITE') &&
          isSubscribersDisplayed)) && (
        <Link
          to="/configuration/mail-template-configuration"
          style={STYLE_MODULE}
        >
          {_t('element.linkMailTemplate')}
        </Link>
      )}
      {userInfo.rights.includes('LAPI_REVIEW_CONFIGURATION_WRITE') &&
        isLapiReviewDisplayed && (
          <Link
            to="/configuration/lapi-review-configuration"
            style={STYLE_MODULE}
          >
            {_t('element.linkLapiReview')}
          </Link>
        )}
      {userInfo.rights.includes('LAPI_REVIEW_CONFIGURATION_WRITE') &&
        isLapiReviewDisplayed && (
          <Link to="/configuration/sectors" style={STYLE_MODULE}>
            {_t('element.linkSectors')}
          </Link>
        )}
      {userInfo.rights.includes('PARKING_CONFIGURATION_WRITE') &&
        isParkingDisplayed && (
          <Link to="/configuration/parking" style={STYLE_MODULE}>
            {_t('element.linkParking')}
          </Link>
        )}
      {userInfo.rights.includes('PARKING_METER_WRITE') && isHVDisplayed && (
        <Link to="/configuration/hv" style={STYLE_MODULE}>
          {_t('element.linkHV')}
        </Link>
      )}
      {userInfo.rights.includes('ORDER_WRITE') && isSubscribersDisplayed && (
        <Link to="/configuration/subscription" style={STYLE_MODULE}>
          {_t('element.linkSubscription')}
        </Link>
      )}
    </div>
  </div>
);

const ConfigHome = connect(state => {
  const {
    modulesConfiguration: {
      subscribers,
      rapo,
      fps,
      planner,
      lapiReview,
      control,
      controlView,
      parking,
      parkingMeter,
    },
    fpsRefundEnabled,
    whiteListEnabled,
  } = getConfigState(state);
  const { userInfo } = getApiState(state);
  return {
    isSubscribersDisplayed: isDisplayed(subscribers),
    isRapoDisplayed: isDisplayed(rapo),
    isFpsDisplayed: isDisplayed(fps),
    isPlannerDisplayed: isDisplayed(planner),
    isLapiReviewDisplayed: isDisplayed(lapiReview),
    isControlDisplayed: isDisplayed(control),
    isControlViewDisplayed: isDisplayed(controlView),
    isParkingDisplayed: isDisplayed(parking),
    isFpsRefundDisplayed: fpsRefundEnabled,
    isHVDisplayed: isDisplayed(parkingMeter),
    whiteListEnabled,
    userInfo,
  };
})(ConfigurationHome);

const Configuration = ({
  match,
  isSubscribersDisplayed,
  isRapoDisplayed,
  isFpsDisplayed,
  isPlannerDisplayed,
  isLapiReviewDisplayed,
  isControlViewDisplayed,
  isParkingDisplayed,
  isHVDisplayed,
  isControlDisplayed,
}: RouteComponentProps & {
  isSubscribersDisplayed: boolean;
  isRapoDisplayed: boolean;
  isParkingDisplayed: boolean;
  isFpsDisplayed: boolean;
  isPlannerDisplayed: boolean;
  isLapiReviewDisplayed: boolean;
  isControlViewDisplayed: boolean;
  isHVDisplayed: boolean;
  isControlDisplayed: boolean;
}) => (
  <div style={{ height: '100%' }}>
    <Switch>
      <Route path={`${match.url}`} exact component={ConfigHome} />
      <Route
        path={`${match.url}/control`}
        exact
        component={ControlConfiguration}
      />
      {isPlannerDisplayed && (
        <Route
          path={`${match.url}/planning`}
          exact
          component={PlanningConfiguration}
        />
      )}
      {isRapoDisplayed && (
        <Route path={`${match.url}/rapo`} exact component={Rapo} />
      )}
      {isFpsDisplayed && (
        <Route path={`${match.url}/whitelist`} exact component={WhiteList} />
      )}
      {isFpsDisplayed && (
        <Route
          path={`${match.url}/fpsconfiguration`}
          exact
          component={FpsConfiguration}
        />
      )}
      <Route
        path={`${match.url}/mail-template-configuration`}
        exact
        component={MailTemplateConfiguration}
      />
      {isLapiReviewDisplayed && (
        <Route
          path={`${match.url}/lapi-review-configuration`}
          exact
          component={LapiReviewConfiguration}
        />
      )}
      {isLapiReviewDisplayed && (
        <Route
          path={`${match.url}/sectors`}
          exact
          component={SectorsConfiguration}
        />
      )}
      {isControlViewDisplayed && (
        <Route
          path={`${match.url}/controlView`}
          exact
          component={ControlViewConfiguration}
        />
      )}
      {isParkingDisplayed && (
        <Route
          path={`${match.url}/parking`}
          exact
          component={ParkingConfiguration}
        />
      )}
      {isHVDisplayed && (
        <Route path={`${match.url}/hv`} exact component={HVConfiguration} />
      )}
      {isControlDisplayed && (
        <Route path={`${match.url}/kpi`} exact component={KpiConfiguration} />
      )}
      {isSubscribersDisplayed && (
        <Route
          path={`${match.url}/subscription`}
          exact
          component={SubscriptionConfiguration}
        />
      )}
      <MissNotFound />
    </Switch>
  </div>
);

const ConnectedConfiguration = connect(state => {
  const {
    modulesConfiguration: {
      subscribers,
      rapo,
      fps,
      planner,
      lapiReview,
      controlView,
      control,
      parking,
      parkingMeter,
    },
  } = getConfigState(state);
  return {
    isSubscribersDisplayed: isDisplayed(subscribers),
    isRapoDisplayed: isDisplayed(rapo),
    isFpsDisplayed: isDisplayed(fps),
    isPlannerDisplayed: isDisplayed(planner),
    isLapiReviewDisplayed: isDisplayed(lapiReview),
    isControlViewDisplayed: isDisplayed(controlView),
    isParkingDisplayed: isDisplayed(parking),
    isControlDisplayed: isDisplayed(control),
    isHVDisplayed: isDisplayed(parkingMeter),
  };
})(Configuration);

export default withRouter(ConnectedConfiguration);
