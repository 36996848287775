import { Filters } from 'commons/types/filterbar';
import { InitialOrganization, RegieProperties } from 'config/duck';

import {
  ccspClaimStatusFilter,
  emailStatusFilter,
  fpsIdsFilter,
  fpsStatusesFilter,
  initialOrganizationFilter,
  lastPaymentDatetimeFilter,
  lastUpdateDatetimeFilter,
  paymentChannelFitler,
  paymentStatusesFilter,
  plateFilter,
  postalAddressStatusFilter,
  postalLetterStatusFilter,
  rapoClaimStatusFilter,
  refundAmountFilter,
  refundStatusFilter,
  regiesFilter,
  statementDatetimeFitler,
} from './Filters';

const buildRegieOptions = (regiesProperties: RegieProperties): any => {
  // Do not build regie options if not regie properties or if only one regie
  if (!regiesProperties || regiesProperties.properties.length <= 1) {
    return undefined;
  }

  return regiesProperties.properties.map(({ id, name }) => ({
    value: id,
    label: name,
  }));
};

const initFpsRefundFilters = (
  regiesOptions: RegieProperties,
  initialOrganizationsConfigurations: Array<InitialOrganization>
): Filters => {
  const filters: Filters = {
    statementDatetime: statementDatetimeFitler(),
    rootFpsIds: fpsIdsFilter(),
    plate: plateFilter(),
    fpsRefundStatuses: refundStatusFilter(),
    fpsStatuses: fpsStatusesFilter(),
    emailAddressStatuses: emailStatusFilter(),
    postalAddressStatuses: postalAddressStatusFilter(),
    postalLetterStatuses: postalLetterStatusFilter(),
    paymentStatuses: paymentStatusesFilter(),
    rapoClaimStatuses: rapoClaimStatusFilter(),
    ccspClaimStatuses: ccspClaimStatusFilter(),
    refundPriceRange: refundAmountFilter(),
    lastPaymentDatetime: lastPaymentDatetimeFilter(),
    lastUpdateDatetime: lastUpdateDatetimeFilter(),
    dgfipPayment: paymentChannelFitler(),
    regieIds: regiesFilter(buildRegieOptions(regiesOptions)),
  };

  if (
    initialOrganizationsConfigurations &&
    initialOrganizationsConfigurations.length > 0
  ) {
    filters.initialOrganizationShortId = initialOrganizationFilter(
      initialOrganizationsConfigurations
    );
  }

  return filters;
};

export default initFpsRefundFilters;
