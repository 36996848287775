import { ChartData, ChartDataset, ChartType } from 'chart.js';

import {
  ChartCriteria,
  ChartDataSource,
  ChartDecomposition,
  ChartLeafLabels,
  ChartLongBucketDTO,
  ChartPipeline,
  ChartStatisticsResult,
  ControlChartFilterParam,
  FpsChartFiltersParam,
  ParkingRightsChartRequestFilters,
  RapoChartFiltersParam,
} from '@cvfm-front/tefps-types';
import { KeyLabel, ValueLabel } from '@cvfm-front/commons-types';
import { CHART_THEME } from 'theme';
import {
  ControlChartCriteria,
  FpsChartCriteria,
  RapoChartCriteria,
  TicketChartCriteria,
} from 'Dashboard/fps/Graphics/types';
import { searchParkingRightStatistics } from 'api/cvfm-core-tv/ParkingRightStatisticsApi';
import { filtersToRequest as ticketFiltersToRequest } from 'tefps/Tickets/List/utils';
import { searchControlStatistics } from 'api/cvfm-core-control/ControlStatisticsApi';
import { filtersToRequest as ControlFiltersToRequest } from 'tefps/Control/List/utils';
import { fetchZoning } from 'api/pricing';
import { FETCH_LIGHT_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import { buildZoneSelect, Zone } from 'commons/Utils/zoneUtils';
import { fetchClientAppTV } from 'api/clientapp';
import { buildClientAppOptions } from 'commons/Utils/clientAppUtils';
import { ExemptionReason } from 'config/duck';
import { formatCtsPrice } from 'commons/Price';
import { filtersToRequest as FpsFiltersToRequest } from 'tefps/Fps/List/utils';
import { searchFpsChartStatistics } from 'api/cvfm-core-fps/FpsStatisticsApi';
import { searchRapoChartStatistics } from 'api/cvfm-core-rapo/StatisticsController';
import { addDayLabelToString } from 'commons/Utils/dateUtil';
import { filtersToRequest as RapoFiltersToRequest } from 'tefps/RecoursesV2/utils/recourseUtils';

import ChartFilterService from './service';

const { _t } = window.loadTranslations(__filename);

function findAllKeys(results: Array<ChartLongBucketDTO>) {
  const firstKeys: Array<string> = [];
  const secondKeys: Array<string> = [];

  results.forEach(firstDataKey => {
    firstKeys.push(firstDataKey.key);
    firstDataKey.subResults?.forEach(subResult => {
      if (!secondKeys.find(elem => elem === subResult.key)) {
        secondKeys.push(subResult.key);
      }
    });
  });

  return [firstKeys, secondKeys];
}

function buildUniDimentionnalMatrice(results: Array<ChartLongBucketDTO>) {
  const matrice = new Array<Array<number>>();
  results.forEach((data, index) => {
    const dataLine = new Array<number>();
    dataLine[index] = data.value;
    matrice.push(dataLine);
  });
  return matrice;
}

function buildBiDimentionnalMatrice(
  results: Array<ChartLongBucketDTO>,
  firstLabels: Array<string>,
  secondLabels: Array<string>
) {
  const matrice = new Array<Array<number>>();
  secondLabels.forEach(secondLabel => {
    const dataLine = new Array<number>();
    results.forEach(firstData => {
      dataLine.push(
        firstData.subResults?.find(elem => elem.key === secondLabel)?.value || 0
      );
    });
    matrice.push(dataLine);
  });
  return matrice;
}

function buildDatasetsMatrice(
  results: Array<ChartLongBucketDTO>,
  firstLabels: Array<string>,
  secondLabels: Array<string>
): Array<Array<number>> {
  return secondLabels.length > 0
    ? buildBiDimentionnalMatrice(results, firstLabels, secondLabels)
    : buildUniDimentionnalMatrice(results);
}

function buildOneDataSetsFromMatrice(
  datasetsMatrice: Array<Array<number>>
): Array<ChartDataset> {
  const data: Array<ChartDataset> = [];
  data.push({
    label: 'valeur',
    data: datasetsMatrice.map((matrice, index) => matrice[index]),
    backgroundColor: datasetsMatrice.map(
      (matrice, index) => CHART_THEME[index % CHART_THEME.length].transparant
    ),
    borderColor: datasetsMatrice.map(
      (matrice, index) => CHART_THEME[index % CHART_THEME.length].opaque
    ),
  });
  return data;
}

function buildDatasetsFromMatrice(
  chartType: ChartType,
  datasetsMatrice: Array<Array<number>>,
  labels: string[],
  forceSingleDataset?: boolean
): Array<ChartDataset> {
  if (forceSingleDataset) {
    return buildOneDataSetsFromMatrice(datasetsMatrice);
  }

  const data: Array<ChartDataset> = [];
  labels.forEach((label, labelIndex) => {
    data.push({
      label,
      data: datasetsMatrice[labelIndex],
      backgroundColor: [
        CHART_THEME[labelIndex % CHART_THEME.length].transparant,
      ],
      borderColor: [CHART_THEME[labelIndex % CHART_THEME.length].opaque],
    });
  });
  return data;
}

export function buildDataFromResult(
  results: Array<ChartLongBucketDTO>,
  chartType: ChartType,
  forceSingleDataset?: boolean
): ChartData {
  const [firstLabels, secondLabels] = findAllKeys(results);
  const datasetsMatrice: Array<Array<number>> = buildDatasetsMatrice(
    results,
    firstLabels,
    secondLabels
  );
  return {
    labels: firstLabels,
    datasets: buildDatasetsFromMatrice(
      chartType,
      datasetsMatrice,
      secondLabels.length > 0 ? secondLabels : firstLabels,
      forceSingleDataset
    ),
  };
}

function getLabelorDefault(key: string, dataLabel: ChartLeafLabels): string {
  if (dataLabel === ChartLeafLabels.PRICE_RANGE) {
    return `${formatCtsPrice(parseInt(key, 10))} - ${formatCtsPrice(
      parseInt(key, 10) + 499
    )}`;
  }
  if (dataLabel === ChartLeafLabels.HOUR_OF_DAY) {
    // the first parse removes leading "0"
    return `${parseInt(key, 10)}h-${parseInt(key, 10) + 1}h`;
  }
  if (dataLabel === ChartLeafLabels.PRICE) {
    return formatCtsPrice(parseInt(key, 10));
  }
  if (dataLabel === ChartLeafLabels.DAYS) {
    if (key === '0') {
      return '< 24h';
    }
    return addDayLabelToString(key);
  }
  const valueLabels = ChartFilterService.getLabel(dataLabel);
  let label: string | undefined;

  valueLabels?.forEach((pair: KeyLabel | ValueLabel) => {
    if (('value' in pair ? pair.value : pair.key) === key) {
      label = pair.label;
    }
  });
  return label || key;
}

export function replaceAllLabels(
  results: Array<ChartLongBucketDTO>,
  pipelines?: Array<ChartPipeline>
): void {
  if (!pipelines) {
    return;
  }
  const pipelinesCopy = Object.create(pipelines) as ChartPipeline[];
  const topLabel = pipelinesCopy.shift()?.fieldLabel;

  results.forEach(bucket => {
    if (topLabel) {
      bucket.key = getLabelorDefault(bucket.key, topLabel);
    }
    if (pipelinesCopy.length > 0 && bucket.subResults) {
      replaceAllLabels(bucket.subResults, pipelinesCopy);
    }
  });
}

export function multiplyAllValues(
  results: Array<ChartLongBucketDTO>,
  pipelines?: Array<ChartPipeline>
): void {
  if (!pipelines) {
    return;
  }
  pipelines.forEach(pipeline => {
    const multiplier = pipeline.multiplier ? pipeline.multiplier : 1;

    if (multiplier !== 1) {
      results.forEach(bucket => {
        bucket.value *= multiplier;
      });
    }
  });
}

export function chartFiltersToRequest(
  searchFilters:
    | TicketChartCriteria
    | ControlChartCriteria
    | FpsChartCriteria
    | RapoChartCriteria,
  request:
    | ParkingRightsChartRequestFilters
    | ControlChartFilterParam
    | FpsChartFiltersParam
    | RapoChartFiltersParam
): void {
  request.aggregationPipeline = searchFilters.aggregationPipeline;
}

export const defaultControlFilters: ControlChartCriteria = {
  esLAPIControlStates: new Set(),
  controlStatuses: new Set(),
  esControlDeviceTypes: new Set(),
  fpsCreationStatuses: new Set(),
  exemptionReasons: new Set(),
  tags: new Set(),
  lapiReviewFetchabilityFailReasons: new Set(),
  statementDatetime: {
    from: undefined,
    to: undefined,
  },
  times: {
    from: undefined,
    to: undefined,
  },
  controlId: undefined,
  fineId: undefined,
  zoneId: undefined,
  patrolZoneId: undefined,
  agentId: undefined,
  agentReviewId: undefined,
  terminalId: undefined,
  parkingSpaceId: undefined,
  plate: undefined,
  organizationIds: new Set(),
  organizationShortId: undefined,
  mediaStatuses: new Set(),
  qualityControlComparativeStates: new Set(),
  reviewReasons: new Set(),
  vehicleTypes: new Set(),
  presumedVehicleTypes: new Set(),
  hasParkingSpaceId: new Set(),
};

export const defaultTicketFilters: TicketChartCriteria = {
  ticketId: undefined,
  plate: undefined,
  clientAppIds: new Set(),
  price: {
    from: undefined,
    to: undefined,
  },
  creationDate: {
    from: undefined,
    to: undefined,
  },
  creationTime: {
    from: undefined,
    to: undefined,
  },
  startDate: {
    from: undefined,
    to: undefined,
  },
  startTime: {
    from: undefined,
    to: undefined,
  },
  endTime: {
    from: undefined,
    to: undefined,
  },
  endDate: {
    from: undefined,
    to: undefined,
  },
  duration: {
    from: undefined,
    to: undefined,
  },
  zoneId: undefined,
  vehicleCategories: new Set<string>(),
};

export function translateChartDataSource(
  chartDataSource: ChartDataSource
): string {
  switch (chartDataSource) {
    case ChartDataSource.RAPO:
      return _t('element.type.RAPO');
    case ChartDataSource.FPS:
      return _t('element.type.FPS');
    case ChartDataSource.TICKET:
      return _t('element.type.TICKET');
    case ChartDataSource.CONTROL:
      return _t('element.type.CONTROL');
    default:
      return _t('element.error');
  }
}

export function translateChartCriteria(chartCriteria: ChartCriteria): string {
  switch (chartCriteria) {
    case ChartCriteria.QUANTITY:
      return _t('element.criteria.QUANTITY');
    case ChartCriteria.PRICE:
      return _t('element.criteria.PRICE');
    case ChartCriteria.DURATION:
      return _t('element.criteria.DURATION');
    default:
      return _t('element.error');
  }
}

export function translateChartDecomposition(
  chartDecomposition: ChartDecomposition
): string {
  switch (chartDecomposition) {
    case ChartDecomposition.ZONE:
      return _t('element.decomposition.ZONE');
    case ChartDecomposition.PRICING_CATEGORY:
      return _t('element.decomposition.PRICING_CATEGORY');
    case ChartDecomposition.SOURCE:
      return _t('element.decomposition.SOURCE');
    case ChartDecomposition.CREATION_TIME:
      return _t('element.decomposition.CREATION_TIME');
    case ChartDecomposition.PRICE:
      return _t('element.decomposition.PRICE');
    case ChartDecomposition.DATE:
      return _t('element.decomposition.DATE');
    case ChartDecomposition.HOUR_OF_DAY:
      return _t('element.decomposition.HOUR');
    case ChartDecomposition.EXEMPTION_REASON:
      return _t('element.decomposition.EXEMPTION_REASON');
    case ChartDecomposition.SUBMIT_MODE:
      return _t('element.decomposition.SUBMIT_MODE');
    case ChartDecomposition.CONTROL_STATUS_CLOSED:
      return _t('element.decomposition.CONTROL_STATUS_CLOSED');
    case ChartDecomposition.PAYMENT_STATUS:
      return _t('element.decomposition.PAIEMENT_STATUS');
    case ChartDecomposition.ANTAI_ABANDONMENT_REASON:
      return _t('element.decomposition.ANTAI_ABANDONMENT_REASON');
    case ChartDecomposition.CREATION_DATE:
      return _t('element.decomposition.CREATION_DATE');
    case ChartDecomposition.STATUS:
      return _t('element.decomposition.STATUS');
    case ChartDecomposition.RAPO_REQUEST_REASON:
      return _t('element.decomposition.RAPO_REQUEST_REASON');
    case ChartDecomposition.DECISION:
      return _t('element.decomposition.DECISION');
    case ChartDecomposition.DAYS_OF_WEEK:
      return _t('element.decomposition.DAYS_OF_WEEK');
    case ChartDecomposition.AVERAGE_RECEPTION_TIME:
      return _t('element.decomposition.AVERAGE_RECEPTION_TIME');
    case ChartDecomposition.ORIGIN:
      return _t('element.decomposition.ORIGIN');
    case ChartDecomposition.HOURS:
      return _t('element.decomposition.HOURS');
    case ChartDecomposition.MINUTES:
      return _t('element.decomposition.MINUTES');
    case ChartDecomposition.WITH_FPS:
      return _t('element.decomposition.WITH_FPS');
    case ChartDecomposition.RAPO_RESPONSE_REASON:
      return _t('element.decomposition.RAPO_RESPONSE_REASON');
    default:
      return _t('element.error');
  }
}

export function generateRequestAndGetResult(
  dataSource: ChartDataSource,
  filters:
    | TicketChartCriteria
    | ControlChartCriteria
    | FpsChartCriteria
    | RapoChartCriteria
): Promise<ChartStatisticsResult> {
  let request:
    | ParkingRightsChartRequestFilters
    | ControlChartFilterParam
    | FpsChartFiltersParam
    | RapoChartFiltersParam;
  switch (dataSource) {
    case ChartDataSource.TICKET:
    default:
      request = ticketFiltersToRequest(filters as TicketChartCriteria);
      chartFiltersToRequest(filters, request);
      return searchParkingRightStatistics(
        request as ParkingRightsChartRequestFilters
      );
    case ChartDataSource.CONTROL:
      request = ControlFiltersToRequest(filters as ControlChartCriteria);
      chartFiltersToRequest(filters, request);
      return searchControlStatistics(request as ControlChartFilterParam);
    case ChartDataSource.FPS:
      request = FpsFiltersToRequest(filters as FpsChartCriteria);
      chartFiltersToRequest(filters, request);
      return searchFpsChartStatistics(request as FpsChartFiltersParam);
    case ChartDataSource.RAPO:
      request = RapoFiltersToRequest(filters as RapoChartCriteria);
      chartFiltersToRequest(filters, request);
      return searchRapoChartStatistics(request);
  }
}

export function additionalChartOptions(
  chartType?: ChartType
): Record<string, unknown> {
  if (!chartType) {
    return {};
  }
  if (chartType === 'bar') {
    return {
      scales: {
        x: {
          stacked: true,
        },
        y: {
          beginAtZero: true,
          stacked: true,
        },
      },
    };
  }
  return {};
}

export async function buildZonesLabels(): Promise<Zone[]> {
  const zoning = await fetchZoning(FETCH_LIGHT_ZONING_CONFIG);
  return buildZoneSelect(zoning.zones);
}

export function buildExemptionsLabels(
  exemptionReasonsConfigurations: ExemptionReason[]
): ValueLabel[] {
  return exemptionReasonsConfigurations.map(reason => ({
    value: reason.key,
    label: reason.label,
  }));
}

export async function fetchClientAppAndBuildOptions(): Promise<ValueLabel[]> {
  const clientApp = await fetchClientAppTV();
  return buildClientAppOptions(clientApp);
}
