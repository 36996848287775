import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import {
  Table,
  TableBody,
  TableHeader,
  TableHeaderColumn,
  TableRow,
  TableRowColumn,
} from 'material-ui/Table';
import Snackbar from 'material-ui/Snackbar';
import Checkbox from 'material-ui/Checkbox';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import MenuItem from 'material-ui/MenuItem';
import SelectField from 'material-ui/SelectField';
import IconButton from 'material-ui/IconButton';
import SettingsIcon from 'material-ui/svg-icons/action/assignment';
import EyeIcon from 'material-ui/svg-icons/image/remove-red-eye';
import CircularProgress from 'material-ui/CircularProgress';

import BoButton from 'facade/BoButton';
import { BKG_PINK, BRD_GREY, TXT_ERROR_RED } from 'theme';
import Content from 'commons/Content';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import AdvertisingModal from 'commons/AdvertisingModal';
import {
  RAPO_TEMPLATE_FIELDS_LIST,
  RapoTemplatingInfo,
} from 'commons/RapoTemplatingInfo';
import TextAreaWithHints from 'commons/TextAreaWithHints';
import { RapoMessageLengthChecker } from 'commons/RapoMessageLengthChecker';
import { CityOrganizationDTO } from 'api/organizations/types';
import { fetchOrganizations } from 'api/organizations';
import {
  fetchRapoChoiceReasonList,
  updateRapoChoiceReasonMail,
} from 'api/rapo-choice-reason';
import { fetchRapoAnswer, updateRapoAnswer } from 'api/rapo-answer';
import {
  RapoChoiceReasonDTO,
  RapoChoiceReasonList,
} from 'api/rapo-choice-reason/types';
import { RapoAnswerDTO } from 'api/rapo-answer/types';
import { getConfigState } from 'config/duck';
import { ImageDropZone, ImagePreview } from 'commons/ImagePreviewAndUpload';
import {
  fetchRapoConfiguration,
  upsertRapoConfiguration,
} from 'api/rapoConfiguration';
import { RapoConfigurationDTO } from '@cvfm-front/tefps-types';
import { getApiState } from 'api/duck';
import { previewResponseUrl } from 'api/recourse';
import { Gender } from 'api/recourse/types';
import { openNewAuthentifiedTab } from 'api/helpers';
import { getStatusLabel } from 'tefps/RecoursesV2/pages/Detail/helpers';
import NumberField from 'commons/NumberField';
import { TagsTypes } from 'api/tags';

import AdminTags from './AdminTags';

import 'commons/css/commons.css';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_LOGS_TITLE: CSSProperties = {
  padding: '0 30px',
  margin: '30px 0px 0px',
};

const STYLE_DIALOG_CONTENT: CSSProperties = {
  position: 'relative',
  maxWidth: 'none',
  transform: '',
};

const STYLE_DIALOG: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: 0,
};

const STYLE_BACKGROUND: CSSProperties = {
  width: '300px',
  height: '425px',
  border: `1px solid ${BRD_GREY}`,
};
const STYLE_HEADER_FOOTER_SIGNATURE: CSSProperties = {
  width: '90%',
  height: '90%',
};

// On prends un usager avec une adresse longue (7 lignes ici contre 4 bien souvent)
// Pour prendre en compte le cas le plus défavorable.
// Si l'adresse est plus courte, l'agent pourra alors ralonger la réponse lors de son édition
const translateMockRapoUser = () => {
  return {
    gender: 'MALE' as Gender,
    firstName: _t('mockRapoUser.firstName'),
    lastName: _t('mockRapoUser.lastName'),
    email: _t('mockRapoUser.email'),
    addressStreetType: _t('mockRapoUser.addressStreetType'),
    addressStreetName: _t('mockRapoUser.addressStreetName'),
    addressStreetNumber: _t('mockRapoUser.addressStreetNumber'),
    addressStreetNumberBis: _t('mockRapoUser.addressStreetNumberBis'),
    addressPostalCode: _t('mockRapoUser.addressPostalCode'),
    addressComplement: _t('mockRapoUser.addressComplement'),
    addressLocality: _t('mockRapoUser.addressLocality'),
    addressCountry: _t('mockRapoUser.addressCountry'),
    illegibleAddress: false,
  };
};

const STYLE_ERROR_MESSAGE: CSSProperties = {
  color: TXT_ERROR_RED,
  fontSize: 13,
  marginLeft: 16,
  marginRight: 'auto',
};

const ROW_STYLE: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  marginLeft: '20%',
  marginRight: '20%',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const FIT_CONTENT: CSSProperties = {
  width: 'fit-content',
};

type RapoState = {
  organizations: Array<CityOrganizationDTO>;
  rapoChoiceReasons: RapoChoiceReasonList | null | undefined;
  rapoAnswer: RapoAnswerDTO | null | undefined;
  selectedOrganizationId: string | null | undefined;
  error: Error | null | undefined;
  alert: string | null | undefined;
  open: boolean;
  savable: boolean;
  currentReason: RapoChoiceReasonDTO | null | undefined;
  validationErrorMessage: string | null | undefined;
  rapoConfiguration: RapoConfigurationDTO;
  originalRapoAnswer: RapoAnswerDTO | null | undefined;
};

const initialState = (): RapoState => ({
  rapoChoiceReasons: null,
  rapoAnswer: null,
  organizations: [],
  selectedOrganizationId: null,
  error: null,
  alert: '',
  open: false,
  savable: false,
  currentReason: null,
  validationErrorMessage: null,
  rapoConfiguration: {
    complementPeriod: undefined,
    letterComplementPeriod: undefined,
    tagsMandatory: false,
    allowRapoOverDeadline: true,
  },
  originalRapoAnswer: null,
});

type RapoProps = {
  organizationRapoAnswerEnabled: boolean;
  organizationRapoChoiceReasonEnabled: boolean;
  isCvfmRapoPartner: boolean;
  canWrite: boolean;
};

class Rapo extends React.Component<RapoProps, RapoState> {
  rapoMailPreviewRef: RapoMessageLengthChecker | undefined = undefined;

  constructor(props: RapoProps) {
    super(props);
    this.state = initialState();
  }

  componentDidMount() {
    void this.loadOrganizations();
    void this.loadRapoConfiguration();
    this.checkAllowToSubmit();
  }

  onChangeComment = (newValue: string) => {
    const { currentReason } = this.state;
    this.setState(
      {
        currentReason: {
          ...(currentReason as RapoChoiceReasonDTO),
          mail: newValue,
        },
      },
      this.checkAllowToSubmit
    );
  };

  onDropBackground = (files: Array<File>) =>
    this.onDrop(files, 'rapoAnswerPageBackground');
  onDropHeader = (files: Array<File>) => this.onDrop(files, 'rapoAnswerHeader');
  onDropFooter = (files: Array<File>) => this.onDrop(files, 'rapoAnswerFooter');
  onDropSignature = (files: Array<File>) =>
    this.onDrop(files, 'rapoAnswerSignature');

  onDeleteBackground = () => this.onDelete('rapoAnswerPageBackground');
  onDeleteHeader = () => this.onDelete('rapoAnswerHeader');
  onDeleteFooter = () => this.onDelete('rapoAnswerFooter');
  onDeleteSignature = () => this.onDelete('rapoAnswerSignature');
  onDelete = (field: string): void => {
    const { rapoAnswer } = this.state;
    this.setState({
      rapoAnswer: {
        ...(rapoAnswer as RapoAnswerDTO),
        [field]: null,
      },
      savable: true,
    });
  };

  onDrop = (files: Array<File>, field: string) => {
    // Read file content
    const reader = new FileReader();
    const { rapoAnswer } = this.state;
    reader.onload = () => {
      this.setState({
        rapoAnswer: {
          ...(rapoAnswer as RapoAnswerDTO),
          [field]: reader.result,
        },
        savable: true,
      });
    };
    reader.readAsDataURL(files[0]);
  };

  onComplementPeriodChange = (newValue: number | null | undefined) => {
    const { rapoConfiguration } = this.state;

    this.setState({
      rapoConfiguration: {
        ...rapoConfiguration,
        complementPeriod: newValue,
      },
    });
  };

  onLetterComplementPeriodChange = (newValue: number | null | undefined) => {
    const { rapoConfiguration } = this.state;

    this.setState({
      rapoConfiguration: {
        ...rapoConfiguration,
        letterComplementPeriod: newValue,
      },
    });
  };

  loadRapoConfiguration = async () => {
    try {
      const rapoConfiguration = await fetchRapoConfiguration();
      this.setState({ rapoConfiguration });
    } catch (e) {
      this.setState({ error: e as Error });
    }
  };

  saveRapoConfiguration = async () => {
    const { rapoConfiguration } = this.state;
    try {
      await upsertRapoConfiguration(rapoConfiguration);
      this.setState({ alert: 'Modifications enregistrées' });
    } catch (error) {
      this.setState({ alert: (error as Error).message });
    }
  };

  loadReasonsAndAnswers = (): void => {
    void this.fetchReasonList();
    void this.fetchRapoAnswer();
  };

  loadOrganizations = async (): Promise<void> => {
    const {
      organizationRapoAnswerEnabled,
      organizationRapoChoiceReasonEnabled,
    } = this.props;

    if (organizationRapoAnswerEnabled || organizationRapoChoiceReasonEnabled) {
      const organizations = await fetchOrganizations(true, 'fps');
      if (organizations.length === 0) {
        this.setState({
          error: new Error(_t('feedback.error.noOrg')),
        });
      } else {
        this.setState(
          {
            organizations,
            selectedOrganizationId: organizations[0].organizationId,
          },
          this.loadReasonsAndAnswers
        );
      }
    } else {
      this.loadReasonsAndAnswers();
    }
  };

  fetchReasonList = async () => {
    const { selectedOrganizationId } = this.state;

    try {
      const rapoChoiceReasons = await fetchRapoChoiceReasonList(
        selectedOrganizationId
      );

      this.setState({ rapoChoiceReasons, error: null });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  fetchRapoAnswer = async () => {
    const { selectedOrganizationId } = this.state;

    try {
      const rapoAnswer = await fetchRapoAnswer(selectedOrganizationId);

      this.setState({
        rapoAnswer,
        originalRapoAnswer: rapoAnswer,
        error: null,
      });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  computeErrorNode = (content: string): Element | null => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(
      `<html>${content.replace(/&/g, '&amp;')}</html>`,
      'application/xml'
    );
    return doc.querySelector('parsererror');
  };

  updateMail = async () => {
    const { currentReason, selectedOrganizationId } = this.state;

    if (!currentReason) {
      return;
    }

    // if an html tag is not close, prevent to save
    const errorNode = this.computeErrorNode(currentReason.mail);
    if (errorNode) {
      this.setState({ alert: "Une balise html n'est pas correctement fermée" });
      return;
    }

    currentReason.organizationId = selectedOrganizationId;

    try {
      await updateRapoChoiceReasonMail(currentReason);
      await this.fetchReasonList();
    } catch (error) {
      this.setState({ error: error as Error });
    }

    this.closeModal();
  };

  previewMail = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (event.currentTarget.dataset.key == null) {
      return;
    }

    try {
      const url = previewResponseUrl(
        event.currentTarget.dataset.key,
        this.state.selectedOrganizationId
      );

      openNewAuthentifiedTab(url);
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  openModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { rapoChoiceReasons } = this.state;

    if (!rapoChoiceReasons || event.currentTarget.dataset.key == null) {
      return;
    }

    this.setState(
      {
        open: true,
        currentReason: rapoChoiceReasons[event.currentTarget.dataset.key],
      },
      this.checkAllowToSubmit
    );
  };

  closeModal = () =>
    this.setState({ open: false, validationErrorMessage: null });

  save = async () => {
    const {
      rapoAnswer,
      selectedOrganizationId,
      originalRapoAnswer,
    } = this.state;

    if (rapoAnswer == null || originalRapoAnswer == null) {
      return;
    }

    rapoAnswer.organizationId = selectedOrganizationId;

    try {
      await updateRapoAnswer(rapoAnswer);
      await this.fetchRapoAnswer();
      this.setState({ alert: 'Modifications enregistrées', savable: false });
    } catch (error) {
      this.setState({ alert: (error as Error).message });
    }
  };

  cancel = () => {
    this.setState({
      rapoAnswer: this.state.originalRapoAnswer,
      savable: false,
    });
  };

  openErrorSnackBar = (alert: string) => this.setState({ alert });
  closeErrorSnackBar = () => this.setState({ alert: '' });

  changeOrganization = (
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    id: string
  ) => {
    this.setState({ selectedOrganizationId: id }, this.loadReasonsAndAnswers);
  };

  createRapoMailPreviewRef = (elem: RapoMessageLengthChecker) => {
    this.rapoMailPreviewRef = elem;
  };
  checkAllowToSubmit = () => {
    // Check answer size limit
    if (this.rapoMailPreviewRef && this.rapoMailPreviewRef) {
      if (this.rapoMailPreviewRef.isExceedingOnePage()) {
        this.setState({
          validationErrorMessage: _t('feedback.error.needMore'),
        });
        return;
      }
      if (this.rapoMailPreviewRef.isExceedingTwoPages()) {
        this.setState({
          validationErrorMessage: _t('feedback.error.tooLong'),
        });
        return;
      }
    }
    this.setState({ validationErrorMessage: null });
  };

  onCheckTagsMandatory = () => {
    const { rapoConfiguration } = this.state;

    const updatedConfiguration = {
      ...rapoConfiguration,
      tagsMandatory: !rapoConfiguration.tagsMandatory,
    };

    this.setState({ rapoConfiguration: updatedConfiguration }, () => {
      (async () => {
        try {
          await this.saveRapoConfiguration();
        } catch (error) {
          this.setState({ alert: (error as Error).message });
        }
      })(); // Immediately invoked async function
    });
  };

  onCheckAllowRapoOverDeadline = () => {
    const { rapoConfiguration } = this.state;
    const updatedConfiguration = {
      ...rapoConfiguration,
      allowRapoOverDeadline: !rapoConfiguration.allowRapoOverDeadline,
    };

    this.setState({ rapoConfiguration: updatedConfiguration }, () => {
      void (async () => {
        try {
          await this.saveRapoConfiguration();
        } catch (error) {
          this.setState({ alert: (error as Error).message });
        }
      })();
    });
  };

  render() {
    const {
      rapoChoiceReasons,
      currentReason,
      organizations,
      selectedOrganizationId,
      rapoAnswer,
      savable,
      alert,
      error,
      validationErrorMessage,
      rapoConfiguration,
      open,
    } = this.state;
    const {
      organizationRapoAnswerEnabled,
      organizationRapoChoiceReasonEnabled,
      isCvfmRapoPartner,
      canWrite,
    } = this.props;

    if (error || !isCvfmRapoPartner) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock
              message={
                error && isCvfmRapoPartner
                  ? error.message
                  : _tg('feedback.error.permission')
              }
              error={error}
            />
          </FlexCenter>
        </Content>
      );
    }

    if (!rapoChoiceReasons || !organizations || !rapoAnswer) {
      return (
        <Content>
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        </Content>
      );
    }

    return (
      <Content>
        <AdvertisingModal module="rapo" />
        {(organizationRapoAnswerEnabled ||
          organizationRapoChoiceReasonEnabled) && (
          <div>
            <SeparatorWithTitle
              style={STYLE_LOGS_TITLE}
              title={_t('element.separatorOrg.title')}
              color={BKG_PINK}
              titleSize={20}
            />
            <div style={{ ...ROW_STYLE, flexDirection: 'column' }}>
              <SelectField
                value={selectedOrganizationId}
                onChange={this.changeOrganization}
                disabled={!canWrite}
              >
                {organizations.map(org => (
                  <MenuItem
                    key={org.organizationId}
                    value={org.organizationId}
                    primaryText={org.name}
                  />
                ))}
              </SelectField>
            </div>
          </div>
        )}
        <SeparatorWithTitle
          style={STYLE_LOGS_TITLE}
          title={_t('element.separatorLabel.title')}
          color={BKG_PINK}
          titleSize={20}
        />
        <div className="align-center">
          <AdminTags
            organizationId={selectedOrganizationId}
            type={TagsTypes.rapo}
            showMessage={this.openErrorSnackBar}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              width: '100%',
              marginLeft: 45,
            }}
          >
            <Checkbox
              label="Labels obligatoires"
              style={FIT_CONTENT}
              labelStyle={FIT_CONTENT}
              checked={rapoConfiguration.tagsMandatory}
              onCheck={this.onCheckTagsMandatory}
            />
          </div>
        </div>
        <>
          <SeparatorWithTitle
            style={STYLE_LOGS_TITLE}
            title="Délai de soumission"
            color={BKG_PINK}
            titleSize={20}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',
              width: '100%',
              marginLeft: 45,
            }}
          >
            <Checkbox
              label={_t('element.allowRapoOverDeadline')}
              style={{ ...FIT_CONTENT, marginTop: 10 }}
              labelStyle={FIT_CONTENT}
              checked={rapoConfiguration.allowRapoOverDeadline}
              onCheck={this.onCheckAllowRapoOverDeadline}
            />
          </div>
        </>
        <>
          <SeparatorWithTitle
            style={STYLE_LOGS_TITLE}
            title={_t('element.separatorDuration.title')}
            color={BKG_PINK}
            titleSize={20}
          />
          <div style={ROW_STYLE}>
            <NumberField
              name={_tg('tefps.rapo.complementPeriod')}
              onChange={this.onComplementPeriodChange}
              value={rapoConfiguration.complementPeriod}
              min={0}
              floatingLabelText={_tg('tefps.rapo.complementPeriod')}
              floatingLabelFixed
              disabled={!canWrite}
              fullWidth
            />
          </div>
          <div style={ROW_STYLE}>
            <NumberField
              name={_tg('tefps.rapo.letterComplementPeriod')}
              onChange={this.onLetterComplementPeriodChange}
              value={rapoConfiguration.letterComplementPeriod}
              min={0}
              floatingLabelText={_tg('tefps.rapo.letterComplementPeriod')}
              floatingLabelFixed
              disabled={!canWrite}
              fullWidth
            />
          </div>
          <div style={ROW_STYLE}>
            <FlatButton
              label={_tg('action.save_2')}
              primary
              onClick={this.saveRapoConfiguration}
              disabled={!canWrite}
            />
          </div>
        </>

        <SeparatorWithTitle
          style={STYLE_LOGS_TITLE}
          title={_t('element.separatorEditLetter.title')}
          color={BKG_PINK}
          titleSize={20}
        />
        <div
          style={{ position: 'relative', padding: 20, fontFamily: 'Roboto' }}
        >
          <Table selectable={false}>
            <TableHeader adjustForCheckbox={false} displaySelectAll={false}>
              <TableRow>
                <TableHeaderColumn>{_tg('field.status')}</TableHeaderColumn>
                <TableHeaderColumn style={{ width: '65%' }}>
                  {_t('element.table.cols.reason')}
                </TableHeaderColumn>
                <TableHeaderColumn />
              </TableRow>
            </TableHeader>
            <TableBody displayRowCheckbox={false}>
              {rapoChoiceReasons != null &&
                Object.keys(rapoChoiceReasons).map(key => (
                  <TableRow key={key}>
                    <TableRowColumn>
                      {getStatusLabel(rapoChoiceReasons[key].status)}
                    </TableRowColumn>
                    <TableRowColumn style={{ width: '65%' }}>
                      {rapoChoiceReasons[key].label}
                    </TableRowColumn>
                    <TableRowColumn>
                      <IconButton
                        title={_t('element.table.body.editLetter')}
                        data-key={key}
                        onClick={this.openModal}
                        disabled={!canWrite}
                      >
                        <SettingsIcon />
                      </IconButton>
                      <IconButton
                        title={_tg('action.preview')}
                        data-key={key}
                        onClick={this.previewMail}
                      >
                        <EyeIcon />
                      </IconButton>
                    </TableRowColumn>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </div>

        <SeparatorWithTitle
          style={STYLE_LOGS_TITLE}
          title={_t('element.separatorLetterHeader.title')}
          color={BKG_PINK}
          titleSize={20}
        />

        <div
          style={{ position: 'relative', padding: 20, fontFamily: 'Roboto' }}
        >
          {rapoAnswer != null && [
            <ImagePreview
              key="header"
              img={rapoAnswer.rapoAnswerHeader}
              title={_t('element.imagePreviewHeader.title')}
              style={STYLE_HEADER_FOOTER_SIGNATURE}
              deleteImage={this.onDeleteHeader}
            />,
            <ImagePreview
              key="footer"
              img={rapoAnswer.rapoAnswerFooter}
              title={_t('element.imagePreviewFooter.title')}
              style={STYLE_HEADER_FOOTER_SIGNATURE}
              deleteImage={this.onDeleteFooter}
            />,
            <ImagePreview
              key="background"
              img={rapoAnswer.rapoAnswerPageBackground}
              title={_t('element.imagePreviewBackground.title')}
              style={STYLE_BACKGROUND}
              deleteImage={this.onDeleteBackground}
            />,
            <ImagePreview
              key="signature"
              img={rapoAnswer.rapoAnswerSignature}
              title={_t('element.imagePreviewSignature.title')}
              style={STYLE_HEADER_FOOTER_SIGNATURE}
              deleteImage={this.onDeleteSignature}
            />,
          ]}

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ImageDropZone
              onDrop={this.onDropHeader}
              legend={_t('element.imageDropzoneHeader.legend')}
              disabled={!canWrite}
            />
            <ImageDropZone
              onDrop={this.onDropFooter}
              legend={_t('element.imageDropzoneFooter.legend')}
              disabled={!canWrite}
            />
            <ImageDropZone
              onDrop={this.onDropBackground}
              legend={_t('element.imageDropzoneBackground.legend')}
              disabled={!canWrite}
            />
            <ImageDropZone
              onDrop={this.onDropSignature}
              legend="Signature"
              disabled={!canWrite}
            />
            <BoButton
              label="Sauvegarder"
              primary
              onClick={this.save}
              disabled={!savable}
              style={{ marginRight: '20px' }}
            />
            {savable && (
              <BoButton
                label="Annuler"
                onClick={this.cancel}
                disabled={!savable}
              />
            )}
          </div>
        </div>
        <Dialog
          open={open}
          title={
            currentReason
              ? `${getStatusLabel(currentReason.status)} / ${
                  currentReason.label
                }`
              : _t('element.dialogRedaction.title')
          }
          actions={[
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div style={STYLE_ERROR_MESSAGE}>{validationErrorMessage}</div>
              <div>
                <FlatButton
                  label={_tg('action.cancel')}
                  onClick={this.closeModal}
                />
                <FlatButton
                  label={_tg('action.validate')}
                  primary
                  onClick={this.updateMail}
                />
              </div>
            </div>,
          ]}
          modal
          autoScrollBodyContent // Les 4 lignes suivantes corrigent le mauvais positionnement de la modale dû à un textarea avec de nombreuses lignes
          repositionOnUpdate={false}
          style={STYLE_DIALOG}
          contentStyle={{
            ...STYLE_DIALOG_CONTENT,
            width: window.innerWidth >= 1100 ? 1100 : '98vw',
          }}
          bodyStyle={{ paddingBottom: 0 }}
        >
          <div>
            <RapoTemplatingInfo />
            <TextAreaWithHints
              hintText={_t('element.fieldCourrierType.hint')}
              multiLine
              fullWidth
              rows={6}
              value={currentReason != null ? currentReason.mail : ''}
              onChange={this.onChangeComment}
              hints={RAPO_TEMPLATE_FIELDS_LIST}
            />
            <RapoMessageLengthChecker
              ref={this.createRapoMailPreviewRef}
              message={currentReason != null ? currentReason.mail : ''}
              cityName={_t('element.rapoChecker.cityName')}
              user={translateMockRapoUser()}
            />
          </div>
        </Dialog>

        <Snackbar
          open={!!alert}
          message={alert}
          autoHideDuration={4000}
          onRequestClose={this.closeErrorSnackBar}
        />
      </Content>
    );
  }
}

export default connect(state => {
  const {
    organizationRapoAnswerEnabled,
    organizationRapoChoiceReasonEnabled,
    cvfmRapoPartner,
  } = getConfigState(state);
  const { userInfo } = getApiState(state);

  return {
    organizationRapoAnswerEnabled,
    organizationRapoChoiceReasonEnabled,
    isCvfmRapoPartner: cvfmRapoPartner,
    canWrite: userInfo && userInfo.rights.includes('RAPO_CONFIGURATION_WRITE'),
  };
})(Rapo);
