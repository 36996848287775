import { LOGOUT, ApiAction } from 'api/duck';

import { Period, SortParameters } from './types';

export const SET_PERIOD = 'Dashboard/LapiReviewReport/SET_PERIOD';
export const SET_SORT_PARAMETERS =
  'Dashboard/LapiReviewReport/SET_SORT_PARAMETERS';

export type State = {
  period: Period | null | undefined;
  sort: SortParameters | null | undefined;
};

type SetFiltersAction = {
  type: typeof SET_PERIOD | typeof SET_SORT_PARAMETERS;
  period: Period;
  sort: SortParameters;
};

type Action = SetFiltersAction | ApiAction;

const initialState = () => ({
  period: {
    from: undefined,
    to: undefined,
  },
  sort: {
    colSorted: 1,
    increasingOrder: false,
  },
});

export default function lapiReviewReportReducer(
  state: State = initialState(),
  action: Action
): State {
  switch (action.type) {
    case SET_SORT_PARAMETERS:
      return {
        ...state,
        sort: action.sort,
      };
    case SET_PERIOD:
      return {
        ...state,
        period: action.period,
      };
    case LOGOUT:
      return initialState();
    default:
      return state;
  }
}

export function setPeriod(
  period: Period
): { type: typeof SET_PERIOD; period: Period } {
  return {
    type: SET_PERIOD,
    period,
  };
}

export function setSortParameters(
  sort: SortParameters
): { type: typeof SET_SORT_PARAMETERS; sort: SortParameters } {
  return {
    type: SET_SORT_PARAMETERS,
    sort,
  };
}
