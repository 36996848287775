import { ControlViewPrivateDTO } from '@cvfm-front/tefps-types';
import { PatchObject } from 'api/commonTypes';

function makeReplacePatch(path: string, value: unknown): PatchObject<unknown> {
  return {
    op: 'replace',
    path,
    value,
  };
}

export function computeControlViewPatchDiff(
  viewSrc: ControlViewPrivateDTO,
  viewDest: ControlViewPrivateDTO
): Array<PatchObject<unknown>> {
  const patches = [];

  if (viewSrc.name !== viewDest.name) {
    patches.push(makeReplacePatch('/name', viewDest.name));
  }
  if (viewSrc.enabled !== viewDest.enabled) {
    patches.push(makeReplacePatch('/enable', viewDest.enabled));
  }
  if (viewSrc.controlStatuses !== viewDest.controlStatuses) {
    patches.push(makeReplacePatch('/status', viewDest.controlStatuses));
  }
  if (viewSrc.deviceTypes !== viewDest.deviceTypes) {
    patches.push(makeReplacePatch('/deviceTypes', viewDest.deviceTypes));
  }
  if (viewSrc.reviewReasons !== viewDest.reviewReasons) {
    patches.push(makeReplacePatch('/reviewReasons', viewDest.reviewReasons));
  }
  if (viewSrc.vehicleTypes !== viewDest.vehicleTypes) {
    patches.push(makeReplacePatch('/vehicleTypes', viewDest.vehicleTypes));
  }
  if (viewSrc.presumedVehicleTypes !== viewDest.presumedVehicleTypes) {
    patches.push(
      makeReplacePatch('/presumedVehicleTypes', viewDest.presumedVehicleTypes)
    );
  }
  if (
    viewSrc.relevantParkingRightPricingCategories !==
    viewDest.relevantParkingRightPricingCategories
  ) {
    patches.push(
      makeReplacePatch(
        '/relevantParkingRightPricingCategories',
        viewDest.relevantParkingRightPricingCategories
      )
    );
  }
  if (viewSrc.userCanSwitch !== viewDest.userCanSwitch) {
    patches.push(makeReplacePatch('/userCanSwitch', viewDest.userCanSwitch));
  }
  if (viewSrc.userDefaultSwitchState !== viewDest.userDefaultSwitchState) {
    patches.push(
      makeReplacePatch(
        '/userDefaultSwitchState',
        viewDest.userDefaultSwitchState
      )
    );
  }
  if (viewSrc.exemptionReasons !== viewDest.exemptionReasons) {
    patches.push(
      makeReplacePatch('/exemptionReasons', viewDest.exemptionReasons)
    );
  }
  if (viewSrc.fpsFilters !== viewDest.fpsFilters) {
    patches.push(makeReplacePatch('/fpsFilters', viewDest.fpsFilters));
  }
  if (viewSrc.useReliabilityThreshold !== viewDest.useReliabilityThreshold) {
    patches.push(
      makeReplacePatch(
        '/useReliabilityThreshold',
        viewDest.useReliabilityThreshold
      )
    );
  }
  if (viewSrc.displayColorHex !== viewDest.displayColorHex) {
    patches.push(
      makeReplacePatch('/displayColorHex', viewDest.displayColorHex)
    );
  }
  if (viewSrc.teamIds !== viewDest.teamIds) {
    patches.push(makeReplacePatch('/teamIds', viewDest.teamIds));
  }
  if (viewSrc.refreshDelayMinutes !== viewDest.refreshDelayMinutes) {
    patches.push(
      makeReplacePatch('/refreshDelayMinutes', viewDest.refreshDelayMinutes)
    );
  }
  return patches;
}
