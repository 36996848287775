import React, { CSSProperties } from 'react';
import moment, { Moment } from 'moment';
import Card from 'material-ui/Card';
import CircularProgress from 'material-ui/CircularProgress';

import ErrorBlock from 'commons/ErrorBlock';
import SimpleTable from 'commons/SimpleTable';
import Exporter from 'commons/Exporter';
import { TXT_CHART_TITLE } from 'theme';
import {
  askOrganizationReportingExport,
  fetchFpsCountByOrganization,
} from 'api/dashboard';
import {
  FpsOrganizationReportDTO,
  OrganizationReport,
} from 'api/dashboard/types';
import { ApiError } from 'api/ApiError';

const { _tg } = window.loadTranslations(__filename);

type Header = {
  label: JSX.Element | string;
  width: number;
  headerStyle: React.CSSProperties;
};

const formatDayMonth = (date: Moment) => {
  return date.format('DD/MM');
};

const computeWeekHeader = (monday: Moment) => {
  const weekNumber = monday.isoWeek();
  const sunday = monday.clone().endOf('isoWeek');
  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ fontWeight: 'bold', marginBottom: 12 }}>S{weekNumber}</div>
      <div style={{ fontWeight: 'normal', fontSize: 14 }}>
        {formatDayMonth(monday)} - {formatDayMonth(sunday)}
      </div>
    </div>
  );
};

const previousMonday = (monday: Moment) => {
  return monday.clone().subtract(1, 'week');
};

const computeLast5Mondays = (): Array<Moment> => {
  const currentWeekMonday = moment().startOf('isoWeek');
  const mondays = [currentWeekMonday];
  for (let i = 1; i < 5; i += 1) {
    mondays.unshift(previousMonday(mondays[0]));
  }
  return mondays;
};

const COLUMN_HEADER_STYLE: CSSProperties = { justifyContent: 'center' };

const computeColumnTitles = (mondays: Array<Moment>) => {
  const headers: Header[] = [
    {
      label: _tg('tefps.filters.misc.organizationNumber'),
      width: 100,
      headerStyle: COLUMN_HEADER_STYLE,
    },
    {
      label: _tg('field.organisation.organisation'),
      width: 180,
      headerStyle: COLUMN_HEADER_STYLE,
    },
  ];
  mondays.forEach(monday => {
    const label = computeWeekHeader(monday);
    headers.push({
      label,
      width: 140,
      headerStyle: COLUMN_HEADER_STYLE,
    });
  });
  return headers;
};

const ROW_ITEM_STYLE: CSSProperties = {
  textAlign: 'center',
};

const TITLE_STYLE: CSSProperties = {
  textAlign: 'center',
  fontWeight: 'bold',
};

const TABLE_TITLE_STYLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  color: TXT_CHART_TITLE,
  fontSize: 18,
  fontWeight: 'bold',
  marginBottom: 20,
};

const EXPORT_BUTTON_STYLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: 14,
};

type State = {
  mondaysOfLast5weeks: Array<Moment>;
  columnTitles: Array<any>;
  organizationReport: FpsOrganizationReportDTO | null | undefined;
  error: Error | null | undefined;
};

type TitleProps = {
  title: string;
  style?: Record<string, any>;
};

const Title = ({ title, style = TITLE_STYLE }: TitleProps) => (
  <div style={style}>{title}</div>
);

const Cell = ({ value }: { value: number }) => (
  <div style={ROW_ITEM_STYLE}>{value.toLocaleString()}</div>
);

const INITIAL_STATE: State = {
  organizationReport: null,
  error: null,
  mondaysOfLast5weeks: [],
  columnTitles: [],
};

class OrganizationsReport extends React.Component<
  Record<string, never>,
  State
> {
  EXPORT_COL = [
    { key: 'date', label: _tg('field.date.day'), checked: true },
    {
      key: 'nbrFpses',
      label: _tg('field.fps.fpsAmount'),
      checked: true,
    },
  ];
  constructor(props: Record<string, never>) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount(): void {
    void this.fetchStat();
  }

  fetchStat = async (): Promise<void> => {
    const mondaysOfLast5weeks = computeLast5Mondays();
    const columnTitles = computeColumnTitles(mondaysOfLast5weeks);
    this.setState({
      ...INITIAL_STATE,
      mondaysOfLast5weeks,
      columnTitles,
    });
    try {
      const organizationReport = await fetchFpsCountByOrganization();
      this.setState({ organizationReport });
    } catch (error) {
      this.setState({ error: error as ApiError });
    }
  };

  itemRenderer = (row: OrganizationReport): JSX.Element[] => {
    const { mondaysOfLast5weeks } = this.state;
    const weeks = mondaysOfLast5weeks.map(m => {
      const week = row.values.find(r => r.key === m.format('YYYY-MM-DD'));
      const value = week ? week.value : 0;
      return <Cell value={value} />;
    });
    return [
      <Title title={row.organizationShortId} />,
      <Title title={row.organizationName} />,
      ...weeks,
    ];
  };

  render(): JSX.Element {
    const { organizationReport, columnTitles, error } = this.state;

    if (error) {
      return (
        <ErrorBlock
          message={_tg('feedback.error.errorFetchingReportStats')}
          error={error}
        />
      );
    }

    return (
      <Card style={{ marginTop: 30 }}>
        <div style={{ padding: 20 }}>
          <div style={TABLE_TITLE_STYLE}>
            {_tg('tefps.dashboard.report.fpsCreatedByOrgaLastFiveWeeks')}
          </div>
          {organizationReport ? (
            <div>
              <SimpleTable
                maxHeight={2500}
                cols={columnTitles}
                rowHeight={65}
                header
                itemsRenderer={this.itemRenderer}
                items={organizationReport.organizationReports}
              />
            </div>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress />
            </div>
          )}
          <div style={EXPORT_BUTTON_STYLE}>
            <Exporter
              columns={this.EXPORT_COL}
              modalTitle={_tg(
                'tefps.dashboard.report.exportAmountOfFpsCreations'
              )}
              description={_tg('tefps.dashboard.report.exportExplanation')}
              filters={{}}
              fileExport={askOrganizationReportingExport}
              disabled={false}
              type=""
            />
          </div>
        </div>
      </Card>
    );
  }
}

export default OrganizationsReport;
