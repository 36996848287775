import React, { Component, CSSProperties } from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import TextField from 'material-ui/TextField';
import Snackbar from 'material-ui/Snackbar';
import _cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItemWrapper,
  DataBoxWithName,
} from 'commons/DataBox';
import Content from 'commons/Content';
import { getApiState } from 'api/duck';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import {
  fetchControlConfiguration,
  upsertControlConfiguration,
  buildControlConfigurationPatch,
} from 'api/controlConfiguration';
import { ControlConfiguration } from 'api/controlConfiguration/types';

import { BKG_PINK } from '../../theme';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_HEADER_LEFT: CSSProperties = {
  fontWeight: 'bold',
  marginLeft: 30,
};
const STYLE_WRAPPER: CSSProperties = {
  marginTop: 40,
  justifyContent: 'space-around',
};

const STYLE_BOLD_RED: CSSProperties = {
  fontWeight: 'bold',
  color: BKG_PINK,
};

const STYLE_DATABOX: CSSProperties = { width: '95%', margin: '20px auto 0' };

type ControlConfigurationState = {
  configuration: ControlConfiguration | null | undefined;
  backupConfiguration: ControlConfiguration | null | undefined;
  hasChanges: boolean;
  updating: boolean;
  message: string;
  error: Error | null | undefined;
};

type ReduxStateProps = {
  canWrite: boolean;
};

class ControlConfigurationPage extends Component<
  ReduxStateProps,
  ControlConfigurationState
> {
  constructor(props: ReduxStateProps) {
    super(props);
    this.state = {
      configuration: null,
      backupConfiguration: null,
      hasChanges: false,
      updating: false,
      message: '',
      error: null,
    };
  }

  componentDidMount() {
    void this.fetchControlConfig();
  }

  onAlertConfigurationChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const {
      value,
      dataset: { field },
    } = ev.currentTarget;
    const { configuration } = this.state;

    this.setState({
      configuration: {
        ...configuration,
        [field as string]: parseInt(value, 10),
      } as ControlConfiguration,
      hasChanges: true,
    });
  };

  fetchControlConfig = async () => {
    try {
      const configuration = await fetchControlConfiguration();
      this.setState({ configuration, backupConfiguration: configuration });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  closeErrorSnackBar = () => this.setState({ message: '' });

  submit = async () => {
    const { configuration } = this.state;
    if (!configuration) {
      return;
    }

    this.setState({ updating: true });
    try {
      await upsertControlConfiguration(
        buildControlConfigurationPatch(configuration)
      );
      this.setState({
        hasChanges: false,
        updating: false,
        message: _t('feedback.success'),
      });
    } catch (error) {
      this.setState({ error: error as Error, updating: false });
    }
  };

  cancel = () => {
    const { backupConfiguration } = this.state;
    this.setState({
      configuration: _cloneDeep(backupConfiguration),
      hasChanges: false,
    });
  };

  render() {
    const { configuration, hasChanges, updating, message, error } = this.state;

    if (error) {
      return (
        <FlexCenter>
          <ErrorBlock error={error} />
        </FlexCenter>
      );
    }

    if (!configuration) {
      return (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      );
    }
    return (
      <Content>
        <DataBox panel style={STYLE_DATABOX}>
          <DataBoxHeader>
            <div style={STYLE_HEADER_LEFT}>{_t('element.title')}</div>
          </DataBoxHeader>
          <DataBoxContent>
            <DataBoxItemWrapper style={STYLE_WRAPPER}>
              <p style={STYLE_BOLD_RED}>{_t('element.warning')} </p>
            </DataBoxItemWrapper>
            {DataBoxWithName({
              name: _t('element.delayBefore'),
              node: (
                <TextField
                  id="aPrioriGracePeriod"
                  min={0}
                  type="number"
                  value={configuration.aPrioriGracePeriod}
                  onChange={this.onAlertConfigurationChange}
                  data-field="aPrioriGracePeriod"
                />
              ),
              containerStyle: {
                display: 'flex',
                justifyContent: 'space-between',
              },
            })}
            {DataBoxWithName({
              name: _t('element.delayAfter'),
              node: (
                <TextField
                  id="aPosterioriGracePeriod"
                  min={0}
                  type="number"
                  value={configuration.aPosterioriGracePeriod}
                  onChange={this.onAlertConfigurationChange}
                  data-field="aPosterioriGracePeriod"
                />
              ),
              containerStyle: {
                display: 'flex',
                justifyContent: 'space-between',
              },
            })}
            <DataBoxItemWrapper style={STYLE_WRAPPER}>
              <BoButton
                label={_tg('action.save_1')}
                primary
                disabled={!hasChanges || updating}
                onClick={this.submit}
              />
              <BoButton
                label={_tg('action.cancel')}
                disabled={!hasChanges || updating}
                onClick={this.cancel}
              />
            </DataBoxItemWrapper>
            {updating && (
              <DataBoxItemWrapper>
                <CircularProgress />
              </DataBoxItemWrapper>
            )}
          </DataBoxContent>
        </DataBox>
        <Snackbar
          open={!!message}
          message={message}
          autoHideDuration={4000}
          onRequestClose={this.closeErrorSnackBar}
        />
      </Content>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canWrite: !!(
      userInfo && userInfo.rights.includes('CONTROL_CONFIGURATION_WRITE')
    ),
  };
})(ControlConfigurationPage);
