import React, { CSSProperties, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CircularProgress from 'material-ui/CircularProgress';
import Dialog from 'material-ui/Dialog';
import { Location } from 'history';

import FilterBar from 'commons/Generics/FilterBar';
import Exporter from 'commons/Exporter';
import AdvertisingModal from 'commons/AdvertisingModal';
import ListWithActions from 'commons/ListWithActions';
import { fetchRefundFilteredList, getRefundExport } from 'api/refund';
import {
  getConfigState,
  InitialOrganization,
  RegieProperties,
} from 'config/duck';
import { getApiState } from 'api/duck';

import useRefundPageFetcher from './useRefundPageFetcher';
import RefundTable from './RefundTable';
import initFpsRefundFilters from './fpsRefundFilters';
import MultipleRefundAction from './MultipleRefundAction';
import { BoolContext, RefundMap, RefundMapContext } from './Context';
import EXPORT_COL from './exportColumns';

const { _tg } = window.loadTranslations(__filename);

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  height: '100%',
};

const MAX_BATCH_SIZE = 9999;

interface Props {
  canExport: boolean;
  location: Location<{ filters: any }>;
  regiesProperties: RegieProperties;
  initialOrganizationsConfigurations: Array<InitialOrganization>;
}

const RefundList = ({
  regiesProperties,
  initialOrganizationsConfigurations,
  location,
  canExport,
}: Props) => {
  const [filters, setFilters] = useState(
    initFpsRefundFilters(regiesProperties, initialOrganizationsConfigurations)
  );
  const [filterValues, setFilterValues] = useState({});
  const [openLoadingModal, setOpenLoadingModal] = useState<boolean>(false);
  const [selectedRefunds, setSelectedRefunds] = useState<RefundMap>({});
  const [multipleMode, setMultipleMode] = useState<boolean>(false);
  const {
    convertFiltersToRequest,
    facetings,
    fetchNextPage,
    fetchWithNewFilter,
    initFilters,
    list,
    totalHits,
  } = useRefundPageFetcher(filters);

  useEffect(() => {
    if (location.state) {
      setFilters({ ...filters, ...location.state.filters });
    }
  }, [location]);

  // Fetch data when filter change
  useEffect(() => {
    fetchWithNewFilter(filters);
  }, [filters]);

  function resetSelection() {
    setMultipleMode(false);
    setSelectedRefunds({});
  }

  async function selectAll() {
    setOpenLoadingModal(true);
    const refundsOverviews = await fetchRefundFilteredList({
      query: convertFiltersToRequest(filters),
      page: 0,
      maxRecords: MAX_BATCH_SIZE,
    });
    const ids = refundsOverviews.response.reduce((acc, item) => {
      acc[item.id] = {
        rootFpsId: item.rootFpsId,
        refundPrice: item.refundPrice,
      };
      return acc;
    }, {});
    setSelectedRefunds(ids);
    setOpenLoadingModal(false);
  }

  function reload() {
    resetSelection();
    initFilters(filters);
    void fetchNextPage(undefined);
  }

  const actions = () => {
    const arr = [
      <MultipleRefundAction
        items={list}
        reload={reload}
        onSelectAll={selectAll}
        totalHits={totalHits}
      />,
    ];

    if (canExport) {
      arr.unshift(
        <Exporter
          columns={EXPORT_COL()}
          type={_tg('field.payment.refund')}
          disabled={totalHits === 0}
          filters={filterValues}
          fileExport={getRefundExport}
        />
      );
    }

    return arr;
  };

  return (
    <div style={STYLE_CONTENT}>
      <AdvertisingModal module="fps" />
      <FilterBar
        totalHits={totalHits}
        initialFilters={filters}
        onChangeFilters={newFilters => {
          resetSelection();
          setFilters(newFilters);
          setFilterValues(convertFiltersToRequest(newFilters));
        }}
        facetings={facetings}
      />

      <RefundMapContext.Provider
        value={{ selectedRefunds, setSelectedRefunds }}
      >
        <BoolContext.Provider value={{ multipleMode, setMultipleMode }}>
          <ListWithActions
            list={
              <RefundTable
                items={list}
                totalHits={totalHits}
                loadMoreRows={async () => {
                  await fetchNextPage(undefined);
                }}
              />
            }
            actions={actions()}
          />
        </BoolContext.Provider>
      </RefundMapContext.Provider>

      <Dialog
        open={openLoadingModal}
        title={_tg('tefps.dashboard.refund.selectingRefunds')}
      >
        <CircularProgress />
      </Dialog>
    </div>
  );
};

export default connect(state => {
  const {
    regiesProperties,
    initialOrganizationsConfigurations,
  } = getConfigState(state);
  const { userInfo } = getApiState(state);
  return {
    canExport:
      userInfo && userInfo.rights.includes('BACKOFFICE_FPS_REFUND_EXPORT'),
    regiesProperties,
    initialOrganizationsConfigurations,
  };
}, null)(RefundList);
