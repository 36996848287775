import { FpsRefundReason } from 'api/refund/types';

const { _tg } = window.loadTranslations(__filename);

export const STATUS_OPTIONS = () => ({
  REFUND_PENDING: _tg('tefps.dashboard.refund.refundToDo'),
  REFUND_IN_PROGRESS: _tg('tefps.dashboard.refund.refundInProgess'),
  NO_RESPONSE: _tg('tefps.dashboard.refund.noResponse'),
  CONTACT_MISSING: _tg('tefps.dashboard.refund.noContact'),
  RIB_MISSING: _tg('tefps.dashboard.refund.askForRIB'),
  CONTACT_MISSING_AFTER_CANCELLATION_CCSP: _tg(
    'tefps.dashboard.refund.noContactAfterCcspCancellation'
  ),
  RIB_ASKED: _tg('tefps.dashboard.refund.askedForRIB'),
  RIB_VALIDATED: _tg('tefps.dashboard.refund.receivedRIB'),
  REFUND_DONE: _tg('tefps.dashboard.refund.refundDoneAlternate'),
});

export const FPS_REFUND_REASON_LABEL: () => Record<
  FpsRefundReason,
  string
> = () => ({
  APPROVED_CCSP: _tg('tefps.dashboard.refund.refundReason.APPROVED_CCSP'),
  APPROVED_RAPO: _tg('tefps.dashboard.refund.refundReason.APPROVED_RAPO'),
  CANCELLED_FPS: _tg('tefps.dashboard.refund.refundReason.CANCELLED_FPS'),
  UPDATED_FPS: _tg('tefps.dashboard.refund.refundReason.UPDATED_FPS'),
  OVERPAID: _tg('field.overpaid'),
  OTHER: _tg('field.other'),
});
