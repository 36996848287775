import React, { CSSProperties } from 'react';

import Tag from 'commons/Tag';
import { fetchTags, upsertTags, TagsType } from 'api/tags';

import AddTag from './AddTag';

const { _tg } = window.loadTranslations(__filename);

const STYLE_WRAPPER: CSSProperties = {
  margin: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexWrap: 'nowrap',
};

type AdminTagsProps = {
  organizationId: string | null | undefined;
  type: TagsType;
  showMessage: (arg0: string) => void;
};

type AdminTagsState = {
  tags: Array<string>;
};

class AdminTags extends React.Component<AdminTagsProps, AdminTagsState> {
  constructor(props: AdminTagsProps) {
    super(props);
    this.state = {
      tags: [],
    };
  }

  componentDidMount(): void {
    const { organizationId } = this.props;
    void this.loadTagsList(organizationId);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: AdminTagsProps): void {
    void this.loadTagsList(newProps.organizationId);
  }

  loadTagsList = async (orgId: string | null | undefined): Promise<void> => {
    const { type, showMessage } = this.props;
    try {
      const tags = await fetchTags(type, orgId, false);
      this.setState({ tags });
    } catch (e) {
      showMessage(
        _tg('Configuration.commons.AdminTags.index.feedback.error.load')
      );
    }
  };

  handleDelete = async (tag: string): Promise<void> => {
    const { organizationId, type, showMessage } = this.props;
    const { tags } = this.state;
    const newTags = new Set(tags);
    newTags.delete(tag);
    try {
      await upsertTags(type, organizationId, newTags);
      this.setState({ tags: [...newTags] });
    } catch (e) {
      showMessage(
        _tg('Configuration.commons.AdminTags.index.feedback.error.delete')
      );
    }
  };

  handleAdd = async (tag: string): Promise<void> => {
    const { organizationId, type, showMessage } = this.props;
    const { tags } = this.state;
    const newTags = new Set(tags);
    newTags.add(tag);
    try {
      await upsertTags(type, organizationId, newTags);
      this.setState({
        tags: [...newTags].sort((a, b) =>
          a.toLowerCase().localeCompare(b.toLocaleUpperCase())
        ),
      });
    } catch (e) {
      showMessage(
        _tg('Configuration.commons.AdminTags.index.feedback.error.add')
      );
    }
  };

  render(): React.ReactNode {
    const { tags } = this.state;

    return (
      <div style={STYLE_WRAPPER}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {Array.from(tags).map(tag => (
            <Tag key={tag} tag={tag} onDelete={this.handleDelete} />
          ))}
        </div>
        <AddTag tags={tags} onAdd={this.handleAdd} />
      </div>
    );
  }
}

export default AdminTags;
