import React from 'react';
import './CcspLink.css';
import { History } from 'history';

import BoButton from 'facade/BoButton';
import { ccspFpsIdFilters } from 'tefps/Tasks/helpers';
import services from 'commons/services';

const { _tg } = window.loadTranslations(__filename);

interface Props {
  fpsId: string;
  history: History;
}
function CcspLink({ fpsId, history }: Props): JSX.Element {
  function redirectToCcsp(): void {
    void services.ccspList.updateFilters(ccspFpsIdFilters(fpsId));
    history.push(`/tsp/list`);
  }
  return (
    <div className="ccsp-link">
      <p>{_tg('tefps.dashboard.refund.subscriberContact')}</p>
      <BoButton
        label={_tg('tefps.dashboard.refund.consultCcsp')}
        primary
        onClick={redirectToCcsp}
        style={{ width: 250 }}
      />
    </div>
  );
}

export default CcspLink;
