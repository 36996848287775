import * as React from 'react';
import IconMenu from 'material-ui/IconMenu';
import MenuItem from 'material-ui/MenuItem';
import IconButton from 'material-ui/IconButton';
import Divider from 'material-ui/Divider';
import Download from 'material-ui/svg-icons/file/file-download';
import Add from 'material-ui/svg-icons/file/create-new-folder';
import ArrowDropRight from 'material-ui/svg-icons/navigation-arrow-drop-right';
import Delete from 'material-ui/svg-icons/action/delete';
import Edit from 'material-ui/svg-icons/editor/mode-edit';
import MoreVertIcon from 'material-ui/svg-icons/navigation/more-vert';
import SelectField from 'material-ui/SelectField';

import { pageList } from '../Pages/constants';

const { _tg } = window.loadTranslations(__filename);

type MenuProps = {
  changePage: (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number,
    menuItemValue: any
  ) => void;
  pageId: number | null | undefined;
  isCvfmRapoPartner: boolean;
  isCvfmFpsPartner: boolean;
};

/**
 * Barre de menu/navigation pour les Pages du module rapport du "dashboard"
 */
class MenuBar extends React.Component<MenuProps> {
  getPages = (): React.ReactElement<'MenuItem'>[] => {
    const { isCvfmFpsPartner, isCvfmRapoPartner } = this.props;
    return pageList(isCvfmRapoPartner, isCvfmFpsPartner).map<
      React.ReactElement<'MenuItem'>
    >(({ label }, i: number) => (
      <MenuItem key={i} value={i} primaryText={label} />
    ));
  };

  render(): React.ReactNode {
    const { changePage, pageId } = this.props;
    return (
      <div
        style={{
          padding: 8,
          margin: 32,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          border: '2px solid #999',
          borderRadius: 8,
          height: 50,
        }}
      >
        <span style={{ display: 'none' }}>
          <IconMenu
            iconButtonElement={
              <IconButton>
                <MoreVertIcon />
              </IconButton>
            }
            anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
          >
            <MenuItem
              primaryText={_tg('tefps.dashboard.report.createNewPage')}
              leftIcon={<Add />}
            />

            <MenuItem
              primaryText={_tg('tefps.dashboard.report.modifyPage')}
              leftIcon={<Edit />}
              rightIcon={<ArrowDropRight />}
              menuItems={[
                <MenuItem
                  primaryText={_tg('tefps.dashboard.report.editName')}
                />,
                <MenuItem
                  primaryText={_tg('tefps.dashboard.report.editGraphs')}
                />,
              ]}
            />
            <Divider />
            <MenuItem
              primaryText={_tg('Topbar.PdfDownload.PdfDownloadElement.tooltip')}
              rightIcon={<ArrowDropRight />}
              menuItems={[
                <MenuItem
                  primaryText={_tg('tefps.dashboard.report.downloadExcel')}
                />,
                <MenuItem
                  primaryText={_tg('tefps.dashboard.report.downloadPdf')}
                />,
              ]}
              leftIcon={<Download />}
            />
            <Divider />
            <MenuItem
              value="Del"
              primaryText={_tg('tefps.dashboard.report.deletePage')}
              leftIcon={<Delete />}
            />
          </IconMenu>
        </span>
        <span style={{ position: 'absolute', right: '50px' }}>
          <SelectField
            value={pageId}
            onChange={changePage}
            floatingLabelText={_tg('tefps.dashboard.report.choosePage')}
          >
            {this.getPages()}
          </SelectField>
        </span>
      </div>
    );
  }
}

export default MenuBar;
