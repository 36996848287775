import React, { CSSProperties, useState } from 'react';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import { InternalApiState } from 'api/duck';
import { fetchConfig, getConfigState } from 'config/duck';
import { updateRefundConfiguration } from 'api/fpsConfiguration';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import Content from 'commons/Content';
import {
  RefundConfigurationDTO,
  RefundDocumentType,
} from 'api/fpsConfiguration/types';

import DocumentConfiguration from './DocumentConfiguration';
import FpsCommentConfiguration from './FpsCommentConfiguration';

const { _t } = window.loadTranslations(__filename);

const STYLE_BUTTON: CSSProperties = {
  margin: 12,
};

interface IProps {
  refundConfigurationDTO: RefundConfigurationDTO;
  reloadConfig: () => void;
}

function FpsConfiguration({ refundConfigurationDTO, reloadConfig }: IProps) {
  const [config, setConfig] = useState<RefundConfigurationDTO>(
    refundConfigurationDTO
  );
  const [prevSet, setPrevSet] = useState<Set<RefundDocumentType>>(
    refundConfigurationDTO.refundDocuments
  );
  const setMessage = useSnackbar();

  const setDocuments = (refundDocuments: Set<RefundDocumentType>) => {
    setConfig({ ...config, refundDocuments });
  };

  const builConfigurationUpdate = (configuration: RefundConfigurationDTO) => {
    return {
      op: 'replace',
      path: '/refundConfiguration',
      value: {
        ...configuration,
        refundDocuments: Array.from(configuration.refundDocuments),
      },
    };
  };

  async function handleValidation(): Promise<void> {
    try {
      if (config) {
        await updateRefundConfiguration(builConfigurationUpdate(config));
        setPrevSet(config.refundDocuments);
        setMessage(_t('feedback.success.sending'));
        reloadConfig();
      }
    } catch (e) {
      setMessage(_t('feedback.error.sending'));
    }
  }

  function handleCancel() {
    setDocuments(prevSet);
    setMessage(_t('feedback.success.cancel'));
  }

  if (!config) {
    return <div />;
  }
  return (
    <Content>
      <div
        style={{
          position: 'relative',
          padding: 20,
          paddingTop: 0,
          justifyContent: 'center',
        }}
      >
        <DocumentConfiguration
          documents={new Set(config.refundDocuments)}
          setDocuments={setDocuments}
        />
        <BoButton
          label="Valider"
          primary
          style={STYLE_BUTTON}
          onClick={handleValidation}
        />
        <BoButton label="Annuler" style={STYLE_BUTTON} onClick={handleCancel} />
        <FpsCommentConfiguration />
      </div>
    </Content>
  );
}

function mapStateToProps(state: InternalApiState) {
  const { refundConfigurationDTO } = getConfigState(state);
  return {
    refundConfigurationDTO,
  };
}

function mapDispatchToProps(dispatch: any) {
  return { reloadConfig: () => dispatch(fetchConfig()) };
}
export default connect(mapStateToProps, mapDispatchToProps)(FpsConfiguration);
