import React, { useState } from 'react';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import Toggle from 'material-ui/Toggle';
import Checkbox from 'material-ui/Checkbox';
import { ColorResult, HuePicker } from 'react-color';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import {
  ControlStatus,
  ControlViewPrivateDTO,
  DeviceType,
} from '@cvfm-front/tefps-types';
import './ControlViewDetail.css';
import { AuthorizedVehicleCategory, getConfigState } from 'config/duck';
import { translateVehicleTypeFilterOptions } from 'commons/Utils/vehicleTypeUtils';

const { _t, _tg } = window.loadTranslations(__filename);

const CONTROL_VIEW_STATUS_ITEMS: Array<{
  id: string;
  label: string;
  value: ControlStatus;
}> = [
  { id: 'KO', label: 'KO', value: 'KO' },
  { id: 'OK', label: 'OK', value: 'OK' },
  { id: 'EXEMPTION', label: 'EXEMPTION', value: 'EXEMPTION' },
  { id: 'WAITING_REVIEW', label: 'A VERIFIER', value: 'WAITING_REVIEW' },
];

const CONTROL_VIEW_ORIGIN_ITEMS: Array<{
  id: string;
  label: string;
  value: DeviceType;
}> = [
  { id: 'pda', label: 'PDA', value: 'PDA' },
  { id: 'lapi', label: 'LAPI', value: 'AUTOMATED_CONTROL_VEHICLE' },
];

type ControlViewDetailReduxProps = {
  authorizedVehicleCategories: Array<AuthorizedVehicleCategory>;
};

type ControlViewDetailProps = {
  open: boolean;
  onCancel: () => void;
  onConfirm: (controlView: ControlViewPrivateDTO) => void;
  controlView: ControlViewPrivateDTO;
  exemptionReasons: Array<{ id: string; name: string }>;
  withTeams: boolean;
  controlTeams: Array<{ id: string; name: string }>;
} & ControlViewDetailReduxProps;

const ControlViewDetail = ({
  open,
  onCancel,
  onConfirm,
  controlView,
  exemptionReasons,
  withTeams,
  controlTeams,
  authorizedVehicleCategories,
}: ControlViewDetailProps): JSX.Element => {
  const REVIEW_REASON_ITEMS: Array<{
    id: string;
    label: string;
    value: string;
  }> = [
    {
      id: 'pricingCategory',
      label: _t('element.selector.pricingCategory'),
      value: 'pricingCategory',
    },
    {
      id: 'vehicleType',
      label: _t('element.selector.vehicleType'),
      value: 'vehicleType',
    },
  ];

  const CONTROL_VIEW_FPS_ITEMS: Array<{
    id: string;
    label: string;
    value: string;
  }> = [
    { id: 'with', label: _t('element.fps.with'), value: 'WITH' },
    { id: 'without', label: _t('element.fps.without'), value: 'WITHOUT' },
  ];

  const [editControlView, setEditControlView] = useState<ControlViewPrivateDTO>(
    { ...controlView }
  );

  function onCancelAction() {
    onCancel();
  }

  function onConfirmAction() {
    onConfirm(editControlView);
  }

  function handleChangeField(field: string, value: unknown) {
    setEditControlView({ ...editControlView, [field]: value });
  }

  function handleChangeColor(color: ColorResult) {
    setEditControlView({ ...editControlView, displayColorHex: color.hex });
  }

  function handleChangeRelevantParkingRightPricingCategories(value: string) {
    const splitedValues: string[] = value
      .split(',')
      .map(splitedValue => splitedValue.trim());
    setEditControlView({
      ...editControlView,
      relevantParkingRightPricingCategories: splitedValues,
    });
  }

  const actions = [
    <BoButton
      label={_tg('action.cancel')}
      key="control-view-detail-cancel"
      secondary
      style={{ marginRight: 10 }}
      onClick={onCancelAction}
    />,
    <BoButton
      label={_tg('action.confirm')}
      key="control-view-detail-confirm"
      primary
      style={{ marginRight: 10 }}
      onClick={onConfirmAction}
      disabled={editControlView.name.length === 0}
    />,
  ];

  return (
    <Dialog
      title={_t('element.controlViewTitle')}
      open={open}
      actions={actions}
      autoScrollBodyContent
      onRequestClose={onCancelAction}
      className="control-view-detail_dialog"
    >
      <div className="control-view-detail_container">
        <div className="control-view-detail_section">
          <div className="control-view-detail_row">
            <div className="control-view-detail_field">
              <span className="control-view-detail_label">
                {_t('element.field.name')}
              </span>
              <TextField
                id="controlViewDetail-name"
                className="control-view-detail_value"
                value={editControlView.name}
                onChange={(e, v) => handleChangeField('name', v)}
              />
            </div>
            <div className="control-view-detail_field">
              <span className="control-view-detail_toggle">
                {_t('element.field.enabled')}
              </span>
              <Toggle
                id="controlViewDetail-enable"
                className="control-view-detail_toggle"
                toggled={editControlView.enabled}
                labelPosition="right"
                color="primary"
                onToggle={({ currentTarget }: React.MouseEvent<any>) =>
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  handleChangeField('enabled', currentTarget.checked)
                }
              />
            </div>
          </div>
        </div>
        <div className="control-view-detail_section">
          <div className="control-view-detail_row">
            <div className="control-view-detail_field">
              <span className="control-view-detail_label">
                {_t('element.field.status')}
              </span>
              <SelectField
                id="controlViewDetail-controlStatus"
                className="control-view-detail_value"
                multiple
                value={editControlView.controlStatuses}
                onChange={(e, idx, v) =>
                  handleChangeField('controlStatuses', v)
                }
              >
                {CONTROL_VIEW_STATUS_ITEMS.map(item => (
                  <MenuItem
                    key={item.id}
                    id={item.id}
                    value={item.value}
                    primaryText={item.label}
                    checked={editControlView.controlStatuses.includes(
                      item.value
                    )}
                  />
                ))}
              </SelectField>
            </div>
          </div>
          {authorizedVehicleCategories.length > 0 && (
            <>
              <div className="control-view-detail_row">
                <div className="control-view-detail_field">
                  <span className="control-view-detail_label">
                    {_t('element.field.vehicleType')}
                  </span>
                  <SelectField
                    id="controlViewDetail-vehicleType"
                    className="control-view-detail_value"
                    multiple
                    value={editControlView.vehicleTypes}
                    onChange={(e, idx, v) =>
                      handleChangeField('vehicleTypes', v)
                    }
                  >
                    {translateVehicleTypeFilterOptions(
                      authorizedVehicleCategories
                    ).map(item => (
                      <MenuItem
                        key={item.value}
                        id={item.value}
                        value={item.value}
                        primaryText={item.label}
                        checked={editControlView.vehicleTypes.includes(
                          item.value
                        )}
                      />
                    ))}
                  </SelectField>
                </div>
              </div>
              <div className="control-view-detail_row">
                <div className="control-view-detail_field">
                  <span className="control-view-detail_label">
                    {_t('element.field.presumedVehicleTypes')}
                  </span>
                  <SelectField
                    id="controlViewDetail-presumedVehicleTypes"
                    className="control-view-detail_value"
                    multiple
                    value={editControlView.presumedVehicleTypes}
                    onChange={(e, idx, v) =>
                      handleChangeField('presumedVehicleTypes', v)
                    }
                  >
                    {translateVehicleTypeFilterOptions(
                      authorizedVehicleCategories
                    ).map(item => (
                      <MenuItem
                        key={item.value}
                        id={item.value}
                        value={item.value}
                        primaryText={item.label}
                        checked={editControlView.presumedVehicleTypes.includes(
                          item.value
                        )}
                      />
                    ))}
                  </SelectField>
                </div>
              </div>
            </>
          )}
          <div className="control-view-detail_row">
            <div className="control-view-detail_field">
              <span className="control-view-detail_label">
                {_t('element.field.relevantParkingRightPricingCategory')}
              </span>
              <TextField
                id="controlViewDetail-relevantParkingRightPricingCategories"
                className="control-view-detail_value"
                type="text"
                value={editControlView.relevantParkingRightPricingCategories.toString()}
                onChange={(e, v) =>
                  handleChangeRelevantParkingRightPricingCategories(v)
                }
              />
            </div>
          </div>
          <div className="control-view-detail_row">
            <div className="control-view-detail_field">
              <span className="control-view-detail_label">
                {_t('element.field.reviewReason')}
              </span>
              <SelectField
                id="controlViewDetail-reviewReason"
                className="control-view-detail_value"
                multiple
                value={editControlView.reviewReasons}
                onChange={(e, idx, v) => handleChangeField('reviewReasons', v)}
              >
                {REVIEW_REASON_ITEMS.map(item => (
                  <MenuItem
                    key={item.id}
                    id={item.id}
                    value={item.value}
                    primaryText={item.label}
                    checked={editControlView.reviewReasons.includes(item.value)}
                  />
                ))}
              </SelectField>
            </div>
          </div>
          <div className="control-view-detail_row">
            <div className="control-view-detail_field">
              <span className="control-view-detail_label">
                {_t('element.field.origin')}
              </span>
              <SelectField
                id="controlViewDetail-deviceTypes"
                className="control-view-detail_value"
                multiple
                value={editControlView.deviceTypes}
                onChange={(e, idx, v) => handleChangeField('deviceTypes', v)}
              >
                {CONTROL_VIEW_ORIGIN_ITEMS.map(item => (
                  <MenuItem
                    key={item.id}
                    id={item.id}
                    value={item.value}
                    primaryText={item.label}
                    checked={editControlView.deviceTypes.includes(item.value)}
                  />
                ))}
              </SelectField>
            </div>
          </div>
          <div className="control-view-detail_row">
            <div className="control-view-detail_field">
              <span className="control-view-detail_label">
                {_t('element.field.exemption')}
              </span>
              <SelectField
                id="controlViewDetail-exemptionReasons"
                className="control-view-detail_value"
                multiple
                value={editControlView.exemptionReasons}
                onChange={(e, idx, v) =>
                  handleChangeField('exemptionReasons', v)
                }
              >
                {exemptionReasons.map(item => (
                  <MenuItem
                    key={item.id}
                    id={item.id}
                    value={item.id}
                    primaryText={item.name}
                    checked={editControlView.exemptionReasons.includes(item.id)}
                  />
                ))}
              </SelectField>
            </div>
          </div>
          <div className="control-view-detail_row">
            <div className="control-view-detail_field">
              <span className="control-view-detail_label">
                {_t('element.field.fps')}
              </span>
              <SelectField
                id="controlViewDetail-fpsFilters"
                className="control-view-detail_value"
                multiple
                value={editControlView.fpsFilters}
                onChange={(e, idx, v) => handleChangeField('fpsFilters', v)}
              >
                {CONTROL_VIEW_FPS_ITEMS.map(item => (
                  <MenuItem
                    key={item.id}
                    id={item.id}
                    value={item.value}
                    primaryText={item.label}
                    checked={editControlView.fpsFilters.includes(item.value)}
                  />
                ))}
              </SelectField>
            </div>
          </div>
          <div className="control-view-detail_row">
            <div className="control-view-detail_field">
              <span className="control-view-detail_label">
                {_t('element.field.useReliabilityThreshold')}
              </span>
              <div className="control-view-detail_toggle">
                <Checkbox
                  key="controlViewDetail-useReliabilityThreshold"
                  className="control-view-detail_value"
                  checked={editControlView.useReliabilityThreshold}
                  onCheck={(e, v) =>
                    handleChangeField('useReliabilityThreshold', v)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="control-view-detail_section">
          <div className="control-view-detail_row">
            <div className="control-view-detail_field">
              <span className="control-view-detail_label">
                {_t('element.field.userCanSwitch')}
              </span>
              <div className="control-view-detail_toggle">
                <Checkbox
                  key="controlViewDetail-userCanSwitch"
                  className="control-view-detail_value"
                  checked={editControlView.userCanSwitch}
                  onCheck={(e, v) => handleChangeField('userCanSwitch', v)}
                />
              </div>
            </div>
          </div>
          <div className="control-view-detail_row">
            <div className="control-view-detail_field">
              <span className="control-view-detail_label">
                {_t('element.field.userDefaultSwitchState')}
              </span>
              <div className="control-view-detail_toggle">
                <Checkbox
                  key="controlViewDetail-userDefaultSwitchState"
                  className="control-view-detail_value"
                  checked={
                    !editControlView.userCanSwitch ||
                    editControlView.userDefaultSwitchState
                  }
                  disabled={!editControlView.userCanSwitch}
                  onCheck={(e, v) =>
                    handleChangeField('userDefaultSwitchState', v)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="control-view-detail_section">
          <div className="control-view-detail_row">
            <div className="control-view-detail_field">
              <span className="control-view-detail_label">
                {_t('element.field.displayColorHex')}
              </span>
            </div>
          </div>
          <div className="control-view-detail_row">
            <div
              className="control-view-detail_color-preview"
              style={{ backgroundColor: editControlView.displayColorHex }}
            />
            <HuePicker
              key="controlViewDetail-displayColorHex"
              className="control-view-detail_color"
              color={editControlView.displayColorHex}
              width="66%"
              onChangeComplete={handleChangeColor}
            />
          </div>
          <div className="control-view-detail_row">
            <div className="control-view-detail_field">
              <span className="control-view-detail_label">
                {_t('element.field.refreshDelayMinutes')}
              </span>
              <TextField
                id="controlViewDetail-refreshDelayMinutes"
                className="control-view-detail_value"
                type="number"
                min={1}
                step={1}
                value={editControlView.refreshDelayMinutes}
                onChange={(e, v) =>
                  handleChangeField('refreshDelayMinutes', parseInt(v, 10))
                }
              />
            </div>
          </div>
        </div>
        {withTeams && (
          <div className="control-view-detail_section">
            <div className="control-view-detail_row">
              <div className="control-view-detail_field">
                <span className="control-view-detail_label">
                  {_t('element.field.teams')}
                </span>
                <SelectField
                  id="controlViewDetail-teamIds"
                  className="control-view-detail_value"
                  multiple
                  value={editControlView.teamIds}
                  onChange={(e, idx, v) => handleChangeField('teamIds', v)}
                >
                  {controlTeams.map(item => (
                    <MenuItem
                      key={item.id}
                      id={item.id}
                      value={item.id}
                      primaryText={item.name}
                      checked={editControlView.teamIds.includes(item.id)}
                    />
                  ))}
                </SelectField>
              </div>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
};

export default connect(state => {
  const { authorizedVehicleCategories } = getConfigState(state);
  return { authorizedVehicleCategories };
})(ControlViewDetail);
