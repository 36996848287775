import React, { CSSProperties } from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import Card from 'material-ui/Card';
import moment, { Moment } from 'moment';

import BoButton from 'facade/BoButton';
import MonthYearPicker from 'commons/MonthYearPicker';
import FlexCenter from 'commons/FlexCenter';
import ChartJSWrapper from 'commons/ChartJSWrapper';
import ErrorBlock from 'commons/ErrorBlock';
import { getDefaultPage } from 'api/dashboard';
import { ChartDTO } from 'api/dashboard/types';
import { ApiError } from 'api/ApiError';

import { buildChartData, buildOptions } from '../../commons/helper';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TXT: CSSProperties = {
  marginRight: 30,
};

type FpsPageProps = {
  pageId: string;
};

type FpsPageState = {
  charts: Array<ChartDTO> | null | undefined;
  error: Error | null | undefined;
  start: Moment;
};

const INITIAL_STATE: FpsPageState = {
  charts: null,
  error: null,
  start: moment().subtract(12, 'month'),
};

export default class FpsPage extends React.Component<
  FpsPageProps,
  FpsPageState
> {
  constructor(props: FpsPageProps) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount(): void {
    const { pageId } = this.props;
    void this.fetchStat(pageId);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: FpsPageProps): void {
    const { pageId } = nextProps;
    void this.fetchStat(pageId);
  }

  fetchStat = async (pageId: string): Promise<void> => {
    const { start } = this.state;
    this.setState({ error: null });
    try {
      const charts = await getDefaultPage(pageId, {
        from: start.valueOf(),
        to: start
          .clone()
          .add(13, 'month')
          .valueOf(),
      });
      this.setState({ charts });
    } catch (error) {
      this.setState({ error: error as ApiError });
    }
  };

  onChangeStart = (start: Moment): void => {
    this.setState({ start: start.startOf('month') });
  };

  render(): React.ReactNode {
    const { charts, error, start } = this.state;
    const { pageId } = this.props;
    if (error) {
      return (
        <ErrorBlock
          message={_tg(
            'tefps.dashboard.finance.finance.errorWhileLoadingGraphs'
          )}
          error={error}
        />
      );
    }

    return (
      <div>
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            justifyItems: 'center',
            marginTop: 20,
          }}
        >
          <div style={{ ...STYLE_TXT, marginLeft: 30 }}>
            {_tg('field.date.from')}
          </div>
          <MonthYearPicker date={start} changeDate={this.onChangeStart} />
          <BoButton
            onClick={() => this.fetchStat(pageId)}
            label={_tg('commons.toFilter')}
          />
        </div>
        {!charts && (
          <FlexCenter>
            <CircularProgress />
          </FlexCenter>
        )}
        {charts &&
          charts.map(data => (
            <div key={data.title} style={{ marginTop: '30px' }}>
              <Card>
                <div style={{ padding: 20 }}>
                  <ChartJSWrapper
                    update
                    type="line"
                    width={400}
                    options={buildOptions(
                      data.title,
                      0,
                      data.title.includes('Pourcentage') ||
                        data.title.includes('Percentage')
                        ? ' %'
                        : ''
                    )}
                    data={buildChartData(data)}
                    height={150}
                  />
                </div>
              </Card>
            </div>
          ))}
      </div>
    );
  }
}
