import FileSaver from 'file-saver';
import * as React from 'react';

import BoButton from 'facade/BoButton';
import { GpvStatsDTO } from 'api/tepv/stats/types';
import { exportGpvGraph } from 'api/tepv/stats';
import Exporter from 'commons/Exporter';
import ChartCustom from 'Dashboard/pv/GpvGraphs/Charts/ChartCustom';

import {
  EXPORT_COLUMNS,
  monthNameMapper,
  sortByCount,
  sortByMonth,
} from './utils';

type Props = {
  statsFirst: GpvStatsDTO;
  statsSecond: GpvStatsDTO;
  exportStats: () => void | null | undefined;
};

class ChartContainerComparison extends React.Component<Props> {
  gpvByMonthRef = React.createRef<ChartCustom>();
  gpvByCategoryRef = React.createRef<ChartCustom>();
  gpvByGroupRef = React.createRef<ChartCustom>();
  gpvByPresetNoteRef = React.createRef<ChartCustom>();
  gpvByVehicleCountryRef = React.createRef<ChartCustom>();
  gpvByVehicleCategoryRef = React.createRef<ChartCustom>();

  getTotalFirst = () => this.props.statsFirst.gpvCount;
  getTotalSecond = () => this.props.statsSecond.gpvCount;

  exportGraphs = async () => {
    const refs = [
      this.gpvByMonthRef,
      this.gpvByCategoryRef,
      this.gpvByGroupRef,
      this.gpvByPresetNoteRef,
      this.gpvByVehicleCountryRef,
      this.gpvByVehicleCategoryRef,
    ];

    const exportData = refs.reduce(
      (acc: any, ref: React.RefObject<ChartCustom>) => {
        if (ref.current) {
          return acc.concat(ref.current.getExportData());
        }
        return acc;
      },
      []
    );

    const file = await exportGpvGraph(exportData);
    const blobFile = await file.blob();
    FileSaver.saveAs(blobFile, 'export.pdf');
  };

  render() {
    const {
      statsFirst: {
        gpvsBySignatureDate: gpvsBySignatureDateFirst,
        gpvsByCategory: gpvsByCategoryFirst,
        gpvsByPresetNote: gpvsByPresetNoteFirst,
        gpvsByPlateCountry: gpvsByPlateCountryFirst,
        gpvsByVehicleCategory: gpvsByVehicleCategoryFirst,
      },
      statsSecond: {
        gpvsBySignatureDate: gpvsBySignatureDateSecond,
        gpvsByCategory: gpvsByCategorySecond,
        gpvsByPresetNote: gpvsByPresetNoteSecond,
        gpvsByPlateCountry: gpvsByPlateCountrySecond,
        gpvsByVehicleCategory: gpvsByVehicleCategorySecond,
      },
      exportStats,
    } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <div>
          <ChartCustom
            ref={this.gpvByMonthRef}
            acceptedTypes={['bar']}
            graphsData={[
              {
                title: 'gpv par mois (1)',
                stats: sortByMonth(gpvsBySignatureDateFirst),
              },
              {
                title: 'gpv par mois (2)',
                stats: sortByMonth(gpvsBySignatureDateSecond),
              },
            ]}
            mapper={monthNameMapper}
          />
        </div>
        <div>
          <ChartCustom
            ref={this.gpvByCategoryRef}
            acceptedTypes={['pie', 'bar']}
            graphsData={[
              {
                title: 'gpv par catégorie NATINF (1)',
                stats: gpvsByCategoryFirst,
              },
              {
                title: 'gpv par catégorie NATINF (2)',
                stats: gpvsByCategorySecond,
              },
            ]}
          />
        </div>
        <div>
          <ChartCustom
            ref={this.gpvByPresetNoteRef}
            acceptedTypes={['pie', 'bar']}
            graphsData={[
              {
                title: 'gpv par renseignement prédéfini (1)',
                stats: gpvsByPresetNoteFirst,
              },
              {
                title: 'gpv par renseignement prédéfini (2)',
                stats: gpvsByPresetNoteSecond,
              },
            ]}
          />
        </div>
        <div>
          <ChartCustom
            ref={this.gpvByVehicleCountryRef}
            acceptedTypes={['pie', 'bar']}
            graphsData={[
              {
                title: "gpv par pays d'immatriculation (1)",
                stats: sortByCount(gpvsByPlateCountryFirst),
              },
              {
                title: "gpv par pays d'immatriculation (2)",
                stats: sortByCount(gpvsByPlateCountrySecond),
              },
            ]}
          />
        </div>
        <div>
          <ChartCustom
            ref={this.gpvByVehicleCategoryRef}
            acceptedTypes={['pie', 'bar']}
            graphsData={[
              {
                title: 'gpv par catégorie de véhicule (1)',
                stats: sortByCount(gpvsByVehicleCategoryFirst),
              },
              {
                title: 'gpv par catégorie de véhicule (2)',
                stats: sortByCount(gpvsByVehicleCategorySecond),
              },
            ]}
          />
        </div>
        {exportStats && (
          <div style={{ padding: '20px 35px', display: 'flex' }}>
            <Exporter
              disabled={false}
              columns={EXPORT_COLUMNS}
              type="Stats"
              filters={{}}
              fileExport={exportStats}
              description="L'export générera deux fichiers"
            />
            <BoButton
              style={{ margin: '0 15px' }}
              label="Export tous graphiques"
              onClick={this.exportGraphs}
              primary
            />
          </div>
        )}
      </div>
    );
  }
}

export default ChartContainerComparison;
