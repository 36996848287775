const { _tg } = window.loadTranslations(__filename);

export const translateTableCols = () => [
  { label: _tg('field.organisation.id'), width: 120 },
  { label: _tg('action.edit'), width: 100 },
  { label: _tg('action.delete'), width: 100 },
];

export const translateDashboardProfilesCols = () => [
  {
    label: _tg(
      'tefps.configuration.kpiConfiguration.supersetDashboardConfig.dashboardName'
    ),
    width: 120,
  },
  {
    label: _tg(
      'tefps.configuration.kpiConfiguration.supersetDashboardConfig.authorizedProfiles'
    ),
    width: 100,
  },
];
