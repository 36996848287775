import React from 'react';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';
import Select from 'commons/SidebarV2/Components/Select';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import Times from 'commons/SidebarV2/Components/Times';
import Dates from 'commons/SidebarV2/Components/Dates';
import Input from 'commons/SidebarV2/Components/Input';
import { listProfiles } from 'api/profiles';
import { fetchOrganizations } from 'api/organizations';
import { fetchMatchingAgents } from 'api/accounts';
import { GpvFilter } from 'api/tepv/stats/types';
import { fetchMatchingMotifs } from 'api/tepv/motifs';
import { motifsCategoriesOptions } from 'Dashboard/pv/GpvMaps/utils';
import { fetchMatchingGpvs } from 'api/tepv/gpv';
import { INFRACTION_CASES_OPTIONS } from 'api/tepv/gpv/types';

import { initGpvFilters, vehicleCategories } from './utils';

type GpvSideBarProps = {
  filters: GpvFilter;
  filtersData: any;
  updateFilters: (f: GpvFilter) => void;
  totalHits?: number;
  presetNotes: Array<{ key: string; label: string }>;
  infractionLocations?: Array<{ key: string; label: string }>;
};

const vehicleRemovalStatusOptions = [
  { value: 'HAS', label: 'Enlèvement demandé' },
  {
    value: 'HAS_NOT',
    label: 'Enlèvement non demandé',
  },
];
const saisineStatusOptions = [
  { value: 'HAS', label: 'Saisine demandé' },
  {
    value: 'HAS_NOT',
    label: 'Saisine non demandé',
  },
];
const photosStatusOptions = [
  { value: 'HAS', label: 'Avec photos' },
  {
    value: 'HAS_NOT',
    label: 'Sans photos',
  },
];

const vehicleCategoriesOptions = vehicleCategories.map(cat => ({
  key: `${cat}`,
  label: `${cat}`,
}));

type GpvSidebarState = {
  filterMotif: Array<{ id: string; name: string }>;
  addressGpv: Array<{ id: string; name: string }>;
  profileKeys: Array<{ key: string; label: string }>;
  organizationKeys: Array<{ key: string; label: string }>;
  agentSearchOptions: Array<{ id: string; name: string }>;
};

class GpvSideBar extends React.Component<GpvSideBarProps, GpvSidebarState> {
  state: GpvSidebarState = {
    filterMotif: [],
    addressGpv: [],
    profileKeys: [],
    organizationKeys: [],
    agentSearchOptions: [],
  };

  componentDidMount() {
    this.fetchMotif();
    this.fetchGpvAddress();
    this.fetchProfiles();
    this.fetchOrganizations();
  }

  onChange = (id: string, value: any) => {
    const { updateFilters, filters } = this.props;
    updateFilters({ ...filters, [id]: value });
  };

  fetchMotif = async (motifFilter?: string) => {
    if (!motifFilter || motifFilter.trim().length < 1) {
      return;
    }

    const motifs = (await fetchMatchingMotifs(motifFilter)).matchingMotifs;

    const codesMotif = motifs.map(motif => ({
      id: motif.qualification,
      name: `${motif.natinfCode}: ${motif.qualification}`,
    }));

    this.setState({ filterMotif: codesMotif });
  };

  fetchGpvAddress = async (gpvFilter?: string) => {
    if (!gpvFilter || gpvFilter.length < 3) {
      return;
    }

    const gpvs = (await fetchMatchingGpvs(gpvFilter)).matchingGpvs;

    const addressGpv = gpvs.map(address => ({
      id: address,
      name: address,
    }));

    this.setState({ addressGpv });
  };

  fetchProfiles = async () => {
    try {
      const profiles = await listProfiles(false, 'pv');
      const profileKeys = profiles.map(profile => ({
        key: profile.profileId,
        label: profile.name,
      }));
      this.setState({
        profileKeys,
      });
    } catch (err) {
      this.setState({ profileKeys: [] });
    }
  };

  fetchOrganizations = async () => {
    try {
      const organizations = await fetchOrganizations(false, 'pv');
      const organizationKeys = organizations.map(organization => {
        return {
          key: organization.organizationId,
          label: organization.name,
        };
      });

      this.setState({ organizationKeys });
    } catch (err) {
      this.setState({ organizationKeys: [] });
    }
  };

  fetchAgentsAndGenerateOptions = async (agentFilter?: string) => {
    let agents: any = [];
    if (agentFilter && agentFilter.length >= 3) {
      agents = (await fetchMatchingAgents(agentFilter, 'pv')).matchingAgents;
    }

    const agentSearchOptions = agents.map(
      ({ id, fullName }: { id: string; fullName: string }) => ({
        id,
        name: fullName,
      })
    );
    this.setState({ agentSearchOptions });
  };

  resetFilters = () => this.props.updateFilters(initGpvFilters());

  render() {
    const {
      totalHits,
      filters,
      filtersData,
      presetNotes,
      infractionLocations,
    } = this.props;
    const {
      filterMotif,
      addressGpv,
      profileKeys,
      organizationKeys,
      agentSearchOptions,
    } = this.state;
    return (
      <Sidebar>
        <BarTitle resetFilters={this.resetFilters} />
        {typeof totalHits === 'number' && <HitsCounter hits={totalHits} />}
        <Autocomplete
          id="motifText"
          title="Code ou Qualification du Motif"
          options={filterMotif}
          onChange={this.onChange}
          onAutocomplete={this.fetchMotif}
          search={filters.motifText}
        />
        <Select
          id="category"
          title="Catégories Motifs"
          onChange={this.onChange}
          selected={filters.category}
          options={motifsCategoriesOptions}
          multiple
        />
        <Select
          id="infractionCases"
          title="Cas infraction"
          onChange={this.onChange}
          selected={filters.infractionCases}
          options={INFRACTION_CASES_OPTIONS}
          multiple
        />
        {presetNotes && presetNotes.length > 0 && (
          <Select
            id="presetNote"
            title="Renseignements prédéfinis"
            onChange={this.onChange}
            selected={filters.presetNotes}
            options={presetNotes}
            multiple
          />
        )}
        {infractionLocations && infractionLocations.length > 0 && (
          <Select
            id="infractionLocation"
            title="Lieux de verbalisation"
            onChange={this.onChange}
            selected={filters.infractionLocations}
            options={infractionLocations}
            multiple
          />
        )}
        <Dates
          id="signatureDate"
          title="Date de signature"
          dates={filters.signatureDate}
          onChange={this.onChange}
        />
        <Times
          id="signatureTime"
          title="Horaires"
          times={filters.signatureTime}
          onChange={this.onChange}
        />
        <Autocomplete
          id="address"
          title="Adresse"
          options={addressGpv}
          onChange={this.onChange}
          onAutocomplete={this.fetchGpvAddress}
          search={filters.address}
        />
        <Checkboxes
          id="vehicleRemovalStatus"
          title="Enlèvement du véhicule"
          options={vehicleRemovalStatusOptions}
          filters={filters.vehicleRemovalStatus}
          faceting={filtersData.vehicleRemovalStatus}
          onChange={this.onChange}
        />
        <Checkboxes
          id="saisineStatus"
          title="Saisine"
          options={saisineStatusOptions}
          filters={filters.saisineStatus}
          faceting={filtersData.saisineStatus}
          onChange={this.onChange}
        />
        <Checkboxes
          id="photosStatus"
          title="Photos"
          options={photosStatusOptions}
          filters={filters.photosStatus}
          faceting={filtersData.photosStatus}
          onChange={this.onChange}
        />
        <Autocomplete
          id="agentSearchText"
          title="Agent"
          options={agentSearchOptions}
          onChange={this.onChange}
          onAutocomplete={this.fetchAgentsAndGenerateOptions}
          search={filters.agentSearchText}
        />
        {organizationKeys.length > 0 && (
          <Select
            id="organizationIds"
            title="Organisation"
            onChange={this.onChange}
            selected={filters.organizationIds}
            options={organizationKeys}
            multiple
          />
        )}
        {profileKeys.length > 0 && (
          <Select
            id="profiles"
            title="Profil d'agent"
            onChange={this.onChange}
            selected={filters.profiles}
            options={profileKeys}
            multiple
          />
        )}
        <Select
          id="vehicleCategory"
          title="Catégorie de véhicule"
          onChange={this.onChange}
          selected={filters.vehicleCategory}
          options={vehicleCategoriesOptions}
          multiple
        />
        <Input
          id="immatriculationFulltext"
          title="Plaque d'immatriculation"
          placeholder="Filtrer par plaque"
          onChange={this.onChange}
          value={filters.immatriculationFulltext}
        />
      </Sidebar>
    );
  }
}

export default GpvSideBar;
