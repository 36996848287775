import React from 'react';

import { BKG_CYAN } from 'theme';

const ContactSeparator = (): React.ReactElement => {
  return (
    <div
      style={{
        height: 2,
        width: '40%',
        flex: 1,
        backgroundColor: BKG_CYAN,
        margin: '30px auto',
      }}
    />
  );
};

export default ContactSeparator;
