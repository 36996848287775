import { CSSProperties } from 'react';

const { _tg } = window.loadTranslations(__filename);

const columnsForTable = (): Array<{
  label?: string;
  width: number;
  grow?: number;
  style?: CSSProperties;
  headerStyle?: CSSProperties;
  onSort?: boolean;
}> => [
  { label: _tg('tefps.dashboard.tvPayments.ticketId'), width: 200 },
  { label: _tg('tefps.dashboard.tvPayments.paymentId'), width: 300 },
  { label: _tg('field.payment.mode'), width: 100 },
  { label: _tg('tefps.dashboard.tvPayments.paymentDate'), width: 200 },
  { label: _tg('commons.price'), width: 100 },
  { label: _tg('field.comment'), width: 100 },
];

export default columnsForTable;
