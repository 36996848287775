import './index.css';
import React, { CSSProperties } from 'react';
import {
  HashRouter,
  Route,
  RouteComponentProps,
  Switch,
} from 'react-router-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { AutoSizer } from 'react-virtualized';
import { Scrollbars } from 'react-custom-scrollbars';

import { SnackBarProvider } from 'commons/CustomHooks/SnackBar/SnackBarContext';
// This snackbar is used for class components with redux
import Snackbar from 'commons/Snackbar';
import { BKG_GREY } from 'theme';
import ConfigLoader from 'config/ConfigLoader';
import LapiReview from 'tefps/LapiReview';
import NotificationWrapper from 'NotificationWrapper';
import { fetchVersion } from '@cvfm-front/commons-utils';
import { ModuleInfo } from '@cvfm-front/commons-types';
import { apiGetUnauthenticated } from 'api/helpers';

import Login from './Login';
import Topbar from './Topbar';
import Routes from './Routes';

import '@cvfm-front/commons-css/forms.css';
import '@cvfm-front/commons-css/separator.css';
import '@cvfm-front/commons-css/box-with-title.css';
import '@cvfm-front/commons-css/label-value.css';
import '@cvfm-front/commons-css/time-line-box.css';
import '@cvfm-front/commons-css/ui.css';
import ContextInitializer from './ContextInitializer';

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  position: 'absolute',
  height: '100%',
  width: '100%',
  backgroundColor: BKG_GREY,
  flexDirection: 'column',
  fontFamily: 'Roboto, sans-serif', // TO ADD ??
};

const STYLE_CONTENT: CSSProperties = {
  flex: 1,
  overflow: 'hidden',
};

const Content = ({
  children,
}: {
  children?: React.ReactElement;
}): JSX.Element => (
  <AutoSizer>
    {({ height, width }) => (
      <Scrollbars style={{ width, height }}>{children}</Scrollbars>
    )}
  </AutoSizer>
);

const LapiContainer = ({
  match: { path },
}: RouteComponentProps): JSX.Element => {
  const isQualityControl = path === '/qualityControl';
  return <LapiReview qualityControlMode={isQualityControl} />;
};

const DefaultContainer = (): JSX.Element => (
  <ContextInitializer>
    <>
      <Topbar />
      <div style={STYLE_CONTENT}>
        <Content>
          <Routes />
        </Content>
      </div>
    </>
  </ContextInitializer>
);

const App = (): JSX.Element => {
  async function asyncFetchVersion(): Promise<void> {
    const buildInfo: ModuleInfo = await apiGetUnauthenticated('/api');
    fetchVersion(buildInfo.project.version);
  }

  React.useEffect(() => {
    void asyncFetchVersion();
  }, []);

  return (
    <MuiThemeProvider>
      <SnackBarProvider>
        <div style={STYLE_CONTAINER}>
          <Login>
            <ConfigLoader>
              <HashRouter>
                <div style={STYLE_CONTAINER}>
                  <Switch>
                    <Route
                      path={[
                        '/review/:controlId',
                        '/review',
                        '/qualityControl',
                      ]}
                      component={LapiContainer}
                    />
                    <Route component={DefaultContainer} />
                  </Switch>
                </div>
              </HashRouter>
            </ConfigLoader>
          </Login>
        </div>
      </SnackBarProvider>
      <Snackbar />
      <NotificationWrapper />
    </MuiThemeProvider>
  );
};

export default App;
