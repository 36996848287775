const { _tg } = window.loadTranslations(__filename);

export default (): Array<{
  key: string;
  label: string;
  checked?: boolean;
}> => [
  {
    key: 'rootFpsId',
    label: _tg('tefps.filters.rapo.fpsId.title'),
    checked: true,
  },
  {
    key: 'statementDatetime',
    label: _tg('field.date.emissionDate'),
    checked: true,
  },
  {
    key: 'fpsRefundStatus',
    label: _tg('tefps.dashboard.refund.refundStatus'),
    checked: true,
  },
  {
    key: 'refundPrice',
    label: _tg('tefps.dashboard.refund.refundPrice'),
    checked: true,
  },
  {
    key: 'lastPaymentDatetime',
    label: _tg('tefps.dashboard.refund.lastPayment'),
    checked: true,
  },
  {
    key: 'lastModificationDatetime',
    label: _tg('field.date.lastModificationDateSimple'),
    checked: true,
  },
  {
    key: 'rapoClaimStatus',
    label: _tg('tefps.filters.fps.recourseClaimStatuses'),
  },
  {
    key: 'ccspClaimStatus',
    label: _tg('tefps.filters.fps.ccspClaimStatusesSimple'),
  },
  { key: 'paymentStatus', label: _tg('tefps.filters.fps.paymentStatuses') },
  { key: 'fpsStatus', label: _tg('tefps.filters.fps.fpsStatus.label') },
  { key: 'iban', label: _tg('field.payment.iban') },
  { key: 'bic', label: _tg('field.payment.bic') },
  {
    key: 'regie',
    label: _tg('tefps.dashboard.payment.concernedRegie'),
  },
  {
    key: 'statementAuthority',
    label: _tg('tefps.filters.fps.initialOrganizationShortId'),
  },
  {
    key: 'name',
    label: _tg('field.human.firstname'),
  },
  { key: 'surname', label: _tg('field.human.lastname_short') },
  { key: 'address', label: _tg('field.address.address') },
  { key: 'dgfipRefund', label: _tg('tefps.dashboard.payment.DGFIPdocument') },
];
