import React, { CSSProperties, useEffect, useState } from 'react';
import moment from 'moment';
import CircularProgress from 'material-ui/CircularProgress';
import FileSaver from 'file-saver';

import BoButton from 'facade/BoButton';
import { GpvReportDTO, GpvReportLine } from 'api/tepv/stats/types';
import { FilterDate } from 'commons/SidebarV2/Components/Dates';
import { SortParameters } from 'api/commonTypes';
import { TXT_BLACK } from 'theme';
import Content from 'commons/Content';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import DateFilter, { DatepickerClass } from 'commons/DateFilter';
import SimpleTable from 'commons/SimpleTable';
import { ListBottom } from 'commons/ListWrappers';
import Exporter from 'commons/Exporter';
import {
  askNumberOfGpvByAgentExport,
  askNumberOfGpvByAgentPdfExport,
  askNumberOfGpvByOrgExport,
  askNumberOfGpvByOrgPdfExport,
} from 'api/dashboard';
import {
  EXPORT_COL,
  EXPORT_COL_ORG,
  filtersToRequest,
} from 'Dashboard/pv/GpvReports/utils';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  flexWrap: 'wrap',
  color: TXT_BLACK,
  margin: '25px auto 40px',
  fontSize: 20,
  textAlign: 'center',
};

const STYLE_SUBTITLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  flexWrap: 'wrap',
  margin: '0 auto',
  fontSize: 15,
  textAlign: 'center',
  fontWeight: 'bold',
};

const STYLE_TEXT: CSSProperties = {
  fontSize: 12,
  fontWeight: 'bold',
  marginRight: 10,
};

const typeToName = {
  org: 'organisation',
  agent: 'agent',
};

const tableCols = (type: string) => {
  return [
    { label: `Nom de l'${typeToName[type]}`, width: 300, onSort: true },
    {
      label: "Nombre d'infractions cas A",
      width: 200,
      onSort: true,
    },
    { label: 'Date de la dernière infraction cas A', width: 200, onSort: true },
  ];
};

const footerCols = (agentCount: number, gpvCount: number, type: string) => [
  {
    label: `${agentCount.toLocaleString()} ${typeToName[type]}s actifs`,
    width: 300,
    headerStyle: { marginTop: 14 },
  },
  {
    label: `${gpvCount.toLocaleString()} infractions cas A créées`,
    width: 200,
    headerStyle: { marginTop: 14 },
  },
  {
    label: '',
    width: 200,
    headerStyle: { marginTop: 14 },
  },
];

type Props = {
  error: Error | null | undefined;
  report: GpvReportDTO | null | undefined;
  type: 'agent' | 'org';
  period: FilterDate;
  onChangeDate: (period: FilterDate) => void;
};

const sortByField = (
  report: GpvReportDTO | null | undefined,
  sort: SortParameters
): GpvReportDTO => {
  if (report == null) {
    return { lines: [] };
  }
  const sortedLines = report.lines.sort((lineA, lineB) => {
    let diff = 0;
    switch (sort.sortField) {
      // Agent name
      case 0:
        diff = lineA.name.localeCompare(lineB.name);
        break;
      // GPV count
      case 1:
        diff = lineA.count - lineB.count;
        break;
      case 2: {
        const lastGpvDateA = lineA.lastGpv;
        const lastGpvDateB = lineB.lastGpv;

        if (lastGpvDateA === null) {
          diff = lastGpvDateB == null ? 0 : 1;
          break;
        }
        if (lastGpvDateB === null) {
          diff = -1;
          break;
        }
        diff = moment(lastGpvDateA).isBefore(moment(lastGpvDateB)) ? -1 : 1;
        break;
      }
      default:
        break;
    }

    if (diff === 0) {
      diff = lineB.count - lineA.count;
    }

    return diff;
  });

  return { lines: sort.increasingOrder ? sortedLines : sortedLines.reverse() };
};

const GpvReport = ({ error, report, type, period, onChangeDate }: Props) => {
  const [sort, setSort] = useState<SortParameters>({
    sortField: 0,
    increasingOrder: false,
  });
  const [sortedReport, setSortedReport] = useState<GpvReportDTO>(
    sortByField(report, sort)
  );

  useEffect(() => {
    setSortedReport(sortByField(report, sort));
  }, [report]);

  const onUpdateSort = (sortField: number, increasingOrder: boolean) => {
    setSort({ sortField, increasingOrder });
    setSortedReport(sortByField(report, sort));
  };

  const onPdfExport = async () => {
    let file;
    if (type === 'agent') {
      file = await askNumberOfGpvByAgentPdfExport(report);
    } else {
      file = await askNumberOfGpvByOrgPdfExport(report);
    }
    const blobFile = await file.blob();
    FileSaver.saveAs(blobFile, 'export.pdf');
  };

  const itemsRenderer = (line: GpvReportLine) => [
    <span>{line.name}</span>,
    <span>{line.count}</span>,
    <span>
      {line.lastGpv
        ? moment(line.lastGpv).format('DD/MM/YYYY HH:mm')
        : 'Aucune infraction cas A'}
    </span>,
  ];

  if (error) {
    return (
      <Content>
        <FlexCenter>
          <ErrorBlock
            message="Erreur lors de la récupération des informations"
            error={error}
          />
        </FlexCenter>
      </Content>
    );
  }

  const gpvTotal = report
    ? report.lines.reduce((prev, line) => prev + line.count, 0)
    : 0;

  return (
    <Content>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{ margin: '2em auto', maxWidth: '90%' }}>
          <span style={STYLE_TITLE}>
            {_tg('dashboard.pv.gpvReports.text1', { name: typeToName[type] })}
          </span>
          <div style={{ margin: '40px auto 30px', maxWidth: 400 }}>
            <span style={STYLE_SUBTITLE}>
              {_tg('dashboard.pv.gpvReports.text2')}
            </span>
            <DateFilter
              styleText={STYLE_TEXT}
              datepickerClass={DatepickerClass.filter}
              colorResetButton={TXT_BLACK}
              dates={period}
              onChange={onChangeDate}
            />
          </div>
          {!report ? (
            <FlexCenter>
              <CircularProgress />
            </FlexCenter>
          ) : (
            <div
              style={{
                margin: 'auto',
                width: 740,
              }}
            >
              <SimpleTable
                maxHeight={500}
                cols={tableCols(type)}
                rowHeight={50}
                header
                footer={footerCols(sortedReport.lines.length, gpvTotal, type)}
                itemsRenderer={itemsRenderer}
                items={sortedReport.lines}
                remoteRowCount={report.lines.length}
                colSorted={sort.sortField}
                sortOrder={sort.increasingOrder}
                onSort={onUpdateSort}
              />
            </div>
          )}
          <ListBottom style={{ marginTop: '100px' }}>
            {type === 'agent' ? (
              <Exporter
                disabled={!sortedReport.lines.length}
                columns={EXPORT_COL}
                type="Statistiques Agents"
                filters={filtersToRequest(period, sort, type)}
                fileExport={askNumberOfGpvByAgentExport}
              />
            ) : (
              <Exporter
                disabled={!sortedReport.lines.length}
                columns={EXPORT_COL_ORG}
                type="Statistiques Organisations"
                filters={filtersToRequest(period, sort, type)}
                fileExport={askNumberOfGpvByOrgExport}
              />
            )}
            <BoButton
              style={{ margin: '0 15px' }}
              label="Export PDF"
              onClick={onPdfExport}
              primary
            />
          </ListBottom>
        </div>
      </div>
    </Content>
  );
};

export default GpvReport;
