import React, { CSSProperties } from 'react';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import Snackbar from 'material-ui/Snackbar';
import TextField from 'material-ui/TextField';

import Content from 'commons/Content';
import SimpleTable from 'commons/SimpleTable';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import AdvertisingModal from 'commons/AdvertisingModal';
import { BKG_CYAN, BKG_PINK } from 'theme';
import { fetchWhiteListedPlates } from 'api/whitelist';

import AddPlate from './AddPlates';
import DeletePlate from './DeletePlate';

const { _t } = window.loadTranslations(__filename);

const STYLE_ICON: CSSProperties = {
  cursor: 'pointer',
};

type State = {
  plates: Array<string>;
  filteredPlates: Array<string>;
  deleteOpen: boolean;
  deletePlate: string;
  message: string;
  error: Error | null | undefined;
};

const tableCols = [
  { label: '', width: 50 },
  { label: '', width: 200, grow: 1 },
  { label: '', width: 100 },
];

// eslint-disable-next-line @typescript-eslint/ban-types
class WhiteList extends React.Component<{}, State> {
  constructor(props: never) {
    super(props);
    this.state = {
      plates: [],
      filteredPlates: [],
      deleteOpen: false,
      deletePlate: '',
      message: '',
      error: null,
    };
  }

  componentDidMount(): void {
    void this.loadWhiteListedPlate();
  }

  loadWhiteListedPlate = async (): Promise<void> => {
    try {
      const plates = await fetchWhiteListedPlates();
      this.setState({ plates, filteredPlates: plates });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  closeDeleteModal = (message: string, reload: boolean): void => {
    if (reload) {
      void this.loadWhiteListedPlate();
    }
    this.setState({ deleteOpen: false, message });
  };

  openDeleteModal = ({ currentTarget }: React.MouseEvent<any>): void => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const deletePlateOpened = currentTarget.dataset.plate as string;
    this.setState({
      deleteOpen: true,
      deletePlate: deletePlateOpened,
    });
  };

  handleAddPlates = (message: string): void => {
    void this.loadWhiteListedPlate();
    this.setState({ message });
  };

  hancleCloseMessage = (): void => this.setState({ message: '' });

  handleChangeSearch = (
    _: React.ChangeEvent<HTMLInputElement>,
    search: string
  ): void => {
    const { plates } = this.state;
    const searchNorm = search.replace(/[^A-Za-z0-9]/g, '').toUpperCase();
    const filteredPlates = plates.filter((plate: string) =>
      plate
        .replace(/[^A-Za-z0-9]/g, '')
        .toUpperCase()
        .startsWith(searchNorm)
    );
    this.setState({ filteredPlates });
  };

  render(): React.ReactNode {
    const {
      filteredPlates,
      deleteOpen,
      deletePlate,
      message,
      error,
    } = this.state;

    if (error) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock error={error} message={_t('feedback.error')} />
          </FlexCenter>
        </Content>
      );
    }
    return (
      <Content>
        <AdvertisingModal module="fps" />
        <div style={{ position: 'relative', padding: 20, paddingTop: 0 }}>
          <TextField
            onChange={this.handleChangeSearch}
            floatingLabelText={_t('element.fieldSearch.label')}
            style={{ width: '100%' }}
          />
          <SimpleTable
            maxHeight={1500}
            cols={tableCols}
            header={false}
            rowHeight={50}
            items={filteredPlates}
            itemsRenderer={(plate: string, index: number) => [
              <span>{index}</span>,
              <span>{plate}</span>,
              <DeleteIcon
                style={STYLE_ICON}
                color={BKG_CYAN}
                hoverColor={BKG_PINK}
                onClick={this.openDeleteModal}
                data-plate={plate}
              />,
            ]}
          />
          <AddPlate onAdd={this.handleAddPlates} />
          <DeletePlate
            open={deleteOpen}
            onClose={this.closeDeleteModal}
            plate={deletePlate}
          />
        </div>
        <Snackbar
          open={!!message}
          message={message}
          autoHideDuration={4000}
          style={{ pointerEvents: 'none', whiteSpace: 'nowrap' }}
          bodyStyle={{ pointerEvents: 'initial', maxWidth: 'none' }}
          onRequestClose={this.hancleCloseMessage}
        />
      </Content>
    );
  }
}

export default WhiteList;
