import moment from 'moment';

import { LOGOUT, ApiAction } from 'api/duck';
import { SortParameters } from 'api/commonTypes';
import { ReduxDashboardState } from 'Dashboard/duck';

import { StatsAgentsSearchFilters } from './types';
import { getSortFieldIndex } from './utils';

export const SET_FILTERS = 'Dashboard/StatsAgents/SET_FILTERS';
export const SET_SORT_PARAMETERS = 'Dashboard/StatsAgents/SET_SORT_PARAMETERS';

export type StateStatsAgentsList = {
  filters: StatsAgentsSearchFilters;
  sort: SortParameters;
};

type SetFiltersAction = {
  type: typeof SET_FILTERS;
  filters: StatsAgentsSearchFilters;
};

type SortParametersAction = {
  type: typeof SET_SORT_PARAMETERS;
  sort: SortParameters;
};

type Action = SetFiltersAction | SortParametersAction | ApiAction;

export const initialFilters = (): StatsAgentsSearchFilters => ({
  statementDatetime: {
    from: moment()
      .subtract(6, 'months')
      .startOf('day')
      .toDate(),
    to: undefined,
  },
  times: {
    from: '09:00',
    to: '19:00',
  },
  zoneId: null,
  agentId: null,
  teamIds: new Set(),
  organizationShortId: null,
  sortOrder: 'ASC',
  sortField: 'nbrOfControls',
});

export const initialState = (): StateStatsAgentsList => ({
  filters: initialFilters(),
  sort: {
    sortField: getSortFieldIndex('nbrOfControls') || 0,
    increasingOrder: false,
  },
});

export default function statsAgentsReducer(
  state: StateStatsAgentsList = initialState(),
  action: Action
): StateStatsAgentsList {
  switch (action.type) {
    case SET_SORT_PARAMETERS:
      return {
        ...state,
        sort: action.sort,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case LOGOUT:
      return initialState();
    default:
      return state;
  }
}

export function getStatsAgentsState(state: any): StateStatsAgentsList {
  return (state.dashboard as ReduxDashboardState).statsAgentsList;
}

export function setFilters(filters: StatsAgentsSearchFilters): any {
  return {
    type: SET_FILTERS,
    filters,
  };
}

export function setSortParameters(sort: SortParameters): any {
  return {
    type: SET_SORT_PARAMETERS,
    sort,
  };
}
