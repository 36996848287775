import React, { CSSProperties } from 'react';
import Checkbox from 'material-ui/Checkbox';

import DateFilter, { DatepickerClass } from 'commons/DateFilter';
import {
  BKG_BLUE,
  ICON_STYLE,
  LABEL_STYLE,
  TXT_GREY,
  WIDTH_STYLE,
} from 'theme';
import { FilterDate } from 'commons/SidebarV2/Components/Dates';

import {
  COMPARISON_COLLAPSED,
  COMPARISON_EXTENDED,
  COMPARISON_PADDING,
} from './utils';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TEXT: CSSProperties = {
  fontSize: 12,
  fontFamily: 'Roboto',
  fontWeight: 'bold',
  marginRight: 10,
};

const STYLE_COMPARISON: CSSProperties = {
  backgroundColor: BKG_BLUE,
  fontFamily: 'Roboto, sans-serif',
  color: TXT_GREY,
  padding: COMPARISON_PADDING,
};

const STYLE_DATES_WRAPPER: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: 5,
};

const STYLE_DATES_ITEMS: CSSProperties = {
  flex: 1,
  maxWidth: 350,
};

type HeaderProps = {
  comparison: {
    enabled: boolean;
    firstPeriod: FilterDate;
    secondPeriod: FilterDate;
  };
  onCheck: (
    event: React.MouseEvent<HTMLInputElement>,
    enabled: boolean
  ) => void;
  onChangeFirstPeriod: (dates: {
    from?: Date | undefined;
    to?: Date | undefined;
  }) => void;
  onChangeSecondPeriod: (dates: {
    from?: Date | undefined;
    to?: Date | undefined;
  }) => void;
};

const ComparisonHeader = ({
  comparison: { enabled, firstPeriod, secondPeriod },
  onCheck,
  onChangeFirstPeriod,
  onChangeSecondPeriod,
}: HeaderProps) => {
  return (
    <div
      style={{
        ...STYLE_COMPARISON,
        height: enabled ? COMPARISON_EXTENDED : COMPARISON_COLLAPSED,
      }}
    >
      <Checkbox
        label="Comparaison"
        iconStyle={ICON_STYLE}
        labelStyle={LABEL_STYLE}
        style={WIDTH_STYLE}
        checked={enabled}
        onCheck={onCheck}
      />
      {enabled && (
        <div style={STYLE_DATES_WRAPPER}>
          <div style={STYLE_DATES_ITEMS}>
            {_tg('dashboard.pv.graphes.comparaisonHeader.period1')}
            <DateFilter
              styleText={STYLE_TEXT}
              datepickerClass={DatepickerClass.sidebar}
              colorResetButton="white"
              dates={firstPeriod}
              periodSelectionEnabled={false}
              onChange={onChangeFirstPeriod}
            />
          </div>
          <div style={STYLE_DATES_ITEMS}>
            {_tg('dashboard.pv.graphes.comparaisonHeader.period2')}
            <DateFilter
              styleText={STYLE_TEXT}
              datepickerClass={DatepickerClass.sidebar}
              colorResetButton="white"
              dates={secondPeriod}
              periodSelectionEnabled={false}
              onChange={onChangeSecondPeriod}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ComparisonHeader;
