import React, { CSSProperties, useState } from 'react';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { FpsRefundStatus, RefundComment } from 'api/refund/types';
import { ControlAgent } from 'api/commonTypes';
import { getActions } from 'commons/ModalUtils';
import { BKG_CYAN } from 'theme';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_CYAN,
  color: '#ffffff',
  fontWeight: 'bold',
};

const STYLE_CONTENT_STATUS: CSSProperties = {
  marginTop: '20px',
};

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_CYAN,
  color: BKG_CYAN,
};

type Props = {
  onSubmit: (arg0: RefundComment) => void;
  isOpen: boolean;
  onClose: () => void;
};

function DoMultipleRefundModal({
  onSubmit,
  onClose,
  isOpen,
}: Props): JSX.Element {
  const STATUS_OPTIONS = {
    REFUND_PENDING: _tg('tefps.dashboard.refund.refundToDo'),
    REFUND_IN_PROGRESS: _tg('tefps.dashboard.refund.refundInProgess'),
    NO_RESPONSE: _tg('tefps.dashboard.refund.noResponse'),
    RIB_MISSING: _tg('tefps.dashboard.refund.askForRIB'),
  };

  const [comment, setComment] = useState<string>('');
  const [status, setStatus] = useState<FpsRefundStatus>('RIB_VALIDATED');

  function onClickValidate() {
    const agent: ControlAgent = {
      agentId: '',
      name: '',
      worksFor: undefined,
    };
    const refundcomment: RefundComment = {
      status,
      date: undefined,
      content: comment,
      agent,
    };
    onSubmit(refundcomment);
  }

  function onChangeComment(
    e: React.ChangeEvent<HTMLInputElement>,
    newComment: string
  ) {
    setComment(newComment);
  }

  function handleChange(
    event: React.ChangeEvent<any>,
    index: number,
    value: any
  ) {
    setStatus(value);
  }

  const actions = getActions(onClickValidate, onClose);
  const statusOnChange = Object.entries(STATUS_OPTIONS).map(([key, value]) => (
    <MenuItem key={key} value={key} primaryText={value} />
  ));

  return (
    <Dialog
      title={_tg('tefps.dashboard.refund.selectNewStatus')}
      actions={actions}
      modal
      autoScrollBodyContent
      open={isOpen}
      titleStyle={STYLE_TITLE}
    >
      <div style={STYLE_CONTENT_STATUS}>
        <SelectField
          key="status update"
          value={status}
          floatingLabelText={_tg(
            'tefps.dashboard.refund.changeStatusAlternate'
          )}
          onChange={handleChange}
        >
          {statusOnChange}
        </SelectField>
      </div>
      <TextField
        underlineFocusStyle={STYLE_INPUTS}
        floatingLabelFocusStyle={STYLE_INPUTS}
        value={comment}
        onChange={onChangeComment}
        floatingLabelText={_tg('field.comment')}
        fullWidth
        multiLine
        rows={3}
        rowsMax={3}
      />
    </Dialog>
  );
}

export default DoMultipleRefundModal;
