import React, { CSSProperties } from 'react';
import ActionNoteAdd from 'material-ui/svg-icons/action/note-add';

import { RefundDetailDTO, RefundFile } from 'api/refund/types';
import { getRecoursePdfUrl, getResponseDownloadUrl } from 'api/recourse';
import { getFpsCasePdfUrl } from 'api/fps';
import { openNewAuthentifiedTab } from 'api/helpers';
import { groupBySize } from 'commons/Utils/arrayUtils';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import FileBlock, { buildRowsFromFile } from 'commons/FileBlock';
import { BKG_PINK, BRD_GREY, STYLE_SEPARATOR } from 'theme';
import { EsRecourseStatus } from 'api/recourse/types';
import { RefundDocumentType } from 'api/fpsConfiguration/types';
import { DOCUMENT_ENTITLED } from 'api/fpsConfiguration/utils';

import AddDocumentModal from './AddDocumentModal';

const { _tg } = window.loadTranslations(__filename);

const STYLE_DROPZONE: CSSProperties = {
  border: `3px dashed ${BRD_GREY}`,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  borderRadius: 10,
};

type Props = {
  refund: RefundDetailDTO;
  lastRapoId: string | null | undefined;
  lastRecourseStatus: EsRecourseStatus | null | undefined;
  onUpdate: (shouldReload: boolean, message: string) => void;
};

function DocumentBlock({
  refund,
  lastRapoId,
  onUpdate,
  lastRecourseStatus,
}: Props): JSX.Element {
  const [open, setOpen] = React.useState<boolean>(false);

  function handleClose() {
    setOpen(false);
  }

  const fpsUrl = getFpsCasePdfUrl(refund.rootFpsId, true);
  const rapoUrl = lastRapoId ? getRecoursePdfUrl(lastRapoId) : null;
  const rapoAnswerUrl =
    lastRapoId &&
    (lastRecourseStatus === 'ACCEPTED' || lastRecourseStatus === 'REJECTED')
      ? getResponseDownloadUrl(lastRapoId, undefined)
      : null;

  const openPdfUrl = (url: string): void => {
    openNewAuthentifiedTab(url);
  };

  // we build an array with all the documents we need
  // then we groupe them by 3, that represent the line's size
  function buildFileArray(): Array<Array<React.ReactElement<'div' | 'span'>>> {
    const files = [];
    files.push(
      <FileBlock
        key="fpsPdf"
        rows={[
          _tg('commons.fps'),
          _tg('tefps.dashboard.refund.export.exportPDF'),
          _tg('tefps.dashboard.refund.export.pdf'),
        ]}
        onClick={() => openPdfUrl(fpsUrl)}
      />
    );
    // we don't always have a RAPO linked to a refund
    if (rapoUrl) {
      files.push(
        <FileBlock
          key="rapoPdf"
          rows={[
            _tg('tefps.dashboard.refund.export.RAPO'),
            _tg('tefps.dashboard.refund.export.exportPDF'),
            _tg('tefps.dashboard.refund.export.pdf'),
          ]}
          onClick={() => openPdfUrl(rapoUrl)}
        />
      );
    }
    if (rapoAnswerUrl) {
      files.push(
        <FileBlock
          key="rapoAnswer"
          rows={[
            _tg('tefps.dashboard.refund.export.RAPO'),
            _tg('tefps.dashboard.refund.export.mailPDF'),
            _tg('tefps.dashboard.refund.export.pdf'),
          ]}
          onClick={() => openPdfUrl(rapoAnswerUrl)}
        />
      );
    }
    Object.keys(refund.documents).forEach((key: RefundDocumentType) => {
      const file: RefundFile = refund.documents[key];
      files.push(
        <FileBlock
          rows={buildRowsFromFile(
            DOCUMENT_ENTITLED[key],
            file.name || '',
            file.size,
            file?.dateRemoved
          )}
          contentUrl={file?.dateRemoved ? '' : file.downloadUrl || ''}
          purgedFile={!!file.dateRemoved}
        />
      );
    });
    return groupBySize(files, 3);
  }

  return (
    <div>
      <SeparatorWithTitle
        style={STYLE_SEPARATOR}
        title={_tg('tefps.dashboard.refund.evidences')}
        color={BKG_PINK}
        titleSize={20}
      />
      <div
        style={{
          padding: '20px 5%',
        }}
      >
        {buildFileArray().map(group => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              width: '100%',
            }}
          >
            {group.map(element => (
              <div style={{ flex: '1', margin: '0 5%' }}> {element}</div>
            ))}
          </div>
        ))}
      </div>
      <span
        onClick={() => setOpen(true)}
        style={{
          cursor: 'pointer',
          width: '45%',
        }}
        title={_tg('tefps.dashboard.refund.addDocument')}
      >
        <div style={{ ...STYLE_DROPZONE, width: '30%', margin: '0 auto' }}>
          <ActionNoteAdd style={{ width: 40, height: 50 }} />
          {_tg('tefps.dashboard.refund.addDocument')}
        </div>
      </span>
      <AddDocumentModal
        refund={refund}
        open={open}
        handleClose={handleClose}
        onUpdate={onUpdate}
      />
    </div>
  );
}

export default DocumentBlock;
