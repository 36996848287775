import moment from 'moment';

import { SortParameters } from 'api/commonTypes';

import {
  StatsAgentsSearchFilters,
  StatsAgentsSearchFiltersParam,
} from './types';

const { _tg } = window.loadTranslations(__filename);

export const EXPORT_COL = (): Array<{
  key: string;
  label: string;
  checked?: boolean;
}> => [
  { key: 'agentName', label: _tg('field.agent.name'), checked: true },
  {
    key: 'nbrOfFpses',
    label: _tg('field.fps.fpsAmount'),
    checked: true,
  },
  { key: 'nbrOfControls', label: _tg('field.control.controlAmount') },
  {
    key: 'controlsMeanPerHour',
    label: _tg('field.control.hourlyRate'),
  },
];

function getSortFieldText(sortFieldNumber: number) {
  return [
    'agentName',
    'nbrOfFpses',
    'nbrOfControls',
    'controlsMeanPerHour',
    'nbrOfFpsesCurrentDay',
    'nbrOfControlsCurrentDay',
    'controlsMeanPerHourCurrentDay',
  ][sortFieldNumber];
}

export function getSortFieldIndex(sortField: string): number | undefined {
  return {
    agentName: 0,
    nbrOfFpses: 1,
    nbrOfControls: 2,
    controlsMeanPerHour: 3,
    nbrOfFpsesCurrentDay: 4,
    nbrOfControlsCurrentDay: 5,
    controlsMeanPerHourCurrentDay: 6,
  }[sortField];
}

export const filtersToRequest = (
  filters: StatsAgentsSearchFilters,
  sort: SortParameters
): StatsAgentsSearchFiltersParam => {
  const { from, to } = filters.statementDatetime || {};

  return {
    agentId: filters.agentId,
    zoneId: filters.zoneId,
    teamIds: Array.from(filters.teamIds),
    organizationShortId: filters.organizationShortId,
    startTime: filters.times.from,
    endTime: filters.times.to,
    statementStartDatetime: from ? moment(from).toISOString() : null,
    statementEndDatetime: to ? moment(to).toISOString() : null,
    sortOrder: sort.increasingOrder ? 'ASC' : 'DESC',
    sortField: getSortFieldText(sort.sortField),
  };
};
