import { apiPost } from 'api/helpers';

import {
  LapiDriverStatsItemType,
  LapiDriverStatsSearchRequestDTO,
} from './types';

const API_URL = '/api/proxy/control/api/v0/cities/{cityId}/lapiDriverStats';

export const fetchData = async (
  searchRequest: LapiDriverStatsSearchRequestDTO
): Promise<LapiDriverStatsItemType[]> => {
  return apiPost<LapiDriverStatsItemType[]>(API_URL, searchRequest);
};
