import FileSaver from 'file-saver';
import * as React from 'react';

import BoButton from 'facade/BoButton';
import Exporter from 'commons/Exporter';
import { GpvStatsDTO } from 'api/tepv/stats/types';
import { exportGpvGraph } from 'api/tepv/stats';
import ChartCustom from 'Dashboard/pv/GpvGraphs/Charts/ChartCustom';

import {
  EXPORT_COLUMNS,
  monthNameMapper,
  sortByCount,
  sortByMonth,
} from './utils';

type Props = {
  stats: GpvStatsDTO;
  exportStats: () => void | null | undefined;
};

class ChartContainerSingle extends React.Component<Props> {
  gpvByMonthRef = React.createRef<ChartCustom>();
  gpvByCategoryRef = React.createRef<ChartCustom>();
  gpvByPresetNoteRef = React.createRef<ChartCustom>();
  gpvByVehicleCountryRef = React.createRef<ChartCustom>();
  gpvByVehicleCategoryRef = React.createRef<ChartCustom>();
  gpvByNatinfCodeRef = React.createRef<ChartCustom>();

  getTotal = () => this.props.stats.gpvCount;

  exportGraphs = async () => {
    const refs = [
      this.gpvByMonthRef,
      this.gpvByCategoryRef,
      this.gpvByPresetNoteRef,
      this.gpvByVehicleCountryRef,
      this.gpvByVehicleCategoryRef,
      this.gpvByNatinfCodeRef,
    ];

    const exportData = refs.reduce(
      (acc: any, ref: React.RefObject<ChartCustom>) => {
        if (ref.current) {
          return acc.concat(ref.current.getExportData());
        }
        return acc;
      },
      []
    );

    const file = await exportGpvGraph(exportData);
    const blobFile = await file.blob();
    FileSaver.saveAs(blobFile, 'export.pdf');
  };

  render() {
    const { exportStats, stats } = this.props;
    const sortedgpvByMonth = sortByMonth(stats.gpvsBySignatureDate);
    const sortedgpvByPlateCountry = sortByCount(stats.gpvsByPlateCountry);
    const sortedgpvByVehicleCategory = sortByCount(stats.gpvsByVehicleCategory);
    const sortedgpvByNatinfCode = sortByCount(stats.gpvsByNatinfCode);

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <ChartCustom
          ref={this.gpvByMonthRef}
          acceptedTypes={['bar']}
          graphsData={[
            {
              title: 'Cas A par mois',
              stats: sortedgpvByMonth,
            },
          ]}
          mapper={monthNameMapper}
          graphLabel="byMonth"
        />
        <ChartCustom
          ref={this.gpvByCategoryRef}
          acceptedTypes={['pie', 'bar']}
          graphsData={[
            {
              title: 'Cas A par motif',
              stats: stats.gpvsByCategory,
            },
          ]}
          graphLabel="byMotifCode"
        />
        <ChartCustom
          ref={this.gpvByPresetNoteRef}
          acceptedTypes={['pie', 'bar']}
          graphsData={[
            {
              title: 'Cas A par renseignement prédéfini',
              stats: stats.gpvsByPresetNote,
            },
          ]}
          graphLabel="byPresetNotes"
        />
        <ChartCustom
          ref={this.gpvByVehicleCountryRef}
          acceptedTypes={['pie', 'bar']}
          graphsData={[
            {
              title: "Cas A par pays d'immatriculation",
              stats: sortedgpvByPlateCountry,
            },
          ]}
          graphLabel="byPlateCountry"
        />
        <ChartCustom
          ref={this.gpvByVehicleCategoryRef}
          acceptedTypes={['pie', 'bar']}
          graphsData={[
            {
              title: 'Cas A par catégorie de véhicule',
              stats: sortedgpvByVehicleCategory,
            },
          ]}
          graphLabel="byVehicleCategory"
        />
        <ChartCustom
          ref={this.gpvByNatinfCodeRef}
          acceptedTypes={['pie', 'bar']}
          graphsData={[
            {
              title: 'Cas A par code Natinf',
              stats: sortedgpvByNatinfCode,
            },
          ]}
          graphLabel="byNatinfCode"
        />
        {exportStats && (
          <div style={{ padding: '20px 35px', display: 'flex' }}>
            <Exporter
              disabled={false}
              columns={EXPORT_COLUMNS}
              type="Stats"
              filters={{}}
              fileExport={exportStats}
            />
            <BoButton
              style={{ margin: '0 15px' }}
              label="Export tous graphiques"
              onClick={this.exportGraphs}
              primary
            />
          </div>
        )}
      </div>
    );
  }
}

export default ChartContainerSingle;
