import React from 'react';

export type RefundMap = {
  [key: string]: { rootFpsId: string; refundPrice: number };
};

export type RefundMapContextType = {
  selectedRefunds: RefundMap;
  setSelectedRefunds: (arg0: (map: RefundMap) => RefundMap) => void;
};

export const RefundMapContext = React.createContext<
  RefundMapContextType | Record<string, never>
>({});

export type BoolContextType = {
  multipleMode: boolean;
  setMultipleMode: (arg0: (b: boolean) => boolean) => void;
};

export const BoolContext = React.createContext<
  BoolContextType | Record<string, never>
>({});
