import * as React from 'react';
import { connect } from 'react-redux';

import { getActiveUniverse } from 'UniverseHandler/helpers';
import { Universe } from 'UniverseHandler/duck';

import FpsDashboard from './fps';
import PvDashboard from './pv';

type Props = {
  activeUniverse: Universe;
};

const Dashboard = ({ activeUniverse }: Props) =>
  activeUniverse === 'fps' ? <FpsDashboard /> : <PvDashboard />;

export default connect(state => {
  const activeUniverse = getActiveUniverse(state);
  return {
    activeUniverse,
  };
})(Dashboard);
