import { DatasetConfig } from '../commons/types';

const { _tg } = window.loadTranslations(__filename);

const COLORS = ['#344c77', '#338b9b'];

export const FO_APPLICATION_CONFIG = (): DatasetConfig => ({
  label: _tg('tefps.dashboard.subscriptionStats.fromFO'),
  stack: 'stacked',
  backgroundColor: COLORS[0],
  borderColor: COLORS[0],
  order: 1,
  field: 'FRONT',
});

export const BO_APPLICATION_CONFIG = (): DatasetConfig => ({
  label: _tg('tefps.dashboard.subscriptionStats.fromBO'),
  stack: 'stacked',
  backgroundColor: COLORS[1],
  borderColor: COLORS[1],
  order: 2,
  field: 'BACK',
});

export const ACCEPTED_APPLICATION_CONFIG = (): DatasetConfig => ({
  label: _tg('tefps.dashboard.subscriptionStats.acceptedDemands'),
  stack: 'stacked',
  backgroundColor: COLORS[0],
  borderColor: COLORS[0],
  order: 1,
  field: 'ACCEPTED',
});

export const REJECTED_APPLICATION_CONFIG = (): DatasetConfig => ({
  label: _tg('tefps.dashboard.subscriptionStats.refusedDemands'),
  stack: 'stacked',
  backgroundColor: COLORS[1],
  borderColor: COLORS[1],
  order: 2,
  field: 'REJECTED',
});

export const SUBSCRIPTION_PAYMENT_CONFIG = (): DatasetConfig => ({
  label: _tg('field.subscription.subscriptions'),
  stack: 'stacked',
  backgroundColor: COLORS[0],
  borderColor: COLORS[0],
  order: 1,
  field: 'SUBSCRIPTION',
});

export const TICKET_PAYMENT_CONFIG = (): DatasetConfig => ({
  label: _tg('tefps.tickets.tickets'),
  stack: 'stacked',
  backgroundColor: COLORS[1],
  borderColor: COLORS[1],
  order: 2,
  field: 'PARKING_RIGHT_REQUEST',
});
