import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import {
  Link,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import MissNotFound from 'commons/MissNotFound';
import { BKG_CYAN, TXT_BLACK } from 'theme';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import GpvMaps from 'Dashboard/pv/GpvMaps';
import GpvReports from 'Dashboard/pv/GpvGraphs';
import GpvAgentReport from 'Dashboard/pv/GpvReports/agentReport';
import GpvOrganizationReport from 'Dashboard/pv/GpvReports/organizationReport';
import { getConfigState, Config } from 'config/duck';

import Graphs from './Graphs';
import Maps from './Maps';
import AgentReport from './Reports/agentReport';
import OrganizationReport from './Reports/organizationReport';

const { _tg } = window.loadTranslations(__filename);

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  flexWrap: 'wrap',
  color: TXT_BLACK,
  width: '60%',
  margin: '0 auto',
};

const STYLE_TITLE: CSSProperties = {
  fontSize: 25,
  margin: 30,
};

const STYLE_MODULE: CSSProperties = {
  width: 485,
  height: 200,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 5,
  border: `1px solid ${BKG_CYAN}`,
  backgroundColor: 'white',
  margin: 30,
  fontWeight: 'bold',
  fontSize: 35,
};

type Props = {
  config: Config;
  userInfo: InternalAgent;
};

const generateLinks = (userInfo: InternalAgent) => {
  const links = [];
  if (userInfo.rights.includes('PV_DASHBOARD_REPORTS')) {
    links.push(
      <Link key="graphs" to="/dashboard/graphs" style={STYLE_MODULE}>
        {_tg('dashboard.pv.index.reports')}
      </Link>
    );
  }
  if (userInfo.rights.includes('PV_DASHBOARD_CARTOGRAPHY')) {
    if (userInfo.rights.includes('BACKOFFICE_MIFS')) {
      links.push(
        <Link key="maps" to="/dashboard/maps" style={STYLE_MODULE}>
          {_tg('dashboard.pv.index.cartography')}
        </Link>
      );
    }
  }
  if (userInfo.rights.includes('PV_DASHBOARD_MONITORING')) {
    links.push(
      <Link key="agentReport" to="/dashboard/report/agent" style={STYLE_MODULE}>
        {_tg('dashboard.pv.index.agentTracking')}
      </Link>
    );
    links.push(
      <Link
        key="orgReport"
        to="/dashboard/report/organization"
        style={STYLE_MODULE}
      >
        {_tg('dashboard.pv.index.orgaTracking')}
      </Link>
    );
  }
  return links;
};

function generateGpvLinks(userInfo: InternalAgent) {
  const links = [];
  if (userInfo.rights.includes('PV_DASHBOARD_REPORTS')) {
    links.push(
      <Link key="gpvgraphs" to="/dashboard/graphs/gpv" style={STYLE_MODULE}>
        {_tg('dashboard.pv.index.reports')}
      </Link>
    );
  }
  if (userInfo.rights.includes('PV_DASHBOARD_CARTOGRAPHY')) {
    if (userInfo.rights.includes('BACKOFFICE_GPV')) {
      links.push(
        <Link key="gpvmaps" to="/dashboard/maps/gpv" style={STYLE_MODULE}>
          {_tg('dashboard.pv.index.cartography')}
        </Link>
      );
    }
  }
  if (userInfo.rights.includes('PV_DASHBOARD_MONITORING')) {
    links.push(
      <Link
        key="gpvAgentReport"
        to="/dashboard/report/agent/gpv"
        style={STYLE_MODULE}
      >
        {_tg('dashboard.pv.index.agentTracking')}
      </Link>
    );
    links.push(
      <Link
        key="gpvOrgReport"
        to="/dashboard/report/organization/gpv"
        style={STYLE_MODULE}
      >
        {_tg('dashboard.pv.index.orgaTracking')}
      </Link>
    );
  }
  return links;
}

const PvDashboardHome = ({ userInfo, config }: Props) => (
  <div style={{ height: '100%', fontFamily: 'Roboto' }}>
    {userInfo.rights.includes('BACKOFFICE_PV_DASHBOARD') && (
      <>
        <div style={STYLE_CONTAINER}>
          <span style={STYLE_TITLE}>
            {_tg('dashboard.pv.index.mifsModule')}
          </span>
        </div>
        <div style={STYLE_CONTAINER}>{generateLinks(userInfo)}</div>
      </>
    )}
    {config.modulesConfiguration.gpv.enabled &&
      userInfo.rights.includes('BACKOFFICE_GPV') && (
        <>
          <div style={STYLE_CONTAINER}>
            <span style={STYLE_TITLE}>
              {_tg('dashboard.pv.index.gpvModule')}
            </span>
          </div>
          <div style={STYLE_CONTAINER}>{generateGpvLinks(userInfo)}</div>
        </>
      )}
  </div>
);

const ConnectedHome = connect(state => {
  const config = getConfigState(state);
  const { userInfo } = getApiState(state);
  return {
    config,
    userInfo,
  };
})(PvDashboardHome);

const PvDashboard = ({ match }: RouteComponentProps) => (
  <div style={{ height: '100%', margin: 'auto' }}>
    <Switch>
      <Route path={`${match.url}`} exact component={ConnectedHome} />
      <Route path={`${match.url}/graphs`} exact component={Graphs} />
      <Route path={`${match.url}/maps`} exact component={Maps} />
      <Route path={`${match.url}/report/agent`} exact component={AgentReport} />
      <Route
        path={`${match.url}/report/organization`}
        exact
        component={OrganizationReport}
      />
      <Route
        path={`${match.url}/report/agent/gpv`}
        exact
        component={GpvAgentReport}
      />
      <Route
        path={`${match.url}/report/organization/gpv`}
        exact
        component={GpvOrganizationReport}
      />
      <Route path={`${match.url}/maps/gpv`} exact component={GpvMaps} />
      <Route path={`${match.url}/graphs/gpv`} exact component={GpvReports} />
      <MissNotFound />
    </Switch>
  </div>
);

export default withRouter(PvDashboard);
