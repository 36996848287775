import React, { useState } from 'react';
import { TextField } from 'material-ui';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import Content from 'commons/Content';
import SectionTitle from 'commons/SectionTitle';
import { ParkingMeterConfiguration } from 'api/documentLibrary/types';
import { fetchConfig, getConfigState } from 'config/duck';
import { NotificationService } from '@cvfm-front/commons-services';
import { updateParkingMeterConfig } from 'api/parkingMeter';

const { _t } = window.loadTranslations(__filename);

interface HVConfigurationProps {
  parkingMeterConfigurationDTO: ParkingMeterConfiguration;
  reloadConfig: () => Promise<void>;
}

const HVConfiguration = ({
  parkingMeterConfigurationDTO,
  reloadConfig,
}: HVConfigurationProps): JSX.Element => {
  const [hasChange, setHasChange] = useState(false);
  const [tolerance, setTolerance] = useState(
    parkingMeterConfigurationDTO.tolerance
  );

  async function patchTolerance(): Promise<void> {
    try {
      await updateParkingMeterConfig([
        {
          op: 'REPLACE',
          path: '/parkingMeterConfiguration',
          value: {
            ...parkingMeterConfigurationDTO,
            tolerance,
          },
        },
      ]);

      NotificationService.pushNotification({
        id: 'parking-meter-configuration',
        type: 'success',
        message: _t('successfulRequest'),
      });
      setHasChange(false);
      await reloadConfig();
    } catch (e) {
      NotificationService.pushNotification({
        id: 'parking-meter-configuration',
        type: 'error',
        message: e.message,
      });
    }
  }

  function handleOnChangeTolerance(
    e: React.SyntheticEvent,
    newValue: string
  ): void {
    setTolerance(parseInt(newValue, 10));
    setHasChange(true);
  }

  return (
    <Content>
      <SectionTitle title={_t('title')} />
      <TextField
        type="number"
        floatingLabelText={_t('toleranceLabel')}
        value={tolerance.toString()}
        onChange={handleOnChangeTolerance}
      />
      <div>
        <BoButton
          primary
          disabled={!hasChange}
          label={_t('save')}
          onClick={patchTolerance}
        />
      </div>
    </Content>
  );
};

function mapState(state: any) {
  const { parkingMeterConfigurationDTO } = getConfigState(state);

  return {
    parkingMeterConfigurationDTO,
  };
}

function mapAction(dispatch: any) {
  return {
    reloadConfig: () => dispatch(fetchConfig()),
  };
}

export default connect(mapState, mapAction)(HVConfiguration);
