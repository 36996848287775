import { ChartConfiguration, ChartDataset } from 'chart.js';
import moment from 'moment';

import { MonthlyPercentages } from 'api/dashboard/types';
import { MonthlyAmountHistogramData } from 'Dashboard/fps/commons/MonthlyAmountHistogram';

import { DatasetConfig, EUR_UNIT, PERCENT_UNIT } from './types';

const { _tg } = window.loadTranslations(__filename);

export const buildPaidPercentages = (
  data: {
    [k: string]: MonthlyPercentages;
  },
  startMonth: moment.Moment
): MonthlyAmountHistogramData => {
  const month = startMonth.clone();
  for (let i = 0; i < 13; i += 1) {
    const key = month.format('YYYY-MM');
    if (data[key]) {
      data[key].NOT_PAID = Math.min(100, 100 - data[key].PAID_PERCENT);
    } else {
      data[key] = {
        NOT_PAID: 0,
        PAID_PERCENT: 0,
      };
    }
    month.add(1, 'months');
  }
  return data as MonthlyAmountHistogramData;
};

export const buildEmptyDataset = (config: DatasetConfig): ChartDataset => {
  return {
    label: config.label,
    backgroundColor: config.backgroundColor,
    borderColor: config.borderColor,
    borderWidth: 1,
    stack: config.stack,
    data: [],
  };
};

export const buildGraphDatas = (
  title: string,
  unit?: string
): ChartConfiguration => {
  const yAxes = unit
    ? {
        stacked: true,
        ticks: {
          callback: (value: number) => {
            if (unit === EUR_UNIT) {
              return `${value.toLocaleString()} ${_tg(
                'field.payment.currency'
              )}`;
            }
            if (unit === PERCENT_UNIT) {
              return `${value.toLocaleString()} %`;
            }
            return value.toLocaleString();
          },
        },
      }
    : {
        stacked: true,
        ticks: {
          callback: (value: number) => value.toLocaleString(),
        },
      };

  return {
    type: 'bar',
    data: {
      labels: [] as string[],
      datasets: [],
    },
    options: {
      plugins: {
        title: {
          display: true,
          text: title,
          font: {
            size: 18,
          },
        },
        /* tooltip: {
          mode: 'index',
          intersect: false,
          itemSort: (a: any, b: any) => {
            return b.datasetIndex - a.datasetIndex;
          },
          callbacks: {
            label: context => {
              const { dataset, datasetIndex, label } = context;
              return `${
                dataset[datasetIndex].label
              }: ${label.toLocaleString()}${unit === EUR_UNIT ? ' €' : ''}${
                unit === PERCENT_UNIT ? ' %' : ''
              }`;
            },
          },
        }, */
      },
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        yAxes,
      },
    },
  };
};
