import {
  CCSP_FILTER_OPTIONS,
  PAYMENT_FILTER_OPTIONS,
  RAPO_FILTER_OPTIONS,
  STATUSES_FILTER_OPTIONS,
} from 'tefps/Fps/List/utils';
import {
  CheckboxBooleanType,
  CheckboxFilterType,
  DatetimeFilterType,
  FilterType,
  MultiSearchFilterType,
  SelectFilterType,
} from 'commons/types/filterbar';
import { CheckboxOption } from 'commons/SidebarV2/types';
import { InitialOrganization } from 'config/duck';

const { _tg } = window.loadTranslations(__filename);

const REFUND_FILTER_OPTIONS = (): Array<{
  value: string;
  label: string;
}> => [
  {
    value: 'CONTACT_MISSING',
    label: _tg('tefps.dashboard.refund.noContactAlternate'),
  },
  {
    value: 'RIB_MISSING',
    label: _tg('tefps.dashboard.refund.askForRIB'),
  },
  {
    value: 'CONTACT_MISSING_AFTER_CANCELLATION_CCSP',
    label: _tg('tefps.dashboard.refund.noContactAfterCcspCancellation'),
  },
  { value: 'RIB_ASKED', label: _tg('tefps.dashboard.refund.askedForRIB') },
  { value: 'RIB_VALIDATED', label: _tg('tefps.dashboard.refund.receivedRIB') },
  {
    value: 'REFUND_PENDING',
    label: _tg('tefps.dashboard.refund.refundToDo'),
  },
  {
    value: 'REFUND_IN_PROGRESS',
    label: _tg('tefps.dashboard.refund.refundInProgess'),
  },
  {
    value: 'NO_RESPONSE',
    label: _tg('tefps.dashboard.refund.noResponse'),
  },
  {
    value: 'REFUND_DONE',
    label: _tg('tefps.dashboard.refund.refundDoneAlternate'),
  },
];

const PAYMENT_CHANNEL_OPTION = (): {
  value: string;
  label: string;
} => ({
  value: 'true',
  label: _tg('tefps.dashboard.refund.maskDGFIP'),
});

const EMAIL_FILTER_OPTIONS = (): Array<{
  value: string;
  label: string;
}> => [
  { value: 'MISSING', label: _tg('field.unavailable') },
  {
    value: 'TO_SEND',
    label: _tg('tefps.dashboard.refund.toSend'),
  },
  { value: 'SENT', label: _tg('tefps.dashboard.refund.sent') },
];

const ADDRESS_FILTER_OPTIONS = (): Array<{
  value: string;
  label: string;
}> => [
  { value: 'AVAILABLE', label: _tg('field.available') },
  ...EMAIL_FILTER_OPTIONS(),
];

const POSTAL_STATUS_FILTER_OPTIONS = (): Array<{
  value: string;
  label: string;
}> => [
  { value: 'MISSING', label: _tg('tefps.dashboard.refund.notSent') },
  { value: 'SENDING', label: _tg('tefps.dashboard.refund.sending') },
  { value: 'SENT', label: _tg('tefps.dashboard.refund.beingSent') },
  { value: 'ERROR', label: _tg('feedback.error.inError') },
  { value: 'WRONG_ADDRESS', label: _tg('tefps.dashboard.refund.badAddress') },
];

export const statementDatetimeFitler = (): DatetimeFilterType => ({
  id: 'statementDatetime',
  label: _tg('tefps.filters.fps.statementDatetime'),
  type: 'DATETIME',
  datetimeDefaultValue: {
    from: new Date(Date.now() - 1000 * 60 * 60 * 24 * 30 * 3), // 3 months back
    to: new Date(),
  },
  datetimeValue: {
    from: new Date(Date.now() - 1000 * 60 * 60 * 24 * 30 * 3), // 3 months back
    to: new Date(),
  },
});

export const fpsIdsFilter = (): MultiSearchFilterType => ({
  id: 'rootFpsIds',
  label: _tg('tefps.filters.refund.fpsId'),
  hint: _tg('tefps.dashboard.payment.fpsNumberFilter'),
  type: 'MULTISEARCH',
  multiSearchValue: new Set(),
  modalTitle: _tg('tefps.filters.misc.fpsOrFpsmIds.modalTitle'),
  modalFloatingLabelText: _tg(
    'tefps.filters.misc.fpsOrFpsmIds.modalFloatingLabelText'
  ),
  multiSearchDefaultValue: new Set<string>(),
});

export const plateFilter = (): FilterType => ({
  id: 'plate',
  type: 'INPUT',
  label: _tg('field.vehicle.licensePlate'),
  hint: _tg('tefps.filters.misc.plates.placeholder'),
});

export const rapoClaimStatusFilter = (): FilterType => ({
  id: 'rapoClaimStatuses',
  checkboxFacetName: 'rapoClaimStatus',
  label: _tg('tefps.filters.fps.rapoClaimStatuses'),
  type: 'CHECKBOX',
  checkboxOptions: RAPO_FILTER_OPTIONS(),
  checkboxDefaultValues: new Set(),
});

export const ccspClaimStatusFilter = (): FilterType => ({
  id: 'ccspClaimStatuses',
  checkboxFacetName: 'ccspClaimStatus',
  label: _tg('tefps.filters.fps.ccspClaimStatuses'),
  type: 'CHECKBOX',
  checkboxOptions: CCSP_FILTER_OPTIONS(),
});

export const fpsStatusesFilter = (): CheckboxFilterType => ({
  id: 'fpsStatuses',
  checkboxFacetName: 'fpsState',
  label: _tg('tefps.filters.fps.fpsStatus.label'),
  type: 'CHECKBOX',
  checkboxOptions: STATUSES_FILTER_OPTIONS(),
});

export const paymentStatusesFilter = (): CheckboxFilterType => ({
  id: 'paymentStatuses',
  checkboxFacetName: 'paymentStatus',
  label: _tg('tefps.filters.fps.paymentStatuses'),
  type: 'CHECKBOX',
  checkboxOptions: PAYMENT_FILTER_OPTIONS(),
});

export const refundStatusFilter = (): CheckboxFilterType => ({
  id: 'fpsRefundStatuses',
  checkboxFacetName: 'fpsRefundStatus',
  label: _tg('tefps.filters.fps.refundStatus'),
  type: 'CHECKBOX',
  checkboxOptions: REFUND_FILTER_OPTIONS(),
});

export const paymentChannelFitler = (): CheckboxBooleanType => ({
  id: 'dgfipPayment',
  checkboxFacetName: 'dgfipPayment',
  label: _tg('tefps.dashboard.refund.refundResponsibility'),
  type: 'CHECKBOX_TO_BOOLEAN',
  checkboxOptions: PAYMENT_CHANNEL_OPTION(),
  checkboxValue: true,
  checkboxDefaultValue: true,
});

export const emailStatusFilter = (): FilterType => ({
  id: 'emailAddressStatuses',
  checkboxFacetName: 'emailChannel',
  label: _tg('tefps.dashboard.refund.contactChannel.email'),
  type: 'CHECKBOX',
  checkboxOptions: EMAIL_FILTER_OPTIONS(),
});

export const postalAddressStatusFilter = (): FilterType => ({
  id: 'postalAddressStatuses',
  checkboxFacetName: 'postalChannel',
  label: _tg('tefps.dashboard.refund.contactChannel.letter'),
  type: 'CHECKBOX',
  checkboxOptions: ADDRESS_FILTER_OPTIONS(),
});

export const postalLetterStatusFilter = (): FilterType => ({
  id: 'postalLetterStatuses',
  checkboxFacetName: 'postalStatus',
  label: _tg('tefps.dashboard.refund.lastLetterState'),
  type: 'CHECKBOX',
  checkboxOptions: POSTAL_STATUS_FILTER_OPTIONS(),
});

export const refundAmountFilter = (): FilterType => ({
  id: 'refundPriceRange',
  label: _tg('tefps.dashboard.refund.refundPrice'),
  type: 'PRICE_RANGE',
});

export const lastPaymentDatetimeFilter = (): FilterType => ({
  id: 'lastPaymentDatetime',
  label: _tg('tefps.dashboard.refund.lastPaymentDate'),
  type: 'DATETIME',
});

export const lastUpdateDatetimeFilter = (): FilterType => ({
  id: 'lastUpdateDatetime',
  label: _tg('field.date.lastModificationDateAlternate'),
  type: 'DATETIME',
});

export const regiesFilter = (
  regiesOptions: Array<CheckboxOption>
): CheckboxFilterType => {
  return {
    id: 'regieIds',
    label: _tg('tefps.dashboard.payment.regie'),
    type: 'CHECKBOX',
    checkboxOptions: regiesOptions,
    checkboxFacetName: 'regieId',
  };
};

export const initialOrganizationFilter = (
  initialOrganizationsConfigurations: Array<InitialOrganization>
): SelectFilterType => {
  return {
    id: 'initialOrganizationShortId',
    label: _tg('tefps.filters.fps.initialOrganizationShortId'),
    type: 'SELECT',
    selectOptions: [
      {
        key: undefined,
        label: _tg('tefps.filters.fps.allInitialOrganizationShortId'),
      },
      ...initialOrganizationsConfigurations,
    ],
  };
};
