import { ControlMapSearchRequest } from 'api/control/types';

import { MapFilters } from './types';

const { _tg } = window.loadTranslations(__filename);

export const STATUSES_FILTER_OPTIONS = (): Array<{
  value: string;
  label: string;
}> => [
  { value: 'OK', label: _tg('tefps.filters.misc.OK') },
  { value: 'KO', label: _tg('tefps.filters.misc.KO') },
  {
    value: 'EXEMPTION',
    label: _tg('tefps.filters.strategy.exemption'),
  },
  { value: 'EMPTY', label: _tg('tefps.filters.strategy.emptySpace') },
  { value: 'UNKNOWN', label: _tg('tefps.filters.strategy.unknownLocation') },
];

export const DEVICE_TYPE_FILTER_OPTIONS = (): Array<{
  value: string;
  label: string;
}> => [
  { value: 'LAPI', label: _tg('tefps.filters.misc.LAPI') },
  { value: 'AGENT', label: _tg('field.agent.agent') },
];

export const FPS_FROM_CONTROL_FILTER_OPTIONS = (): Array<{
  value: string;
  label: string;
}> => [
  { value: 'WITH', label: _tg('field.yes') },
  { value: 'WITHOUT', label: _tg('field.no') },
];

export const filtersToRequest = (
  filters: MapFilters
): ControlMapSearchRequest => {
  const {
    dates,
    times,
    fineId,
    controlId,
    agentId,
    organizationIds,
    controlStatuses,
    esControlDeviceTypes,
    fpsCreationStatuses,
    plate,
    statementDaysOfWeek,
    boundingBoxNorthEast,
    boundingSouthWest,
    zoom,
  } = filters;
  return {
    agentIds: agentId ? [agentId] : undefined,
    fineId,
    controlId,
    plate,
    statementStartDatetime:
      dates && dates.from ? dates.from.toISOString() : undefined,
    statementEndDatetime:
      dates && dates.to ? dates.to.toISOString() : undefined,
    startTime: times ? times.from : undefined,
    endTime: times ? times.to : undefined,
    statementDaysOfWeek: statementDaysOfWeek
      ? Array.from(statementDaysOfWeek)
      : undefined,
    organizationIds: organizationIds ? Array.from(organizationIds) : undefined,
    controlStatuses: controlStatuses ? Array.from(controlStatuses) : undefined,
    esControlDeviceTypes: esControlDeviceTypes
      ? Array.from(esControlDeviceTypes)
      : undefined,
    fpsCreationStatuses: fpsCreationStatuses
      ? Array.from(fpsCreationStatuses)
      : undefined,
    boundingBoxNorthEast,
    boundingSouthWest,
    zoom,
  };
};
