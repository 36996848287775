import { computeDatesWithPeriod } from 'commons/DateFilter';
import { DAYS } from 'commons/Days';
import { ReduxDashboardState } from 'Dashboard/duck';

import { MapFilters } from './types';

const FILTER_CHANGE = 'STRATEGY_FILTER_CHANGED';

type Action = {
  type: typeof FILTER_CHANGE;
  filters: MapFilters;
};

export const initialFilters = (): MapFilters => ({
  dates: computeDatesWithPeriod('12'),
  times: {
    from: '00:00',
    to: '23:59',
  },
  statementDaysOfWeek: new Set(DAYS),
  organizationIds: new Set(),
  controlStatuses: new Set(),
  esControlDeviceTypes: new Set(),
  fpsCreationStatuses: new Set(),
  fineId: null,
  controlId: null,
  plate: null,
  agentId: null,
  boundingBoxNorthEast: { latitude: 90, longitude: 90 },
  boundingSouthWest: { latitude: -90, longitude: -90 },
  zoom: 12,
});

export default function controlStrategyFiltersReducer(
  state: MapFilters = initialFilters(),
  action: Action
): MapFilters {
  switch (action.type) {
    case FILTER_CHANGE: {
      const { filters } = action;
      return {
        ...state,
        ...filters,
      };
    }
    default:
      return state;
  }
}

export function getStrategyFiltersState(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  state: any
): MapFilters {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return (state.dashboard as ReduxDashboardState).controlStrategyFilters;
}

export function strategyFiltersChanged(filters: MapFilters): Action {
  return {
    type: FILTER_CHANGE,
    filters,
  };
}
