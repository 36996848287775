import * as React from 'react';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import Dates, { FilterDate } from 'commons/SidebarV2/Components/Dates';
import Times from 'commons/SidebarV2/Components/Times';
import Input from 'commons/SidebarV2/Components/Input';
import Select from 'commons/SidebarV2/Components/Select';
import { translateDayOptions } from 'commons/Days';
import { fetchNatinfGroups } from 'api/tepv/natinfs';
import { getPresetNotes } from 'api/tepv/presetNote';
import { fetchOrganizations } from 'api/organizations';
import { FilterTime } from 'tefps/Tickets/List/types';

import CheckboxV1 from '../../../commons/SidebarV2/Components/CheckboxV1';

import { MapFilters } from './types';
import { natinfCategoriesOptions } from './utils';

type OnChangeValueType =
  | Set<any>
  | FilterDate
  | FilterTime
  | string
  | string[]
  | null
  | undefined;

type Props = {
  filters: MapFilters;
  updateFilters: (f: MapFilters) => void;
  totalHits: number;
  displayZones: boolean;
  toggleDisplayZone: (id: string, filters: any) => void;
};

type State = {
  groupKeys: Array<{ key: string | undefined; label: string }>;
  presetNoteKeys: Array<{ key: string | undefined; label: string }>;
  organizationKeys: Array<{ key: string | undefined; label: string }>;
};

class MifMapFilters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      groupKeys: [],
      presetNoteKeys: [],
      organizationKeys: [],
    };
  }

  componentDidMount(): void {
    void this.fetchNatinfGroups();
    void this.fetchPresetNotes();
    void this.fetchOrganizations();
  }

  onChange = (id: string, value: OnChangeValueType): void => {
    const { filters, updateFilters } = this.props;
    updateFilters({ ...filters, [id]: value });
  };

  fetchNatinfGroups = async (): Promise<void> => {
    const groups = await fetchNatinfGroups();
    const groupKeys = groups.map(group => ({
      key: group.identifier,
      label: group.name,
    }));

    this.setState({ groupKeys });
  };

  fetchOrganizations = async (): Promise<void> => {
    const orgs = await fetchOrganizations(false, 'pv');
    const organizationKeys = orgs.map(org => ({
      key: org.organizationId,
      label: org.name,
    }));
    this.setState({ organizationKeys });
  };

  fetchPresetNotes = async (): Promise<void> => {
    const notes = await getPresetNotes();
    const presetNoteKeys = notes.map(preset => ({
      key: preset.preset,
      label: preset.preset,
    }));
    this.setState({ presetNoteKeys });
  };

  render(): JSX.Element {
    const { filters, totalHits, displayZones, toggleDisplayZone } = this.props;
    const { groupKeys, presetNoteKeys, organizationKeys } = this.state;

    return (
      <Sidebar>
        {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
        <BarTitle resetFilters={() => {}} />
        <HitsCounter hits={totalHits} />
        <CheckboxV1
          id="display"
          title="Afficher les zones"
          filter={displayZones}
          options={{ value: 'zones', label: 'Zones' }}
          onChange={toggleDisplayZone}
        />
        <Checkboxes
          id="signatureDaysOfWeek"
          title="Jour de la semaine"
          options={translateDayOptions()}
          filters={filters.signatureDaysOfWeek}
          onChange={this.onChange}
          faceting={null}
        />
        <Dates
          id="dates"
          title="Dates"
          dates={filters.dates}
          onChange={this.onChange}
          periodSelectionEnabled
        />
        <Times
          id="times"
          title="Horaires"
          times={filters.times}
          onChange={this.onChange}
        />
        <Select
          id="organizationIds"
          title="Organisations"
          onChange={this.onChange}
          selected={filters.organizationIds}
          options={organizationKeys}
          multiple
        />
        <Select
          id="natinfCategories"
          title="Catégories de NATINFs"
          onChange={this.onChange}
          selected={filters.natinfCategories}
          options={natinfCategoriesOptions}
          multiple
        />
        <Select
          id="groupIds"
          title="Groupes de NATINFs"
          onChange={this.onChange}
          selected={filters.groupIds}
          options={groupKeys}
          multiple
        />
        <Select
          id="presetNote"
          title="Renseignements prédéfinis"
          onChange={this.onChange}
          selected={filters.presetNotes}
          options={presetNoteKeys}
          multiple
        />
        <Input
          id="plate"
          title="Plaque d'immatriculation"
          placeholder="Rechercher par plaque d'immatriculation"
          onChange={this.onChange}
          value={filters.plate || ''}
        />
      </Sidebar>
    );
  }
}

export default MifMapFilters;
