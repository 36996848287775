import React, { CSSProperties } from 'react';
import _cloneDeep from 'lodash.clonedeep';

import Tag from 'commons/Tag';
import {
  ControlTag,
  LapiReviewConfigurationDTO,
} from 'api/lapiReviewConfiguration/types';

import AddTag from './AddTag';

const { _t } = window.loadTranslations(__filename);

const STYLE_WRAPPER: CSSProperties = {
  margin: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  flexWrap: 'nowrap',
};

type AdminTagsProps = {
  configuration: LapiReviewConfigurationDTO;
  setTags: (tags: Array<ControlTag>) => void;
  showMessage: (arg0: string) => void;
};

class AdminTags extends React.Component<AdminTagsProps> {
  handleDelete = (arg: string): void => {
    const { configuration, setTags, showMessage } = this.props;
    const text = arg.split(' : ')[1];
    const newTags = configuration.tags.filter(testTag => testTag.text !== text);
    try {
      setTags(newTags);
    } catch (e) {
      showMessage(_t('feedback.error.delete'));
    }
  };

  handleAdd = (tag: ControlTag): void => {
    const { configuration, setTags, showMessage } = this.props;
    // the condition should always be true, as it should already be verified when calling handleAdd
    // But just in case something changes, we can have a nice error message
    if (configuration.tags.every(testTag => testTag.text !== tag.text)) {
      const newTags = _cloneDeep(configuration.tags);
      newTags.push(tag);
      try {
        setTags(newTags);
      } catch (e) {
        showMessage(_t('feedback.error.delete'));
      }
    } else {
      showMessage(_t('feedback.error.alreadyExists'));
    }
  };

  render(): React.ReactNode {
    const { configuration } = this.props;

    return (
      <div style={STYLE_WRAPPER}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {configuration.tags
            .sort((tag1, tag2) => tag1.priority - tag2.priority)
            .map(tag => (
              <Tag
                key={tag.text}
                tag={`${tag.priority} : ${tag.text}`}
                onDelete={this.handleDelete}
              />
            ))}
        </div>
        <AddTag tags={configuration.tags} onAdd={this.handleAdd} />
      </div>
    );
  }
}

export default AdminTags;
