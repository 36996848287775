import { ChartType } from 'chart.js';

import {
  AggregationType,
  ChartCriteria,
  ChartDataSource,
  ChartDecomposition,
  ChartLeafLabels,
  ChartPipeline,
} from '@cvfm-front/tefps-types';

const { _t } = window.loadTranslations(__filename);

interface CHART_CRITERIA {
  graphName: () => string;
  chartType: ChartType;
  chartPipeline: Array<ChartPipeline>;
  forceSingleDataset?: boolean; // Mandatory for doughnut and pie Chart. For line if chart contain only one pipeline
}

export interface CHART_SELECTOR_TREE {
  type: ChartDataSource | ChartCriteria | ChartDecomposition;
  children?: Array<CHART_SELECTOR_TREE>;
  chart?: CHART_CRITERIA;
}

export const CHART_TICKET_TREE: CHART_SELECTOR_TREE = {
  type: ChartDataSource.TICKET,
  children: [
    {
      type: ChartCriteria.QUANTITY,
      children: [
        {
          type: ChartDecomposition.SOURCE,
          chart: {
            graphName: () => _t('name.ticketQuantity.byClient'),
            chartType: 'pie',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'initialClientAppId',
                fieldLabel: ChartLeafLabels.FOURNISSEUR,
                aggregationType: AggregationType.QUANTITY,
                aggregationExtractor: AggregationType.QUANTITY,
              },
            ],
          },
        },
        {
          type: ChartDecomposition.ZONE,
          chart: {
            graphName: () => _t('name.ticketQuantity.byZoning'),
            chartType: 'pie',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'zoneId',
                fieldLabel: ChartLeafLabels.ZONE,
                aggregationType: AggregationType.QUANTITY,
                aggregationExtractor: AggregationType.QUANTITY,
              },
            ],
          },
          children: [
            {
              type: ChartDecomposition.SOURCE,
              chart: {
                graphName: () => _t('name.ticketQuantity.byZoningAndClient'),
                chartType: 'bar',
                chartPipeline: [
                  {
                    field: 'zoneId',
                    fieldLabel: ChartLeafLabels.ZONE,
                    aggregationType: AggregationType.QUANTITY,
                    aggregationExtractor: AggregationType.QUANTITY,
                  },
                  {
                    field: 'initialClientAppId',
                    fieldLabel: ChartLeafLabels.FOURNISSEUR,
                    aggregationType: AggregationType.QUANTITY,
                    aggregationExtractor: AggregationType.QUANTITY,
                  },
                ],
              },
            },
          ],
        },
        {
          type: ChartDecomposition.HOUR_OF_DAY,
          children: [
            {
              type: ChartDecomposition.ZONE,
              chart: {
                graphName: () => _t('name.ticketQuantity.byZoningAndHourOfDay'),
                chartType: 'line',
                chartPipeline: [
                  {
                    field: 'startHour',
                    fieldLabel: ChartLeafLabels.HOUR_OF_DAY,
                    aggregationType: AggregationType.HOUR_OF_DAY,
                    aggregationExtractor: AggregationType.HOUR_OF_DAY,
                  },
                  {
                    field: 'zoneId',
                    fieldLabel: ChartLeafLabels.ZONE,
                    aggregationType: AggregationType.QUANTITY,
                    aggregationExtractor: AggregationType.QUANTITY,
                  },
                ],
              },
            },
          ],
        },
        {
          type: ChartDecomposition.PRICE,
          chart: {
            graphName: () => _t('name.ticketQuantity.byPrice'),
            chartType: 'pie',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'price',
                fieldLabel: ChartLeafLabels.PRICE_RANGE,
                aggregationType: AggregationType.INTERVAL,
                aggregationExtractor: AggregationType.INTERVAL,
              },
            ],
          },
          children: [
            {
              type: ChartDecomposition.ZONE,
              chart: {
                graphName: () => _t('name.ticketQuantity.byPriceAndZoning'),
                chartType: 'bar',
                chartPipeline: [
                  {
                    field: 'price',
                    fieldLabel: ChartLeafLabels.PRICE_RANGE,
                    aggregationType: AggregationType.INTERVAL,
                    aggregationExtractor: AggregationType.INTERVAL,
                  },
                  {
                    field: 'zoneId',
                    fieldLabel: ChartLeafLabels.ZONE,
                    aggregationType: AggregationType.QUANTITY,
                    aggregationExtractor: AggregationType.QUANTITY,
                  },
                ],
              },
            },
          ],
        },
        {
          type: ChartDecomposition.PRICING_CATEGORY,
          children: [
            {
              type: ChartDecomposition.ZONE,
              chart: {
                graphName: () =>
                  _t('name.ticketQuantity.byZoningAndPricingCategory'),
                chartType: 'bar',
                chartPipeline: [
                  {
                    field: 'pricingCategory',
                    aggregationType: AggregationType.QUANTITY,
                    aggregationExtractor: AggregationType.QUANTITY,
                  },
                  {
                    field: 'zoneId',
                    fieldLabel: ChartLeafLabels.ZONE,
                    aggregationType: AggregationType.QUANTITY,
                    aggregationExtractor: AggregationType.QUANTITY,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      type: ChartCriteria.PRICE,
      children: [
        {
          type: ChartDecomposition.ZONE,
          chart: {
            graphName: () => _t('name.ticketPrice.byPriceAndZoning'),
            chartType: 'pie',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'zoneId',
                fieldLabel: ChartLeafLabels.ZONE,
                aggregationType: AggregationType.QUANTITY,
                aggregationExtractor: AggregationType.SUM,
              },
              {
                field: 'price',
                fieldLabel: ChartLeafLabels.PRICE,
                aggregationType: AggregationType.SUM,
                multiplier: 0.01,
              },
            ],
          },
          children: [
            {
              type: ChartDecomposition.PRICING_CATEGORY,
              chart: {
                graphName: () =>
                  _t('name.ticketPrice.byPriceAndZoningAndPricingCategory'),
                chartType: 'bar',
                chartPipeline: [
                  {
                    field: 'zoneId',
                    fieldLabel: ChartLeafLabels.ZONE,
                    aggregationType: AggregationType.QUANTITY,
                    aggregationExtractor: AggregationType.QUANTITY,
                  },
                  {
                    field: 'pricingCategory',
                    aggregationType: AggregationType.QUANTITY,
                    aggregationExtractor: AggregationType.SUM,
                  },
                  {
                    field: 'price',
                    fieldLabel: ChartLeafLabels.PRICE,
                    aggregationType: AggregationType.SUM,
                    multiplier: 0.01,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      type: ChartCriteria.DURATION,
      children: [
        {
          type: ChartDecomposition.ZONE,
          children: [
            {
              type: ChartDecomposition.HOURS,
              chart: {
                graphName: () => _t('name.ticketAvgDuration.byZoningAndHour'),
                chartType: 'pie',
                forceSingleDataset: true,
                chartPipeline: [
                  {
                    field: 'zoneId',
                    fieldLabel: ChartLeafLabels.ZONE,
                    aggregationType: AggregationType.QUANTITY,
                    aggregationExtractor: AggregationType.AVERAGE,
                  },
                  {
                    field: 'durationHours',
                    fieldLabel: ChartLeafLabels.PRICE,
                    aggregationType: AggregationType.AVERAGE,
                  },
                ],
              },
            },
            {
              type: ChartDecomposition.MINUTES,
              chart: {
                graphName: () =>
                  _t('name.ticketAvgDuration.byZoningAndMinutes'),
                chartType: 'pie',
                forceSingleDataset: true,
                chartPipeline: [
                  {
                    field: 'zoneId',
                    fieldLabel: ChartLeafLabels.ZONE,
                    aggregationType: AggregationType.QUANTITY,
                    aggregationExtractor: AggregationType.AVERAGE,
                  },
                  {
                    field: 'durationMinutes',
                    aggregationType: AggregationType.AVERAGE,
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  ],
};

export const CHART_CONTROL_TREE: CHART_SELECTOR_TREE = {
  type: ChartDataSource.CONTROL,
  children: [
    {
      type: ChartCriteria.QUANTITY,
      children: [
        {
          type: ChartDecomposition.CREATION_DATE,
          chart: {
            graphName: () => _t('name.controlQuantity.byStatementDatetime'),
            chartType: 'line',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'statementDatetime',
                aggregationType: AggregationType.DATE_HISTOGRAM,
                aggregationExtractor: AggregationType.DATE_HISTOGRAM,
              },
            ],
          },
        },
        {
          type: ChartDecomposition.EXEMPTION_REASON,
          chart: {
            graphName: () => _t('name.controlQuantity.byExemptionReason'),
            chartType: 'pie',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'exemptionReason',
                fieldLabel: ChartLeafLabels.EXEMPTION,
                aggregationType: AggregationType.QUANTITY,
                aggregationExtractor: AggregationType.QUANTITY,
              },
            ],
          },
        },
        {
          type: ChartDecomposition.HOUR_OF_DAY,
          chart: {
            graphName: () => _t('name.controlQuantity.byHours'),
            chartType: 'line',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'statementHour',
                fieldLabel: ChartLeafLabels.HOUR_OF_DAY,
                aggregationType: AggregationType.HOUR_OF_DAY,
                aggregationExtractor: AggregationType.HOUR_OF_DAY,
              },
            ],
          },
        },
        {
          type: ChartDecomposition.SUBMIT_MODE,
          chart: {
            graphName: () => _t('name.controlQuantity.bySubmitMode'),
            chartType: 'pie',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'esControlDeviceType',
                aggregationType: AggregationType.QUANTITY,
                aggregationExtractor: AggregationType.QUANTITY,
              },
            ],
          },
        },
        {
          type: ChartDecomposition.WITH_FPS,
          chart: {
            graphName: () => _t('name.controlQuantity.byFpsFollowing'),
            chartType: 'pie',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'fpsCreationStatus',
                fieldLabel: ChartLeafLabels.WITH_FPS,
                aggregationType: AggregationType.QUANTITY,
                aggregationExtractor: AggregationType.QUANTITY,
              },
            ],
          },
        },
      ],
    },
  ],
};

export const CHART_FPS_TREE: CHART_SELECTOR_TREE = {
  type: ChartDataSource.FPS,
  children: [
    {
      type: ChartCriteria.QUANTITY,
      children: [
        {
          type: ChartDecomposition.ZONE,
          chart: {
            graphName: () => _t('name.fpsQuantity.byZoning'),
            chartType: 'bar',
            chartPipeline: [
              {
                field: 'zoneId',
                fieldLabel: ChartLeafLabels.ZONE,
                aggregationType: AggregationType.QUANTITY,
                aggregationExtractor: AggregationType.QUANTITY,
              },
            ],
          },
        },
        {
          type: ChartDecomposition.PAYMENT_STATUS,
          chart: {
            graphName: () => _t('name.fpsQuantity.byPaymentStatus'),
            chartType: 'pie',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'paymentStatus',
                fieldLabel: ChartLeafLabels.FPS_PAIEMENT_STATUS,
                aggregationType: AggregationType.QUANTITY,
                aggregationExtractor: AggregationType.QUANTITY,
              },
            ],
          },
        },
        {
          type: ChartDecomposition.ANTAI_ABANDONMENT_REASON,
          chart: {
            graphName: () => _t('name.fpsQuantity.byAbandonedReason'),
            chartType: 'pie',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'abandonedReason',
                fieldLabel: ChartLeafLabels.ANTAI_ABANDONNED_REASON,
                aggregationType: AggregationType.QUANTITY,
                aggregationExtractor: AggregationType.QUANTITY,
              },
            ],
          },
        },
        {
          type: ChartDecomposition.HOUR_OF_DAY,
          chart: {
            graphName: () => _t('name.fpsQuantity.byHours'),
            chartType: 'line',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'statementHour',
                fieldLabel: ChartLeafLabels.HOUR_OF_DAY,
                aggregationType: AggregationType.HOUR_OF_DAY,
                aggregationExtractor: AggregationType.HOUR_OF_DAY,
              },
            ],
          },
        },
        {
          type: ChartDecomposition.PRICE,
          chart: {
            graphName: () => _t('name.fpsQuantity.byPrice'),
            chartType: 'pie',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'finePrice',
                fieldLabel: ChartLeafLabels.PRICE_RANGE,
                aggregationType: AggregationType.INTERVAL,
                aggregationExtractor: AggregationType.INTERVAL,
              },
            ],
          },
        },
        {
          type: ChartDecomposition.CREATION_DATE,
          chart: {
            graphName: () => _t('name.fpsQuantity.byCreationDate'),
            chartType: 'line',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'statementDatetime',
                aggregationType: AggregationType.DATE_HISTOGRAM,
                aggregationExtractor: AggregationType.DATE_HISTOGRAM,
              },
            ],
          },
        },
        {
          type: ChartDecomposition.STATUS,
          chart: {
            graphName: () => _t('name.fpsQuantity.byStatus'),
            chartType: 'pie',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'fpsState',
                fieldLabel: ChartLeafLabels.FPS_STATUS,
                aggregationType: AggregationType.QUANTITY,
                aggregationExtractor: AggregationType.QUANTITY,
              },
            ],
          },
        },
        {
          type: ChartDecomposition.ORIGIN,
          chart: {
            graphName: () => _t('name.fpsQuantity.byOrigin'),
            chartType: 'pie',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'origin',
                aggregationType: AggregationType.QUANTITY,
                aggregationExtractor: AggregationType.QUANTITY,
              },
            ],
          },
        },
      ],
    },
    {
      type: ChartCriteria.PRICE,
      children: [
        {
          type: ChartDecomposition.ZONE,
          chart: {
            graphName: () => _t('name.fpsPrice.byPriceAndZoning'),
            chartType: 'pie',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'zoneId',
                fieldLabel: ChartLeafLabels.ZONE,
                aggregationType: AggregationType.QUANTITY,
                aggregationExtractor: AggregationType.SUM,
              },
              {
                field: 'finePrice',
                fieldLabel: ChartLeafLabels.PRICE,
                aggregationType: AggregationType.SUM,
                multiplier: 0.01,
              },
            ],
          },
        },
      ],
    },
  ],
};

export const CHART_RAPO_TREE: CHART_SELECTOR_TREE = {
  type: ChartDataSource.RAPO,
  children: [
    {
      type: ChartCriteria.QUANTITY,
      children: [
        {
          type: ChartDecomposition.STATUS,
          chart: {
            graphName: () => _t('name.rapoQuantity.byStatus'),
            chartType: 'bar',
            chartPipeline: [
              {
                field: 'status',
                fieldLabel: ChartLeafLabels.RAPO_STATUS,
                aggregationType: AggregationType.QUANTITY,
                aggregationExtractor: AggregationType.QUANTITY,
              },
            ],
          },
        },
        {
          type: ChartDecomposition.RAPO_REQUEST_REASON,
          chart: {
            graphName: () => _t('name.rapoQuantity.byRequestReason'),
            chartType: 'pie',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'reason',
                fieldLabel: ChartLeafLabels.RAPO_REASONS,
                aggregationType: AggregationType.QUANTITY,
                aggregationExtractor: AggregationType.QUANTITY,
              },
            ],
          },
        },
        {
          type: ChartDecomposition.RAPO_RESPONSE_REASON,
          chart: {
            graphName: () => _t('name.rapoQuantity.byResponseReason'),
            chartType: 'pie',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'cityReason',
                fieldLabel: ChartLeafLabels.RAPO_RESPONSE_REASONS,
                aggregationType: AggregationType.QUANTITY,
                aggregationExtractor: AggregationType.QUANTITY,
              },
            ],
          },
        },
        {
          type: ChartDecomposition.CREATION_DATE,
          chart: {
            graphName: () => _t('name.rapoQuantity.byCreationDate'),
            chartType: 'line',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'created',
                aggregationType: AggregationType.DATE_HISTOGRAM,
                aggregationExtractor: AggregationType.DATE_HISTOGRAM,
              },
            ],
          },
        },
        {
          type: ChartDecomposition.AVERAGE_RECEPTION_TIME,
          chart: {
            graphName: () => _t('name.rapoQuantity.byProcessingTime'),
            chartType: 'pie',
            forceSingleDataset: true,
            chartPipeline: [
              {
                field: 'processingDuration',
                fieldLabel: ChartLeafLabels.DAYS,
                aggregationType: AggregationType.QUANTITY,
                aggregationExtractor: AggregationType.QUANTITY,
              },
            ],
          },
        },
      ],
    },
  ],
};
