import Dialog from 'material-ui/Dialog';
import React, { CSSProperties, useContext, useState } from 'react';
import { History } from 'history';
import CircularProgress from 'material-ui/CircularProgress';
import { set } from 'lodash';

import BoButton from 'facade/BoButton';
import { getRefundPdfUrl, sendContact, updateMainContact } from 'api/refund';
import { RefundDetailDTO } from 'api/refund/types';
import {
  BaseFpsContactDTO,
  FpsContactDTO,
  FpsContactType,
  IContactable,
} from 'api/fps/types';
import { addContact, deleteContact } from 'api/fps';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import FlexCenter from 'commons/FlexCenter';
import { BKG_PINK, STYLE_MODAL_TITLE, STYLE_SEPARATOR } from 'theme';
import EditMailBar from 'commons/Mail/EditMailBar/EditMailBar';
import { openNewAuthentifiedTab } from 'api/helpers';

import { RefreshContext, RefreshContextType } from '../Context';

import AddContactModal from './AddContactModal';
import ContactList from './ContactList';
import CcspLink from './CcspLink';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  refund: RefundDetailDTO;
  history: History;
};

const STYLE_ROW: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 20,
};

function ContactBlock({ refund, history }: Props): JSX.Element {
  const [stateAddContactModal, setStateAddContactModal] = useState<{
    open: boolean;
    type: FpsContactType;
  }>({ open: false, type: 'EMAIL' });
  const [openContactChoice, setOpenContactChoice] = useState(false);
  const [sending, setSending] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { onUpdate } = useContext(RefreshContext) as RefreshContextType;

  function openMailAddContactModal() {
    setStateAddContactModal({ open: true, type: 'EMAIL' });
  }

  function openAddressAddContactModal() {
    setStateAddContactModal({ open: true, type: 'POSTAL_ADDRESS' });
  }

  function handleContactChoiceModal() {
    setOpenContactChoice(!openContactChoice);
  }

  async function onSend(contact: FpsContactDTO) {
    setSending(true);
    try {
      await sendContact(contact, refund.refundId);
      onUpdate(true, _tg('tefps.dashboard.refund.mailSent'));
    } catch (err) {
      onUpdate(false, (err as Error).message);
    } finally {
      setSending(false);
    }
  }

  async function onDeleteContact(contact: FpsContactDTO) {
    setDeleting(true);
    try {
      await deleteContact(contact.id);
      onUpdate(true, _tg('tefps.dashboard.refund.contactDeleted'));
    } catch (err) {
      onUpdate(false, (err as Error).message);
    } finally {
      setDeleting(false);
    }
  }

  const onEditMailClick = () => {
    history.push(`/dashboard/refund/${refund.refundId}/mail`);
  };

  const openPdfUrl = () => {
    const url = getRefundPdfUrl(refund.refundId);
    openNewAuthentifiedTab(url);
  };

  const actions = [
    <BoButton
      style={{ marginRight: 10 }}
      key={1}
      label={_tg('action.back')}
      onClick={handleContactChoiceModal}
    />,
  ];

  async function onSubmit(
    contact: IContactable | null | undefined,
    type: FpsContactType,
    illegibleAddress?: boolean
  ) {
    if (contact) {
      try {
        await addContact(
          {
            rootFpsId: refund.rootFpsId,
            contactSource: 'MANUAL',
            contactType: type,
            contact,
            illegibleAddress,
          } as BaseFpsContactDTO,
          refund.rootFpsId
        );
        onUpdate(
          true,
          type === 'EMAIL'
            ? _tg('tefps.dashboard.refund.newEmailAddressAdded')
            : _tg('tefps.dashboard.refund.newPostingAddressAdded')
        );
      } catch (err) {
        onUpdate(false, (err as Error).message);
      }
    }
    setStateAddContactModal({ open: false, type });
    setOpenContactChoice(false);
  }

  const updateRefundMainContact = async (contact: FpsContactDTO) => {
    try {
      await updateMainContact(refund.refundId, contact);
      onUpdate(true, _tg('tefps.dashboard.refund.mainContactUpdated'));
    } catch (err) {
      onUpdate(false, (err as Error).message);
    }
  };

  return (
    <div>
      <SeparatorWithTitle
        style={STYLE_SEPARATOR}
        title={_tg('tefps.dashboard.refund.contact')}
        color={BKG_PINK}
        titleSize={20}
      />
      {refund.fpsContacts.length === 0 && refund.ccspAttached && (
        <CcspLink fpsId={refund.rootFpsId} history={history} />
      )}
      <ContactList
        fpsContacts={refund.fpsContacts}
        onSend={onSend}
        onDelete={onDeleteContact}
        updateMainContact={updateRefundMainContact}
        mainContactId={refund.mainContactId}
        style={{ marginTop: '20px' }}
      />
      <div style={STYLE_ROW}>
        <BoButton
          label={_tg('tefps.dashboard.refund.addContact')}
          primary
          onClick={handleContactChoiceModal}
        />
        <EditMailBar
          onEditMailClick={onEditMailClick}
          openPdfUrl={openPdfUrl}
        />
      </div>
      <Dialog
        open={openContactChoice}
        actions={actions}
        title={_tg('tefps.dashboard.refund.contactType')}
        titleStyle={STYLE_MODAL_TITLE}
      >
        <div style={{ display: 'flex' }}>
          <BoButton
            label={_tg('tefps.dashboard.refund.emailAddress')}
            primary
            onClick={openMailAddContactModal}
            style={{ flex: 1, marginTop: '10px', marginRight: '20px' }}
          />
          <BoButton
            label={_tg('tefps.dashboard.refund.physicalAddress')}
            primary
            onClick={openAddressAddContactModal}
            style={{ flex: 1, marginTop: '10px', marginLeft: '20px' }}
          />
        </div>
      </Dialog>
      <AddContactModal
        /* fpsId={refund.rootFpsId} */ isOpen={stateAddContactModal.open}
        type={stateAddContactModal.type}
        submit={onSubmit}
      />
      <Dialog open={sending} title={_tg('tefps.dashboard.refund.sending')}>
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      </Dialog>
      <Dialog open={deleting} title={_tg('tefps.dashboard.refund.deleting')}>
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      </Dialog>
    </div>
  );
}

export default ContactBlock;
