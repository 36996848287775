const { _tg } = window.loadTranslations(__filename);

export default (): Array<{
  key: string;
  label: string;
  checked: boolean;
}> => [
  {
    key: 'paymentId',
    label: _tg('tefps.dashboard.tvPayments.paymentId'),
    checked: true,
  },
  {
    key: 'parkingRightId',
    label: _tg('tefps.dashboard.tvPayments.ticketId'),
    checked: true,
  },
  {
    key: 'paymentDatetime',
    label: _tg('tefps.dashboard.tvPayments.paymentDate'),
    checked: true,
  },
  { key: 'paymentMode', label: _tg('field.payment.mode'), checked: true },
  { key: 'paymentAmount', label: _tg('commons.price'), checked: true },
  { key: 'comment', label: _tg('field.comment'), checked: true },
];
