import React, { useEffect, useState } from 'react';

import Date from 'commons/Date';
import Exporter from 'commons/Exporter';
import Price from 'commons/Price';
import SimpleTable from 'commons/SimpleTable';
import { Flex } from '@cvfm-front/commons-ui';
import { Filters } from 'commons/types/filterbar';
import { translatePaymentMode } from 'commons/Utils/paymentUtil';

import TvPaymentsFilters from './TvPaymentsFilters';
import TvPaymentsService from './TvPaymentsService';
import columnsForExport from './ColumnsForExport';
import columnsForTable from './ColumnsForTable';
import { TvPaymentExportDTO } from './types';

const { _tg } = window.loadTranslations(__filename);

function renderTvPaymentRow(item: TvPaymentExportDTO) {
  return [
    item.parkingRightId,
    item.paymentId,
    translatePaymentMode()[item.paymentMode],
    <Date datetime={item.paymentDatetime} />,
    Price({ price: item.paymentAmount }),
    item.comment,
  ];
}

export default function TvPaymentsTable(): JSX.Element {
  const [totalHits, setTotalHits] = useState(0);
  const [items, setItems] = useState<TvPaymentExportDTO[]>([]);
  const [filters, setFilters] = useState<Filters>(TvPaymentsFilters());

  useEffect(() => TvPaymentsService.watchItems(setItems), []);
  useEffect(() => TvPaymentsService.watchTotalHits(setTotalHits), []);
  useEffect(() => TvPaymentsService.watchFilters(setFilters), []);

  return (
    <Flex className="page-content" width100 height100 flexDirection="column">
      <h1 className="page__title">
        {_tg('tefps.dashboard.tvPayments.paymentTable')}
      </h1>
      {/* We need to wrap SimpleTable in a 100% height div */}
      {/* For whatever reason, SimpleTable auto wrap himself in a 0px height div (Autosizer I guess ?), and this mess the expected layout. */}
      <div className="page__table">
        <SimpleTable
          cols={columnsForTable()}
          rowHeight={50}
          loadMoreRows={TvPaymentsService.loadMoreRows}
          remoteRowCount={totalHits}
          items={items}
          itemsRenderer={renderTvPaymentRow}
        />
      </div>
      <Exporter
        columns={columnsForExport()}
        type={_tg('tefps.dashboard.finance.tvPayment.label')}
        disabled={totalHits === 0}
        filters={filters}
        fileExport={TvPaymentsService.fileExport}
      />
    </Flex>
  );
}
