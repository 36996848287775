import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { computeDatesWithPeriod } from 'commons/DateFilter';
import { DAYS } from 'commons/Days';
import { GpvMapError, GpvMapFilters } from 'Dashboard/pv/GpvMaps/types';
import { Coordinates, MapClusterDTO } from 'api/commonTypes';
import { searchGpvForMap } from 'api/tepv/gpv';
import ErrorBlock from 'commons/ErrorBlock';
import { CityLocation, getConfigState } from 'config/duck';
import ClusterMap from 'commons/ClusterMap';
import { InternalApiState } from 'api/duck';
import GpvMapFilterBar from 'Dashboard/pv/GpvMaps/GpvMapFilterBar';

import { fetchZoning } from '../../../api/pricing';
import { FETCH_PV_ZONING_CONFIG } from '../../../commons/FetchZoningConfigs';
import { toZoneforMap } from '../../../commons/ZoningComponents/helpers';
import { MapZoning } from '../../../api/pricing/types';

import { filtersToRequest } from './utils';

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  width: '100%',
  height: '100%',
};

const STYLE_LIST_WRAPPER: CSSProperties = {
  backgroundColor: 'white',
  width: '100%',
  margin: '0 auto',
};

const INITIAL_FILTERS: GpvMapFilters = {
  agentId: null,
  boundingBoxNorthEast: { latitude: 90, longitude: 90 },
  boundingSouthWest: { latitude: -90, longitude: -90 },
  dates: computeDatesWithPeriod('12'),
  times: {
    from: '00:00',
    to: '23:59',
  },
  plate: null,
  signatureDaysOfWeek: new Set(DAYS),
  zoom: 12,
  motifsCategories: [],
  presetNotes: [],
  organizationIds: [],
};

type Props = {
  location: CityLocation;
};

const GpvMaps = ({ location }: Props) => {
  const [filters, setFilters] = useState<GpvMapFilters>(INITIAL_FILTERS);
  const [error, setError] = useState<GpvMapError>(null);
  const [markers, setMarkers] = useState([]);
  const [isClusters, setIsClusters] = useState<boolean>(false);
  const [totalHits, setTotalHits] = useState<number>(0);
  const [zonings, setZonings] = useState<MapZoning | null>(null);
  const [displayZones, setDisplayZones] = useState<boolean>(false);
  const map = useRef<ClusterMap | null>(null);

  function updateFilters(newFilters: GpvMapFilters) {
    setFilters(newFilters);
  }

  function updateMapPosition(
    boundingBoxNorthEast: Coordinates,
    boundingSouthWest: Coordinates,
    zoom: number
  ) {
    const updatedFilters = {
      ...filters,
      boundingBoxNorthEast,
      boundingSouthWest,
      zoom,
    };
    updateFilters(updatedFilters);
  }

  const toggleDisplayZones = () => {
    map.current?.togglePolygonVisibility(!displayZones);
    setDisplayZones(prevState => !prevState);
  };

  const fetchZones = async () => {
    try {
      const zoning = await fetchZoning(FETCH_PV_ZONING_CONFIG);
      const zoningMap = toZoneforMap(zoning);
      setZonings(zoningMap);
    } catch (e) {
      // ignored
    }
  };

  async function fetchGpvs() {
    try {
      const result = await searchGpvForMap(filtersToRequest(filters));
      let m = [];
      setTotalHits(result.totalHits);
      if (result && result.gpvs) {
        m = result.gpvs.map((gpv: any) => ({
          position: {
            lat: gpv.latitude,
            lng: gpv.longitude,
          },
        }));
        setMarkers(m);
      } else if (result && result.clusters) {
        setIsClusters(true);
        m = result.clusters.map((cluster: MapClusterDTO) => ({
          position: {
            lat: cluster.latitude,
            lng: cluster.longitude,
          },
          count: cluster.number,
        }));
        setMarkers(m);
      }
    } catch (e) {
      setError(e);
    }
  }

  useEffect(() => {
    fetchZones();
  }, []);

  useEffect(() => {
    fetchGpvs();
  }, [filters]);

  if (error) {
    return (
      <ErrorBlock
        message="Erreur lors de la récupération des graphes de la page"
        error={error}
      />
    );
  }

  const { latitude: lat, longitude: lng } = location || {
    latitude: 0,
    longitude: 0,
  };

  return (
    <div style={STYLE_CONTENT}>
      <GpvMapFilterBar
        filters={filters}
        totalHits={totalHits}
        updateFilters={updateFilters}
        setError={setError}
        displayZones={displayZones}
        toggleDisplayZone={toggleDisplayZones}
      />
      <div style={STYLE_LIST_WRAPPER}>
        <ClusterMap
          ref={map}
          displayAsClusters={isClusters}
          updateFilters={updateMapPosition}
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
          center={{ lat, lng }}
          markers={markers}
          zonings={zonings}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state: InternalApiState) => {
  const { location } = getConfigState(state);
  return {
    location,
  };
};

export default connect(mapStateToProps, null)(GpvMaps);
