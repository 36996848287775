import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import CircularProgress from 'material-ui/CircularProgress';

import FlexCenter from 'commons/FlexCenter';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import SimpleTable from 'commons/SimpleTable';
import DateFilter, { DatepickerClass } from 'commons/DateFilter';
import Exporter from 'commons/Exporter';
import AdvertisingModal from 'commons/AdvertisingModal';
import {
  askAgentSubscriptionsReportingExport,
  fetchNbrSubscriptionsByAgent,
} from 'api/dashboard';
import { AgentsSubsStatisticsDTO } from 'api/dashboard/types';
import { TXT_BLACK } from 'theme';
import { ReduxDashboardState } from 'Dashboard/duck';

import { setPeriod, setSortParameters } from './duck';
import { FiltersDTO, Period, SortParameters } from './types';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  flexWrap: 'wrap',
  color: TXT_BLACK,
  margin: '25px auto 40px',
  fontSize: 20,
  textAlign: 'center',
};

const STYLE_SUBTITLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  flexWrap: 'wrap',
  margin: '0 auto',
  fontSize: 15,
  textAlign: 'center',
  fontWeight: 'bold',
};

const STYLE_TEXT: CSSProperties = {
  fontSize: 12,
  fontWeight: 'bold',
  marginRight: 10,
};

type State = {
  reportInfo: AgentsSubsStatisticsDTO | null | undefined;
  error: Error | null | undefined;
};

type Props = {
  subscriptionsReport: {
    sort: SortParameters; // eslint-disable-line react/no-unused-prop-types
    period: Period; // eslint-disable-line react/no-unused-prop-types
  };
  updateSortParameters: (sort: SortParameters) => void;
  updatePeriod: (period: Period) => void;
};

function getSortFieldText(colNumber: number) {
  switch (colNumber) {
    case 0:
      return 'nameAgent';
    case 1:
    default:
      return 'ALL';
    case 2:
      return 'ACCEPTED';
    case 3:
      return 'REJECTED';
    case 4:
      return 'DISCARDED';
  }
}

const filtersToRequest = (period: Period, sort: SortParameters): FiltersDTO => {
  const { from, to } = period;
  return {
    from: from ? moment(from).toISOString() : null,
    to: to ? moment(to).toISOString() : null,
    sortOrder: sort.increasingOrder ? 'ASC' : 'DESC',
    sortField: getSortFieldText(sort.colSorted),
  };
};

const exportParam = (period: Period): Record<string, any> => {
  const { from, to } = period;
  return {
    from: from ? moment(from).toISOString() : null,
    to: to ? moment(to).toISOString() : null,
  };
};

class SubscriptionsReport extends React.Component<Props, State> {
  EXPORT_COL = [
    { key: 'agentName', label: _tg('field.agent.name'), checked: true },
    {
      key: 'treated',
      label: _tg('tefps.filters.rapo.statuses.wasProcessedFP'),
      checked: true,
    },
    {
      key: 'accepted',
      label: _tg('tefps.filters.rapo.statuses.acceptedFP'),
      checked: true,
    },
    {
      key: 'rejected',
      label: _tg('tefps.filters.rapo.statuses.rejectedFP'),
      checked: true,
    },
    {
      key: 'discarded',
      label: _tg('tefps.filters.rapo.statuses.canceledFP'),
      checked: true,
    },
  ];

  TABLE_COL = [
    { label: _tg('field.agent.name'), width: 300, onSort: true },
    {
      label: _tg('tefps.filters.rapo.statuses.wasProcessedFP'),
      width: 100,
      onSort: true,
    },
    {
      label: _tg('tefps.filters.rapo.statuses.acceptedFP'),
      width: 100,
      onSort: true,
    },
    {
      label: _tg('tefps.filters.rapo.statuses.declinedFP'),
      width: 100,
      onSort: true,
    },
    {
      label: _tg('tefps.filters.rapo.statuses.canceledFP'),
      width: 100,
      onSort: true,
    },
  ];

  constructor(props: Props) {
    super(props);
    this.state = { reportInfo: null, error: null };
  }

  componentDidMount() {
    void this.getSubscriptionsInfo(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: Props) {
    void this.getSubscriptionsInfo(newProps);
  }

  footerCols = (totalAgents: number, total: number) => {
    const columns = [
      {
        label: `${totalAgents.toLocaleString()} ${_tg('commons.activeAgents')}`,
        width: 300,
        headerStyle: { marginTop: 14 },
      },
      {
        label: `${total.toLocaleString()} ${_tg(
          'field.subscription.subscriptions'
        ).toLocaleLowerCase()}`,
        width: 200,
        headerStyle: { marginTop: 14 },
      },
    ];
    return columns;
  };

  onUpdateSort = (colSorted: number, increasingOrder: boolean): void => {
    const { updateSortParameters } = this.props;
    updateSortParameters({
      colSorted,
      increasingOrder,
    });
  };

  onChangeDate = (newDate: Period): void => {
    const { updatePeriod } = this.props;
    updatePeriod(newDate);
  };

  getSubscriptionsInfo = async (props: Props) => {
    try {
      const {
        subscriptionsReport: { sort, period },
      } = props;
      const reportInfo = await fetchNbrSubscriptionsByAgent(
        filtersToRequest(period, sort)
      );
      this.setState({ reportInfo });
    } catch (error) {
      this.setState({ error: error as Error, reportInfo: null });
    }
  };

  itemsRenderer = (key: string) => {
    const { reportInfo } = this.state;
    if (!reportInfo) return [];
    const { countsByAgents } = reportInfo;
    const line = countsByAgents[key];

    return [
      <span>{line.agentName}</span>,
      <span>
        {(line.counts.ACCEPTED || 0) +
          (line.counts.REJECTED || 0) +
          (line.counts.AWAITING_PAYMENT || 0) +
          (line.counts.DISCARDED || 0)}
      </span>,
      <span>
        {(line.counts.ACCEPTED || 0) + (line.counts.AWAITING_PAYMENT || 0)}
      </span>,
      <span>{line.counts.REJECTED || 0}</span>,
      <span>{line.counts.DISCARDED || 0}</span>,
    ];
  };

  render() {
    const { reportInfo, error } = this.state;
    const { countsByAgents, total } = reportInfo || {};
    const {
      subscriptionsReport: { period, sort },
    } = this.props;
    const items = countsByAgents ? Object.keys(countsByAgents) : null;

    if (error) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock
              message={_tg(
                'tefps.dashboard.lapiReviewReport.errorWhileFetchingData'
              )}
              error={error}
            />
          </FlexCenter>
        </Content>
      );
    }

    return (
      <Content>
        <AdvertisingModal module="subscribers" />
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ margin: '2em auto', maxWidth: '90%' }}>
            <span style={STYLE_TITLE}>
              {_tg('tefps.dashboard.subscribers.numberOfSubscriptionsPerAgent')}
            </span>
            <div style={{ margin: '40px auto 30px', maxWidth: 400 }}>
              <span style={STYLE_SUBTITLE}>
                {_tg('tefps.dashboard.subscribers.countSubscriptions')}
              </span>
              <DateFilter
                styleText={STYLE_TEXT}
                datepickerClass={DatepickerClass.filter}
                colorResetButton={TXT_BLACK}
                dates={period}
                onChange={this.onChangeDate}
              />
            </div>
            {!items ? (
              <FlexCenter>
                <CircularProgress />
              </FlexCenter>
            ) : (
              <div style={{ margin: 'auto', width: '730px' }}>
                <SimpleTable
                  maxHeight={500}
                  cols={this.TABLE_COL}
                  rowHeight={50}
                  header
                  footer={this.footerCols(items.length, total || 0)}
                  itemsRenderer={this.itemsRenderer}
                  items={items}
                  remoteRowCount={items.length}
                  colSorted={sort.colSorted}
                  sortOrder={sort.increasingOrder}
                  onSort={this.onUpdateSort}
                />
                <div style={{ marginTop: 20 }}>
                  <Exporter
                    disabled={!items.length}
                    columns={this.EXPORT_COL}
                    type={_tg('tefps.dashboard.subscribers.reportingAgent')}
                    filters={exportParam(period)}
                    fileExport={askAgentSubscriptionsReportingExport}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Content>
    );
  }
}

export default connect(
  (state: any) => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    subscriptionsReport: (state.dashboard as ReduxDashboardState)
      .subscriptionsReport,
  }),
  dispatch => ({
    updatePeriod: (period: Period) => dispatch(setPeriod(period)),
    updateSortParameters: (sort: SortParameters) =>
      dispatch(setSortParameters(sort)),
  })
)(SubscriptionsReport);
