import * as React from 'react';
import { connect } from 'react-redux';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import Dates, { FilterDate } from 'commons/SidebarV2/Components/Dates';
import Input from 'commons/SidebarV2/Components/Input';
import { KeyBucketDTO } from 'api/commonTypes';
import { getConfigState, RegieProperties } from 'config/duck';

import { CHANNELS_OPTIONS, MODES_OPTIONS } from '../../Payment/List/utils';

import { getSubscriberPaymentState, initialFilters, setFilters } from './duck';
import { PaymentSearchCriterias } from './types';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  totalHits: number;
  filters: PaymentSearchCriterias;
  facetings:
    | {
        [key: string]: Array<KeyBucketDTO>;
      }
    | null
    | undefined;
  updateFilters: (newFilters: PaymentSearchCriterias) => void;
  regiesProperties: RegieProperties;
};

type State = {
  regieOptions: Array<{ value: string; label: string }>;
};

class PaymentFilters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      regieOptions: [],
    };
  }

  componentDidMount() {
    const { regiesProperties } = this.props;
    this.buildRegieOptions(regiesProperties);
  }

  onChange = (id: string, value: FilterDate | Set<any> | string) => {
    const { filters, updateFilters } = this.props;
    updateFilters({ ...filters, [id]: value });
  };

  buildRegieOptions = (regiesProperties: RegieProperties) => {
    // Do not build regie options if not regie properties or if only one regie
    if (
      !regiesProperties ||
      regiesProperties.subscriptionProperties.length <= 1
    ) {
      return;
    }

    const regieOptions = regiesProperties.subscriptionProperties.map(
      ({ id, name }) => ({
        value: id,
        label: name,
      })
    );

    this.setState({ regieOptions });
  };

  resetFilters = (): void => {
    const { updateFilters } = this.props;
    updateFilters(initialFilters());
  };

  render() {
    const { totalHits, filters, facetings } = this.props;
    const { regieOptions } = this.state;

    return (
      <Sidebar>
        <BarTitle resetFilters={this.resetFilters} />
        <HitsCounter hits={totalHits} />
        <Dates
          id="dates"
          title={_tg('field.date.date_plural')}
          dates={filters.dates}
          onChange={this.onChange}
        />
        <Checkboxes
          id="channels"
          title={_tg('tefps.dashboard.payment.paymentChannel')}
          options={CHANNELS_OPTIONS()}
          filters={filters.channels}
          onChange={this.onChange}
          faceting={facetings ? facetings.channels : null}
        />
        <Checkboxes
          id="paymentModes"
          title={_tg('field.payment.mode')}
          options={MODES_OPTIONS()}
          filters={filters.paymentModes}
          onChange={this.onChange}
          faceting={facetings ? facetings.paymentModes : null}
        />
        {regieOptions.length > 0 && (
          <Checkboxes
            id="regieIds"
            title={_tg('tefps.dashboard.payment.regie')}
            options={regieOptions}
            filters={filters.regieIds}
            onChange={this.onChange}
            faceting={facetings ? facetings.regieIds : null}
          />
        )}
        <Input
          id="operationId"
          title={_tg('tefps.dashboard.payment.operationNumber')}
          placeholder={_tg('tefps.dashboard.payment.operationNumberFilter')}
          onChange={this.onChange}
          value={filters.operationId || ''}
        />
        <Input
          id="resourceId"
          title={_tg('tefps.dashboard.payment.resourceId')}
          placeholder={_tg('tefps.dashboard.payment.resourceIdFilter')}
          onChange={this.onChange}
          value={filters.resourceId || ''}
        />
      </Sidebar>
    );
  }
}

export default connect(
  state => {
    const { filters } = getSubscriberPaymentState(state);
    const { regiesProperties } = getConfigState(state);
    return {
      filters,
      regiesProperties,
    };
  },
  dispatch => ({
    updateFilters: (filters: PaymentSearchCriterias) =>
      dispatch(setFilters(filters)),
  })
)(PaymentFilters);
