import React, { CSSProperties } from 'react';
import moment from 'moment';
import CircularProgress from 'material-ui/CircularProgress';
import FileSaver from 'file-saver';

import BoButton from 'facade/BoButton';
import { FilterDate } from 'commons/SidebarV2/Components/Dates';
import { PvReportLine, PvReportDTO } from 'api/tepv/stats/types';
import { SortParameters } from 'api/commonTypes';
import {
  askNumberOfMifByAgentExport,
  askNumberOfMifByAgentPdfExport,
  askNumberOfMifByOrgExport,
} from 'api/dashboard';
import FlexCenter from 'commons/FlexCenter';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import Exporter from 'commons/Exporter';
import SimpleTable from 'commons/SimpleTable';
import DateFilter, { DatepickerClass } from 'commons/DateFilter';
import { TXT_BLACK } from 'theme';
import { ListBottom } from 'commons/ListWrappers';

import { EXPORT_COL, EXPORT_COL_ORG, filtersToRequest } from './utils';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  flexWrap: 'wrap',
  color: TXT_BLACK,
  margin: '25px auto 40px',
  fontSize: 20,
  textAlign: 'center',
};

const STYLE_SUBTITLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  flexWrap: 'wrap',
  margin: '0 auto',
  fontSize: 15,
  textAlign: 'center',
  fontWeight: 'bold',
};

const STYLE_TEXT: CSSProperties = {
  fontSize: 12,
  fontWeight: 'bold',
  marginRight: 10,
};

const typeToName = {
  org: 'organisation',
  agent: 'agent',
};

const tableCols = (type: 'org' | 'agent') => {
  return [
    { label: `Nom de l'${typeToName[type]}`, width: 300, onSort: true },
    {
      label: 'Nombre de MIF',
      width: 200,
      onSort: true,
    },
    { label: 'Date du dernier MIF', width: 200, onSort: true },
  ];
};

const footerCols = (agentCount: number, mifCount: number) => [
  {
    label: `${agentCount.toLocaleString()} agents actifs`,
    width: 300,
    headerStyle: { marginTop: 14 },
  },
  {
    label: `${mifCount.toLocaleString()} MIF créés`,
    width: 200,
    headerStyle: { marginTop: 14 },
  },
  {
    label: '',
    width: 200,
    headerStyle: { marginTop: 14 },
  },
];

type Props = {
  error: Error | null | undefined;
  report: PvReportDTO | null | undefined;
  type: 'agent' | 'org';
  period: FilterDate;
  onChangeDate: (period: FilterDate) => void;
};

type State = {
  sort: SortParameters;
  sortedReport: PvReportDTO;
};

const sortByField = (
  report: PvReportDTO | null | undefined,
  sort: SortParameters
): PvReportDTO => {
  if (report == null) {
    return { lines: [] };
  }
  const sortedLines = report.lines.sort((lineA, lineB) => {
    let diff = 0;
    switch (sort.sortField) {
      // Agent name
      case 0:
        diff = lineA.name.localeCompare(lineB.name);
        break;
      // MIF count
      case 1:
        diff = lineA.count - lineB.count;
        break;
      case 2: {
        const lastMifDateA = lineA.lastMif;
        const lastMifDateB = lineB.lastMif;

        if (lastMifDateA === null) {
          diff = lastMifDateB == null ? 0 : 1;
          break;
        }
        if (lastMifDateB === null) {
          diff = -1;
          break;
        }
        diff = moment(lastMifDateA).isBefore(moment(lastMifDateB)) ? -1 : 1;
        break;
      }
      default:
        break;
    }

    if (diff === 0) {
      diff = lineB.count - lineA.count;
    }

    return diff;
  });

  return { lines: sort.increasingOrder ? sortedLines : sortedLines.reverse() };
};

class PvReport extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const initialSort = {
      sortField: 0,
      increasingOrder: true,
    };
    this.state = {
      sort: initialSort,
      sortedReport: sortByField(props.report, initialSort),
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: Props): void {
    const { report } = this.props;
    const { sort } = this.state;
    if (report !== newProps.report) {
      this.setState({
        sortedReport: sortByField(newProps.report, sort),
      });
    }
  }

  onUpdateSort = (sortField: number, increasingOrder: boolean): void => {
    const { report } = this.props;
    const sort = { sortField, increasingOrder };
    this.setState({ sort, sortedReport: sortByField(report, sort) });
  };

  onPdfExport = async (): Promise<void> => {
    const { report } = this.props;
    const file = await askNumberOfMifByAgentPdfExport(report);
    const blobFile = await file.blob();
    FileSaver.saveAs(blobFile, 'export.pdf');
  };

  itemsRenderer = (line: PvReportLine): JSX.Element[] => [
    <span>{line.name}</span>,
    <span>{line.count}</span>,
    <span>
      {line.lastMif
        ? moment(line.lastMif).format('DD/MM/YYYY HH:mm')
        : 'Aucun MIF'}
    </span>,
  ];

  render(): JSX.Element {
    const { error, type, report, period, onChangeDate } = this.props;
    const { sort, sortedReport } = this.state;
    if (error) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock
              message="Erreur lors de la récupération des informations"
              error={error}
            />
          </FlexCenter>
        </Content>
      );
    }

    const pvTotal = report
      ? report.lines.reduce((prev, line) => prev + line.count, 0)
      : 0;

    return (
      <Content>
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ margin: '2em auto', maxWidth: '90%' }}>
            <span style={STYLE_TITLE}>
              {' '}
              {_tg('dashboard.pv.reports.report.mifsInfo', {
                name: typeToName[type],
              })}
            </span>
            <div style={{ margin: '40px auto 30px', maxWidth: 400 }}>
              <span style={STYLE_SUBTITLE}>
                {_tg('dashboard.pv.reports.report.countMifs')}
              </span>
              <DateFilter
                styleText={STYLE_TEXT}
                datepickerClass={DatepickerClass.filter}
                colorResetButton={TXT_BLACK}
                dates={period}
                onChange={onChangeDate}
              />
            </div>
            {!report ? (
              <FlexCenter>
                <CircularProgress />
              </FlexCenter>
            ) : (
              <div
                style={{
                  margin: 'auto',
                  width: 740,
                }}
              >
                <SimpleTable
                  maxHeight={500}
                  cols={tableCols(type)}
                  rowHeight={50}
                  header
                  footer={footerCols(sortedReport.lines.length, pvTotal)}
                  itemsRenderer={this.itemsRenderer}
                  items={sortedReport.lines}
                  remoteRowCount={report.lines.length}
                  colSorted={sort.sortField}
                  sortOrder={sort.increasingOrder}
                  onSort={this.onUpdateSort}
                />
              </div>
            )}
            <ListBottom style={{ marginTop: '100px' }}>
              {type === 'agent' ? (
                <Exporter
                  disabled={!sortedReport.lines.length}
                  columns={EXPORT_COL}
                  type="Statistiques Agents"
                  filters={filtersToRequest(period, sort, type)}
                  fileExport={askNumberOfMifByAgentExport}
                />
              ) : (
                <Exporter
                  disabled={!sortedReport.lines.length}
                  columns={EXPORT_COL_ORG}
                  type="Statistiques Organisations"
                  filters={filtersToRequest(period, sort, type)}
                  fileExport={askNumberOfMifByOrgExport}
                />
              )}
              <BoButton
                style={{ margin: '0 15px' }}
                label="Export PDF"
                onClick={this.onPdfExport}
                primary
              />
            </ListBottom>
          </div>
        </div>
      </Content>
    );
  }
}

export default PvReport;
