import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';

import { searchMifForMap } from 'api/tepv/mif';
import { Coordinates, MapClusterDTO } from 'api/commonTypes';
import MifMap from 'commons/ClusterMap';
import { getConfigState } from 'config/duck';
import { computeDatesWithPeriod } from 'commons/DateFilter';
import { DAYS } from 'commons/Days';
import ErrorBlock from 'commons/ErrorBlock';
import { InternalApiState } from 'api/duck';
import { fetchZoning } from 'api/pricing';
import { FETCH_PV_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import { toZoneforMap } from 'commons/ZoningComponents/helpers';
import { Marker } from 'commons/ClusterMap/types';

import { MapFilters, MifMapProps, MifMapState } from './types';
import { filtersToRequest } from './utils';
import MifMapFilters from './MifMapFilters';

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  width: '100%',
  height: '100%',
};

const STYLE_LIST_WRAPPER: CSSProperties = {
  backgroundColor: 'white',
  width: '100%',
  margin: '0 auto',
};

const INITIAL_FILTERS: MapFilters = {
  agentId: null,
  boundingBoxNorthEast: { latitude: 90, longitude: 90 },
  boundingSouthWest: { latitude: -90, longitude: -90 },
  dates: computeDatesWithPeriod('12'),
  times: {
    from: '00:00',
    to: '23:59',
  },
  plate: null,
  signatureDaysOfWeek: new Set(DAYS),
  zoom: 12,
  natinfCategories: [],
  groupIds: [],
  presetNotes: [],
  organizationIds: [],
};

const INITIAL_STATE: MifMapState = {
  displayAsClusters: false,
  markers: [],
  filters: INITIAL_FILTERS,
  totalHits: 0,
  error: null,
  zonings: null,
  displayZones: false,
};

class Maps extends React.Component<MifMapProps, MifMapState> {
  map: MifMap | null = null;

  constructor(props: MifMapProps) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    void this.fetchZones();
  }

  updateFilters = (filters: MapFilters) => {
    this.setState({ filters });
    void this.fetchMifs(filters);
  };

  updateMapPosition = (
    boundingBoxNorthEast: Coordinates,
    boundingSouthWest: Coordinates,
    zoom: number
  ) => {
    const { filters } = this.state;
    const updatedFilters = {
      ...filters,
      boundingBoxNorthEast,
      boundingSouthWest,
      zoom,
    };
    this.updateFilters(updatedFilters);
  };

  fetchMifs = async (filters: MapFilters) => {
    try {
      const result = await searchMifForMap(filtersToRequest(filters));
      let markers: Array<Marker> = [];
      let displayAsClusters = false;
      if (result && result.mifs) {
        markers = result.mifs.map((mif: Coordinates) => ({
          position: {
            lat: mif.latitude,
            lng: mif.longitude,
          },
        }));
      } else if (result && result.clusters) {
        displayAsClusters = true;
        markers = result.clusters.map((cluster: MapClusterDTO) => ({
          position: {
            lat: cluster.latitude,
            lng: cluster.longitude,
          },
          count: cluster.number,
        }));
      }
      this.setState({
        markers,
        displayAsClusters,
        totalHits: result.totalHits,
        error: null,
      });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  toggleDisplayZones = () => {
    const { displayZones } = this.state;
    this.map?.togglePolygonVisibility(!displayZones);
    this.setState(prevState => {
      return {
        ...prevState,
        displayZones: !prevState.displayZones,
      };
    });
  };

  fetchZones = async () => {
    try {
      const zoning = await fetchZoning(FETCH_PV_ZONING_CONFIG);
      this.setState({
        zonings: toZoneforMap(zoning),
      });
    } catch (e) {
      // ignored
    }
  };

  render() {
    const { location } = this.props;
    const {
      displayAsClusters,
      markers,
      filters,
      totalHits,
      error,
      zonings,
      displayZones,
    } = this.state;
    const { latitude: lat, longitude: lng } = location || {
      latitude: 0,
      longitude: 0,
    };

    if (error) {
      return (
        <ErrorBlock
          message="Erreur lors de la récupération des graphes de la page"
          error={error}
        />
      );
    }

    return (
      <div style={STYLE_CONTENT}>
        <MifMapFilters
          filters={filters}
          totalHits={totalHits}
          updateFilters={this.updateFilters}
          displayZones={displayZones}
          toggleDisplayZone={this.toggleDisplayZones}
        />
        <div style={STYLE_LIST_WRAPPER}>
          <MifMap
            ref={ref => {
              this.map = ref;
            }}
            displayAsClusters={displayAsClusters}
            updateFilters={this.updateMapPosition}
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            center={{ lat, lng }}
            markers={markers}
            zonings={zonings}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: InternalApiState) => {
  const { name: cityName, location } = getConfigState(state);
  return {
    cityName,
    location,
  };
};

export default connect(mapStateToProps)(Maps);
