import * as React from 'react';
import { ChartData, ChartOptions, ChartType } from 'chart.js';

import ChartJSWrapper from 'commons/ChartJSWrapper';

export const colors = [
  '#a6cee3',
  '#1f78b4',
  '#b2df8a',
  '#33a02c',
  '#fb9a99',
  '#e31a1c',
  '#fdbf6f',
  '#ff7f00',
  '#cab2d6',
  '#6a3d9a',
  '#ffff99',
  '#b15928',
];

type Props = {
  type: ChartType;
  data: ChartData;
  options?: ChartOptions;
};

const Chart = React.forwardRef(({ options, data, type }: Props, ref: any) => (
  <div style={{ padding: 20 }}>
    <ChartJSWrapper
      /*
          la key force le comosant Chart à se réactualiser entièrement,
          sinon il ne met pas à jour le type du graphe, seulement ses données.
       */
      ref={ref}
      key={type}
      update
      type={type}
      width={600}
      options={options}
      data={data}
      height={300}
    />
  </div>
));

export default Chart;
