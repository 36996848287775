import React, { CSSProperties, useEffect, useState } from 'react';
import { CircularProgress } from 'material-ui';
import ChartComponent from 'react-chartjs-2';
import { connect } from 'react-redux';
import { ChartTypeRegistry } from 'chart.js';

import {
  ChartDataSource,
  ChartLeafLabels,
  ChartStatisticsResult,
} from '@cvfm-front/tefps-types';
import FlexCenter from 'commons/FlexCenter';
import {
  ControlChartCriteria,
  FpsChartCriteria,
  RapoChartCriteria,
  TicketChartCriteria,
} from 'Dashboard/fps/Graphics/types';
import {
  additionalChartOptions,
  buildDataFromResult,
  buildExemptionsLabels,
  buildZonesLabels,
  defaultTicketFilters,
  fetchClientAppAndBuildOptions,
  generateRequestAndGetResult,
  replaceAllLabels,
  multiplyAllValues,
} from 'Dashboard/fps/Graphics/Utils';
import SelectChartsFields from 'Dashboard/fps/Graphics/SelectChartsFields';
import ChartFiltersBar from 'Dashboard/fps/Graphics/ChartFiltersBar';
import { ExemptionReason, getConfigState } from 'config/duck';
import {
  PAYMENT_FILTER_OPTIONS,
  REASONS_FILTER_OPTIONS,
  STATUSES_FILTER_OPTIONS,
} from 'tefps/Fps/List/utils';
import { translateFPSFromControlFilterOptions } from 'tefps/Control/List/utils';
import { isDisplayed } from 'commons/helpers/ModulesConfiguration';
import { getApiState } from 'api/duck';
import {
  RECOURSE_STATUSES_FILTER_OPTIONS,
  REQUEST_REASONS_FILTER_OPTIONS,
  RESPONSE_REASONS_FILTER_OPTIONS,
} from 'tefps/RecoursesV2/utils/translationUtils';

import ChartFilterService from './service';

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  height: '100%',
  backgroundColor: 'white',
};

const STYLE_RIGHT_AREA: CSSProperties = {
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
};

const STYLE_CIRCULAR_WRAPPER: CSSProperties = {
  width: '90%',
  height: '90%',
  margin: 'auto',
  position: 'relative',
};

type Props = {
  exemptionReasonsConfigurations: ExemptionReason[];
  withFpses: boolean;
  withRapos: boolean;
  withControls: boolean;
  withTV: boolean;
};

function Graphics({
  exemptionReasonsConfigurations,
  withFpses,
  withRapos,
  withControls,
  withTV,
}: Props): JSX.Element {
  const [
    searchResult,
    setSearchResult,
  ] = useState<ChartStatisticsResult | null>(null);
  const [chartLoading, setChartLoading] = useState(false);
  const [filters, setFilters] = useState<
    | TicketChartCriteria
    | ControlChartCriteria
    | FpsChartCriteria
    | RapoChartCriteria
  >(defaultTicketFilters);
  useEffect(() => ChartFilterService.watchFilters(setFilters), []);

  async function fetchStatistics(): Promise<void> {
    if (ChartFilterService.getFilters().aggregationPipeline) {
      try {
        setChartLoading(true);
        const result = await generateRequestAndGetResult(
          ChartFilterService.getDataSource(),
          ChartFilterService.getFilters()
        );
        replaceAllLabels(result.results, filters.aggregationPipeline);
        multiplyAllValues(result.results, filters.aggregationPipeline);
        setSearchResult(result);
        setChartLoading(false);
      } catch (error) {
        setChartLoading(false);
      }
    }
  }

  function cleanUp() {
    ChartFilterService.setDataSource(ChartDataSource.TICKET);
  }

  async function initLabels() {
    const zoneLabels = await buildZonesLabels();
    const exemptionLabels = buildExemptionsLabels(
      exemptionReasonsConfigurations
    );
    const clientAppLabels = await fetchClientAppAndBuildOptions();

    ChartFilterService.initLabels(ChartLeafLabels.ZONE, zoneLabels);
    ChartFilterService.initLabels(ChartLeafLabels.EXEMPTION, exemptionLabels);
    ChartFilterService.initLabels(ChartLeafLabels.FOURNISSEUR, clientAppLabels);
    ChartFilterService.initLabels(
      ChartLeafLabels.RAPO_REASONS,
      REQUEST_REASONS_FILTER_OPTIONS
    );
    ChartFilterService.initLabels(
      ChartLeafLabels.RAPO_RESPONSE_REASONS,
      RESPONSE_REASONS_FILTER_OPTIONS
    );
    ChartFilterService.initLabels(
      ChartLeafLabels.RAPO_STATUS,
      RECOURSE_STATUSES_FILTER_OPTIONS
    );
    ChartFilterService.initLabels(
      ChartLeafLabels.FPS_STATUS,
      STATUSES_FILTER_OPTIONS()
    );
    ChartFilterService.initLabels(
      ChartLeafLabels.ANTAI_ABANDONNED_REASON,
      REASONS_FILTER_OPTIONS()
    );
    ChartFilterService.initLabels(
      ChartLeafLabels.WITH_FPS,
      translateFPSFromControlFilterOptions()
    );
    ChartFilterService.initLabels(
      ChartLeafLabels.FPS_PAIEMENT_STATUS,
      PAYMENT_FILTER_OPTIONS()
    );
  }

  useEffect(() => {
    ChartFilterService.initSelectorTreeAndDataSource(
      withFpses,
      withRapos,
      withControls,
      withTV
    );
    void initLabels();
    return cleanUp;
  }, []);

  useEffect(() => {
    setSearchResult(null);
  }, [ChartFilterService.getDataSource()]);

  useEffect(() => {
    setSearchResult(null);
    void fetchStatistics();
  }, [ChartFilterService.getFilters()]);
  return (
    <div style={STYLE_CONTENT}>
      <ChartFiltersBar totalHits={searchResult?.totalHits} />
      <div style={STYLE_RIGHT_AREA}>
        <SelectChartsFields />
        {searchResult && !chartLoading && (
          <div
            className="charts"
            style={{
              width: '90%',
              height: '90%',
              margin: 'auto',
            }}
          >
            <ChartComponent
              type={filters.chartType}
              width={400}
              height={400}
              data={buildDataFromResult(
                searchResult.results,
                filters.chartType as keyof ChartTypeRegistry,
                filters.forceSingleDataset
              )}
              options={{
                maintainAspectRatio: false,
                plugins: {
                  title: {
                    display: true,
                    text: filters.chartName,
                    font: {
                      size: 20,
                    },
                  },
                  legend: {
                    display:
                      filters.chartType !== 'line' ||
                      !filters.forceSingleDataset,
                  },
                },
                ...additionalChartOptions(filters.chartType),
              }}
            />
          </div>
        )}
        {chartLoading && (
          <div style={STYLE_CIRCULAR_WRAPPER}>
            <FlexCenter>
              <CircularProgress />
            </FlexCenter>
          </div>
        )}
      </div>
    </div>
  );
}

export default connect(state => {
  const {
    exemptionReasonsConfigurations,
    modulesConfiguration: { fps, rapo, control, tv },
  } = getConfigState(state);
  const { userInfo } = getApiState(state);
  return {
    exemptionReasonsConfigurations,
    withFpses: isDisplayed(fps) && !!userInfo?.rights?.includes('FPS_READ'),
    withRapos: isDisplayed(rapo) && !!userInfo?.rights?.includes('RAPO_READ'),
    withControls:
      isDisplayed(control) && !!userInfo?.rights?.includes('CONTROL_READ'),
    withTV: isDisplayed(tv) && !!userInfo?.rights?.includes('TV_READ'),
  };
})(Graphics);
