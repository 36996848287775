import React, { Component, CSSProperties } from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import TextField from 'material-ui/TextField';
import Snackbar from 'material-ui/Snackbar';
import _cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import Checkbox from 'material-ui/Checkbox';

import BoButton from 'facade/BoButton';
import {
  PublicLightSubscriptionPlanDTO,
  SivConfiguration,
} from '@cvfm-front/tefps-types';
import { isDisplayed } from 'commons/helpers/ModulesConfiguration';
import { fetchConfig, getConfigState } from 'config/duck';
import MultiSelect from 'commons/MultiSelect';
import {
  ControlTag,
  LapiReviewConfigurationDTO,
} from 'api/lapiReviewConfiguration/types';
import { upsertLapiReviewConfiguration } from 'api/lapiReviewConfiguration';
import { findSubscriptionPlansDTO } from 'api/cvfm-core-subscription/subscriptionPlanPublic';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItemWrapper,
  DataBoxWithName,
} from 'commons/DataBox';
import Content from 'commons/Content';
import { getApiState, InternalApiState } from 'api/duck';
import NumberField from 'commons/NumberField';

import AdminTags from './AdminTags';

const { _t, _tg } = window.loadTranslations(__filename);

const WEIGHT_BOUND_MIN = -99.99;
const WEIGHT_BOUND_MAX = 99.99;
const WEIGHT_STEP = 0.1;
const ROW_FORM_STYLE: CSSProperties = {
  display: 'flex',
  flexFlow: 'row nowrap',
  justifyContent: 'space-between',
  width: '100%',
};
const WEIGHT_RANGE_STYLE: CSSProperties = {
  flex: '1 1 500px',
  cursor: 'grab',
};
const WEIGHT_VALUE_STYLE: CSSProperties = {
  flex: '1 1 150px',
};
const WEIGHT_VALUE_INPUT_PROP_STYLE: CSSProperties = {
  textAlign: 'center',
  color: 'black',
};

const STYLE_HEADER_LEFT: CSSProperties = {
  fontWeight: 'bold',
  marginLeft: 30,
};
const STYLE_WRAPPER = { marginTop: 40, justifyContent: 'space-around' };

const STYLE_DATABOX = { width: '95%', margin: '20px auto 0' };

const MIN_STACK_LEN = 1;
const MAX_STACK_LEN = 15;

const MIN_CONSERVATION_DAYS = 0;
const MAX_CONSERVATION_DAYS = 10;

type State = {
  configuration?: LapiReviewConfigurationDTO;
  backupConfiguration?: LapiReviewConfigurationDTO;
  subscriptionPlans?: Array<PublicLightSubscriptionPlanDTO>;
  hasChanges: boolean;
  updating: boolean;
  message: string;
  error?: Error;
};

type ReduxStateProps = {
  canWrite: boolean;
  canModifyConservationPeriod: boolean;
  isSubsDisplayed: boolean;
  lapiReviewConfigurationDTO: LapiReviewConfigurationDTO;
  reloadConfig: () => void;
  sivConfiguration: SivConfiguration;
};

class LapiReviewConfiguration extends Component<ReduxStateProps, State> {
  constructor(props: ReduxStateProps) {
    super(props);
    this.state = {
      configuration: props.lapiReviewConfigurationDTO,
      backupConfiguration: props.lapiReviewConfigurationDTO,
      subscriptionPlans: undefined,
      hasChanges: false,
      updating: false,
      message: '',
      error: undefined,
    };
  }

  componentDidMount(): void {
    void this.fetchSubscriptions();
  }

  onAlertConfigurationChange = (
    ev: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const {
      value,
      dataset: { field },
    } = ev.currentTarget;
    const { configuration } = this.state;

    this.setState({
      configuration: {
        ...configuration,
        [field as string]: value,
      } as LapiReviewConfigurationDTO,
      hasChanges: true,
    });
  };

  onNumberChange = (key: keyof LapiReviewConfigurationDTO) => (
    value: number
  ): void => {
    const { configuration } = this.state;

    this.setState({
      configuration: {
        ...configuration,
        [key]: value,
      } as LapiReviewConfigurationDTO,
      hasChanges: true,
    });
  };

  handleTagsEnabledCheck = (): void => {
    const { configuration } = this.state;

    if (!configuration) {
      return;
    }

    this.setState({
      configuration: {
        ...configuration,
        tagsEnabled: !configuration.tagsEnabled,
      } as LapiReviewConfigurationDTO,
      hasChanges: true,
    });
  };

  handleSetTags = (tags: Array<ControlTag>): void => {
    const { configuration } = this.state;

    if (!configuration) {
      return;
    }

    this.setState({
      configuration: {
        ...configuration,
        tags,
      } as LapiReviewConfigurationDTO,
      hasChanges: true,
    });
  };

  handleFetchControlCheck = (): void => {
    const { configuration } = this.state;

    if (!configuration) {
      return;
    }

    this.setState({
      configuration: {
        ...configuration,
        fetchControlActivated: !configuration.fetchControlActivated,
      } as LapiReviewConfigurationDTO,
      hasChanges: true,
    });
  };

  handleFetchSectorCheck = (): void => {
    const { configuration } = this.state;

    if (!configuration) {
      return;
    }

    this.setState({
      configuration: {
        ...configuration,
        fetchSectors: !configuration.fetchSectors,
      } as LapiReviewConfigurationDTO,
      hasChanges: true,
    });
  };

  handleFetchVehicleFromPlateCheck = (): void => {
    const { configuration } = this.state;

    if (!configuration) {
      return;
    }

    this.setState({
      configuration: {
        ...configuration,
        fetchVehicleFromPlate: !configuration.fetchVehicleFromPlate,
      } as LapiReviewConfigurationDTO,
      hasChanges: true,
    });
  };

  fetchSubscriptions = async (): Promise<void> => {
    const { isSubsDisplayed } = this.props;

    if (!isSubsDisplayed) {
      return;
    }

    try {
      const subscriptionPlans = await findSubscriptionPlansDTO();
      this.setState({ subscriptionPlans });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  saveExcludedRights = (checkedIds: Array<string>): void => {
    const { configuration } = this.state;
    this.setState({
      configuration: {
        ...configuration,
        subscriptionPlanIdsExcluded: checkedIds,
      } as LapiReviewConfigurationDTO,
      hasChanges: true,
    });
  };

  handleCatchUpModeCheck = () => {
    const { configuration } = this.state;

    if (!configuration) {
      return;
    }

    this.setState({
      configuration: {
        ...configuration,
        catchUpModeEnabled: !configuration.catchUpModeEnabled,
      } as LapiReviewConfigurationDTO,
      hasChanges: true,
    });
  };

  handleIgnoreControlsOutsideWorkingHoursCheck = () => {
    const { configuration } = this.state;

    if (!configuration) {
      return;
    }

    this.setState({
      configuration: {
        ...configuration,
        ignoreControlsOutsideWorkingHours: !configuration.ignoreControlsOutsideWorkingHours,
      } as LapiReviewConfigurationDTO,
      hasChanges: true,
    });
  };

  handleCheckThresholdReliability = (): void => {
    const { configuration } = this.state;

    if (!configuration) {
      return;
    }

    this.setState({
      configuration: {
        ...configuration,
        useThresholdReliability: !configuration.useThresholdReliability,
      } as LapiReviewConfigurationDTO,
      hasChanges: true,
    });
  };

  closeErrorSnackBar = (): void => this.setState({ message: '' });

  handleErrors = (errorMessage: string): void => {
    this.setState({
      hasChanges: false,
      updating: false,
      message: errorMessage,
    });
  };

  builConfigurationUpdate = (configuration: LapiReviewConfigurationDTO) => {
    return {
      op: 'replace',
      path: '/BOLapiReviewConfiguration',
      value: {
        conservationPeriod: configuration.conservationPeriod,
        fetchControlActivated: configuration.fetchControlActivated,
        fetchControlDelayMinutes: configuration.fetchControlDelayMinutes,
        subscriptionPlanIdsExcluded: configuration.subscriptionPlanIdsExcluded,
        fetchStackLen: configuration.fetchStackLen,
        fetchSectors: configuration.fetchSectors,
        fetchVehicleFromPlate: configuration.fetchVehicleFromPlate,
        catchUpModeEnabled: configuration.catchUpModeEnabled,
        ignoreControlsOutsideWorkingHours:
          configuration.ignoreControlsOutsideWorkingHours,
        presenceOfFrontCarMediaWeight:
          configuration.presenceOfFrontCarMediaWeight,
        numberOfMediaWeight: configuration.numberOfMediaWeight,
        distanceBetweenControlAndAddressWeight:
          configuration.distanceBetweenControlAndAddressWeight,
        numberOfControlWithoutFpsWeight:
          configuration.numberOfControlWithoutFpsWeight,
        pastPerformanceOfAgentWeight:
          configuration.pastPerformanceOfAgentWeight,
        qualityControlGoal: configuration.qualityControlGoal,
        thresholdReliability: configuration.thresholdReliability,
        useThresholdReliability: configuration.useThresholdReliability,
        tagsEnabled: configuration.tagsEnabled,
        tags: configuration.tags,
      },
    };
  };

  onChangeWeightRange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      value,
      dataset: { field },
    } = ev.currentTarget;
    const { configuration } = this.state;

    if (!configuration || !value || !field) {
      return;
    }

    const valueNum = parseFloat(value);

    this.setState({
      configuration: {
        ...configuration,
        [field]: Math.min(
          Math.max(valueNum, WEIGHT_BOUND_MIN),
          WEIGHT_BOUND_MAX
        ),
      } as LapiReviewConfigurationDTO,
      hasChanges: true,
    });
  };

  renderWeightNode = (dataField: string, enabled: boolean): JSX.Element => {
    const inputId = `${dataField}-input`;
    const inputRangeId = `${dataField}-range`;
    const inputValueId = `${dataField}-value`;
    const { configuration } = this.state;
    return (
      <div style={ROW_FORM_STYLE} id={inputId}>
        <TextField
          id={inputRangeId}
          type="range"
          min={WEIGHT_BOUND_MIN}
          max={WEIGHT_BOUND_MAX}
          step={WEIGHT_STEP}
          style={WEIGHT_RANGE_STYLE}
          value={(configuration?.[dataField] as number) || 0}
          data-field={dataField}
          onChange={this.onChangeWeightRange}
          disabled={!enabled}
        />
        <TextField
          id={inputValueId}
          type="number"
          min={WEIGHT_BOUND_MIN}
          max={WEIGHT_BOUND_MAX}
          step={WEIGHT_STEP}
          style={WEIGHT_VALUE_STYLE}
          inputStyle={WEIGHT_VALUE_INPUT_PROP_STYLE}
          value={(configuration?.[dataField] as number) || 0}
          data-field={dataField}
          onChange={this.onChangeWeightRange}
          disabled={!enabled}
        />
      </div>
    );
  };

  submit = async (): Promise<void> => {
    const { configuration } = this.state;
    const { reloadConfig } = this.props;

    if (!configuration) {
      return;
    }

    this.setState({ updating: true });
    if (
      configuration.fetchStackLen < MIN_STACK_LEN ||
      configuration.fetchStackLen > MAX_STACK_LEN
    ) {
      this.handleErrors(_t('feedback.error.incorrectPileSize'));
    } else if (
      configuration.conservationPeriod < MIN_CONSERVATION_DAYS ||
      configuration.conservationPeriod > MAX_CONSERVATION_DAYS
    ) {
      this.handleErrors(_t('feedback.error.incorrectConservationDelay'));
    } else {
      try {
        const update = this.builConfigurationUpdate(configuration);
        await upsertLapiReviewConfiguration(update);
        reloadConfig();
        this.setState({
          hasChanges: false,
          updating: false,
          message: _t('feedback.success.configUpdate'),
        });
      } catch (error) {
        this.setState({ error: error as Error, updating: false });
      }
    }
  };

  cancel = (): void => {
    const { backupConfiguration } = this.state;
    this.setState({
      configuration: _cloneDeep(backupConfiguration),
      hasChanges: false,
    });
  };

  render(): React.ReactNode {
    const {
      configuration,
      subscriptionPlans,
      hasChanges,
      updating,
      message,
      error,
    } = this.state;

    const {
      lapiReviewConfigurationDTO,
      canModifyConservationPeriod,
      sivConfiguration,
    } = this.props;

    if (error) {
      return (
        <FlexCenter>
          <ErrorBlock error={error} />
        </FlexCenter>
      );
    }

    if (!configuration) {
      return (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      );
    }
    return (
      <Content>
        <DataBox panel style={STYLE_DATABOX}>
          <DataBoxHeader>
            <div style={STYLE_HEADER_LEFT}>{_t('element.title')}</div>
          </DataBoxHeader>
          <DataBoxContent>
            {DataBoxWithName({
              name: _t('element.boxFieldTags.name'),
              node: (
                <Checkbox
                  id="tagsEnabled"
                  checked={configuration.tagsEnabled}
                  onCheck={this.handleTagsEnabledCheck}
                />
              ),
            })}
            <AdminTags
              configuration={configuration}
              setTags={this.handleSetTags}
              showMessage={(arg: string) =>
                this.setState({
                  hasChanges: false,
                  updating: false,
                  message: arg,
                })
              }
            />
            {DataBoxWithName({
              name: _t('element.boxFieldControl.name'),
              node: (
                <Checkbox
                  id="fetchControlActivated"
                  checked={configuration.fetchControlActivated}
                  onCheck={this.handleFetchControlCheck}
                />
              ),
            })}
            {DataBoxWithName({
              name: _t('element.boxFieldCatchUpMode.name'),
              node: (
                <Checkbox
                  id="catchUpModeEnabled"
                  checked={configuration.catchUpModeEnabled}
                  onCheck={this.handleCatchUpModeCheck}
                />
              ),
            })}
            {DataBoxWithName({
              name: _tg('field.control.ignoreControlsOutsideWorkingHours'),
              node: (
                <Checkbox
                  id="ignoreControlsOutsideWorkingHours"
                  checked={configuration.ignoreControlsOutsideWorkingHours}
                  onCheck={this.handleIgnoreControlsOutsideWorkingHoursCheck}
                />
              ),
            })}
            <>
              {DataBoxWithName({
                name: _t('element.boxFieldPile.name', {
                  min: MIN_STACK_LEN,
                  max: MAX_STACK_LEN,
                }),
                node: (
                  <TextField
                    id="fetchStackLen"
                    min={MIN_STACK_LEN}
                    max={MAX_STACK_LEN}
                    type="number"
                    value={configuration.fetchStackLen}
                    onChange={this.onAlertConfigurationChange}
                    data-field="fetchStackLen"
                  />
                ),
              })}
              {DataBoxWithName({
                name: _t('element.boxFieldKeepingDelay.name', {
                  max: MAX_CONSERVATION_DAYS,
                }),
                node: (
                  <TextField
                    id="conservationPeriod"
                    min={MIN_CONSERVATION_DAYS}
                    max={MAX_CONSERVATION_DAYS}
                    type="number"
                    value={configuration.conservationPeriod}
                    onChange={this.onAlertConfigurationChange}
                    disabled={!canModifyConservationPeriod}
                    data-field="conservationPeriod"
                  />
                ),
              })}
              {DataBoxWithName({
                name: _t('element.boxFieldNumberOfMediaWeight.name'),
                node: this.renderWeightNode('numberOfMediaWeight', true),
              })}
              {DataBoxWithName({
                name: _t('element.boxFieldPresenceOfFrontCarMediaWeight.name'),
                node: this.renderWeightNode(
                  'presenceOfFrontCarMediaWeight',
                  true
                ),
              })}
              {DataBoxWithName({
                name: _t(
                  'element.boxFieldDistanceBetweenControlAndAddressWeight.name'
                ),
                node: this.renderWeightNode(
                  'distanceBetweenControlAndAddressWeight',
                  true
                ),
              })}
              {DataBoxWithName({
                name: _t(
                  'element.boxFieldNumberOfControlWithoutFpsWeight.name'
                ),
                node: this.renderWeightNode(
                  'numberOfControlWithoutFpsWeight',
                  true
                ),
              })}
              {DataBoxWithName({
                name: _t('element.boxFieldPastPerformanceOfAgentWeight.name'),
                node: this.renderWeightNode(
                  'pastPerformanceOfAgentWeight',
                  true
                ),
              })}
              {DataBoxWithName({
                name: _t('element.boxUseThresholdReliability.name'),
                node: (
                  <Checkbox
                    id="useThresholdReliability"
                    checked={configuration.useThresholdReliability}
                    onCheck={this.handleCheckThresholdReliability}
                  />
                ),
              })}
              {DataBoxWithName({
                name: _t('element.boxThresholdReliability.name'),
                node: (
                  <NumberField
                    name="thresholdReliability"
                    id="thresholdReliability"
                    type="number"
                    min={0}
                    disabled={!configuration.useThresholdReliability}
                    value={configuration.thresholdReliability}
                    data-field="thresholdReliability"
                    onChange={this.onNumberChange('thresholdReliability')}
                  />
                ),
              })}
              {DataBoxWithName({
                name: _t('element.boxFieldSectorsFetch.name'),
                node: (
                  <Checkbox
                    id="fetchSectorActivated"
                    checked={configuration.fetchSectors}
                    onCheck={this.handleFetchSectorCheck}
                  />
                ),
              })}
              {DataBoxWithName({
                name: _t('element.boxFieldFetchBrand.name'),
                node: (
                  <Checkbox
                    id="fetchVehicleFromPlateActivated"
                    checked={configuration.fetchVehicleFromPlate}
                    onCheck={this.handleFetchVehicleFromPlateCheck}
                    disabled={sivConfiguration.enableLapiReviewSiv}
                    title={
                      sivConfiguration.enableLapiReviewSiv
                        ? _t('element.boxFieldFetchBrand.disabledText')
                        : ''
                    }
                  />
                ),
              })}
              {DataBoxWithName({
                name: _t('element.boxQualityControlGoal.name'),
                node: (
                  <NumberField
                    name="qualityControlGoal"
                    id="qualityControlGoal"
                    type="number"
                    min={0}
                    value={configuration.qualityControlGoal}
                    onChange={this.onNumberChange('qualityControlGoal')}
                  />
                ),
              })}
              {subscriptionPlans &&
                subscriptionPlans.length > 0 &&
                DataBoxWithName({
                  name: _t('element.boxFieldRightsToExclude.name'),
                  node: (
                    <MultiSelect
                      items={subscriptionPlans.map(subscriptionPlan => {
                        return {
                          id: subscriptionPlan.subscriptionPlanId,
                          name: subscriptionPlan.name,
                        };
                      })}
                      checkedIds={configuration.subscriptionPlanIdsExcluded}
                      title={_t('element.boxFieldRightsToExclude.title')}
                      itemNameSingular={_t(
                        'element.boxFieldRightsToExclude.selectedRight'
                      )}
                      itemNamePlural={_t(
                        'element.boxFieldRightsToExclude.selectedRight_plural'
                      )}
                      save={this.saveExcludedRights}
                      disabled={false}
                    />
                  ),
                })}
            </>
            <DataBoxItemWrapper style={STYLE_WRAPPER}>
              <BoButton
                label={_tg('action.save_1')}
                primary
                disabled={!hasChanges || updating}
                onClick={this.submit}
              />
              <BoButton
                label={_tg('action.cancel')}
                disabled={!hasChanges || updating}
                onClick={this.cancel}
              />
            </DataBoxItemWrapper>
            {updating && (
              <DataBoxItemWrapper>
                <CircularProgress />
              </DataBoxItemWrapper>
            )}
          </DataBoxContent>
        </DataBox>

        <Snackbar
          open={!!message}
          message={message}
          autoHideDuration={4000}
          onRequestClose={this.closeErrorSnackBar}
        />
      </Content>
    );
  }
}

function mapStateToProps(state: InternalApiState) {
  const { userInfo } = getApiState(state);
  const {
    modulesConfiguration: { subscribers },
    lapiReviewConfigurationDTO,
    sivConfiguration,
  } = getConfigState(state);

  return {
    canWrite: !!(
      userInfo && userInfo.rights.includes('LAPI_REVIEW_CONFIGURATION_WRITE')
    ),
    canModifyConservationPeriod:
      userInfo && userInfo.rights.includes('LAPI_REVIEW_HOLDING_DELAY_WRITE'),
    isSubsDisplayed: isDisplayed(subscribers),
    lapiReviewConfigurationDTO,
    sivConfiguration,
  };
}

function mapDispatchToProps(dispatch: any) {
  return { reloadConfig: () => dispatch(fetchConfig()) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LapiReviewConfiguration);
