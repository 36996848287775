import './index.css';
import React, { useEffect, useState } from 'react';

import GenericFilterBar from 'commons/Generics/FilterBar';
import { Filters } from 'commons/types/filterbar';
import { Flex } from '@cvfm-front/commons-ui';

import TvPaymentsService from './TvPaymentsService';
import TvPaymentsFilters from './TvPaymentsFilters';
import TvPaymentsTable from './TvPaymentsTable';

function TvPayments(): JSX.Element {
  const [filters, setFilters] = useState<Filters>(TvPaymentsFilters());
  const [totalHits, setTotalHits] = useState(0);

  useEffect(() => TvPaymentsService.init(), []);
  useEffect(() => TvPaymentsService.watchFilters(setFilters), []);
  useEffect(() => TvPaymentsService.watchTotalHits(setTotalHits), []);

  return (
    <Flex height100 width100>
      <GenericFilterBar
        initialFilters={filters}
        totalHits={totalHits}
        onChangeFilters={TvPaymentsService.setFiltersAndRefetch}
        facetings={{}}
      />
      <TvPaymentsTable />
    </Flex>
  );
}

export default TvPayments;
