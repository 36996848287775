import React, { useState } from 'react';
import Dialog from 'material-ui/Dialog';

import { getActions } from 'commons/ModalUtils';
import { EmailAddress, FpsContactType, IContactable } from 'api/fps/types';
import { STYLE_INPUTS, STYLE_MODAL_TITLE } from 'theme';
import { Address } from 'api/commonTypes';
import FormComponent from 'commons/FormComponent';

import AddContactForm from './AddContactForm';

const { _tg } = window.loadTranslations(__filename);

type FormValues = {
  user: {
    streetNumber: string;
    streetNumberBis: string;
    streetType: string;
    streetName: string;
    complement: string;
    postalCode: string;
    locality: string;
    addressCountry: string;
    email: string;
    illegibleAddress: boolean;
  };
};

type Props = {
  type: FpsContactType;
  isOpen: boolean;
  submit: (
    contact: IContactable | null | undefined,
    type: FpsContactType,
    illegibleAddress?: boolean
  ) => Promise<void>;
};

function AddContactModal({ type, isOpen, submit }: Props): JSX.Element {
  const [newAddress, addNewAddress] = useState<Address>({
    streetNumber: '',
    streetNumberBis: '',
    streetType: '',
    streetName: '',
    specialPlace: '',
    postOfficeBoxNumber: undefined,
    postalCode: '',
    addressSubRegion: '',
    addressRegion: '',
    addressLocality: '',
    addressCountry: '',
  });
  const [newMailAddress, addNewMailAddress] = useState<EmailAddress>({
    email: '',
  });
  const [formEntries, setFormEntries] = useState<FormComponent | null>(null);

  const attachRef = (node: FormComponent) => {
    setFormEntries(node);
  };

  function submitAddress(): void {
    switch (type) {
      case 'POSTAL_ADDRESS':
        if (formEntries && formEntries.isValid()) {
          const formValues = formEntries.getFormEntries() as FormValues;
          const updatedAddress = {
            ...newAddress,
            streetNumber: formValues.user.streetNumber,
            streetNumberBis: formValues.user.streetNumberBis,
            streetType: formValues.user.streetType,
            streetName: formValues.user.streetName,
            specialPlace: formValues.user.complement,
            postalCode: formValues.user.postalCode,
            addressLocality: formValues.user.locality,
            addressCountry: formValues.user.addressCountry,
          };
          console.log({ formValues, updatedAddress });
          addNewAddress(updatedAddress);
          void submit(updatedAddress, type, formValues.user.illegibleAddress);
        }

        break;
      case 'EMAIL':
        if (formEntries && formEntries.isValid()) {
          const formValues: FormValues = formEntries.getFormEntries() as FormValues;
          const updatedMailAddress = {
            ...newMailAddress,
            email: formValues.user.email,
          };
          addNewMailAddress(updatedMailAddress);
          void submit(updatedMailAddress, type);
        }

        break;
      default:
    }
  }

  function handleCloseAddress(): void {
    void submit(null, type);
  }

  const actionsAddress = getActions(submitAddress, handleCloseAddress);

  return (
    <Dialog
      open={isOpen}
      actions={actionsAddress}
      title={_tg('tefps.dashboard.refund.addAnAddress')}
      titleStyle={STYLE_MODAL_TITLE}
    >
      {type === 'POSTAL_ADDRESS' && (
        <div>
          <FormComponent ref={attachRef}>
            <AddContactForm
              field="user"
              styleInputs={STYLE_INPUTS}
              isEmail={false}
            />
          </FormComponent>
        </div>
      )}
      {type === 'EMAIL' && (
        <div>
          <FormComponent ref={attachRef}>
            <AddContactForm field="user" styleInputs={STYLE_INPUTS} isEmail />
          </FormComponent>
        </div>
      )}
    </Dialog>
  );
}

export default AddContactModal;
