import React, { Dispatch, SetStateAction } from 'react';

import { Camera, CameraOrientation } from '@cvfm-front/tefps-types';
import { CameraMode } from '@cvfm-front/tefps-types/build/parking/CameraMode';
import './CameraTable.css';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  configLines: Array<Camera>;
  setConfigLines: Dispatch<SetStateAction<Array<Camera>>>;
};

const cameraModeSelectorTrad = (mode: CameraMode): string => {
  switch (mode) {
    case CameraMode.EXIT:
      return _t('cameraModeSelector.exit');
    case CameraMode.ENTRY_EXIT:
      return _t('cameraModeSelector.entryExit');
    case CameraMode.ENTRY:
    default:
      return _t('cameraModeSelector.entry');
  }
};

const orientationSelectorTrad = (orientation: CameraOrientation): string => {
  switch (orientation) {
    case CameraOrientation.SUBJECT_MOVES_AWAY:
      return _t('orientationSelector.movesAway');
    case CameraOrientation.SUBJECT_MOVES_CLOSER:
    default:
      return _t('orientationSelector.movesCloser');
  }
};

const CameraTable = ({ configLines, setConfigLines }: Props): JSX.Element => {
  const addConfigLine = () => {
    setConfigLines([
      ...configLines,
      {
        cameraId: '',
        cameraMode: CameraMode.ENTRY,
        cameraOrientation: CameraOrientation.SUBJECT_MOVES_CLOSER,
      },
    ]);
  };

  const removeConfigLine = (index: number) => {
    const newConfigLines = [...configLines];
    newConfigLines.splice(index, 1);
    setConfigLines(newConfigLines);
  };

  const updateConfigLine = (index: number, field: string, value: string) => {
    const newConfigLines = [...configLines];
    newConfigLines[index][field] = value;
    setConfigLines(newConfigLines);
  };

  return (
    <div className="camera-table">
      <table>
        {/* Afficher l'en-tête seulement si le tableau n'est pas vide */}
        {configLines.length > 0 ? (
          <thead>
            <tr>
              <th>{_t('camerasConfiguration.id')}</th>
              <th>{_t('camerasConfiguration.mode')}</th>
              <th>{_t('camerasConfiguration.orientation')}</th>
            </tr>
          </thead>
        ) : null}
        <tbody>
          {configLines.map((line, index) => (
            <tr key={`cameraId ${line.cameraId}`}>
              <td>
                <input
                  type="text"
                  placeholder={_t('camerasConfiguration.idPlaceholder')}
                  value={line.cameraId}
                  onChange={e =>
                    updateConfigLine(index, 'cameraId', e.target.value)
                  }
                />
              </td>
              <td>
                <select
                  value={line.cameraMode}
                  onChange={e =>
                    updateConfigLine(index, 'cameraMode', e.target.value)
                  }
                >
                  {Object.entries(CameraMode).map(([key, value]) => (
                    <option key={key} value={key}>
                      {cameraModeSelectorTrad(value)}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <select
                  value={line.cameraOrientation}
                  onChange={e =>
                    updateConfigLine(index, 'cameraOrientation', e.target.value)
                  }
                >
                  {Object.entries(CameraOrientation).map(([key, value]) => (
                    <option key={key} value={key}>
                      {orientationSelectorTrad(value)}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <button type="button" onClick={() => removeConfigLine(index)}>
                  {_tg('action.delete')}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button type="button" onClick={addConfigLine}>
        {_tg('characters.plus')}
      </button>
    </div>
  );
};

export default CameraTable;
