import { Pager, SortParameters } from 'api/commonTypes';

import { PaymentFiltersParam, PaymentSearchCriterias } from './types';

const { _tg } = window.loadTranslations(__filename);

export const EXPORT_COL = (): Array<{
  key: string;
  label: string;
  checked?: boolean;
}> => [
  {
    key: 'resourceId',
    label: _tg('tefps.dashboard.payment.resourceId'),
    checked: true,
  },
  {
    key: 'resourceType',
    label: _tg('tefps.dashboard.payment.resourceType'),
    checked: true,
  },
  {
    key: 'operationId',
    label: _tg('tefps.dashboard.payment.columns.operationId'),
    checked: true,
  },
  {
    key: 'paymentAmount',
    label: _tg('tefps.dashboard.payment.columns.paymentAmount'),
    checked: true,
  },
  {
    key: 'paymentDate',
    label: _tg('tefps.dashboard.payment.columns.transactionDate'),
    checked: true,
  },
  {
    key: 'transactionDate',
    label: _tg('tefps.dashboard.payment.columns.partnerTransactionDate'),
    checked: true,
  },
  {
    key: 'subscriberId',
    label: _tg('tefps.dashboard.payment.columns.subscriberId'),
  },
  {
    key: 'paymentReference',
    label: _tg('tefps.dashboard.payment.paymentReference'),
  },
  { key: 'channel', label: _tg('tefps.dashboard.payment.paymentChannel') },
  { key: 'mode', label: _tg('tefps.dashboard.payment.paymentModeAlternate') },
  {
    key: 'agent',
    label: _tg('tefps.dashboard.payment.columns.agentName'),
  },
  { key: 'comment', label: _tg('field.comment') },
  { key: 'validityAreas', label: _tg('field.address.validityZones') },
  {
    key: 'paymentValidityAreas',
    label: _tg('field.address.paymentValidityZones'),
  },
  { key: 'productId', label: _tg('tefps.dashboard.payment.columns.productId') },
  { key: 'vehicleType', label: _tg('field.vehicle.type') },
];

export const EXPORT_COL_MULTI_CITIES = (): Array<{
  key: string;
  label: string;
  checked?: boolean;
}> => [
  ...EXPORT_COL(),
  { key: 'regie', label: _tg('tefps.dashboard.payment.concernedRegie') },
];

export const PAYMENT_TABLE_COLUMNS_WITH_SORT = {
  date: 2,
  mode: 5,
  agentName: 6,
};

function getSortFieldText(sortField: number): string {
  switch (sortField) {
    case PAYMENT_TABLE_COLUMNS_WITH_SORT.date:
      return 'date';
    case PAYMENT_TABLE_COLUMNS_WITH_SORT.mode:
      return 'mode';
    case PAYMENT_TABLE_COLUMNS_WITH_SORT.agentName:
      return 'agentName';
    default:
      return 'date';
  }
}

export const filtersToRequest = (
  filters: PaymentSearchCriterias,
  sort: SortParameters,
  pager: Pager
): PaymentFiltersParam => {
  const dates = filters.dates || null;
  return {
    channels: Array.from(filters.channels),
    paymentModes: Array.from(filters.paymentModes),
    regieIds: Array.from(filters.regieIds),
    startPaymentDate:
      dates && dates.from != null ? dates.from.toISOString() : null,
    endPaymentDate: dates && dates.to != null ? dates.to.toISOString() : null,
    operationId: filters.operationId,
    resourceId: filters.resourceId,
    page: pager.page,
    maxRecords: pager.maxRecords,
    sortOrder: sort.increasingOrder ? 'ASC' : 'DESC',
    sortField: getSortFieldText(sort.sortField),
  };
};
