import { ChartDataSource, ChartLeafLabels } from '@cvfm-front/tefps-types';
import {
  defaultControlFilters,
  defaultTicketFilters,
} from 'Dashboard/fps/Graphics/Utils';
import {
  ControlChartCriteria,
  FpsChartCriteria,
  RapoChartCriteria,
  TicketChartCriteria,
} from 'Dashboard/fps/Graphics/types';
import { Watcher } from '@cvfm-front/commons-utils';
import { ValueLabel, KeyLabel } from '@cvfm-front/commons-types';
import { initialFilters as defaultFpsCriteria } from 'tefps/Fps/List/utils';
import {
  CHART_CONTROL_TREE,
  CHART_FPS_TREE,
  CHART_RAPO_TREE,
  CHART_SELECTOR_TREE,
  CHART_TICKET_TREE,
} from 'Dashboard/fps/Graphics/Charts';
import { initialFilters as defaultRapoCriteria } from 'tefps/RecoursesV2/utils/recourseUtils';

function ChartFilterService() {
  let dataSource: ChartDataSource;
  const chartSelectorTree: Array<CHART_SELECTOR_TREE> = [];
  const labels = new Map<
    ChartLeafLabels,
    Array<ValueLabel> | Array<KeyLabel>
  >();
  const { getValue: getFilters, setValue, watchValue: watchFilters } = Watcher<
    | TicketChartCriteria
    | ControlChartCriteria
    | FpsChartCriteria
    | RapoChartCriteria
  >(defaultTicketFilters);

  const setDataSource = (data: ChartDataSource) => {
    dataSource = data;
    switch (data) {
      case ChartDataSource.TICKET:
      default:
        setValue(defaultTicketFilters);
        break;
      case ChartDataSource.CONTROL:
        setValue(defaultControlFilters);
        break;
      case ChartDataSource.FPS:
        setValue(defaultFpsCriteria());
        break;
      case ChartDataSource.RAPO:
        setValue(defaultRapoCriteria());
    }
  };

  const getDataSource = () => dataSource;

  const setFilters = (
    callback: (
      filters:
        | TicketChartCriteria
        | ControlChartCriteria
        | FpsChartCriteria
        | RapoChartCriteria
    ) =>
      | TicketChartCriteria
      | ControlChartCriteria
      | FpsChartCriteria
      | RapoChartCriteria
  ) => {
    const newFilters = callback(getFilters());
    setValue(newFilters);
  };

  const resetFilters = () => {
    setDataSource(dataSource);
  };

  const initLabels = (
    type: ChartLeafLabels,
    pair: ValueLabel[] | KeyLabel[]
  ) => {
    labels.set(type, pair);
  };

  const initSelectorTreeAndDataSource = (
    withFpses: boolean,
    withRapos: boolean,
    withControls: boolean,
    withTV: boolean
  ) => {
    if (withFpses && !chartSelectorTree.includes(CHART_FPS_TREE)) {
      chartSelectorTree.push(CHART_FPS_TREE);
    }
    if (withRapos && !chartSelectorTree.includes(CHART_RAPO_TREE)) {
      chartSelectorTree.push(CHART_RAPO_TREE);
    }
    if (withControls && !chartSelectorTree.includes(CHART_CONTROL_TREE)) {
      chartSelectorTree.push(CHART_CONTROL_TREE);
    }
    if (withTV && !chartSelectorTree.includes(CHART_TICKET_TREE)) {
      chartSelectorTree.push(CHART_TICKET_TREE);
    }
    if (dataSource === undefined) {
      if (withFpses) {
        setDataSource(ChartDataSource.FPS);
      } else if (withRapos) {
        setDataSource(ChartDataSource.RAPO);
      } else if (withControls) {
        setDataSource(ChartDataSource.CONTROL);
      } else if (withTV) {
        setDataSource(ChartDataSource.TICKET);
      }
    }
  };

  const getLabel = (
    labelType: ChartLeafLabels
  ): Array<ValueLabel> | Array<KeyLabel> | undefined => {
    return labels.get(labelType);
  };

  return {
    setDataSource,
    getDataSource,
    setFilters,
    getFilters,
    resetFilters,
    watchFilters,
    initLabels,
    getLabel,
    initSelectorTreeAndDataSource,
    chartSelectorTree,
  };
}

export default ChartFilterService();
