import React, { CSSProperties, useEffect, useState } from 'react';
import Snackbar from 'material-ui/Snackbar';
import { connect } from 'react-redux';
import DeleteIcon from 'material-ui/svg-icons/action/delete';
import EditIcon from 'material-ui/svg-icons/editor/mode-edit';

import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { BKG_CYAN, BKG_PINK } from 'theme';
import Tag from 'commons/Tag';
import {
  AgentRight,
  KpiConfigurationDTO,
  KpiOrganizationThreshold,
  KpiThresholdsDTO,
  ObservatoryConfigurationDTO,
  SupersetDashboardConfigurationDTO,
} from '@cvfm-front/tefps-types';
import AdvertisingModal from 'commons/AdvertisingModal';
import Content from 'commons/Content';
import { fetchConfig, getConfigState } from 'config/duck';
import {
  patchKpiConfiguration,
  upsertExportEmails,
  upsertPmrPricingCategories,
} from 'api/kpi';
import { Flex } from '@cvfm-front/commons-ui';
import SimpleTable from 'commons/SimpleTable';
import BoButton from 'facade/BoButton';
import AddTag from 'Configuration/Rapo/AdminTags/AddTag';
import './kpi.css';

import Select, { SelectOption } from 'commons/SidebarV2/Components/Select';

import { listProfiles } from '../../api/profiles';
import { getApiState } from '../../api/duck';

import KpiThresholdConfigModal from './KpiThresholdConfigModal';
import KpiThresholdRemovalModal from './KpiThresholdRemovalModal';
import { translateDashboardProfilesCols, translateTableCols } from './utils';
import WorkingDays from './WorkingDays';

const STYLE_FIELD: CSSProperties = {
  width: 300,
  marginLeft: 10,
};

const STYLE_SELECT_TEXT: CSSProperties = {
  color: 'black',
  fontSize: 16,
  fontFamily: 'Roboto',
  marginLeft: 5,
  height: 40,
};

const STYLE_SVG_ICON_DISABLED: CSSProperties = {
  pointerEvents: 'none',
  cursor: 'not-allowed',
  color: 'grey',
};

const { _tg } = window.loadTranslations(__filename);

type Props = {
  agentRights: AgentRight[];
  kpiConfiguration: KpiConfigurationDTO;
  observatoryConfigurationDTO: ObservatoryConfigurationDTO;
  reloadConfig: () => void;
};

const KpiConfiguration = ({
  agentRights,
  kpiConfiguration,
  observatoryConfigurationDTO,
  reloadConfig,
}: Props): JSX.Element => {
  const [message, setMessage] = useState<string>('');
  const [pmrPricingCategories, setPmrPricingCategories] = useState<
    Array<string>
  >(kpiConfiguration?.pmrPricingCategories || []);
  const [exportEmails, setExportEmails] = useState<Array<string>>(
    kpiConfiguration?.exportEmails || []
  );
  const [selectedKpiConfig, setSelectedKpiConfig] = useState<
    KpiOrganizationThreshold
  >();
  const [open, setOpen] = useState<boolean>(false);
  const [removalModalOpen, setRemovalModalOpen] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [profiles, setProfiles] = useState<Array<SelectOption>>([]);
  const [dashboardConfigs, setDashboardConfigs] = useState<
    SupersetDashboardConfigurationDTO[]
  >(observatoryConfigurationDTO.supersetDashboardConfigurations);

  const disabled = !agentRights.includes('KPI_WRITE');

  useEffect(() => {
    listProfiles(false).then(profiles => {
      const options = profiles.map(profile => ({
        label: profile.name,
        key: profile.profileId,
      }));
      setProfiles(options);
    });
  }, []);

  const closeSnackBar = () => setMessage('');
  const openSnackBar = (message: string) => setMessage(message);

  const patchPmrPricingCategories = async (
    newPmrPricingCategories: Array<string>
  ): Promise<void> => {
    await upsertPmrPricingCategories({
      op: 'replace',
      path: '/kpiPmrPricingCategories',
      value: newPmrPricingCategories,
    });
    reloadConfig();
  };

  const patchExportEmails = async (
    exportEmails: Array<string>
  ): Promise<void> => {
    await upsertExportEmails({
      op: 'replace',
      path: '/exportEmails',
      value: exportEmails,
    });
    reloadConfig();
  };

  const handleAddPmrPricingCat = async (
    pricingCategory: string
  ): Promise<void> => {
    try {
      const newPmrPricingCategories = new Set<string>(pmrPricingCategories);
      newPmrPricingCategories.add(pricingCategory);
      const sortedCategories = Array.from(
        newPmrPricingCategories
      ).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
      setPmrPricingCategories(sortedCategories);
      await patchPmrPricingCategories(sortedCategories);
      openSnackBar(_tg('tefps.configuration.kpiConfiguration.success'));
    } catch (e) {
      openSnackBar(_tg('tefps.configuration.kpiConfiguration.error'));
    }
  };

  const handleDeletePmrPricingCat = async (
    pricingCategory: string
  ): Promise<void> => {
    const newPmrPricingCategories = pmrPricingCategories.filter(
      pc => pc !== pricingCategory
    );
    setPmrPricingCategories(pmrPricingCategories);
    await patchPmrPricingCategories(newPmrPricingCategories);
  };

  const handleAddExportMails = async (exportMail: string): Promise<void> => {
    try {
      const newExportMails = new Set<string>(exportEmails);
      newExportMails.add(exportMail);
      const sortedMails = Array.from(newExportMails).sort((a, b) =>
        a.toLowerCase().localeCompare(b.toLowerCase())
      );
      setExportEmails(sortedMails);
      await patchExportEmails(sortedMails);
      openSnackBar(_tg('tefps.configuration.kpiConfiguration.success'));
    } catch (e) {
      openSnackBar(_tg('tefps.configuration.kpiConfiguration.error'));
    }
  };

  const handleDeleteExportMails = async (exportMail: string): Promise<void> => {
    const newExportMails = exportEmails.filter(pc => pc !== exportMail);
    setExportEmails(newExportMails);
    await patchExportEmails(newExportMails);
  };

  const initNewConf = (): void => {
    const newConf: KpiOrganizationThreshold = {
      organizationId: '',
      kpiThresholds: {
        minRatioOfDailyGoalPerBorough2C: {},
        scanDuplicationLevels1C: new Array(4).fill(0),
      } as KpiThresholdsDTO,
    };
    setSelectedKpiConfig(newConf);
    setOpen(true);
  };

  const onClose = (): void => {
    setOpen(false);
    setIsEditing(false);
    setSelectedKpiConfig(undefined);
  };

  const openRemovalModal = (newConf: KpiOrganizationThreshold): void => {
    if (disabled) {
      return;
    }
    setSelectedKpiConfig(newConf);
    setRemovalModalOpen(true);
  };
  const closeRemovalModal = (): void => {
    setRemovalModalOpen(false);
    setSelectedKpiConfig(undefined);
  };

  const onSelect = (config: KpiOrganizationThreshold): void => {
    setSelectedKpiConfig(config);
    setOpen(true);
    setIsEditing(true);
  };

  const onSave = async (config: KpiOrganizationThreshold): Promise<void> => {
    await patchKpiConfiguration({
      op: 'add',
      path: '/kpiThresholdVersion',
      value: config,
    });
    setOpen(false);
    setIsEditing(false);
    reloadConfig();
  };

  const removeKpiThresholdConfig = async (): Promise<void> => {
    await patchKpiConfiguration({
      op: 'remove',
      path: '/kpiThresholdVersion',
      value: selectedKpiConfig?.organizationId,
    });
    setOpen(false);
    reloadConfig();
  };

  const patchSupersetDashboardConfig = async () => {
    try {
      await patchKpiConfiguration({
        op: 'replace',
        path: '/supersetDashboardConfiguration',
        value: dashboardConfigs,
      });
      reloadConfig();
      openSnackBar(
        _tg(
          'tefps.configuration.kpiConfiguration.supersetDashboardConfig.success'
        )
      );
    } catch (e) {
      openSnackBar(
        _tg(
          'tefps.configuration.kpiConfiguration.supersetDashboardConfig.error'
        )
      );
    }
  };

  function onChange(primaryKey: string, profileIds: any) {
    dashboardConfigs.forEach((conf: SupersetDashboardConfigurationDTO) => {
      if (conf.primaryKey === primaryKey) {
        conf.authorizedProfiles = profileIds;
      }
    });
    setDashboardConfigs(Object.assign([], dashboardConfigs));
  }

  return (
    <Content>
      <AdvertisingModal module="control" />
      <SeparatorWithTitle
        className="kpi_title"
        title={_tg('tefps.configuration.kpiConfiguration.pmrTitle')}
        color={BKG_PINK}
        titleSize={20}
      />
      <div className="kpi_wrapper">
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {Array.from(pmrPricingCategories).map(tag => (
            <Tag
              disabled={disabled}
              key={tag}
              tag={tag}
              onDelete={handleDeletePmrPricingCat}
            />
          ))}
        </div>
        <AddTag
          disabled={disabled}
          hintText={_tg(
            'tefps.configuration.kpiConfiguration.prmTextFieldHint'
          )}
          textFieldStyle={STYLE_FIELD}
          tags={pmrPricingCategories}
          onAdd={handleAddPmrPricingCat}
        />
      </div>

      <SeparatorWithTitle
        className="kpi_title"
        title={_tg('tefps.configuration.kpiConfiguration.kpiThresholdsTitle')}
        color={BKG_PINK}
        titleSize={20}
      />
      <div className="kpi_config_table">
        <Flex flexDirection="row-reverse">
          <BoButton
            primary
            label={_tg(
              'tefps.configuration.kpiConfiguration.addNewOrgKpiConfig'
            )}
            disabled={disabled}
            onClick={initNewConf}
          />
        </Flex>
        {(kpiConfiguration?.kpiOrganizationThresholds || []).length !== 0 && (
          <SimpleTable
            maxHeight={1500}
            cols={translateTableCols()}
            rowHeight={50}
            itemsRenderer={(config: KpiOrganizationThreshold) => [
              <span>{config.organizationId}</span>,
              <EditIcon
                className="kpi_style_icon"
                color={BKG_CYAN}
                hoverColor={BKG_PINK}
                onClick={() => onSelect(config)}
                data-id={config.organizationId}
              />,
              <DeleteIcon
                className="kpi_style_icon"
                color={BKG_CYAN}
                hoverColor={BKG_PINK}
                onClick={() => openRemovalModal(config)}
                data-id={config.organizationId}
                style={disabled ? STYLE_SVG_ICON_DISABLED : {}}
              />,
            ]}
            items={kpiConfiguration?.kpiOrganizationThresholds}
          />
        )}
      </div>
      <SeparatorWithTitle
        className="kpi_title"
        title={_tg(
          'tefps.configuration.kpiConfiguration.supersetDashboardConfig.profileMappingToDashboard'
        )}
        color={BKG_PINK}
        titleSize={20}
      />
      <div className="kpi_config_table">
        {dashboardConfigs.length > 0 && (
          <SimpleTable
            maxHeight={1500}
            cols={translateDashboardProfilesCols()}
            rowHeight={80}
            itemsRenderer={(config: SupersetDashboardConfigurationDTO) => [
              <span>{config.name}</span>,
              <Select
                id={config.primaryKey}
                floatingLabelText={_tg(
                  'tefps.configuration.kpiConfiguration.supersetDashboardConfig.selectProfiles'
                )}
                onChange={onChange}
                selected={config.authorizedProfiles || undefined}
                multiple
                options={profiles}
                textStyle={STYLE_SELECT_TEXT}
                disabled={disabled}
              />,
            ]}
            items={dashboardConfigs}
          />
        )}
        <Flex flexDirection="row-reverse">
          <BoButton
            primary
            disabled={disabled}
            label={_tg('action.save_2')}
            onClick={patchSupersetDashboardConfig}
          />
        </Flex>
      </div>
      <WorkingDays
        disabled={disabled}
        kpiConfiguration={kpiConfiguration}
        reloadConfig={reloadConfig}
        openSnackBar={openSnackBar}
      />

      <SeparatorWithTitle
        className="kpi_title"
        title={_tg('tefps.configuration.kpiConfiguration.exportEmailsTitle')}
        color={BKG_PINK}
        titleSize={20}
      />
      <div className="kpi_wrapper">
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {Array.from(exportEmails).map(tag => (
            <Tag
              disabled={disabled}
              key={tag}
              tag={tag}
              onDelete={handleDeleteExportMails}
            />
          ))}
        </div>
        <AddTag
          disabled={disabled}
          hintText={_tg(
            'tefps.configuration.kpiConfiguration.emailsTextFieldHint'
          )}
          textFieldStyle={STYLE_FIELD}
          tags={exportEmails}
          onAdd={handleAddExportMails}
        />
      </div>
      <KpiThresholdRemovalModal
        isOpen={removalModalOpen}
        onClose={closeRemovalModal}
        onConfirm={removeKpiThresholdConfig}
      />

      <KpiThresholdConfigModal
        isOpen={open}
        kpiOrganizationThreshold={selectedKpiConfig}
        isEditing={isEditing}
        onClose={onClose}
        onSave={onSave}
        disabled={disabled}
      />
      <Snackbar
        open={!!message}
        message={message}
        autoHideDuration={4000}
        onRequestClose={closeSnackBar}
      />
    </Content>
  );
};

export default connect(
  state => {
    const { userInfo } = getApiState(state);
    const { kpiConfiguration, observatoryConfigurationDTO } = getConfigState(
      state
    );
    return {
      agentRights: userInfo?.rights || [],
      kpiConfiguration,
      observatoryConfigurationDTO,
    };
  },
  (dispatch: any) => {
    return { reloadConfig: () => dispatch(fetchConfig()) };
  }
)(KpiConfiguration);
