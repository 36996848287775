import { FpsContactDTO } from 'api/fps/types';
import { EmailAddress } from 'api/refund/types';
import { Address } from 'api/commonTypes';

const { _tg } = window.loadTranslations(__filename);

export const buildDisplayName = (contact: FpsContactDTO): string => {
  switch (contact.contactType) {
    case 'EMAIL':
      switch (contact.contactSource) {
        case 'RAPO':
          return _tg('tefps.dashboard.refund.addContactDisplay.EMAIL.RAPO');
        case 'MANUAL':
          return _tg('tefps.dashboard.refund.addContactDisplay.EMAIL.MANUAL');
        case 'TIPI' || 'INGENICO':
          return _tg('tefps.dashboard.refund.addContactDisplay.EMAIL.TIPI');
        default:
          return '';
      }

    case 'POSTAL_ADDRESS':
      switch (contact.contactSource) {
        case 'RAPO':
          return _tg(
            'tefps.dashboard.refund.addContactDisplay.POSTAL_ADDRESS.RAPO'
          );
        case 'MANUAL':
          return _tg(
            'tefps.dashboard.refund.addContactDisplay.POSTAL_ADDRESS.MANUAL'
          );
        case 'TIPI' || 'INGENICO':
          return _tg(
            'tefps.dashboard.refund.addContactDisplay.POSTAL_ADDRESS.TIPI'
          );
        default:
          return '';
      }

    default:
      return '';
  }
};

export const getContactValue = (contact: FpsContactDTO): string => {
  let addressData;
  switch (contact.contactType) {
    case 'EMAIL':
      addressData = (contact.contact as any) as EmailAddress;
      return addressData.email;
    case 'POSTAL_ADDRESS':
      addressData = (contact.contact as any) as Address;
      return `${addressData.streetNumber ?? ''}
          ${addressData.streetNumberBis ?? ''}
          ${addressData.streetType ?? ''}
          ${addressData.streetName ?? ''}
          ${addressData.specialPlace ?? ''} -
          ${addressData.postalCode ?? ''}
          ${addressData.addressLocality ?? ''}`;
    default:
      return '';
  }
};
