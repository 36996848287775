import moment from 'moment';
import { ChartData, ChartOptions } from 'chart.js';
import { _DeepPartialArray } from 'utility-types/dist/mapped-types';

import { ChartDTO, FinanceGraphType } from 'api/dashboard/types';
import { KeyBucketDTO } from 'api/commonTypes';

const defaultColor = '#5D6B74';
const colors = [
  '#dd5269',
  '#3fb4c1',
  '#344c77',
  '#338b9b',
  '#969696',
  '#6ba0ff',
  '#B276B2',
  '#00d6a0',
  '#F15854',
];

export const buildOptions = (
  title: string | _DeepPartialArray<string> | undefined,
  maxValue: number,
  unit: string
): ChartOptions => {
  // if (maxValue > 0) { options.scales.yAxes[0].ticks.max = maxValue; }
  return {
    plugins: {
      title: {
        display: true,
        text: title,
        font: {
          size: 18,
        },
      },
      legend: {
        position: 'bottom',
      },
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        beginAtZero: true,
        max: maxValue > 0 ? maxValue : undefined,
        ticks: {
          callback: (label: number) => {
            // when the floored value is the same as the value we have a whole number
            if (Math.floor(label) === label) {
              return label.toLocaleString() + unit;
            }
            return '';
          },
        },
      },
    },
    elements: {
      line: {
        tension: 0,
      },
      point: {
        radius: 3,
        hitRadius: 35,
      },
    },
  };
};

const formatDay = (date: string) => {
  return moment(date).format('DD-MM-YYYY');
};

const formatMonth = (date: string) => {
  return moment(date).format('MMM-YYYY');
};

const formatYear = (date: string) => {
  return moment(date).format('YYYY');
};

const formatDate = (step: string, date: string) => {
  if (step === 'day') return formatDay(date);
  if (step === 'month') return formatMonth(date);
  if (step === 'year') return formatYear(date);
  return null;
};

const getColorForIndex = (index: number) => {
  if (colors[index]) return colors[index];
  return defaultColor;
};

const getPercentage = (
  value: KeyBucketDTO,
  totals: Array<KeyBucketDTO>
): number => {
  const total = totals.filter(t => t.key === value.key).map(r => r.value)[0];
  if (!total || total === 0) return 0;
  return Math.round((value.value / total) * 10000) / 100;
};

export const buildChartDataFromMap = (
  chart: {
    [k: string]: { FinanceGraphType: number };
  },
  graphType: FinanceGraphType,
  label: string
): ChartData => {
  const data: ChartData = {
    labels: [],
    datasets: [],
  };

  data.datasets[0] = {
    label,
    borderColor: getColorForIndex(0),
    backgroundColor: getColorForIndex(0),
    borderWidth: 2,
    data: [],
  };

  Object.keys(chart).forEach((month, i) => {
    if (data.labels) {
      data.labels[i] = month;
    }
    data.datasets[0].data[i] = chart[month][graphType] as number;
  });

  return data;
};

export const buildChartData = (chart: ChartDTO): ChartData => {
  const data: ChartData = {
    labels: [],
    datasets: [],
  };

  if (!chart.points) {
    return data;
  }

  if (chart.points.length > 1) {
    // If there are multiple series, abscissas are the same
    chart.totals.forEach((vT, it) => {
      if (data.labels) {
        data.labels[it] = formatDate(chart.stepUnit, vT.key) as string;
      }
    });
  }

  // Build the data for chart.js
  let idx = 0;
  chart.points.forEach((list, il) => {
    const isPercentage = chart.ordinateNames[il]
      ? chart.ordinateNames[il].includes('%')
      : false; // Ordinate is percentage ?
    if (list.length > 0) {
      list.forEach((point, ik) => {
        if (point) {
          if (!data.datasets[idx]) {
            data.datasets[idx] = {
              label: chart.ordinateNames[il],
              borderColor: getColorForIndex(idx),
              backgroundColor: getColorForIndex(idx),
              borderWidth: 2,
              data: [],
            };
            // In case of multiple series on the same chart, some series may not have the same number of points
            // Below, all ordinates are initialized to zero for all the abscissas of the chart
            if (chart.points.length > 1) {
              chart.totals.forEach((vT, it) => {
                data.datasets[idx].data[it] = 0;
              });
            }
          }

          const value =
            isPercentage && chart.totals
              ? getPercentage(point, chart.totals)
              : point.value;
          const pointIndex =
            chart.points.length > 1
              ? chart.totals.findIndex(x => x.key === point.key)
              : ik;
          data.datasets[idx].data[pointIndex] = value;
          /* if (point.additionalLegend) {
            data.datasets[idx].label += ` ${point.additionalLegend}`;
          } */

          if (chart.points.length === 1) {
            if (data.labels) {
              data.labels[ik] = formatDate(chart.stepUnit, point.key) as string;
            }
          }
        }
      });
      idx += 1;
    }
  });

  return data;
};
