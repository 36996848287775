import React, { CSSProperties } from 'react';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';

import { BKG_LIGHT_BLUE } from 'theme';
import './kpi.css';

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_LIGHT_BLUE,
  color: '#ffffff',
  fontWeight: 'bold',
};

const { _tg } = window.loadTranslations(__filename);

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
};

const KpiThresholdRemovalModal = ({
  isOpen,
  onClose,
  onConfirm,
}: Props): JSX.Element => {
  return (
    <Dialog
      title={_tg('tefps.configuration.kpiConfiguration.removeConfigTitle')}
      open={isOpen}
      titleStyle={STYLE_TITLE}
      modal
      autoScrollBodyContent
      actions={[
        <FlatButton label={_tg('action.cancel')} onClick={onClose} />,
        <FlatButton
          label={_tg('action.confirm')}
          primary
          onClick={onConfirm}
        />,
      ]}
    >
      <div style={{ marginTop: 10 }}>
        {_tg('tefps.configuration.kpiConfiguration.confirmationText')}
      </div>
    </Dialog>
  );
};

export default KpiThresholdRemovalModal;
