import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';

import Content from 'commons/Content';
import { getMifFiltersState } from 'tepv/Mif/List/duck';
import MifSidebar from 'tepv/Mif/List/MifSidebar';
import { MifFilters } from 'tepv/Mif/List/types';
import { filterToSearchParams } from 'tepv/Mif/List/utils';
import { fetchMifStatsExport, getStats } from 'api/tepv/stats';
import { getPresetNotes } from 'api/tepv/presetNote';
import { MifStatsDTO } from 'api/tepv/stats/types';
import { FilterDate } from 'commons/SidebarV2/Components/Dates';
import { getApiState } from 'api/duck';
import ErrorBlock from 'commons/ErrorBlock';

import ComparisonHeader from './ComparisonHeader';
import {
  COMPARISON_COLLAPSED,
  COMPARISON_EXTENDED,
  COMPARISON_PADDING,
} from './utils';
import ChartContainerSingle from './ChartContainerSingle';
import ChartContainerComparison from './ChartContainerComparison';

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  width: '100%',
  margin: '0 auto',
  height: '100%',
};

type Props = {
  filters: MifFilters;
  canExport: boolean;
};

type State = {
  stats: MifStatsDTO | null | undefined;
  statsSecond: MifStatsDTO | null | undefined;
  comparison: {
    enabled: boolean;
    firstPeriod: FilterDate;
    secondPeriod: FilterDate;
  };
  presetNoteKeys: Array<{ key: string; label: string }>;
  error: Error | null | undefined;
};

const noFilterDate = { from: undefined, to: undefined };

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

class Reports extends React.Component<Props, State> {
  state: State = {
    stats: null,
    statsSecond: null,
    comparison: {
      enabled: false,
      firstPeriod: noFilterDate,
      secondPeriod: noFilterDate,
    },
    presetNoteKeys: [],
    error: null,
  };

  componentDidMount() {
    this.fetchStats(this.props.filters);
    this.fetchPresetNotes();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: Props) {
    if (newProps.filters !== this.props.filters) {
      const {
        comparison: { enabled, firstPeriod, secondPeriod },
      } = this.state;
      if (enabled) {
        this.fetchStats(newProps.filters, firstPeriod, secondPeriod);
      } else {
        this.fetchStats(newProps.filters);
      }
    }
  }

  onCheckComparison = (
    event: React.MouseEvent<HTMLInputElement>,
    enabled: boolean
  ) => {
    const { filters } = this.props;
    const { infractionDate } = filters;

    // If the signature date filter is set, we use its dates, otherwise we use a default filter
    const from = new Date();
    from.setFullYear(from.getFullYear() - 1);
    const enabledDate =
      infractionDate.from || infractionDate.to
        ? infractionDate
        : { from, to: undefined };

    const dateToSet = enabled ? enabledDate : noFilterDate;

    this.setState({
      comparison: {
        enabled,
        firstPeriod: dateToSet,
        secondPeriod: dateToSet,
      },
    });

    // When we enable the comparison, it's using the same filters as the sidebar was using (for both periods), except if it wasn't using any
    // When we disable it we fall back to the filters we had before
    if (enabled) {
      this.fetchStats(filters, dateToSet, dateToSet);
    } else {
      this.fetchStats(filters);
    }
  };

  onChangeFirstPeriod = (dates: FilterDate) => {
    this.setState({
      comparison: { ...this.state.comparison, firstPeriod: dates },
    });
    const updatedFilter = { ...this.props.filters, infractionDate: dates };
    this.fetchStatsFirst(updatedFilter);
  };

  onChangeSecondPeriod = (dates: FilterDate) => {
    this.setState({
      comparison: { ...this.state.comparison, secondPeriod: dates },
    });
    const updatedFilter = { ...this.props.filters, infractionDate: dates };
    this.fetchStatsSecond(updatedFilter);
  };

  fetchStats = async (
    filters: MifFilters,
    dateFilterFirst?: FilterDate,
    dateFilterSecond?: FilterDate
  ) => {
    const updatedFilterFirst = {
      ...filters,
      infractionDate: dateFilterFirst || filters.infractionDate,
    };
    try {
      await this.fetchStatsFirst(updatedFilterFirst);

      if (dateFilterSecond) {
        const updatedFilterSecond = {
          ...filters,
          infractionDate: dateFilterSecond,
        };
        await this.fetchStatsSecond(updatedFilterSecond);
      }
      this.setState({ error: null });
    } catch (error) {
      this.setState({ error });
    }
  };

  fetchStatsFirst = async (filters: MifFilters) => {
    const stats = await getStats(filterToSearchParams(filters));
    this.setState({ stats });
  };

  fetchStatsSecond = async (filters: MifFilters) => {
    const stats = await getStats(filterToSearchParams(filters));
    this.setState({ statsSecond: stats });
  };

  fetchPresetNotes = async () => {
    const notes = await getPresetNotes();
    const presetNoteKeys = notes.map(preset => ({
      key: preset.preset,
      label: preset.preset,
    }));
    this.setState({ presetNoteKeys });
  };

  exportStats = () => {
    const {
      comparison: { enabled, firstPeriod, secondPeriod },
    } = this.state;
    const { filters, canExport } = this.props;

    if (!canExport) {
      return;
    }

    if (enabled) {
      const updatedFilterFirst = {
        ...filters,
        signatureDate: firstPeriod,
      };
      const updatedFilterSecond = {
        ...filters,
        signatureDate: secondPeriod,
      };
      fetchMifStatsExport(
        filterToSearchParams(updatedFilterFirst),
        'stats_Period1'
      );
      fetchMifStatsExport(
        filterToSearchParams(updatedFilterSecond),
        'stats_Period2'
      );
    } else {
      fetchMifStatsExport(filterToSearchParams(filters));
    }
  };

  render() {
    const {
      stats,
      statsSecond,
      comparison,
      presetNoteKeys,
      error,
    } = this.state;
    const { canExport } = this.props;
    const filtersData = {
      vehicleRemovalStatus: [],
      saisineStatus: [],
      photosStatus: [],
      antaiStatus: [],
      zoneId: [],
    };

    if (error) {
      return (
        <ErrorBlock
          message="Erreur lors de la récupération des graphes de la page"
          error={error}
        />
      );
    }

    return (
      <div style={{ height: '100%', display: 'flex', flexDirection: 'row' }}>
        <MifSidebar filtersData={filtersData} presetNotes={presetNoteKeys} />
        <div style={STYLE_CONTENT_WRAPPER}>
          <ComparisonHeader
            comparison={comparison}
            onCheck={this.onCheckComparison}
            onChangeFirstPeriod={this.onChangeFirstPeriod}
            onChangeSecondPeriod={this.onChangeSecondPeriod}
          />
          <Content
            style={{
              height: `calc(100% - ${COMPARISON_PADDING * 2 +
                (comparison.enabled
                  ? COMPARISON_EXTENDED
                  : COMPARISON_COLLAPSED)}px)`,
              maxWidth: comparison.enabled ? 1600 : 1200,
            }}
          >
            {stats &&
              (comparison.enabled && statsSecond ? (
                <ChartContainerComparison
                  statsFirst={stats}
                  statsSecond={statsSecond}
                  exportStats={canExport ? this.exportStats : noop}
                />
              ) : (
                <ChartContainerSingle
                  stats={stats}
                  exportStats={canExport ? this.exportStats : noop}
                />
              ))}
          </Content>
        </div>
      </div>
    );
  }
}

export default connect(state => {
  const { filters } = getMifFiltersState(state);
  const { userInfo } = getApiState(state);
  return {
    filters,
    canExport: userInfo
      ? userInfo.rights.includes('PV_DASHBOARD_REPORTS_EXPORT')
      : false,
  };
})(Reports);
