import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import DynamicCheckboxes from 'commons/SidebarV2/Components/DynamicCheckboxes';
import Dates from 'commons/SidebarV2/Components/Dates';
import Times from 'commons/SidebarV2/Components/Times';
import PriceRange from 'commons/SidebarV2/Components/PriceRange';
import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';
import Select from 'commons/SidebarV2/Components/Select';
import Input from 'commons/SidebarV2/Components/Input';
import MultiSearch from 'commons/SidebarV2/Components/MultiSearch';
import { fetchMatchingAgents } from 'api/accounts';
import {
  AuthorizedVehicleCategory,
  getConfigState,
  InitialOrganization,
} from 'config/duck';
import { AgentIdAndNameDTO } from '@cvfm-front/tefps-types';
import { FpsSearchCriterias } from 'tefps/Fps/List/types';
import {
  buildVehicleCategorySelect,
  CANCEL_PROPOSAL_OPTIONS,
  CCSP_FILTER_OPTIONS,
  MEDIAS_OPTIONS,
  PARENTS_OPTIONS,
  PAYMENT_DEFICIENCY_OPTIONS,
  PAYMENT_FILTER_OPTIONS,
  RAPO_FILTER_OPTIONS,
  REASONS_FILTER_OPTIONS,
  REDUCED_DATETIME_UPDATE_OPTIONS,
  STATUSES_FILTER_OPTIONS,
  SUBSCRIPTION_FILTER_OPTIONS,
  TERMINAL_OPTIONS,
  VEHICLE_CONSISTENCY_OPTIONS,
} from 'tefps/Fps/List/utils';
import { Zone } from 'commons/Utils/zoneUtils';
import ChartFilterService from 'Dashboard/fps/Graphics/service';
import { FpsChartCriteria } from 'Dashboard/fps/Graphics/types';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  totalHits?: number;
  filters: FpsSearchCriterias;
  zoneOptions: Array<Zone>;
  resetFilters: () => void;
  authorizedVehicleCategories: Array<AuthorizedVehicleCategory>;
  initialOrganizationsConfigurations: Array<InitialOrganization>;
  withSubscriptions: boolean;
  cancelFpsMultipleEnabled: boolean;
  fpsConsistencyEnabled: boolean;
};

function FpsFiltersBar({
  totalHits,
  filters,
  zoneOptions,
  resetFilters,
  authorizedVehicleCategories,
  initialOrganizationsConfigurations,
  withSubscriptions,
  cancelFpsMultipleEnabled,
  fpsConsistencyEnabled,
}: Props): JSX.Element {
  const [vehicleCategoryOptions, setVehicleCategoryOptions] = useState<
    Array<{ key: string | undefined; label: string }>
  >([]);
  const [agentSearchOptions, setAgentSearchOptions] = useState<
    Array<{ id: string; name: string }>
  >([]);

  function onChange(id: string, value: unknown) {
    ChartFilterService.setFilters((prevFilters: FpsChartCriteria) => {
      return { ...prevFilters, [id]: value };
    });
  }

  function buildVehicleCategoryOptions(
    authorized: Array<AuthorizedVehicleCategory>
  ) {
    setVehicleCategoryOptions(buildVehicleCategorySelect(authorized));
  }

  async function fetchAgentsAndGenerateOptions(
    agentFilter: string | null | undefined
  ) {
    let agents: Array<AgentIdAndNameDTO> = [];
    if (agentFilter && agentFilter.length >= 3) {
      agents = (await fetchMatchingAgents(agentFilter)).matchingAgents;
    }

    const options = agents.map(({ id, fullName }) => ({
      id,
      name: fullName,
    }));
    setAgentSearchOptions(options);
  }

  useEffect(() => {
    void fetchAgentsAndGenerateOptions(null);
    buildVehicleCategoryOptions(authorizedVehicleCategories);
  }, []);

  return (
    <Sidebar>
      <BarTitle resetFilters={resetFilters} />
      <HitsCounter hits={totalHits || 0} />
      <Dates
        id="statementDatetime"
        title={_tg('tefps.filters.fps.statementDatetime')}
        dates={filters.statementDatetime}
        onChange={onChange}
      />
      <br />
      <Times
        id="times"
        title={_tg('tefps.filters.fps.times')}
        times={filters.times}
        onChange={onChange}
      />
      <br />
      <MultiSearch
        id="fpsOrFpsmIds"
        title={_tg('tefps.filters.misc.fpsOrFpsmIds.title')}
        placeholder={_tg('tefps.filters.misc.fpsOrFpsmIds.placeholder')}
        modalTitle={_tg('tefps.filters.misc.fpsOrFpsmIds.modalTitle')}
        modalFloatingLabelText={_tg(
          'tefps.filters.misc.fpsOrFpsmIds.modalFloatingLabelText'
        )}
        onChange={onChange}
        value={filters.fpsOrFpsmIds}
      />
      <MultiSearch
        id="plates"
        title={_tg('tefps.filters.misc.plates.title')}
        placeholder={_tg('tefps.filters.misc.plates.placeholder')}
        modalTitle={_tg('tefps.filters.misc.plates.modalTitle')}
        modalFloatingLabelText={_tg(
          'tefps.filters.misc.plates.modalFloatingLabelText'
        )}
        onChange={onChange}
        value={filters.plates}
      />
      <br />
      <Checkboxes
        id="rapoClaimStatuses"
        title={_tg('tefps.filters.fps.rapoClaimStatuses')}
        options={RAPO_FILTER_OPTIONS()}
        filters={filters.rapoClaimStatuses}
        onChange={onChange}
      />
      <Checkboxes
        id="ccspClaimStatuses"
        title={_tg('tefps.filters.fps.ccspClaimStatuses')}
        options={CCSP_FILTER_OPTIONS()}
        filters={filters.ccspClaimStatuses}
        onChange={onChange}
      />
      <Checkboxes
        id="paymentStatuses"
        title={_tg('tefps.filters.fps.paymentStatuses')}
        options={PAYMENT_FILTER_OPTIONS()}
        filters={filters.paymentStatuses}
        onChange={onChange}
      />
      {withSubscriptions && (
        <Checkboxes
          id="subscriptionStatuses"
          title={_tg('tefps.filters.fps.subscriptionStatuses')}
          options={SUBSCRIPTION_FILTER_OPTIONS()}
          filters={filters.subscriptionStatuses}
          onChange={onChange}
        />
      )}
      <Checkboxes
        id="fpsStatuses"
        title={_tg('tefps.filters.fps.fpsStatus.label')}
        options={STATUSES_FILTER_OPTIONS()}
        filters={filters.fpsStatuses}
        onChange={onChange}
      />
      <Checkboxes
        id="abandonedReasons"
        title={_tg('tefps.filters.fps.abandonedReasons.label')}
        options={REASONS_FILTER_OPTIONS()}
        filters={filters.abandonedReasons}
        onChange={onChange}
      />
      <DynamicCheckboxes
        id="tags"
        title={_tg('tefps.filters.fps.tags')}
        filters={filters.tags}
        onChange={onChange}
      />
      <PriceRange
        id="price"
        title={_tg('tefps.filters.fps.price')}
        price={filters.price}
        onChange={onChange}
      />
      <Checkboxes
        id="paymentDeficiencyStatuses"
        title={_tg('tefps.filters.fps.paymentDeficiencyStatuses.label')}
        options={PAYMENT_DEFICIENCY_OPTIONS()}
        filters={filters.paymentDeficiencyStatuses}
        onChange={onChange}
      />
      {fpsConsistencyEnabled && (
        <Checkboxes
          id="fpsVehicleConsistencyStatuses"
          title={_tg('tefps.filters.fps.vehicleConsistency')}
          options={VEHICLE_CONSISTENCY_OPTIONS()}
          filters={filters.fpsVehicleConsistencyStatuses}
          onChange={onChange}
        />
      )}
      <Checkboxes
        id="mediaStatuses"
        title={_tg('tefps.filters.fps.mediaStatuses.label')}
        options={MEDIAS_OPTIONS()}
        filters={filters.mediaStatuses}
        onChange={onChange}
      />
      <Autocomplete
        id="agentId"
        title={_tg('tefps.filters.fps.agentId')}
        options={agentSearchOptions}
        onChange={onChange}
        onAutocomplete={fetchAgentsAndGenerateOptions}
        search={filters.agentId}
      />
      {initialOrganizationsConfigurations &&
        initialOrganizationsConfigurations.length > 0 && (
          <Select
            id="organizationIds"
            title={_tg('tefps.filters.fps.initialOrganizationShortId')}
            onChange={onChange}
            selected={[...filters.organizationIds] || undefined}
            multiple
            options={[...initialOrganizationsConfigurations]}
          />
        )}
      <Select
        id="zoneId"
        title={_tg('tefps.filters.fps.zoneId')}
        onChange={onChange}
        selected={filters.zoneId}
        options={zoneOptions}
      />
      {vehicleCategoryOptions.length > 1 && (
        <Select
          id="vehicleCategories"
          title={_tg('tefps.filters.fps.vehicleCategory')}
          onChange={onChange}
          selected={[...filters.vehicleCategories]}
          options={vehicleCategoryOptions}
          multiple
        />
      )}
      <Input
        id="address"
        title={_tg('tefps.filters.fps.address.label')}
        placeholder={_tg('tefps.filters.fps.address.placeholder')}
        onChange={onChange}
        value={filters.address || ''}
      />
      <Checkboxes
        id="reducedDatetimeUpdateStatuses"
        title={_tg('tefps.filters.fps.reducedDatetimeUpdateStatuses.label')}
        options={REDUCED_DATETIME_UPDATE_OPTIONS()}
        filters={filters.reducedDatetimeUpdateStatuses}
        onChange={onChange}
      />
      {cancelFpsMultipleEnabled && (
        <Checkboxes
          id="cancelProposalStatuses"
          title={_tg('tefps.filters.fps.cancelProposalStatuses')}
          options={CANCEL_PROPOSAL_OPTIONS()}
          filters={filters.cancelProposalStatuses}
          onChange={onChange}
        />
      )}
      <Select
        id="origins"
        title={_tg('tefps.filters.fps.terminalId.label')}
        multiple
        options={TERMINAL_OPTIONS(undefined, true)}
        selected={filters.origins}
        onChange={onChange}
      />
      <Input
        id="administrativeCertificate"
        title={_tg('tefps.filters.fps.cancelProposalAdminCertificate.label')}
        placeholder={_tg(
          'tefps.filters.fps.cancelProposalAdminCertificate.placeholder'
        )}
        onChange={onChange}
        value={filters.administrativeCertificate || ''}
      />
      <Checkboxes
        id="parentStatuses"
        title={_tg('tefps.filters.fps.parentStatuses')}
        options={PARENTS_OPTIONS()}
        filters={filters.parentStatuses}
        onChange={onChange}
      />
    </Sidebar>
  );
}

export default connect(state => {
  const {
    authorizedVehicleCategories,
    initialOrganizationsConfigurations,
    cancelFpsMultipleEnabled,
    modulesConfiguration,
    sivConfiguration,
  } = getConfigState(state);
  return {
    authorizedVehicleCategories,
    initialOrganizationsConfigurations,
    cancelFpsMultipleEnabled,
    withSubscriptions: modulesConfiguration.subscribers.enabled,
    fpsConsistencyEnabled: !!sivConfiguration?.inconsistentFpsDelay,
  };
})(FpsFiltersBar);
