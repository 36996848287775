export type DatasetConfig = {
  label: string;
  stack: string;
  backgroundColor: string;
  borderColor: string;
  order: number;
  field: string;
};

export const EUR_UNIT = 'EUR';
export const PERCENT_UNIT = 'PERCENT';

export type UNIT_TYPE = typeof EUR_UNIT | typeof PERCENT_UNIT;
