import moment from 'moment';

import { GpvMapFilters } from 'Dashboard/pv/GpvMaps/types';
import { GpvMapSearchRequest } from 'api/tepv/gpv/types';

export const filtersToRequest = (
  filters: GpvMapFilters
): GpvMapSearchRequest => {
  const {
    boundingBoxNorthEast,
    boundingSouthWest,
    zoom,
    agentId,
    dates,
    plate,
    signatureDaysOfWeek,
    times,
    motifsCategories,
    presetNotes,
    organizationIds,
  } = filters;

  let periods;
  if (dates && (dates.to || dates.from)) {
    periods = [
      {
        type: 'STATEMENT',
        startDatetime: dates.from
          ? moment(dates.from).toISOString()
          : undefined,
        endDatetime: dates.to ? moment(dates.to).toISOString() : undefined,
      },
    ];
  }

  return {
    agentId,
    plate,
    periods,
    startTime: times ? times.from : undefined,
    endTime: times ? times.to : undefined,
    signatureDaysOfWeek: signatureDaysOfWeek
      ? Array.from(signatureDaysOfWeek)
      : null,
    motifsCategories,
    presetNotes,
    organizationIds,
    infractionLocations: [], // TODO lieu verbalisation v2
    boundingBoxNorthEast,
    boundingSouthWest,
    zoom,
  };
};

const motifsCategories = [
  'RG des bois ou parcs & espaces verts',
  'RSD',
  'RMDA et RSD',
  'AM spécifique',
  'Chantier - RSD',
  'Dépôt - RSD',
  'RG des bois ou parcs & espaces verts et RMDA',
  'RMDA',
  'RET',
];

export const motifsCategoriesOptions: Array<{
  key: string;
  label: string;
}> = motifsCategories.map(categorie => ({
  key: categorie,
  label: categorie,
}));
