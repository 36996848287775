import React, { CSSProperties, useEffect, useState } from 'react';
import Checkbox from 'material-ui/Checkbox';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import TextField from 'material-ui/TextField';
import DatePicker from 'material-ui/DatePicker';
import TimePicker from 'material-ui/TimePicker';
import _cloneDeep from 'lodash.clonedeep';

import BoButton from 'facade/BoButton';
import { BRD_GREY, TXT_BLACK } from 'theme';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import Content from 'commons/Content';
import { fetchZoning } from 'api/pricing';
import { FETCH_LIGHT_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import { LapiSurveyDTO, ZoneDTO } from '@cvfm-front/tefps-types';

import { listLapiSurveys, createLapiSurvey } from './api';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  fontWeight: 'bold',
  fontFamily: 'Roboto',
  textAlign: 'center',
  color: TXT_BLACK,
  fontSize: 22,
  marginTop: 25,
};

const STYLE_FRAME: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: 25,
  borderRadius: 5,
  paddingLeft: 50,
  paddingRight: 50,
  paddingTop: 25,
  paddingBottom: 25,
  border: `1px solid ${BRD_GREY}`,
  backgroundColor: 'white',
  width: 'fit-content',
  marginLeft: 'auto',
  marginRight: 'auto',
};

const STYLE_ROW: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
};

const EnqueteLapi = (): JSX.Element => {
  const [enableUsingExistingSurvey, setEnableUsingExistingSurvey] = useState<
    boolean
  >(false);

  const initSurveyState = (): LapiSurveyDTO => {
    return {
      name: '',
      lapiVehicles: [],
      capacity: '',
      zoneIds: [],
      startDateTime: new Date(),
      endDateTime: new Date(),
      description: '',
    };
  };

  const [survey, setSurvey] = useState<LapiSurveyDTO>(initSurveyState());
  const [surveyErrors, setSurveyErrors] = useState<
    Partial<Record<keyof LapiSurveyDTO, string>>
  >({
    name: '',
    lapiVehicles: '',
    capacity: '',
    zoneIds: '',
  });
  const [choosableZones, setChoosableZones] = useState<ZoneDTO[]>([]);
  const [choosableLapiSurveys, setChoosableLapiSurveys] = useState<
    LapiSurveyDTO[]
  >([]);
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const setMessage = useSnackbar();

  const loadZoning = (): void => {
    fetchZoning(FETCH_LIGHT_ZONING_CONFIG)
      .then(fetchedZones => {
        setChoosableZones(fetchedZones.zones);
      })
      .catch(err => {
        setMessage((err as Error).message);
      });
  };

  const loadLapiSurveys = (): void => {
    listLapiSurveys()
      .then(lapiSurveys => {
        setChoosableLapiSurveys(lapiSurveys);
      })
      .catch(err => {
        setMessage((err as Error).message);
      });
  };

  useEffect(() => {
    loadZoning();
    loadLapiSurveys();
  }, []);

  const isEmpty = (value: string | string[] | Date | undefined): boolean => {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    if (typeof value === 'string') {
      return value.length === 0;
    }
    return false;
  };

  const updateSurveyError = (
    key: keyof LapiSurveyDTO,
    survey: LapiSurveyDTO,
    updatedSurveyErrors: Partial<Record<keyof LapiSurveyDTO, string>>
  ): void => {
    if (key in surveyErrors) {
      if (isEmpty(survey[key])) {
        updatedSurveyErrors[key] = _tg(
          `tefps.dashboard.lapiSurvey.errors.${key}`
        );
      } else {
        updatedSurveyErrors[key] = '';
      }
      // check name unicity error
      if (
        key === 'name' &&
        choosableLapiSurveys.some(
          choosableLapiSurvey => choosableLapiSurvey.name === survey[key]
        )
      ) {
        updatedSurveyErrors[key] = _tg(
          `tefps.dashboard.lapiSurvey.errors.${key}_unique`
        );
      }
    }
  };

  const updateSurveyErrors = (): Partial<Record<
    keyof LapiSurveyDTO,
    string
  >> => {
    const updatedSurveyErrors = _cloneDeep(surveyErrors);
    (Object.keys(surveyErrors) as Array<keyof typeof surveyErrors>).forEach(
      key => {
        updateSurveyError(key, survey, updatedSurveyErrors);
      }
    );
    setSurveyErrors(updatedSurveyErrors);
    return updatedSurveyErrors;
  };

  const onCreateLapiSurvey = (): void => {
    setDisableSave(true);
    const updatedSurveyErrors = updateSurveyErrors();
    if (
      Object.values(updatedSurveyErrors).every(
        value => typeof value === 'string' && value.length === 0
      )
    ) {
      createLapiSurvey(survey)
        .then(lapiSurveys => {
          setSurvey(initSurveyState());
          setMessage(_tg('tefps.dashboard.lapiSurvey.success.form'));
          setChoosableLapiSurveys(lapiSurveys);
        })
        .catch(err => {
          setMessage((err as Error).message);
        })
        .finally(() => {
          setDisableSave(false);
        });
    } else {
      setMessage(_tg('tefps.dashboard.lapiSurvey.errors.form'));
      setDisableSave(true);
    }
  };

  const onCheckUsingExistingSurvey = (): void => {
    setEnableUsingExistingSurvey(!enableUsingExistingSurvey);
  };

  const onSelectExistingSurveyId = (
    _e: React.ChangeEvent<HTMLInputElement>,
    _i: number,
    selectedSurveyName: string
  ): void => {
    // copy values in the survey to fill form
    choosableLapiSurveys.forEach(choosableLapiSurvey => {
      if (choosableLapiSurvey.name === selectedSurveyName) {
        const filledSurvey = _cloneDeep(choosableLapiSurvey);
        filledSurvey.startDateTime = new Date(
          choosableLapiSurvey.startDateTime
        );
        filledSurvey.endDateTime = new Date(choosableLapiSurvey.endDateTime);
        setSurvey(filledSurvey);
      }
    });
  };

  const onChangeSurveyFieldByKey = (
    key: keyof LapiSurveyDTO,
    inputValue: any
  ): void => {
    const updatedSurvey = _cloneDeep(survey);
    if (key === 'lapiVehicles') {
      if (isEmpty(inputValue)) {
        updatedSurvey[key] = [];
      } else {
        updatedSurvey[key] = inputValue.split(',');
      }
    } else if (key === 'capacity') {
      if (/^\d*$/.test(inputValue)) {
        updatedSurvey[key] = inputValue;
      }
    } else {
      updatedSurvey[key] = inputValue;
    }
    setSurvey(updatedSurvey);
    // error handling
    const updatedSurveyErrors = _cloneDeep(surveyErrors);
    updateSurveyError(key, updatedSurvey, updatedSurveyErrors);
    setSurveyErrors(updatedSurveyErrors);
  };

  const onChangeDateTime = (
    type: 'start' | 'end',
    dateOrTime: 'date' | 'time',
    dateTime: Date
  ): void => {
    const updatedSurvey = _cloneDeep(survey);
    const newDateTime =
      type === 'start'
        ? updatedSurvey.startDateTime
        : updatedSurvey.endDateTime;
    if (dateOrTime === 'date') {
      newDateTime.setDate(dateTime.getDate());
      newDateTime.setMonth(dateTime.getMonth());
      newDateTime.setFullYear(dateTime.getFullYear());
    } else {
      newDateTime.setHours(dateTime.getHours());
      newDateTime.setMinutes(dateTime.getMinutes());
    }
    updatedSurvey[
      type === 'start' ? 'startDateTime' : 'endDateTime'
    ] = newDateTime;
    setSurvey(updatedSurvey);
  };

  return (
    <Content>
      <div style={STYLE_TITLE}>{_tg('tefps.dashboard.lapiSurvey.title')}</div>

      <div style={STYLE_FRAME}>
        <div style={{ ...STYLE_ROW, alignItems: 'flex-end' }}>
          <div style={{ flexGrow: 0 }}>
            <Checkbox
              label={_tg('tefps.dashboard.lapiSurvey.createFromExistingSurvey')}
              checked={enableUsingExistingSurvey}
              onCheck={onCheckUsingExistingSurvey}
            />
          </div>

          <div style={{ flexGrow: 0 }}>
            <SelectField
              floatingLabelText={_tg(
                'tefps.dashboard.lapiSurvey.selectExistingSurvey'
              )}
              onChange={onSelectExistingSurveyId}
              disabled={!enableUsingExistingSurvey}
            >
              {choosableLapiSurveys.map(choice => (
                <MenuItem
                  key={choice.name}
                  value={choice.name}
                  primaryText={choice.name}
                />
              ))}
            </SelectField>
          </div>
        </div>

        <div style={STYLE_ROW}>
          <TextField
            floatingLabelText={_tg('tefps.dashboard.lapiSurvey.surveyName')}
            value={survey.name}
            onChange={(_, value) => onChangeSurveyFieldByKey('name', value)}
            errorText={surveyErrors.name}
          />
        </div>

        <div style={STYLE_ROW}>
          <TextField
            floatingLabelText={_tg(
              'tefps.dashboard.lapiSurvey.selectLapiVehicles'
            )}
            value={survey.lapiVehicles.join(',')}
            onChange={(_, value) =>
              onChangeSurveyFieldByKey('lapiVehicles', value)
            }
            fullWidth
            errorText={surveyErrors.lapiVehicles}
          />
        </div>

        <div style={STYLE_ROW}>
          <TextField
            floatingLabelText={_tg('tefps.dashboard.lapiSurvey.capacity')}
            value={survey.capacity}
            onChange={(_, capacity) =>
              onChangeSurveyFieldByKey('capacity', capacity)
            }
            errorText={surveyErrors.capacity}
          />
        </div>

        <div style={STYLE_ROW}>
          <SelectField
            floatingLabelText={_tg('tefps.dashboard.lapiSurvey.selectZones')}
            value={survey.zoneIds}
            onChange={(_, __, zoneIds) =>
              onChangeSurveyFieldByKey('zoneIds', zoneIds)
            }
            multiple
            errorText={surveyErrors.zoneIds}
          >
            {choosableZones.map(choice => (
              <MenuItem
                key={choice.id}
                value={choice.id}
                primaryText={choice.name}
              />
            ))}
          </SelectField>
        </div>

        <div style={STYLE_ROW}>
          <div style={{ flexGrow: 0 }}>
            <DatePicker
              floatingLabelText={_tg('tefps.dashboard.lapiSurvey.startDate')}
              value={survey.startDateTime}
              onChange={(_event: Event | null | undefined, date: Date) =>
                onChangeDateTime('start', 'date', date)
              }
              locale="fr"
              errorText={surveyErrors.startDateTime}
            />
          </div>

          <div style={{ flexGrow: 0 }}>
            <TimePicker
              id="diff"
              floatingLabelText={_tg('tefps.dashboard.lapiSurvey.startTime')}
              value={survey.startDateTime}
              onChange={(_event: Event | null | undefined, time: Date) =>
                onChangeDateTime('start', 'time', time)
              }
              format="24hr"
              errorText={surveyErrors.startDateTime}
            />
          </div>
        </div>

        <div style={STYLE_ROW}>
          <div style={{ flexGrow: 0 }}>
            <DatePicker
              floatingLabelText={_tg('tefps.dashboard.lapiSurvey.endDate')}
              value={survey.endDateTime}
              onChange={(_event: Event | null | undefined, date: Date) =>
                onChangeDateTime('end', 'date', date)
              }
              locale="fr"
              errorText={surveyErrors.endDateTime}
            />
          </div>

          <div style={{ flexGrow: 0 }}>
            <TimePicker
              id="diff"
              floatingLabelText={_tg('tefps.dashboard.lapiSurvey.endTime')}
              value={survey.endDateTime}
              onChange={(_event: Event | null | undefined, time: Date) =>
                onChangeDateTime('end', 'time', time)
              }
              format="24hr"
              errorText={surveyErrors.endDateTime}
            />
          </div>
        </div>

        <div style={STYLE_ROW}>
          <TextField
            floatingLabelText={_tg('tefps.dashboard.lapiSurvey.description')}
            value={survey.description}
            onChange={(_, description) =>
              onChangeSurveyFieldByKey('description', description)
            }
            errorText={surveyErrors.description}
          />
        </div>

        <div style={{ ...STYLE_ROW, justifyContent: 'flex-end' }}>
          <BoButton
            label={_tg('action.create')}
            primary
            disabled={disableSave}
            onClick={() => onCreateLapiSurvey()}
          />
        </div>
      </div>
    </Content>
  );
};

export default EnqueteLapi;
