import FileSaver from 'file-saver';
import * as React from 'react';

import BoButton from 'facade/BoButton';
import { MifStatsDTO } from 'api/tepv/stats/types';
import { exportGraph } from 'api/tepv/stats';
import Exporter from 'commons/Exporter';

import ChartCustom from './Charts/ChartCustom';
import {
  EXPORT_COLUMNS,
  monthNameMapper,
  sortByCount,
  sortByMonth,
} from './utils';

type Props = {
  statsFirst: MifStatsDTO;
  statsSecond: MifStatsDTO;
  exportStats: () => void | null | undefined;
};

class ChartContainerComparison extends React.Component<Props> {
  mifByMonthRef = React.createRef<ChartCustom>();
  mifByCodeRef = React.createRef<ChartCustom>();
  mifByCategoryRef = React.createRef<ChartCustom>();
  mifByGroupRef = React.createRef<ChartCustom>();
  mifByPresetNoteRef = React.createRef<ChartCustom>();
  mifByVehicleCountryRef = React.createRef<ChartCustom>();
  mifByVehicleCategoryRef = React.createRef<ChartCustom>();

  getTotalFirst = () => this.props.statsFirst.mifCount;
  getTotalSecond = () => this.props.statsSecond.mifCount;

  exportGraphs = async () => {
    const refs = [
      this.mifByMonthRef,
      this.mifByCodeRef,
      this.mifByCategoryRef,
      this.mifByGroupRef,
      this.mifByPresetNoteRef,
      this.mifByVehicleCountryRef,
      this.mifByVehicleCategoryRef,
    ];

    const exportData = refs.reduce(
      (acc: any, ref: React.RefObject<ChartCustom>) => {
        if (ref.current) {
          return acc.concat(ref.current.getExportData());
        }
        return acc;
      },
      []
    );

    const file = await exportGraph(exportData);
    const blobFile = await file.blob();
    FileSaver.saveAs(blobFile, 'export.pdf');
  };

  render() {
    const {
      statsFirst: {
        mifsBySignatureDate: mifsBySignatureDateFirst,
        mifsByCode: mifsByCodeFirst,
        mifsByCategory: mifsByCategoryFirst,
        mifsByGroup: mifsByGroupFirst,
        mifsByPresetNote: mifsByPresetNoteFirst,
        mifsByPlateCountry: mifsByPlateCountryFirst,
        mifsByVehicleCategory: mifsByVehicleCategoryFirst,
      },
      statsSecond: {
        mifsBySignatureDate: mifsBySignatureDateSecond,
        mifsByCode: mifsByCodeSecond,
        mifsByCategory: mifsByCategorySecond,
        mifsByGroup: mifsByGroupSecond,
        mifsByPresetNote: mifsByPresetNoteSecond,
        mifsByPlateCountry: mifsByPlateCountrySecond,
        mifsByVehicleCategory: mifsByVehicleCategorySecond,
      },
      exportStats,
    } = this.props;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <div>
          <ChartCustom
            ref={this.mifByMonthRef}
            acceptedTypes={['bar']}
            graphsData={[
              {
                title: 'MIF par mois (1)',
                stats: sortByMonth(mifsBySignatureDateFirst),
              },
              {
                title: 'MIF par mois (2)',
                stats: sortByMonth(mifsBySignatureDateSecond),
              },
            ]}
            mapper={monthNameMapper}
          />
        </div>
        <div>
          <ChartCustom
            ref={this.mifByCodeRef}
            acceptedTypes={['pie', 'bar']}
            graphsData={[
              {
                title: 'MIF par code NATINF (1)',
                stats: mifsByCodeFirst,
              },
              {
                title: 'MIF par code NATINF (2)',
                stats: mifsByCodeSecond,
              },
            ]}
            graphLabel="byCodeNatinf"
          />
        </div>
        <div>
          <ChartCustom
            ref={this.mifByCategoryRef}
            acceptedTypes={['pie', 'bar']}
            graphsData={[
              {
                title: 'MIF par catégorie NATINF (1)',
                stats: mifsByCategoryFirst,
              },
              {
                title: 'MIF par catégorie NATINF (2)',
                stats: mifsByCategorySecond,
              },
            ]}
          />
        </div>
        <div>
          <ChartCustom
            ref={this.mifByGroupRef}
            acceptedTypes={['bar']}
            graphsData={[
              {
                title: 'MIF par groupe de NATINF (1)',
                stats: mifsByGroupFirst,
              },
              {
                title: 'MIF par groupe de NATINF (2)',
                stats: mifsByGroupSecond,
              },
            ]}
            total={[this.getTotalFirst, this.getTotalSecond]}
          />
        </div>
        <div>
          <ChartCustom
            ref={this.mifByPresetNoteRef}
            acceptedTypes={['pie', 'bar']}
            graphsData={[
              {
                title: 'MIF par renseignement prédéfini (1)',
                stats: mifsByPresetNoteFirst,
              },
              {
                title: 'MIF par renseignement prédéfini (2)',
                stats: mifsByPresetNoteSecond,
              },
            ]}
          />
        </div>
        <div>
          <ChartCustom
            ref={this.mifByVehicleCountryRef}
            acceptedTypes={['pie', 'bar']}
            graphsData={[
              {
                title: "MIF par pays d'immatriculation (1)",
                stats: sortByCount(mifsByPlateCountryFirst),
              },
              {
                title: "MIF par pays d'immatriculation (2)",
                stats: sortByCount(mifsByPlateCountrySecond),
              },
            ]}
          />
        </div>
        <div>
          <ChartCustom
            ref={this.mifByVehicleCategoryRef}
            acceptedTypes={['pie', 'bar']}
            graphsData={[
              {
                title: 'MIF par catégorie de véhicule (1)',
                stats: sortByCount(mifsByVehicleCategoryFirst),
              },
              {
                title: 'MIF par catégorie de véhicule (2)',
                stats: sortByCount(mifsByVehicleCategorySecond),
              },
            ]}
          />
        </div>
        {exportStats && (
          <div style={{ padding: '20px 35px', display: 'flex' }}>
            <Exporter
              disabled={false}
              columns={EXPORT_COLUMNS}
              type="Stats"
              filters={{}}
              fileExport={exportStats}
              description="L'export générera deux fichiers"
            />
            <BoButton
              style={{ margin: '0 15px' }}
              label="Export tous graphiques"
              onClick={this.exportGraphs}
              primary
            />
          </div>
        )}
      </div>
    );
  }
}

export default ChartContainerComparison;
