import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';

import {
  exportPayments,
  fetchPaymentFilteredList,
  getCashVoucherUrl,
} from 'api/fps';
import Exporter from 'commons/Exporter';
import FlexCenter from 'commons/FlexCenter';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import AdvertisingModal from 'commons/AdvertisingModal';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import { getConfigState, RegieProperties } from 'config/duck';
import { Pager, SortParameters } from 'api/commonTypes';
import { PaymentSearchResultDTO } from 'api/fps/types';

import { PaymentSearchCriterias } from './types';
import { getPaymentListState, setSortParameters } from './duck';
import CashVoucher from './CashVoucher';
import PaymentTable from './PaymentTable';
import PaymentFilters from './PaymentFilters';
import { EXPORT_COL, EXPORT_COL_MULTI_CITIES, filtersToRequest } from './utils';

const { _tg } = window.loadTranslations(__filename);

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  height: '100%',
};

const MAX_RECORDS = 20;

type State = {
  paymentSearchResult: PaymentSearchResultDTO | null | undefined;
  error: Error | null | undefined;
  pager: Pager;
};

type Props = {
  payments: {
    filters: PaymentSearchCriterias;
    sort: SortParameters;
  };
  regiesProperties: RegieProperties;
  updateSortParameters: (sort: SortParameters) => void;
};

const initialState = (): State => ({
  paymentSearchResult: null,
  error: null,
  pager: { page: 0, maxRecords: MAX_RECORDS },
});

class PaymentList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = initialState();
  }

  componentDidMount() {
    void this.fetchPaymentList(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: Props) {
    void this.fetchPaymentList(newProps);
  }

  onUpdateSort = (sortField: number, increasingOrder: boolean) => {
    const { updateSortParameters } = this.props;
    updateSortParameters({ sortField, increasingOrder });
  };

  loadMoreRows = async ({ startIndex }: { startIndex: number }) =>
    this.fetchPaymentList(this.props, startIndex);

  fetchPaymentList = async (props: Props, page?: number) => {
    const { paymentSearchResult } = this.state;
    const previousPaymentSearchResult = paymentSearchResult;
    const {
      payments: { filters, sort },
    } = props;
    const pager = { page: page || 0, maxRecords: MAX_RECORDS };

    try {
      const newPaymentSearchResult = await fetchPaymentFilteredList(
        filtersToRequest(filters, sort, pager)
      );
      if (page && previousPaymentSearchResult) {
        const newPaymentList = previousPaymentSearchResult.payments.concat(
          newPaymentSearchResult.payments
        );
        this.setState({
          paymentSearchResult: {
            ...previousPaymentSearchResult,
            payments: newPaymentList,
          },
          error: null,
        });
      } else {
        this.setState({
          paymentSearchResult: newPaymentSearchResult,
          error: null,
        });
      }
    } catch (error) {
      this.setState({
        error: error as Error,
        paymentSearchResult: null,
        pager,
      });
    }
  };

  render() {
    const { payments, regiesProperties } = this.props;
    const { filters, sort } = payments;
    const { paymentSearchResult, error, pager } = this.state;
    const items = paymentSearchResult ? paymentSearchResult.payments : [];
    const totalHits = paymentSearchResult ? paymentSearchResult.totalHits : 0;

    if (error) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock
              message={_tg(
                'tefps.dashboard.payment.errorWhileFetchingPayments'
              )}
              error={error}
            />
          </FlexCenter>
        </Content>
      );
    }

    return (
      <div style={STYLE_CONTENT}>
        <AdvertisingModal module="fps" />
        <PaymentFilters
          totalHits={totalHits}
          facetings={(paymentSearchResult || {}).checkboxFaceting}
        />
        <ListWrapper>
          <ListBody loading={!paymentSearchResult}>
            <PaymentTable
              items={items}
              onUpdateSort={this.onUpdateSort}
              loadMoreRows={this.loadMoreRows}
              remoteRowCount={
                paymentSearchResult ? paymentSearchResult.totalHits : 0
              }
              colSorted={sort.sortField}
              sortOrder={sort.increasingOrder}
            />
          </ListBody>
          <ListBottom>
            <Exporter
              disabled={totalHits === 0}
              columns={
                regiesProperties && regiesProperties.properties.length > 0
                  ? EXPORT_COL_MULTI_CITIES()
                  : EXPORT_COL()
              }
              type={_tg('field.payment.payment_plural').toLocaleLowerCase()}
              // eslint-disable-next-line
              // @ts-ignore
              filters={filtersToRequest(filters, sort, pager)}
              fileExport={exportPayments}
            />
            <CashVoucher getCashVoucherUrl={getCashVoucherUrl} />
          </ListBottom>
        </ListWrapper>
      </div>
    );
  }
}

export default connect(
  state => {
    const payments = getPaymentListState(state);
    const { regiesProperties } = getConfigState(state);
    return {
      payments,
      regiesProperties,
    };
  },
  dispatch => ({
    updateSortParameters: (sort: SortParameters) =>
      dispatch(setSortParameters(sort)),
  })
)(PaymentList);
