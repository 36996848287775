import React, { CSSProperties, useContext, useState } from 'react';
import EditIcon from 'material-ui/svg-icons/image/edit';
import Checkbox from 'material-ui/Checkbox';
import { useHistory, useParams } from 'react-router-dom';

import SimpleTable from 'commons/SimpleTable';
import Price from 'commons/Price';
import FpsNumber from 'commons/FpsNumber';
import Date from 'commons/Date';
import { RefundOverviewDTO } from 'api/refund/types';
import { ICON_STYLE, LABEL_STYLE, WIDTH_STYLE } from 'theme';

import EditModal from './EditModal';
import { STATUS_OPTIONS } from './EditModal/utils';
import {
  BoolContext,
  BoolContextType,
  RefundMapContext,
  RefundMapContextType,
} from './Context';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  items: Array<RefundOverviewDTO>;
  loadMoreRows: () => Promise<void>;
  totalHits: number;
};

const RefundTable = ({
  items,
  totalHits,
  loadMoreRows,
}: Props): JSX.Element => {
  const { id } = useParams<{ id?: string }>();
  const [refundId, setRefundId] = useState(id);
  const { selectedRefunds, setSelectedRefunds } = useContext(
    RefundMapContext
  ) as RefundMapContextType;
  const { multipleMode } = useContext(BoolContext) as BoolContextType;
  const history = useHistory();

  function onCheck(event: React.ChangeEvent<any>, checked: boolean) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
    const { rowRefundId, rowRootFpsId, rowPrice } = event.target.dataset;
    const localMap = { ...selectedRefunds };

    if (checked) {
      localMap[rowRefundId as string] = {
        rootFpsId: rowRootFpsId as string,
        refundPrice: rowPrice as number,
      };
    } else {
      delete localMap[rowRefundId as string];
    }
    setSelectedRefunds(() => localMap);
  }

  const tableCols: Array<{
    label?: string;
    width: number;
    grow?: number;
    style?: CSSProperties;
    headerStyle?: CSSProperties;
    onSort?: boolean;
  }> = [
    { label: _tg('tefps.dashboard.payment.columns.fpsNumber'), width: 300 },
    {
      label: _tg('field.date.emissionDate'),
      width: 150,
      onSort: true,
    },
    { label: _tg('tefps.dashboard.refund.refundStatus'), width: 100, grow: 1 },
    {
      label: _tg('tefps.dashboard.refund.refundPrice'),
      width: 100,
      style: { textAlign: 'center' },
    },
    { label: _tg('tefps.dashboard.refund.lastPayment'), width: 150 },
    { label: _tg('field.date.lastModificationDateSimple'), width: 150 },
    {
      label: '',
      width: 50,
    },
  ];

  if (multipleMode) {
    tableCols.unshift({ label: _tg('field.selection'), width: 80 });
  }

  const onEditClick = (refund: RefundOverviewDTO) => {
    setRefundId(refund.id);
    history.push(`/dashboard/refund/${refund.id}`);
  };

  const onEditClose = () => {
    setRefundId(undefined);
    history.push(`/dashboard/refund`);
  };

  function renderRow(refund: RefundOverviewDTO) {
    const row = [
      <a href={`#/fps/detail/${refund.rootFpsId}`}>
        <FpsNumber fpsId={refund.rootFpsId} />
      </a>,
      <Date datetime={refund.statementDatetime} />,
      <span>{STATUS_OPTIONS()[refund.fpsRefundStatus]}</span>,
      <Price price={refund.refundPrice} />,
      <Date datetime={refund.lastPaymentDate} />,
      <Date datetime={refund.dateModified} />,
      <span
        title={_tg('tefps.dashboard.refund.editARefund')}
        onClick={() => onEditClick(refund)}
      >
        <EditIcon
          style={{
            cursor: 'pointer',
          }}
        />
      </span>,
    ];

    if (multipleMode) {
      row.unshift(
        <Checkbox
          iconStyle={{ ...ICON_STYLE, fill: '#000' }}
          labelStyle={LABEL_STYLE}
          style={WIDTH_STYLE}
          checked={!!selectedRefunds[refund.id]}
          onCheck={onCheck}
          data-row-refund-id={refund.id}
          data-row-root-fps-id={refund.rootFpsId}
          data-row-price={refund.refundPrice}
        />
      );
    }
    return row;
  }

  return (
    <>
      <SimpleTable
        cols={tableCols}
        rowHeight={50}
        loadMoreRows={loadMoreRows}
        remoteRowCount={totalHits}
        items={items}
        itemsRenderer={renderRow}
      />
      {refundId && <EditModal refundId={refundId} onClose={onEditClose} />}
    </>
  );
};

export default RefundTable;
