import FileSaver from 'file-saver';
import * as React from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import PieChartIcon from 'material-ui/svg-icons/editor/pie-chart';
import BarChartIcon from 'material-ui/svg-icons/editor/insert-chart';
import Card from 'material-ui/Card';
import { ChartData, ChartType } from 'chart.js';

import BoButton from 'facade/BoButton';
import { exportGraph } from 'api/tepv/stats';

import Chart, { colors } from './BaseChart';
import {
  CHART_KEY_LABEL,
  CHART_TYPE,
  chartBarOptions,
  chartPieOptions,
  STYLE_GRAPHS_WRAPPER,
} from './chartConfig';

type GraphData = {
  title: string;
  stats: {
    [k: string]: number;
  };
};

type Props = {
  acceptedTypes: Array<ChartType>;
  graphsData: Array<GraphData>;
  graphLabel?: string;
  mapper?: (arg0: string) => string;
  total?: Array<() => number>;
};

type State = {
  chartType: ChartType;
};

const datasetBuilder = (
  stats: {
    [k: string]: number;
  },
  graphColors: string[] | string,
  mapper?: (arg0: string) => string
) => {
  const mappedkeys: Array<string> = Object.keys(stats);
  return {
    datasets: [
      {
        data: Object.values(stats),
        borderColor: graphColors,
        backgroundColor: graphColors,
        borderWidth: 0,
      },
    ],
    labels: mapper ? mappedkeys.map(mapper) : mappedkeys,
  };
};

class ChartCustom extends React.Component<Props, State> {
  chartRefs: any[] = [];

  constructor(props: Props) {
    super(props);
    this.state = {
      chartType: this.props.acceptedTypes[0],
    };

    this.chartRefs = this.props.graphsData.map(() => React.createRef());
  }

  onChange = (event: any, key: any, chartType: ChartType) => {
    this.setState({ chartType });
  };

  getExportData = (): any => {
    const { graphsData } = this.props;

    return graphsData.map((graphData: GraphData, idx: number) => {
      const chartRef = this.chartRefs[idx].current;
      const chart = chartRef.exportGraph();
      return {
        chart,
        title: this.props.graphsData[idx].title,
        total: this.props.total ? this.props.total[idx]() : null,
        stats: this.props.graphsData[idx].stats,
        graphLabel: this.props.graphLabel,
      };
    });
  };

  chartDataset = (
    stats: {
      [k: string]: number;
    },
    mapper?: (arg0: string) => string
  ): ChartData => {
    const { chartType } = this.state;
    switch (chartType) {
      case CHART_TYPE.PIE:
        return datasetBuilder(stats, colors);
      case CHART_TYPE.BAR:
        return datasetBuilder(stats, colors[0], mapper);
      default:
        return {} as ChartData;
    }
  };

  chartOptions = (title: string, total?: Array<() => number>) => {
    const { chartType } = this.state;
    switch (chartType) {
      case CHART_TYPE.PIE:
        return chartPieOptions(title, total);
      case CHART_TYPE.BAR:
        return chartBarOptions(title, total);
      default:
        return {};
    }
  };

  chartIcon = (type: string) => {
    switch (type) {
      case CHART_TYPE.PIE:
        return <PieChartIcon />;
      case CHART_TYPE.BAR:
        return <BarChartIcon />;
      default:
        return <div />;
    }
  };

  exportGraph = async () => {
    const exportData = this.getExportData();

    const file = await exportGraph(exportData);
    const blobFile = await file.blob();
    FileSaver.saveAs(blobFile, 'export.pdf');
  };

  render() {
    const { acceptedTypes, graphsData, mapper, total } = this.props;
    const { chartType } = this.state;
    const style =
      graphsData.length > 1 ? STYLE_GRAPHS_WRAPPER : { width: '100%' };
    return (
      <div>
        <div style={{ margin: '15px 35px' }}>
          <Card>
            {acceptedTypes.length > 1 && (
              <SelectField
                value={chartType}
                floatingLabelText="Sélectionner le type de graphe"
                labelStyle={{
                  textAlign: 'center',
                }}
                onChange={this.onChange}
                style={{ width: '30%' }}
              >
                {acceptedTypes.map(type => (
                  <MenuItem
                    key={type}
                    value={type}
                    primaryText={CHART_KEY_LABEL[type]}
                    leftIcon={this.chartIcon(type)}
                  />
                ))}
              </SelectField>
            )}
            <div style={style}>
              {graphsData.map((graphData, idx) => (
                <Chart
                  ref={this.chartRefs[idx]}
                  type={chartType}
                  options={this.chartOptions(graphData.title, total)}
                  data={this.chartDataset(graphData.stats, mapper)}
                />
              ))}
            </div>
            <div style={{ padding: '20px 35px' }}>
              <BoButton label="Export" onClick={this.exportGraph} primary />
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

export default ChartCustom;
