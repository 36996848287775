import React, { CSSProperties } from 'react';
import Dialog from 'material-ui/Dialog';

import { getActions } from 'commons/ModalUtils';
import { BKG_CYAN } from 'theme';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_CYAN,
  color: '#ffffff',
  fontWeight: 'bold',
};

type Props = {
  onSubmit: (arg0: void) => void;
  isOpen: boolean;
  onClose: () => void;
};

function DoMultipleRefundModal({
  onSubmit,
  onClose,
  isOpen,
}: Props): JSX.Element {
  function onClickValidate() {
    onSubmit();
  }

  const actions = getActions(onClickValidate, onClose);

  return (
    <Dialog
      title={_tg('tefps.dashboard.refund.mailSendingConfirmation')}
      actions={actions}
      modal
      autoScrollBodyContent
      open={isOpen}
      titleStyle={STYLE_TITLE}
    />
  );
}

export default DoMultipleRefundModal;
