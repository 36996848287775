import React, { useState } from 'react';
import _cloneDeep from 'lodash.clonedeep';

import SectionTitle from 'commons/SectionTitle';
import { Flex } from '@cvfm-front/commons-ui';
import './FpsCommentConfiguration.css';

import BoButton from 'facade/BoButton';

import { connect } from 'react-redux';

import { fetchConfig, getConfigState } from 'config/duck';
import { FpsConfigurationDTO } from '@cvfm-front/tefps-types';
import { updateCommentsConfiguration } from 'api/fpsConfiguration';

const { _tg } = window.loadTranslations(__filename);
type Props = {
  fpsConfigurationDTO: FpsConfigurationDTO;
  reloadConfig: () => void;
};
function FpsCommentConfiguration({
  fpsConfigurationDTO,
  reloadConfig,
}: Props): JSX.Element {
  const [comments, setComments] = useState<Array<string>>(
    fpsConfigurationDTO?.fpsComments || []
  );

  const add = () => {
    const editedComments = _cloneDeep(comments);
    editedComments.push('');
    setComments(editedComments);
  };

  function onChange(index: number, value: any) {
    const editedComments = _cloneDeep(comments);
    editedComments[index] = value;
    setComments(editedComments);
  }

  const deleted = (index: number) => {
    setComments(comments.filter((v, i) => i !== index));
  };

  const cancel = () => {
    setComments(fpsConfigurationDTO.fpsComments);
  };

  const save = async () => {
    const updateFpsConfigurationDTO = {
      ...fpsConfigurationDTO,
      fpsComments: comments,
    };
    await updateCommentsConfiguration({
      op: 'replace',
      path: '/fpsConfiguration',
      value: updateFpsConfigurationDTO,
    });
    reloadConfig();
  };

  const hasChanges = () => {
    if ((fpsConfigurationDTO.fpsComments || []).length !== comments.length)
      return true;
    return comments?.some(
      (value: string, index: number) =>
        fpsConfigurationDTO.fpsComments[index] !== value
    );
  };

  return (
    <div
      style={{
        padding: 20,
        paddingTop: 0,
      }}
    >
      <SectionTitle
        title={_tg('tefps.configuration.fpsConfiguration.fpsComments.title')}
      />
      <Flex flexDirection="column" gap={10}>
        <table className="fps-comment-config__table">
          {comments.map((comment, index) => (
            <tr className="fps-comment-config__tr">
              <td style={{ width: '95%' }}>
                <div>
                  <input
                    id={`_${index}`}
                    value={comment || ''}
                    key={index}
                    type="text"
                    className="fps-comment-config__input"
                    onChange={e => onChange(index, e.target.value)}
                    maxLength={100}
                    placeholder="Commentaire"
                  />
                  <hr className="fps-comment-config__input-hr" />
                </div>
              </td>
              <td style={{ display: 'flex', gap: 5 }}>
                <BoButton
                  label="Supprimer"
                  onClick={() => deleted(index)}
                  secondary
                />
              </td>
            </tr>
          ))}
        </table>
        <Flex justifyContent="space-between">
          <BoButton
            label={_tg(
              'tefps.configuration.fpsConfiguration.fpsComments.addComment'
            )}
            primary
            onClick={add}
          />
          <Flex gap={5}>
            <BoButton
              label={_tg('action.cancel')}
              primary
              onClick={cancel}
              disabled={!hasChanges()}
            />
            <BoButton
              label={_tg('action.validate')}
              primary
              disabled={!hasChanges()}
              onClick={save}
            />
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
}

export default connect(
  state => {
    const { fpsConfigurationDTO } = getConfigState(state);
    return { fpsConfigurationDTO };
  },
  (dispatch: any) => {
    return { reloadConfig: () => dispatch(fetchConfig()) };
  }
)(FpsCommentConfiguration);
