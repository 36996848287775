import React, { useEffect, useState } from 'react';

import { ChartDataSource } from '@cvfm-front/tefps-types';
import Sidebar from 'commons/SidebarV2';
import {
  ControlChartCriteria,
  FpsChartCriteria,
  RapoChartCriteria,
  TicketChartCriteria,
} from 'Dashboard/fps/Graphics/types';
import { defaultTicketFilters } from 'Dashboard/fps/Graphics/Utils';
import { fetchZoning } from 'api/pricing';
import { FETCH_LIGHT_ZONING_CONFIG } from 'commons/FetchZoningConfigs';
import { buildZoneSelect, Zone } from 'commons/Utils/zoneUtils';
import ControlFiltersBar from 'Dashboard/fps/Graphics/ChartFiltersBar/ControlFiltersBar';
import FpsFiltersBar from 'Dashboard/fps/Graphics/ChartFiltersBar/FpsFiltersBar';
import RapoFiltersBar from 'Dashboard/fps/Graphics/ChartFiltersBar/RapoFiltersBar';

import ChartFilterService from '../service';

import TicketFiltersBar from './TicketFiltersBar';

type Props = {
  totalHits?: number;
};

function ChartFiltersBar({ totalHits }: Props): JSX.Element {
  const [zoneOptions, setZoneOptions] = useState<Array<Zone>>([]);
  const [filters, setFilters] = useState<
    | TicketChartCriteria
    | ControlChartCriteria
    | FpsChartCriteria
    | RapoChartCriteria
  >(defaultTicketFilters);
  useEffect(() => ChartFilterService.watchFilters(setFilters), []);

  function resetFilters() {
    ChartFilterService.resetFilters();
  }

  async function fetchZonesAndBuildOptions() {
    try {
      const zoning = await fetchZoning(FETCH_LIGHT_ZONING_CONFIG);
      setZoneOptions(buildZoneSelect(zoning.zones));
    } catch (e) {
      setZoneOptions([]);
    }
  }

  useEffect(() => {
    void fetchZonesAndBuildOptions();
  }, []);

  if (ChartFilterService.getDataSource() === ChartDataSource.TICKET) {
    return (
      <TicketFiltersBar
        totalHits={totalHits}
        filters={filters as TicketChartCriteria}
        zoneOptions={zoneOptions}
        resetFilters={resetFilters}
      />
    );
  }
  if (ChartFilterService.getDataSource() === ChartDataSource.CONTROL) {
    return (
      <ControlFiltersBar
        totalHits={totalHits}
        filters={filters as ControlChartCriteria}
        zoneOptions={zoneOptions}
        resetFilters={resetFilters}
      />
    );
  }
  if (ChartFilterService.getDataSource() === ChartDataSource.FPS) {
    return (
      <FpsFiltersBar
        totalHits={totalHits}
        filters={filters as FpsChartCriteria}
        zoneOptions={zoneOptions}
        resetFilters={resetFilters}
      />
    );
  }
  if (ChartFilterService.getDataSource() === ChartDataSource.RAPO) {
    return (
      <RapoFiltersBar
        totalHits={totalHits}
        filters={filters as RapoChartCriteria}
        resetFilters={resetFilters}
      />
    );
  }
  return <Sidebar />;
}

export default ChartFiltersBar;
