import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Zone } from 'commons/Utils/zoneUtils';
import {
  ExemptionReason,
  getConfigState,
  InitialOrganization,
} from 'config/duck';
import { AgentIdAndNameDTO } from '@cvfm-front/tefps-types';
import { fetchMatchingAgents } from 'api/accounts';
import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Times from 'commons/SidebarV2/Components/Times';
import Input from 'commons/SidebarV2/Components/Input';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';
import Select from 'commons/SidebarV2/Components/Select';
import {
  translateDeviceTypeFilterOptions,
  translateFPSFromControlFilterOptions,
  translateLAPIStatesFilterOptions,
  translateMediasOptions,
  translateQualityControlStatesFilterOptions,
  translateStatusesFilterOptions,
} from 'tefps/Control/List/utils';
import Dates from 'commons/SidebarV2/Components/Dates';
import { ControlChartCriteria } from 'Dashboard/fps/Graphics/types';

import ChartFilterService from '../service';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  totalHits?: number;
  filters: ControlChartCriteria;
  zoneOptions: Array<Zone>;
  resetFilters: () => void;
  exemptionReasonsConfigurations: Array<ExemptionReason>;
  initialOrganizationsConfigurations: Array<InitialOrganization>;
};

function ControlFiltersBar({
  totalHits,
  filters,
  zoneOptions,
  resetFilters,
  exemptionReasonsConfigurations,
  initialOrganizationsConfigurations,
}: Props): JSX.Element {
  const [agentSearchOptions, setAgentSearchOptions] = useState<
    Array<{ id: string; name: string }>
  >([]);
  const [reasonsOptions, setReasonsOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);

  function onChange(id: string, value: unknown) {
    ChartFilterService.setFilters((prevFilters: ControlChartCriteria) => {
      return { ...prevFilters, [id]: value };
    });
  }

  function buidExemptionReasons(
    reasons: Array<ExemptionReason> | null | undefined
  ) {
    if (!reasons) {
      return;
    }
    setReasonsOptions(
      reasons.map(reason => ({
        value: reason.key,
        label: reason.label,
      }))
    );
  }

  async function fetchAgentsAndGenerateOptions(
    agentFilter: string | null | undefined
  ) {
    let agents: Array<AgentIdAndNameDTO> = [];
    if (agentFilter && agentFilter.length >= 3) {
      agents = (await fetchMatchingAgents(agentFilter)).matchingAgents;
    }

    setAgentSearchOptions(
      agents.map(({ id, fullName }) => ({
        id,
        name: fullName,
      }))
    );
  }

  useEffect(() => {
    void fetchAgentsAndGenerateOptions(null);
    buidExemptionReasons(exemptionReasonsConfigurations);
  }, []);

  return (
    <Sidebar>
      <BarTitle resetFilters={resetFilters} />
      <HitsCounter hits={totalHits || 0} />
      <Dates
        id="statementDatetime"
        title={_tg('field.date.date_plural')}
        dates={filters.statementDatetime}
        onChange={onChange}
      />
      <br />
      <Times
        id="times"
        title={_t('element.times')}
        times={filters.times}
        onChange={onChange}
      />
      <br />
      <Input
        id="controlId"
        title={_tg('field.control.controlId')}
        placeholder={_t('element.search.byControlId')}
        onChange={onChange}
        value={filters.controlId || ''}
      />
      <Input
        id="fineId"
        title={_tg('field.control.fpsId')}
        placeholder={_t('element.search.byFpsId')}
        onChange={onChange}
        value={filters.fineId || ''}
      />
      <Input
        id="plate"
        title={_tg('field.vehicle.licensePlate')}
        placeholder={_t('element.search.byPlate')}
        onChange={onChange}
        value={filters.plate || ''}
      />
      <Input
        id="terminalId"
        title={_t('element.terminalId')}
        placeholder={_t('element.search.byTer')}
        onChange={onChange}
        value={filters.terminalId || ''}
      />
      <br />
      <Checkboxes
        id="controlStatuses"
        title={_t('element.controlStatus')}
        options={translateStatusesFilterOptions()}
        filters={filters.controlStatuses}
        onChange={onChange}
      />
      <Checkboxes
        id="mediaStatuses"
        title={_t('element.medias')}
        options={translateMediasOptions()}
        filters={filters.mediaStatuses}
        onChange={onChange}
      />
      <Checkboxes
        id="esControlDeviceTypes"
        title={_t('element.controlEmittedBy')}
        options={translateDeviceTypeFilterOptions(undefined, true)}
        filters={filters.esControlDeviceTypes}
        onChange={onChange}
      />
      <Checkboxes
        id="esLAPIControlStates"
        title={_t('element.esLAPIControlState')}
        options={translateLAPIStatesFilterOptions()}
        filters={filters.esLAPIControlStates}
        onChange={onChange}
      />
      <Checkboxes
        id="qualityControlComparativeStates"
        title={_t('element.qualityControlComparativeStates')}
        options={translateQualityControlStatesFilterOptions()}
        filters={filters.qualityControlComparativeStates}
        onChange={onChange}
      />
      <Checkboxes
        id="fpsCreationStatuses"
        title={_t('element.ledToFps')}
        options={translateFPSFromControlFilterOptions()}
        filters={filters.fpsCreationStatuses}
        onChange={onChange}
      />
      {reasonsOptions && reasonsOptions.length > 0 && (
        <Checkboxes
          id="exemptionReasons"
          title={_tg('field.control.exemptionReason')}
          options={reasonsOptions}
          filters={filters.exemptionReasons}
          onChange={onChange}
        />
      )}
      <Autocomplete
        id="agentId"
        title={_tg('field.agent.idOrName')}
        options={agentSearchOptions}
        onChange={onChange}
        onAutocomplete={fetchAgentsAndGenerateOptions}
        search={filters.agentId}
      />
      {initialOrganizationsConfigurations &&
        initialOrganizationsConfigurations.length > 0 && (
          <Select
            id="organizationIds"
            title={_t('element.organisation')}
            onChange={onChange}
            selected={[...filters.organizationIds] || undefined}
            multiple
            options={[...initialOrganizationsConfigurations]}
          />
        )}
      <Select
        id="zoneId"
        title={_tg('field.address.zone')}
        onChange={onChange}
        selected={filters.zoneId}
        options={zoneOptions}
      />
    </Sidebar>
  );
}

export default connect(state => {
  const {
    exemptionReasonsConfigurations,
    initialOrganizationsConfigurations,
  } = getConfigState(state);
  return {
    exemptionReasonsConfigurations,
    initialOrganizationsConfigurations,
  };
})(ControlFiltersBar);
