import React from 'react';

import TextFieldCustom from 'commons/FormComponent/Fields/TextFieldCustom';
import { validateEmail } from 'commons/Validators';
import { STYLE_CONTENT_WRAPPER } from 'theme';
import AddressAutocomplete from 'commons/AddressAutocomplete';
import { fieldKeyAddress } from 'commons/types/fieldKeyAddress';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  field: string;
  styleInputs: Record<string, any>;
  isEmail: boolean;
};

function AddContactForm({ field, styleInputs, isEmail }: Props): JSX.Element {
  return !isEmail ? (
    <AddressAutocomplete
      field={field}
      fieldKeys={fieldKeyAddress}
      mandatoryAddress
      styleInputs={styleInputs}
      illegibleOption
      isForeignAddressAllow
    />
  ) : (
    <div>
      <div style={STYLE_CONTENT_WRAPPER}>
        <TextFieldCustom
          name={`${field}.email`}
          hint={_tg('field.human.email')}
          validators={[validateEmail]}
          mandatory
          style={{ ...styleInputs, width: '45%' }}
          underlineFocusStyle={styleInputs}
          floatingLabelFocusStyle={styleInputs}
        />
      </div>
    </div>
  );
}

export default AddContactForm;
