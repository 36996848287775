import React, { useEffect, useState } from 'react';
import TextField from 'material-ui/TextField';

import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { BKG_PINK } from 'theme';
import { KpiConfigurationDTO } from '@cvfm-front/tefps-types';
import Content from 'commons/Content';
import { patchKpiConfiguration } from 'api/kpi';
import { Flex } from '@cvfm-front/commons-ui';

import './kpi.css';

import BoButton from 'facade/BoButton';
import {
  DropDownArrow,
  METRICS_LIFETIME,
  STYLE_OBS_TEXT,
} from 'tefps/Observatory/helpers';
import Select from 'commons/SidebarV2/Components/Select';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  reloadConfig: () => void;
  kpiConfiguration: KpiConfigurationDTO;
  disabled: boolean;
  openSnackBar: (message: string) => void;
};

const buildMap = (
  yr: number,
  workingDays: Record<string, number | undefined>
): Record<string, number | undefined> => {
  const updatedRecord = { ...workingDays };

  const existingMonths = Object.keys(workingDays).filter(key =>
    key.startsWith(`${yr}-`)
  );
  if (existingMonths.length === 12) {
    return workingDays;
  }

  for (let i = 1; i <= 12; i += 1) {
    const month = i.toString().padStart(2, '0');
    const key = `${yr}-${month}`;

    if (!(key in updatedRecord)) {
      updatedRecord[key] = undefined;
    }
  }

  return updatedRecord;
};

const WorkingDays = ({
  disabled,
  kpiConfiguration,
  reloadConfig,
  openSnackBar,
}: Props): JSX.Element => {
  const [year, setYear] = useState<number>(new Date().getFullYear());
  const [workingDays, setWorkingDays] = useState<
    Record<string, number | undefined>
  >(buildMap(year, kpiConfiguration.workingDays || {}));

  const patch = async (): Promise<void> => {
    try {
      await patchKpiConfiguration({
        op: 'replace',
        path: '/kpiWorkingDaysConfig',
        value: workingDays,
      });
      openSnackBar(_tg('tefps.configuration.kpiConfiguration.success'));
    } catch (e) {
      openSnackBar(_tg('tefps.configuration.kpiConfiguration.error'));
    }
  };

  const onSave = () => {
    void patch();
  };

  const reset = () => {
    reloadConfig();
  };

  const handleInputChange = (monthKey: string, value: string) => {
    setWorkingDays(prevRecord => ({
      ...prevRecord,
      [monthKey]: value ? parseFloat(value) : 0,
    }));
  };

  const onChangeYear = (id: string, yearValue: string) => {
    const castedYear = parseInt(yearValue, 10);
    setYear(castedYear);
    setWorkingDays(buildMap(castedYear, workingDays));
  };

  const getMonthName = (dateStr: string): string => {
    const date = new Date(`${dateStr}-01`);
    const month = parseInt(dateStr.split('-')[1], 10) - 1;
    date.setMonth(month);
    return date.toLocaleDateString(window.i18next.language, {
      month: 'long',
    });
  };

  const isCurrentPeriod = () => {
    return year === new Date().getFullYear();
  };

  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    let yearCounter = currentYear - METRICS_LIFETIME;
    while (yearCounter <= currentYear) {
      years.push({ key: `${yearCounter}`, label: `${yearCounter}` });
      yearCounter += 1;
    }
    return years.sort((a, b) => parseInt(b.key, 10) - parseInt(a.key, 10));
  };

  const isEditable = (dateStr: string): boolean => {
    const MONTH_EDITION_CONSTRAINT = 1;
    const parts = dateStr.split('-');
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth();
    const monthDifference =
      (currentYear - parseInt(parts[0], 10)) * 12 +
      (currentMonth - parseInt(parts[1], 10));
    return monthDifference < MONTH_EDITION_CONSTRAINT;
  };

  return (
    <Content>
      <SeparatorWithTitle
        className="kpi_title"
        title={_tg(
          'tefps.configuration.kpiConfiguration.supersetDashboardConfig.workingDays'
        )}
        color={BKG_PINK}
        titleSize={20}
      />
      <Flex>
        <Select
          id="year"
          onChange={onChangeYear}
          selected={`${year}`}
          textStyle={STYLE_OBS_TEXT}
          options={getYearOptions()}
          underlineStyle={{ display: 'none' }}
          optionStyle={{ color: 'black' }}
          iconStyle={{ marginTop: -5 }}
          dropDownMenuProps={{
            iconButton: <DropDownArrow selected={isCurrentPeriod()} />,
          }}
        />
      </Flex>
      <div className="working_days_config_table">
        {workingDays &&
          Object.entries(workingDays)
            .filter(([key]) => key.includes(`${year}`))
            .sort(([a], [b]) => a.localeCompare(b))
            .map(([monthKey, monthValue]) => (
              <Flex>
                <TextField
                  floatingLabelText={getMonthName(monthKey)}
                  style={{ width: 300, textTransform: 'capitalize' }}
                  type="number"
                  id={monthKey}
                  floatingLabelFixed
                  value={monthValue || ''}
                  min={0}
                  onChange={(e, v) => handleInputChange(monthKey, v)}
                  disabled={!isEditable(monthKey) || disabled}
                />
              </Flex>
            ))}
      </div>
      <Flex flexDirection="row-reverse" gap={10}>
        <BoButton
          primary
          disabled={disabled}
          label={_tg('action.save_2')}
          onClick={onSave}
        />
        <BoButton
          disabled={disabled}
          label={_tg('action.cancel')}
          onClick={reset}
        />
      </Flex>
    </Content>
  );
};

export default WorkingDays;
