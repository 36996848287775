import React, { CSSProperties } from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import { connect } from 'react-redux';

import Content from 'commons/Content';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import AdvertisingModal from 'commons/AdvertisingModal';
import SimpleTable from 'commons/SimpleTable';
import { TXT_BLACK } from 'theme';

import { fetchLapiReviewStat } from '../../../api/lapiReview';
import { LapiReviewStatDTO } from '../../../api/lapiReview/types';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  flexWrap: 'wrap',
  color: TXT_BLACK,
  margin: '25px auto 40px',
  fontSize: 20,
  textAlign: 'center',
};
const LapiReviewStats = () => {
  const [lapiReviewStats, setLapiReviewStats] = React.useState<
    LapiReviewStatDTO
  >();
  const [error, setError] = React.useState<Error>();

  const getLapiReviewStats = async () => {
    try {
      const stats = await fetchLapiReviewStat();
      setLapiReviewStats(stats);
    } catch (fetchError) {
      setError(fetchError as Error);
    }
  };

  React.useEffect(() => void getLapiReviewStats(), []);

  const tableCols = [
    {
      label: _tg(
        'tefps.dashboard.lapiReviewStats.totalNumberOfChecksInTheReviewsForTheCity'
      ),
      width: 120,
    },
    {
      label: _tg('tefps.dashboard.lapiReviewStats.numberOfLockedControls'),
      width: 120,
    },
    {
      label: _tg('tefps.dashboard.lapiReviewStats.numberOfControlsProcessed'),
      width: 120,
    },
    {
      label: _tg('tefps.dashboard.lapiReviewStats.numbeOfProcessableControls'),
      width: 120,
    },
  ];
  const itemsRenderer = (line: LapiReviewStatDTO) => {
    return [
      <span>{line.numberControls}</span>,
      <span>{line.numberLockedControls || 0}</span>,
      <span>{line.numberProcessedControls || 0}</span>,
      <span>{line.numberProcessableControls || 0}</span>,
    ];
  };
  if (error) {
    return (
      <Content>
        <FlexCenter>
          <ErrorBlock
            message={_tg(
              'tefps.dashboard.lapiReviewReport.errorWhileFetchingData'
            )}
            error={error}
          />
        </FlexCenter>
      </Content>
    );
  }

  return (
    <Content>
      <AdvertisingModal module="control" />
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{ margin: '2em auto', maxWidth: '90%' }}>
          <span style={STYLE_TITLE}>
            {_tg('tefps.dashboard.lapiReviewStats.title')}
          </span>
          {!lapiReviewStats ? (
            <FlexCenter>
              <CircularProgress />
            </FlexCenter>
          ) : (
            <div style={{ margin: 'auto', width: '930px' }}>
              <SimpleTable
                maxHeight={500}
                cols={tableCols}
                rowHeight={50}
                header
                itemsRenderer={itemsRenderer}
                items={new Array<LapiReviewStatDTO>(lapiReviewStats)}
              />
            </div>
          )}
        </div>
      </div>
    </Content>
  );
};
export default LapiReviewStats;
