import { DatasetConfig } from '../commons/types';

const { _tg } = window.loadTranslations(__filename);

const COLORS = [
  '#dd5269',
  '#3fb4c1',
  '#344c77',
  '#338b9b',
  '#969696',
  '#6ba0ff',
  '#B276B2',
  '#00d6a0',
  '#F15854',
];

export const CITY_REDUCED_CONFIG: () => DatasetConfig = () => ({
  label: _tg('tefps.dashboard.finance.antaiStats.configs.CITY_REDUCED_CONFIG'),
  stack: 'stacked',
  backgroundColor: COLORS[2],
  borderColor: COLORS[2],
  order: 1,
  field: 'CITY_REDUCED',
});

export const CITY_CONFIG: () => DatasetConfig = () => ({
  label: _tg('tefps.dashboard.finance.antaiStats.configs.CITY_CONFIG'),
  stack: 'stacked',
  backgroundColor: COLORS[3],
  borderColor: COLORS[3],
  order: 2,
  field: 'CITY',
});

export const ANTAI_CONFIG: () => DatasetConfig = () => ({
  label: _tg('tefps.dashboard.finance.antaiStats.configs.ANTAI_CONFIG'),
  stack: 'stacked',
  backgroundColor: COLORS[1],
  borderColor: COLORS[1],
  order: 3,
  field: 'ANTAI',
});

export const ANTAI_ENFORCEABLE_CONFIG: () => DatasetConfig = () => ({
  label: _tg(
    'tefps.dashboard.finance.antaiStats.configs.ANTAI_ENFORCEABLE_CONFIG'
  ),
  stack: 'stacked',
  backgroundColor: COLORS[0],
  borderColor: COLORS[0],
  order: 4,
  field: 'ANTAI_ENFORCEABLE',
});

export const ABANDONNED_CONFIG: () => DatasetConfig = () => ({
  label: _tg('tefps.dashboard.finance.antaiStats.configs.ABANDONNED_CONFIG'),
  stack: 'abandonned',
  backgroundColor: COLORS[4],
  borderColor: COLORS[4],
  order: 5,
  field: 'ABANDONNED',
});

// Monthly amounts, graphe "Sommes en cours de récupération"
export const CITY_SUM_REDUCED_CONFIG: () => DatasetConfig = () => ({
  label: _tg(
    'tefps.dashboard.finance.antaiStats.configs.CITY_SUM_REDUCED_CONFIG'
  ),
  stack: 'stacked',
  backgroundColor: COLORS[2],
  borderColor: COLORS[2],
  order: 1,
  field: 'CITY_REDUCED',
});

export const CITY_SUM_CONFIG: () => DatasetConfig = () => ({
  label: _tg('tefps.dashboard.finance.antaiStats.configs.CITY_SUM_CONFIG'),
  stack: 'stacked',
  backgroundColor: COLORS[3],
  borderColor: COLORS[3],
  order: 2,
  field: 'CITY',
});

export const ANTAI_SUM_CONFIG: () => DatasetConfig = () => ({
  label: _tg('tefps.dashboard.finance.antaiStats.configs.ANTAI_SUM_CONFIG'),
  stack: 'stacked',
  backgroundColor: COLORS[1],
  borderColor: COLORS[1],
  order: 3,
  field: 'ANTAI',
});

export const ANTAI_SUM_ENFORCEABLE_CONFIG: () => DatasetConfig = () => ({
  label: _tg(
    'tefps.dashboard.finance.antaiStats.configs.ANTAI_SUM_ENFORCEABLE_CONFIG'
  ),
  stack: 'stacked',
  backgroundColor: COLORS[0],
  borderColor: COLORS[0],
  order: 4,
  field: 'ANTAI_ENFORCEABLE',
});

export const MAIL_SENT_CONFIG: () => DatasetConfig = () => ({
  label: _tg('tefps.dashboard.finance.antaiStats.configs.MAIL_SENT_CONFIG'),
  stack: 'mails',
  backgroundColor: COLORS[0],
  borderColor: COLORS[0],
  order: 1,
  field: 'APA',
});

export const MAIL_CORRECTION_CONFIG: () => DatasetConfig = () => ({
  label: _tg('tefps.dashboard.finance.antaiStats.configs.MAIL_CORRECTION'),
  stack: 'mails',
  backgroundColor: COLORS[7],
  borderColor: COLORS[7],
  order: 2,
  field: 'APA_CORRECTION',
});

export const E_MAIL_SENT_CONFIG: () => DatasetConfig = () => ({
  label: _tg('tefps.dashboard.finance.antaiStats.configs.E_MAIL_SENT_CONFIG'),
  stack: 'mails',
  backgroundColor: COLORS[1],
  borderColor: COLORS[1],
  order: 3,
  field: 'E_APA',
});

export const RECEIPT_SENT_CONFIG: () => DatasetConfig = () => ({
  label: _tg('tefps.dashboard.finance.antaiStats.configs.RECEIPT_SENT_CONFIG'),
  stack: 'mails',
  backgroundColor: COLORS[2],
  borderColor: COLORS[2],
  order: 4,
  field: 'RECEIPT',
});

// Sommes perçues
export const PAID_CONFIG: () => DatasetConfig = () => ({
  label: _tg('tefps.dashboard.finance.antaiStats.configs.PAID_CONFIG'),
  stack: 'stacked',
  backgroundColor: 'rgba(75, 192, 192, 0.2)',
  borderColor: 'rgba(75, 192, 192, 1)',
  order: 1,
  field: 'PAID_PERCENT',
});

export const NOT_PAID_CONFIG: () => DatasetConfig = () => ({
  label: _tg('tefps.dashboard.finance.antaiStats.configs.NOT_PAID_CONFIG'),
  stack: 'stacked',
  backgroundColor: 'rgba(255, 99, 132, 0.2)',
  borderColor: 'rgba(255, 99, 132, 1)',
  order: 2,
  field: 'NOT_PAID',
});
