import React, { CSSProperties, useContext, useState } from 'react';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { addComment } from 'api/refund';
import { getActions } from 'commons/ModalUtils';
import { BKG_CYAN } from 'theme';
import { ControlAgent } from 'api/commonTypes';
import { RefundComment, RefundDetailDTO } from 'api/refund/types';

import { RefreshContext, RefreshContextType } from './Context';

const { _tg } = window.loadTranslations(__filename);

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_CYAN,
  color: BKG_CYAN,
};

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_CYAN,
  color: '#ffffff',
  fontWeight: 'bold',
};

const STYLE_CONTENT_STATUS: CSSProperties = {
  marginTop: '20px',
};

const STATUS_OPTIONS = () => ({
  REFUND_PENDING: _tg('tefps.dashboard.refund.refundToDo'),
  REFUND_IN_PROGRESS: _tg('tefps.dashboard.refund.refundInProgess'),
  NO_RESPONSE: _tg('tefps.dashboard.refund.noResponse'),
  RIB_MISSING: _tg('tefps.dashboard.refund.askForRIB'),
});

type Props = {
  refund: RefundDetailDTO;
  refundId: string;
  isOpen: boolean;
  setIsOpen: (arg0: (arg: boolean) => boolean) => void;
};

export default function StatusModal({
  refund,
  refundId,
  isOpen,
  setIsOpen,
}: Props): JSX.Element {
  const [status, setStatus] = useState(refund.fpsRefundStatus);
  const [comment, setComment] = useState('');
  const [error, setError] = useState<string | null | undefined>(null);

  const { onUpdate } = useContext(RefreshContext) as RefreshContextType;

  function handleCloseStatus() {
    setStatus(refund.fpsRefundStatus);
    setIsOpen(() => false);
  }

  function handleChange(
    event: React.ChangeEvent<any>,
    index: number,
    value: any
  ) {
    if (value != null) {
      setStatus(value);
    } else {
      setStatus(refund.fpsRefundStatus);
    }
  }

  function handleWriting(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target) {
      setComment(event.target.value);
      if (event.target.value.trim() === '') {
        setError(_tg('tefps.dashboard.refund.needToFillComment'));
      } else {
        setError(null);
      }
    }
  }

  const submitStatus = async () => {
    if (comment === '') {
      setError(_tg('tefps.dashboard.refund.needToFillComment'));
    } else {
      const agent: ControlAgent = {
        agentId: '',
        name: '',
        worksFor: undefined,
      };
      const refundcomment: RefundComment = {
        status,
        date: undefined,
        content: comment,
        agent,
      };
      try {
        await addComment(refundcomment, refundId);
        onUpdate(true, _tg('tefps.dashboard.refund.commentValid'));
        setComment('');
        setIsOpen(() => false);
      } catch (err) {
        onUpdate(false, (err as Error).message);
      }
    }
  };

  const actionsStatus = getActions(submitStatus, handleCloseStatus);
  const statusOnChange = Object.entries(
    STATUS_OPTIONS()
  ).map(([key, value]) => (
    <MenuItem key={key} value={key} primaryText={value} />
  ));

  return (
    <Dialog
      open={isOpen}
      actions={actionsStatus}
      title={_tg('tefps.dashboard.refund.selectNewStatus')}
      titleStyle={STYLE_TITLE}
    >
      <div style={STYLE_CONTENT_STATUS}>
        <SelectField
          key="status update"
          value={status}
          floatingLabelText={_tg(
            'tefps.dashboard.refund.changeStatusAlternate'
          )}
          onChange={handleChange}
        >
          {statusOnChange}
        </SelectField>
      </div>
      <div>
        <TextField
          errorText={error}
          underlineFocusStyle={STYLE_INPUTS}
          floatingLabelFocusStyle={STYLE_INPUTS}
          value={comment}
          onChange={handleWriting}
          floatingLabelText={_tg('field.comment')}
          fullWidth
          multiLine
          rows={3}
          rowsMax={3}
        />
      </div>
    </Dialog>
  );
}
