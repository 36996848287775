import React, { CSSProperties } from 'react';
import RadioButton from 'material-ui/RadioButton';

import BoButton from 'facade/BoButton';
import Item from 'commons/Item';
import Date from 'commons/Date';
import { STYLE_FAKE_INPUT, STYLE_ITEM, TXT_BLACK, TXT_RED } from 'theme';

import ContactSeparator from './ContactSeparator';

const { _tg } = window.loadTranslations(__filename);

const STYLE_ITEM_NAME: CSSProperties = {
  width: 220,
  margin: 2.5,
};

const STYLE_CONTAINER: CSSProperties = {
  marginLeft: '10%',
  marginRight: '10%',
};

const STYLE_RADIO_BUTTON: CSSProperties = {
  width: 300,
};

const STYLE_BUTTON: CSSProperties = {
  width: 250,
};

const STYLE_SMALL_BUTTON: CSSProperties = {
  width: 150,
};

const STYLE_ROW: CSSProperties = {
  paddingLeft:
    (STYLE_ITEM_NAME.margin as number) +
    (STYLE_ITEM.padding as number) +
    (STYLE_FAKE_INPUT.marginLeft as number),
  paddingRight: STYLE_ITEM_NAME.margin,
  paddingTop: 20,
  paddingBottom: STYLE_ITEM.padding,
  display: 'flex',
  flexDirection: 'row-reverse',
  justifyContent: 'space-between',
  alignContent: 'center',
};

type Props = {
  value: string;
  name: string;
  label: string;
  sentDatetime: string;
  category: string;
  sendNow: () => Promise<void>;
  deleteContact: () => Promise<void>;
  setMainContact?: () => Promise<void>;
  isMainContact?: boolean;
  illegibleAddress?: boolean;
  validAddress?: boolean;
};

function ContactItem({
  value,
  name,
  label,
  sentDatetime,
  category,
  sendNow,
  deleteContact,
  setMainContact,
  isMainContact,
  illegibleAddress,
  validAddress,
}: Props): JSX.Element {
  return (
    <div style={STYLE_CONTAINER}>
      <Item name={category} value={value} styleName={STYLE_ITEM_NAME} />
      <Item
        name={name}
        value={
          sentDatetime !== null ? (
            <Date datetime={sentDatetime} />
          ) : (
            _tg('tefps.dashboard.refund.noMailSent')
          )
        }
        styleName={STYLE_ITEM_NAME}
      />
      <div style={STYLE_ROW}>
        <BoButton
          label={label}
          primary
          onClick={sendNow}
          style={STYLE_BUTTON}
          disabled={illegibleAddress || validAddress}
        />
        {illegibleAddress ? (
          <div style={{ color: TXT_RED }}>
            {_tg('feedback.error.illegibleAddress')}
          </div>
        ) : (
          setMainContact && (
            <RadioButton
              style={STYLE_RADIO_BUTTON}
              label={
                isMainContact
                  ? _tg('tefps.dashboard.refund.principalAddress')
                  : _tg('tefps.dashboard.refund.definePrincipalAddress')
              }
              checked={isMainContact}
              onCheck={setMainContact}
              labelStyle={{ color: TXT_BLACK }}
              iconStyle={{ fill: TXT_BLACK }}
            />
          )
        )}
        <BoButton
          label={_tg('action.delete')}
          secondary
          onClick={deleteContact}
          style={STYLE_SMALL_BUTTON}
        />
      </div>
      <ContactSeparator />
    </div>
  );
}

export default ContactItem;
