import React, { CSSProperties, useEffect, useState } from 'react';
import TextField from 'material-ui/TextField';
import FlatButton from 'material-ui/FlatButton';
import Dialog from 'material-ui/Dialog';
import DeleteIcon from 'material-ui/svg-icons/action/delete';

import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { BKG_CYAN, BKG_LIGHT_BLUE, BKG_PINK } from 'theme';
import { KpiOrganizationThreshold } from '@cvfm-front/tefps-types';
import { Flex } from '@cvfm-front/commons-ui';
import { isOrgIdValid } from 'Administration/Organizations/AddOrg/validators';
import './kpi.css';

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_LIGHT_BLUE,
  color: '#ffffff',
  fontWeight: 'bold',
};

const { _tg } = window.loadTranslations(__filename);

type Props = {
  kpiOrganizationThreshold: KpiOrganizationThreshold | undefined;
  isEditing: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSave: (conf: KpiOrganizationThreshold) => Promise<void>;
  disabled?: boolean;
};

type BoroughGoal = {
  borough: string;
  goal: number;
};

const KpiThresholdConfigModal = ({
  kpiOrganizationThreshold,
  isEditing,
  isOpen,
  onClose,
  onSave,
  disabled,
}: Props): JSX.Element => {
  if (!kpiOrganizationThreshold) {
    return <></>;
  }

  const [kpiThresholdConfig, setKpiThresholdConfig] = useState<
    KpiOrganizationThreshold
  >(kpiOrganizationThreshold);
  const [boroughGoals, setBoroughGoals] = useState<Map<number, BoroughGoal>>(
    new Map()
  );
  const [errors, setErrors] = useState<{
    [key: string]: string | {} | null | undefined;
  }>({});

  useEffect(() => {
    const boroughGoals = new Map<number, BoroughGoal>();
    let index = 0;
    Object.entries(
      kpiOrganizationThreshold.kpiThresholds.minRatioOfDailyGoalPerBorough2C
    ).forEach(([key, value]) => {
      boroughGoals.set(index, { borough: key, goal: value });
      index += 1;
    });
    setBoroughGoals(boroughGoals);
    setKpiThresholdConfig(kpiOrganizationThreshold);
  }, [kpiOrganizationThreshold]);

  // Field validattors
  const validate1CInput = (array: number[]): void => {
    const newErrors = { ...errors };
    newErrors.levels1C = null;
    for (let i = 1; i < array.length; i += 1) {
      if (array[i] < array[i - 1]) {
        newErrors.levels1C = _tg('kpiConfiguration.errors.levels');
        break;
      }
    }
    setErrors(newErrors);
  };

  // Field change Handlers
  const onChange = (
    event: React.SyntheticEvent<HTMLInputElement>,
    newValue: string
  ): void => {
    const { field } = event.currentTarget.dataset;
    if (field) {
      const config = {
        ...kpiThresholdConfig,
      } as KpiOrganizationThreshold;
      if (event.currentTarget.type === 'number') {
        config.kpiThresholds[field] = Number.parseFloat(newValue);
      } else {
        config.kpiThresholds[field] = newValue;
      }
      setKpiThresholdConfig(config);
    }
  };

  const onChange1CLevel = (
    event: React.SyntheticEvent<HTMLInputElement>,
    newValue: string
  ): void => {
    const { field } = event.currentTarget.dataset;
    if (field) {
      const config = {
        ...kpiThresholdConfig,
      } as KpiOrganizationThreshold;
      const index = Number.parseFloat(field.split('-')[1]);
      const value = Number.parseFloat(newValue);
      config.kpiThresholds.scanDuplicationLevels1C[index] = value;
      validate1CInput(config.kpiThresholds.scanDuplicationLevels1C);
      setKpiThresholdConfig(config);
    }
  };

  const onChangeOrganization = (
    event: React.SyntheticEvent<HTMLInputElement>,
    newValue: string
  ): void => {
    const { field } = event.currentTarget.dataset;
    const newErrors = { ...errors };
    newErrors.organizationId = isOrgIdValid(newValue);
    setErrors(newErrors);

    if (field) {
      const config: KpiOrganizationThreshold = {
        ...kpiThresholdConfig,
      };
      config[field] = newValue;
      setKpiThresholdConfig(config);
    }
  };

  const addBoroughGoal = () => {
    const goals = new Map(boroughGoals);
    goals.set(boroughGoals.size + 1, { borough: '', goal: 0 });
    setBoroughGoals(goals);
  };

  const removeBoroughGoal = (index: number) => {
    if (disabled) {
      return;
    }
    const newBoroughGoals = new Map(boroughGoals);
    newBoroughGoals.delete(index);
    setBoroughGoals(newBoroughGoals);
  };

  const onChangeGoalPerBorough = (
    event: React.SyntheticEvent<HTMLInputElement>,
    newValue: string
  ): void => {
    const { field } = event.currentTarget.dataset;
    if (field) {
      const fieldTokens = field.split('-');
      const dataId = Number(fieldTokens[0]);
      const newBoroughGoals = new Map(boroughGoals);
      const value = newBoroughGoals.get(dataId);
      if (value) {
        if (fieldTokens[1] === 'key') {
          value.borough = newValue;
          newBoroughGoals.set(dataId, value);
        } else if (fieldTokens[1] === 'value') {
          value.goal = Number.parseFloat(newValue);
        }
        newBoroughGoals.set(dataId, value);
        setBoroughGoals(newBoroughGoals);
      }
    }
  };

  // Data Patching
  const patchKpiThresholdVersion = async (): Promise<void> => {
    const newKpiThresholdConfig = { ...kpiThresholdConfig };
    const resultMap = {};
    boroughGoals.forEach((boroughGoal, index) => {
      resultMap[boroughGoal.borough] = boroughGoal.goal;
    });
    newKpiThresholdConfig.kpiThresholds.minRatioOfDailyGoalPerBorough2C = resultMap;

    const newErrors = { ...errors };
    newErrors.organizationId = isOrgIdValid(
      newKpiThresholdConfig.organizationId
    );
    setErrors(newErrors);
    if (!newErrors.organizationId) {
      await onSave(newKpiThresholdConfig);
    }
  };

  return (
    <Dialog
      title={_tg('tefps.configuration.kpiConfiguration.editKpiConfig')}
      open={isOpen}
      titleStyle={STYLE_TITLE}
      modal
      autoScrollBodyContent
      actions={[
        <FlatButton label={_tg('action.cancel')} onClick={onClose} />,
        <FlatButton
          label={_tg('action.save_2')}
          primary
          onClick={patchKpiThresholdVersion}
          disabled={disabled}
        />,
      ]}
    >
      <div className="kpi_config_table">
        <Flex flexDirection="column" gap={10}>
          <Flex flexDirection="row" flexWrap="wrap" gap={10}>
            <TextField
              floatingLabelText={_tg('field.organisation.id')}
              data-field="organizationId"
              floatingLabelFixed
              onChange={onChangeOrganization}
              value={kpiThresholdConfig?.organizationId}
              disabled={isEditing}
              errorText={errors?.organizationId}
              required
            />
          </Flex>
          <SeparatorWithTitle
            className="kpi_config_separator"
            title={_tg('commons.common')}
            color={BKG_PINK}
            titleSize={20}
          />
          <Flex flexDirection="row" flexWrap="wrap" gap={10}>
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.lapiDailyGoal'
              )}
              style={{ width: 300 }}
              data-field="lapiDailyGoal"
              type="number"
              floatingLabelFixed
              value={kpiThresholdConfig?.kpiThresholds.lapiDailyGoal}
              min={0}
              onChange={onChange}
              disabled={disabled}
            />
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.agentMotorcycleDailyGoal'
              )}
              style={{ width: 300 }}
              data-field="agentMotorcycleDailyGoal"
              type="number"
              floatingLabelFixed
              value={kpiThresholdConfig?.kpiThresholds.agentMotorcycleDailyGoal}
              min={0}
              onChange={onChange}
              disabled={disabled}
            />
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.agentCMIDailyGoal'
              )}
              style={{ width: 300 }}
              data-field="agentCMIDailyGoal"
              type="number"
              floatingLabelFixed
              value={kpiThresholdConfig?.kpiThresholds.agentCMIDailyGoal}
              min={0}
              onChange={onChange}
              disabled={disabled}
            />
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.controlStartTime'
              )}
              style={{ width: 300 }}
              data-field="controlStartTime"
              type="number"
              floatingLabelFixed
              value={kpiThresholdConfig?.kpiThresholds.controlStartTime}
              onChange={onChange}
              disabled={disabled}
            />
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.controlEndTime'
              )}
              style={{ width: 300 }}
              data-field="controlEndTime"
              type="number"
              floatingLabelFixed
              value={kpiThresholdConfig?.kpiThresholds.controlEndTime}
              onChange={onChange}
              disabled={disabled}
            />
          </Flex>
          <SeparatorWithTitle
            className="kpi_config_separator"
            title="1A"
            color={BKG_PINK}
            titleSize={20}
          />
          <Flex flexDirection="row" gap={10}>
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.maxDailyRatio1A'
              )}
              floatingLabelFixed
              data-field="maxDailyRatio1A"
              value={kpiThresholdConfig?.kpiThresholds.maxDailyRatio1A}
              min={0}
              type="number"
              onChange={onChange}
              disabled={disabled}
            />
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.minDailyRatio1A'
              )}
              floatingLabelFixed
              data-field="minDailyRatio1A"
              value={kpiThresholdConfig?.kpiThresholds.minDailyRatio1A}
              min={0}
              type="number"
              onChange={onChange}
              disabled={disabled}
            />
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.minQuarterlyRatio1AT'
              )}
              floatingLabelFixed
              data-field="minQuarterlyRatio1AT"
              min={0}
              value={kpiThresholdConfig?.kpiThresholds.minQuarterlyRatio1AT}
              type="number"
              onChange={onChange}
              disabled={disabled}
            />
          </Flex>

          <SeparatorWithTitle
            className="kpi_config_separator"
            title="1B"
            color={BKG_PINK}
            titleSize={20}
          />
          <Flex flexDirection="row" gap={10}>
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.minHourlyRatioOfDailyGoal1B'
              )}
              floatingLabelFixed
              data-field="minHourlyRatioOfDailyGoal1B"
              min={0}
              value={
                kpiThresholdConfig?.kpiThresholds.minHourlyRatioOfDailyGoal1B
              }
              style={{ width: 350 }}
              type="number"
              onChange={onChange}
              disabled={disabled}
            />
          </Flex>

          <SeparatorWithTitle
            className="kpi_config_separator"
            title="1C"
            color={BKG_PINK}
            titleSize={20}
          />
          <Flex flexDirection="row" gap={10}>
            {kpiThresholdConfig?.kpiThresholds.scanDuplicationLevels1C.map(
              (level, index) => {
                return (
                  <TextField
                    floatingLabelText={_tg(
                      'tefps.configuration.kpiConfiguration.kpiFields.level',
                      { level: index }
                    )}
                    floatingLabelFixed
                    data-field={`level-${index}`}
                    value={level}
                    type="number"
                    min={0}
                    onChange={onChange1CLevel}
                    disabled={disabled}
                  />
                );
              }
            )}
          </Flex>
          {errors.levels1C && (
            <span className="kpi_field_error">{errors.levels1C}</span>
          )}
          <SeparatorWithTitle
            className="kpi_config_separator"
            title="1D"
            color={BKG_PINK}
            titleSize={20}
          />
          <Flex flexDirection="row" flexWrap="wrap" gap={10}>
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.ratioOfSmallStreetToExclude1D'
              )}
              style={{ width: 300 }}
              floatingLabelFixed
              data-field="ratioOfSmallStreetToExclude1D"
              value={
                kpiThresholdConfig?.kpiThresholds.ratioOfSmallStreetToExclude1D
              }
              min={0}
              type="number"
              onChange={onChange}
              disabled={disabled}
            />
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.weeklyStreetGoalThreshold1D'
              )}
              style={{ width: 300 }}
              floatingLabelFixed
              data-field="weeklyStreetGoalThreshold1D"
              value={
                kpiThresholdConfig?.kpiThresholds.weeklyStreetGoalThreshold1D
              }
              type="number"
              max={1}
              min={7}
              step={1}
              onChange={onChange}
              disabled={disabled}
            />
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.controlThresholdGoalPerDay1D'
              )}
              style={{ width: 300 }}
              floatingLabelFixed
              data-field="controlThresholdGoalPerDay1D"
              value={
                kpiThresholdConfig?.kpiThresholds.controlThresholdGoalPerDay1D
              }
              type="number"
              max={1}
              min={0}
              onChange={onChange}
              disabled={disabled}
            />
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.thresholdGoalFor1D'
              )}
              style={{ width: 300 }}
              floatingLabelFixed
              data-field="thresholdGoalFor1D"
              value={kpiThresholdConfig?.kpiThresholds.thresholdGoalFor1D}
              type="number"
              max={1}
              min={0}
              onChange={onChange}
              disabled={disabled}
            />
          </Flex>

          <SeparatorWithTitle
            className="kpi_config_separator"
            title="2A"
            color={BKG_PINK}
            titleSize={20}
          />
          <Flex flexDirection="row" gap={10}>
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.minDailyRatio2A'
              )}
              floatingLabelFixed
              data-field="minDailyRatio2A"
              value={kpiThresholdConfig?.kpiThresholds.minDailyRatio2A}
              min={0}
              type="number"
              onChange={onChange}
              disabled={disabled}
            />
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.maxDailyRatio2A'
              )}
              floatingLabelFixed
              data-field="maxDailyRatio2A"
              value={kpiThresholdConfig?.kpiThresholds.maxDailyRatio2A}
              min={0}
              type="number"
              onChange={onChange}
              disabled={disabled}
            />
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.minQuarterlyRatioFor2AT'
              )}
              floatingLabelFixed
              data-field="minQuarterlyRatioFor2AT"
              value={kpiThresholdConfig?.kpiThresholds.minQuarterlyRatioFor2AT}
              min={0}
              type="number"
              onChange={onChange}
              disabled={disabled}
            />
          </Flex>
          <SeparatorWithTitle
            className="kpi_config_separator"
            title="2B"
            color={BKG_PINK}
            titleSize={20}
          />
          <Flex flexDirection="row" gap={10}>
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.minHourlyRatioOfDailyGoal2B'
              )}
              style={{ width: 350 }}
              floatingLabelFixed
              data-field="minHourlyRatioOfDailyGoal2B"
              value={
                kpiThresholdConfig?.kpiThresholds.minHourlyRatioOfDailyGoal2B
              }
              min={0}
              type="number"
              onChange={onChange}
              disabled={disabled}
            />
          </Flex>
          <SeparatorWithTitle
            className="kpi_config_separator"
            title="2C"
            color={BKG_PINK}
            titleSize={20}
          />
          <Flex flexDirection="column" gap={10}>
            {[...boroughGoals].map(([index, value]) => {
              return (
                <Flex
                  key={index}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <TextField
                    floatingLabelText={_tg(
                      'tefps.configuration.kpiConfiguration.kpiFields.borough'
                    )}
                    floatingLabelFixed
                    data-field={`${index}-key`}
                    value={value.borough}
                    onChange={onChangeGoalPerBorough}
                    disabled={disabled}
                  />
                  <TextField
                    floatingLabelText={_tg(
                      'tefps.configuration.kpiConfiguration.kpiFields.goal'
                    )}
                    floatingLabelFixed
                    data-field={`${index}-value`}
                    value={value.goal}
                    type="number"
                    min={0}
                    onChange={onChangeGoalPerBorough}
                    disabled={disabled}
                  />
                  <DeleteIcon
                    style={{
                      cursor: 'pointer',
                      marginRight: 10,
                    }}
                    color={BKG_CYAN}
                    hoverColor={BKG_PINK}
                    onClick={() => removeBoroughGoal(index)}
                    data-id={index}
                  />
                </Flex>
              );
            })}
            <FlatButton
              label={_tg('action.add')}
              primary
              disabled={disabled}
              onClick={addBoroughGoal}
            />
          </Flex>
          <SeparatorWithTitle
            className="kpi_config_separator"
            title="2D"
            color={BKG_PINK}
            titleSize={20}
          />
          <Flex flexDirection="row" gap={10}>
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.maxDailyRatio2D'
              )}
              floatingLabelFixed
              data-field="maxDailyRatio2D"
              value={kpiThresholdConfig?.kpiThresholds.maxDailyRatio2D}
              min={0}
              type="number"
              onChange={onChange}
              disabled={disabled}
            />
          </Flex>
          <SeparatorWithTitle
            className="kpi_config_separator"
            title="3A"
            color={BKG_PINK}
            titleSize={20}
          />
          <Flex flexDirection="row" gap={10}>
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.minDailyRatio3A'
              )}
              floatingLabelFixed
              data-field="minDailyRatio3A"
              value={kpiThresholdConfig?.kpiThresholds.minDailyRatio3A}
              min={0}
              type="number"
              onChange={onChange}
              disabled={disabled}
            />
          </Flex>
          <SeparatorWithTitle
            className="kpi_config_separator"
            title="3B"
            color={BKG_PINK}
            titleSize={20}
          />
          <Flex flexDirection="row" gap={10}>
            <TextField
              floatingLabelText={_tg(
                'tefps.configuration.kpiConfiguration.kpiFields.minDailyRatio3B'
              )}
              floatingLabelFixed
              data-field="minDailyRatio3B"
              value={kpiThresholdConfig?.kpiThresholds.minDailyRatio3B}
              min={0}
              type="number"
              onChange={onChange}
              disabled={disabled}
            />
          </Flex>
        </Flex>
      </div>
    </Dialog>
  );
};

export default KpiThresholdConfigModal;
