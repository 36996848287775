import { apiGet, apiPost } from 'api/helpers';
import { LapiSurveyDTO } from '@cvfm-front/tefps-types';

const BASE_URL = '/api/prv/city/{cityId}/lapi/survey';

export async function listLapiSurveys(): Promise<Array<LapiSurveyDTO>> {
  return apiGet<Array<LapiSurveyDTO>>(`${BASE_URL}/list`);
}

export async function createLapiSurvey(
  lapiSurvey: LapiSurveyDTO
): Promise<Array<LapiSurveyDTO>> {
  return apiPost<Array<LapiSurveyDTO>>(`${BASE_URL}/create`, lapiSurvey);
}
