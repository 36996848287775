import FileSaver from 'file-saver';
import * as React from 'react';

import BoButton from 'facade/BoButton';
import Exporter from 'commons/Exporter';
import { MifStatsDTO } from 'api/tepv/stats/types';
import { exportGraph } from 'api/tepv/stats';

import ChartCustom from './Charts/ChartCustom';
import {
  EXPORT_COLUMNS,
  monthNameMapper,
  sortByCount,
  sortByMonth,
} from './utils';

type Props = {
  stats: MifStatsDTO;
  exportStats: () => void | null | undefined;
};

class ChartContainerSingle extends React.Component<Props> {
  mifByMonthRef = React.createRef<ChartCustom>();
  mifByCodeRef = React.createRef<ChartCustom>();
  mifByCategoryRef = React.createRef<ChartCustom>();
  mifByGroupRef = React.createRef<ChartCustom>();
  mifByPresetNoteRef = React.createRef<ChartCustom>();
  mifByVehicleCountryRef = React.createRef<ChartCustom>();
  mifByVehicleCategoryRef = React.createRef<ChartCustom>();

  getTotal = () => this.props.stats.mifCount;

  exportGraphs = async () => {
    const refs = [
      this.mifByMonthRef,
      this.mifByCodeRef,
      this.mifByCategoryRef,
      this.mifByGroupRef,
      this.mifByPresetNoteRef,
      this.mifByVehicleCountryRef,
      this.mifByVehicleCategoryRef,
    ];

    const exportData = refs.reduce(
      (acc: any, ref: React.RefObject<ChartCustom>) => {
        if (ref.current) {
          return acc.concat(ref.current.getExportData());
        }
        return acc;
      },
      []
    );

    const file = await exportGraph(exportData);
    const blobFile = await file.blob();
    FileSaver.saveAs(blobFile, 'export.pdf');
  };

  render() {
    const { exportStats, stats } = this.props;
    const sortedMifByMonth = sortByMonth(stats.mifsBySignatureDate);
    const sortedMifByPlateCountry = sortByCount(stats.mifsByPlateCountry);
    const sortedMifByVehicleCategory = sortByCount(stats.mifsByVehicleCategory);

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        }}
      >
        <ChartCustom
          ref={this.mifByMonthRef}
          acceptedTypes={['bar']}
          graphsData={[
            {
              title: 'MIF par mois',
              stats: sortedMifByMonth,
            },
          ]}
          mapper={monthNameMapper}
          graphLabel="byMonth"
        />
        <ChartCustom
          ref={this.mifByCodeRef}
          acceptedTypes={['pie', 'bar']}
          graphsData={[
            {
              title: 'MIF par code NATINF',
              stats: stats.mifsByCode,
            },
          ]}
          graphLabel="byCodeNatinf"
        />
        <ChartCustom
          ref={this.mifByCategoryRef}
          acceptedTypes={['pie', 'bar']}
          graphsData={[
            {
              title: 'MIF par catégorie NATINF',
              stats: stats.mifsByCategory,
            },
          ]}
          graphLabel="byNatinfCategory"
        />
        <ChartCustom
          ref={this.mifByGroupRef}
          acceptedTypes={['bar']}
          graphsData={[
            {
              title: 'MIF par groupe de NATINF',
              stats: stats.mifsByGroup,
            },
          ]}
          total={[this.getTotal]}
          graphLabel="byNatinfGroup"
        />
        <ChartCustom
          ref={this.mifByPresetNoteRef}
          acceptedTypes={['pie', 'bar']}
          graphsData={[
            {
              title: 'MIF par renseignement prédéfini',
              stats: stats.mifsByPresetNote,
            },
          ]}
          graphLabel="byPresetNotes"
        />
        <ChartCustom
          ref={this.mifByVehicleCountryRef}
          acceptedTypes={['pie', 'bar']}
          graphsData={[
            {
              title: "MIF par pays d'immatriculation",
              stats: sortedMifByPlateCountry,
            },
          ]}
          graphLabel="byPlateCountry"
        />
        <ChartCustom
          ref={this.mifByVehicleCategoryRef}
          acceptedTypes={['pie', 'bar']}
          graphsData={[
            {
              title: 'MIF par catégorie de véhicule',
              stats: sortedMifByVehicleCategory,
            },
          ]}
          graphLabel="byVehicleCategory"
        />
        {exportStats && (
          <div style={{ padding: '20px 35px', display: 'flex' }}>
            <Exporter
              disabled={false}
              columns={EXPORT_COLUMNS}
              type="Stats"
              filters={{}}
              fileExport={exportStats}
            />
            <BoButton
              style={{ margin: '0 15px' }}
              label="Export tous graphiques"
              onClick={this.exportGraphs}
              primary
            />
          </div>
        )}
      </div>
    );
  }
}

export default ChartContainerSingle;
