import React, { CSSProperties } from 'react';

import { PaymentOverviewDTO } from '@cvfm-front/tefps-types';
import SimpleTable from 'commons/SimpleTable';
import Price from 'commons/Price';
import Date from 'commons/Date';
import { translatePaymentMode } from 'commons/Utils/paymentUtil';

const { _tg } = window.loadTranslations(__filename);

const STYLE_LINK: CSSProperties = {
  height: 50,
  width: '100%',
};

const STYLE_HOVERED: CSSProperties = {
  ...STYLE_LINK,
  color: 'white',
};

type Props = {
  items: Array<PaymentOverviewDTO>;
  loadMoreRows?: (arg0: { startIndex: number }) => Promise<void>;
  remoteRowCount?: number;
  onUpdateSort?: (sortFieldColNumber: number, increasingOrder: boolean) => void;
  colSorted?: number;
  sortOrder?: boolean;
};

type State = {
  hovered: string | null | undefined;
};

const computeLink = (payment: PaymentOverviewDTO): string => {
  if (payment.resourceType === 'SUBSCRIPTION') {
    return `#/beneficiaries/subscriptions/${payment.subscriberId}/${payment.resourceId}`;
  }
  if (payment.resourceType === 'PARKING_RIGHT_REQUEST') {
    return `#/beneficiaries/parkingRightRequests/${payment.subscriberId}/${payment.resourceId}`;
  }
  if (payment.resourceType === 'BUNDLE') {
    return `#/subscription/bundle/order/${payment.resourceId}`;
  }
  if (payment.resourceType === 'ELIGIBILITY') {
    return `#/subscription/eligibility/eligibilities/${payment.resourceId}`;
  }
  return '';
};

const getStyle = (id: string, hovered: string | null | undefined) => {
  return id === hovered ? STYLE_HOVERED : STYLE_LINK;
};

class PaymentTable extends React.Component<Props, State> {
  tableCols: Array<{
    label?: string;
    width: number;
    grow?: number;
    style?: CSSProperties;
    headerStyle?: CSSProperties;
    onSort?: boolean;
  }> = [
    { label: _tg('tefps.dashboard.payment.resourceId'), width: 300 },
    {
      label: _tg('tefps.dashboard.payment.resourceType'),
      width: 100,
    },
    { label: _tg('tefps.dashboard.payment.columns.operationId'), width: 270 },
    {
      label: _tg('tefps.dashboard.payment.columns.operationDate'),
      width: 150,
      onSort: true,
    },
    {
      label: _tg('tefps.dashboard.payment.columns.paymentAmount'),
      width: 100,
      style: { textAlign: 'center' },
    },
    {
      label: _tg('field.payment.mode'),
      width: 130,
      grow: 1,
      onSort: true,
    },
    {
      label: _tg('tefps.dashboard.payment.columns.agentName'),
      width: 150,
      grow: 1,
      onSort: true,
    },
  ];

  RESOURCE_TYPE_LABELS = {
    SUBSCRIPTION: _tg('field.subscription.subscription'),
    PARKING_RIGHT_REQUEST: _tg('tefps.ticket'),
    BUNDLE: _tg('field.rights.bundle'),
    ELIGIBILITY: _tg('field.rights.right'),
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      hovered: null,
    };
  }

  handleMouseLeave = (): void => this.setState({ hovered: null });
  handleMouseEnter = ({ currentTarget }: React.MouseEvent<HTMLElement>): void =>
    this.setState({ hovered: currentTarget.dataset.paymentId });

  render(): React.ReactNode {
    const { items } = this.props;
    const { hovered } = this.state;
    const {
      loadMoreRows,
      remoteRowCount,
      colSorted,
      sortOrder,
      onUpdateSort,
    } = this.props;

    return (
      <SimpleTable
        style={{ marginTop: 25 }}
        cols={this.tableCols}
        rowHeight={50}
        onSort={onUpdateSort}
        loadMoreRows={loadMoreRows}
        remoteRowCount={remoteRowCount}
        colSorted={colSorted}
        sortOrder={sortOrder}
        items={items}
        itemsRenderer={(payment: PaymentOverviewDTO) => [
          <a
            style={getStyle(payment.paymentId, hovered)}
            data-payment-id={payment.paymentId}
            onMouseLeave={this.handleMouseLeave}
            onMouseEnter={this.handleMouseEnter}
            href={computeLink(payment)}
          >
            {payment.resourceId}
          </a>,
          <span>{this.RESOURCE_TYPE_LABELS[payment.resourceType]}</span>,
          <span>{payment.paymentId}</span>,
          <Date datetime={payment.date} />,
          <Price price={payment.amount} />,
          <span>{translatePaymentMode()[payment.paymentMode]}</span>,
          <span>{payment.agentName}</span>,
        ]}
      />
    );
  }
}

export default PaymentTable;
