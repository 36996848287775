import React, { useContext, useEffect, useState } from 'react';

import { PdfExportContext } from 'tefps/PdfExport/PdfExportContext';

type Props = {
  children: JSX.Element;
};

const ContextInitializer = ({ children }: Props): JSX.Element => {
  const [isReady, setIsReady] = useState(false);
  const pdfExportService = useContext(PdfExportContext);
  // Other service from context here

  useEffect(() => {
    (async () => {
      await pdfExportService.init();
      // Other service init here
      setIsReady(true);
    })();
  }, []);

  if (!isReady) {
    return <></>;
  }

  return children;
};

export default ContextInitializer;
