import React, { useState } from 'react';
import EditIcon from 'material-ui/svg-icons/image/edit';
import DoneIcon from 'material-ui/svg-icons/action/done';
import TextField from 'material-ui/TextField';
import CloseIcon from 'material-ui/svg-icons/navigation/close';

import BoButton from 'facade/BoButton';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { validateFrenchIban } from 'commons/Validators';
import Item from 'commons/Item';
import { BKG_PINK, STYLE_SEPARATOR, TXT_RED } from 'theme';
import { putBankDetailRefund } from 'api/refund';

const { _tg } = window.loadTranslations(__filename);

type BankDetail = {
  name?: string;
  surname?: string;
  iban: string;
  bic: string;
};

type Props = {
  refundId: string;
  detail: BankDetail;
  messageDisplay: (message: string) => void;
  onUpdate: (shouldReload: boolean, message: string) => void;
};

function BankDetailBlock({
  refundId,
  detail,
  messageDisplay,
  onUpdate,
}: Props): JSX.Element {
  const [editBank, setEditBank] = useState<boolean>(false);
  const [bankDetails, setBankDetails] = useState<BankDetail>(detail);
  const [prevDetails, setPrevDetails] = useState<BankDetail>(bankDetails);

  async function toggleEdit() {
    if (editBank) {
      try {
        if (
          bankDetails.name !== prevDetails.name ||
          bankDetails.surname !== prevDetails.surname ||
          bankDetails.iban !== prevDetails.iban ||
          bankDetails.bic !== prevDetails.bic
        ) {
          await putBankDetailRefund(refundId, bankDetails);
          setPrevDetails(bankDetails);
          onUpdate(true, _tg('tefps.dashboard.refund.ribSaved'));
        }
      } catch (e) {
        const error = e as Error;
        messageDisplay(`${_tg('feedback.error.generic')} : ${error.message}`);
        setBankDetails(prevDetails);
      }
    }
    setEditBank(!editBank);
  }

  function handleCancel() {
    setBankDetails(prevDetails);
    setEditBank(false);
  }

  function handleIbanChange(event: React.ChangeEvent<HTMLInputElement>) {
    setBankDetails({ ...bankDetails, iban: event.target.value });
  }

  function handleBicChange(event: React.ChangeEvent<HTMLInputElement>) {
    setBankDetails({ ...bankDetails, bic: event.target.value });
  }

  function handleNameChange(
    event: React.ChangeEvent<HTMLInputElement>,
    name: string
  ) {
    setBankDetails({ ...bankDetails, name });
  }

  function handleSurnameChange(
    event: React.ChangeEvent<HTMLInputElement>,
    surname: string
  ) {
    setBankDetails({ ...bankDetails, surname });
  }

  const ibanWarning = validateFrenchIban(bankDetails.iban);

  let { iban } = bankDetails;
  if (!editBank && iban) {
    const splittedIban = iban.match(/.{1,4}/g);
    iban = (splittedIban && splittedIban.join(' ')) || '';
  }

  const TEXTFIELD_STYLE: React.CSSProperties = {
    fontSize: '30px',
    width: '100%',
  };

  return (
    <div>
      <SeparatorWithTitle
        style={STYLE_SEPARATOR}
        title={_tg('tefps.dashboard.refund.rib')}
        color={BKG_PINK}
        titleSize={20}
      />
      <div style={{ padding: 32, display: 'flex', flexDirection: 'column' }}>
        <Item
          style={{ alignItems: 'center' }}
          styleName={{ boxSizing: 'border-box', padding: 8, width: 64 }}
          name={_tg('field.human.firstname')}
          value={
            <TextField
              id="NAME"
              value={bankDetails.name}
              onChange={handleNameChange}
              underlineShow={false}
              disabled={!editBank}
              style={TEXTFIELD_STYLE}
            />
          }
        />
        <Item
          style={{ alignItems: 'center' }}
          styleName={{ boxSizing: 'border-box', padding: 18, width: 64 }}
          name={_tg('field.human.lastname_short')}
          value={
            <TextField
              id="SURNAME"
              value={bankDetails.surname}
              onChange={handleSurnameChange}
              underlineShow={false}
              disabled={!editBank}
              style={TEXTFIELD_STYLE}
            />
          }
        />
        <Item
          style={{ alignItems: 'center' }}
          styleName={{ boxSizing: 'border-box', padding: 16, width: 64 }}
          name={_tg('field.payment.iban')}
          value={
            <TextField
              id="IBAN"
              value={iban}
              onChange={handleIbanChange}
              underlineShow={false}
              fullWidth
              disabled={!editBank}
              style={TEXTFIELD_STYLE}
            />
          }
        />
        {ibanWarning && (
          <span style={{ color: TXT_RED, marginLeft: 80 }}>{ibanWarning}</span>
        )}
        <Item
          style={{ alignItems: 'center' }}
          styleName={{ boxSizing: 'border-box', padding: 16, width: 64 }}
          name={_tg('field.payment.bic')}
          value={
            <TextField
              id="BIC"
              value={bankDetails.bic}
              onChange={handleBicChange}
              underlineShow={false}
              fullWidth
              disabled={!editBank}
              style={TEXTFIELD_STYLE}
            />
          }
        />
      </div>

      <div style={{ display: 'flex', justifyContent: 'space-around' }}>
        {!editBank && (
          <BoButton
            label={_tg('action.edit')}
            labelPosition="before"
            primary
            icon={<EditIcon />}
            onClick={toggleEdit}
          />
        )}
        {editBank && [
          <BoButton
            label={_tg('action.save_2')}
            labelPosition="before"
            primary
            icon={<DoneIcon />}
            onClick={toggleEdit}
          />,
          <BoButton
            label={_tg('action.cancel')}
            labelPosition="before"
            secondary
            icon={<CloseIcon />}
            onClick={handleCancel}
          />,
        ]}
      </div>
    </div>
  );
}

export default BankDetailBlock;
