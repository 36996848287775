import querystring from 'querystring';
import IntlPolyfill from 'intl';
import DatePicker from 'material-ui/DatePicker';
import moment from 'moment';
import * as React from 'react';
import { RefObject } from 'react';

import BoButton from 'facade/BoButton';
import { openNewAuthentifiedTab } from 'api/helpers';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  getCashVoucherUrl: () => string;
};

class CashVoucher extends React.Component<Props> {
  cashVoucherRef: RefObject<DatePicker> = React.createRef();

  onChangeVoucherDate = (e: unknown, date: Date): void => {
    const { getCashVoucherUrl } = this.props;
    const url = getCashVoucherUrl();
    const queryParams = querystring.stringify({
      selectedDate: moment(date).toISOString(),
    });
    openNewAuthentifiedTab(`${url}?${queryParams}`);
  };

  openDialog = (): void => {
    if (this.cashVoucherRef.current) {
      this.cashVoucherRef.current.openDialog();
    }
  };

  render(): React.ReactNode {
    return (
      <div style={{ marginLeft: 20 }}>
        <BoButton
          label={_tg('tefps.dashboard.payment.cashVoucher')}
          primary
          onClick={this.openDialog}
        />
        <DatePicker
          autoOk
          DateTimeFormat={IntlPolyfill.DateTimeFormat}
          ref={this.cashVoucherRef}
          mode="portrait"
          locale="fr"
          container="inline"
          cancelLabel={_tg('action.cancel')}
          onChange={this.onChangeVoucherDate}
          textFieldStyle={{ display: 'none' }}
        />
      </div>
    );
  }
}

export default CashVoucher;
