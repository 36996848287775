import Dialog from 'material-ui/Dialog';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BoButton from 'facade/BoButton';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import { patchFps } from 'api/fps/';
import { fetchFpsRecourses } from 'api/recourse';
import { getRefund } from 'api/refund';
import { RefundDetailDTO } from 'api/refund/types';
import { RefundPayment } from 'api/commonTypes';
import { getConfigState } from 'config/duck';
import Item from 'commons/Item';
import FpsNumber from 'commons/FpsNumber';
import { buildControlAgent } from 'commons/Utils/agentUtil';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { BKG_PINK, STYLE_MODAL_TITLE, STYLE_SEPARATOR } from 'theme';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { STATUSES_FILTER_OPTIONS } from 'tefps/Fps/List/utils';
import Date from 'commons/Date';
import { formatCtsPrice } from 'commons/Price';
import { EsRecourseStatus } from 'api/recourse/types';

import RefundInfoModal from '../RefundInfoModal';

import ContactBlock from './ContactSection';
import DocumentBlock from './DocumentBlock';
import StatusModal from './StatusModal';
import CommentBlock from './CommentBlock';
import BankDetailBlock from './BankDetailBlock';
import { FPS_REFUND_REASON_LABEL, STATUS_OPTIONS } from './utils';
import { RefreshContext } from './Context';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  refundId: string;
  onClose: () => void;
  userInfo: InternalAgent;
  organizationFilterEnabled: boolean;
};

function EditModal({
  refundId,
  onClose,
  userInfo,
  organizationFilterEnabled,
}: Props) {
  const [refund, setRefund] = useState<RefundDetailDTO | undefined>(undefined);
  const [isOpen, setIsOpen] = useState(false);
  const [openRefundModal, setOpenRefundModal] = useState<boolean>(false);
  const [lastRapoId, setLastRapoId] = useState<string | undefined>(undefined);
  const [lastRecourseStatus, setLastRecourseStatus] = useState<
    EsRecourseStatus | null | undefined
  >();
  // L'appel a useSnackbar se fait ici car chaque modale a son propre contexte
  // On fait en sorte de conserver le contexte parent
  const setMessage = useSnackbar() as (msg: string) => void;

  const history = useHistory();

  const STYLE_ITEM_NAME_PERSO = {
    width: 170,
  };

  const STYLE_ITEM_PERSO = {
    paddingTop: '10px',
  };

  async function fetchLastRapoId(rootFpsId: string) {
    const rapoDTO = await fetchFpsRecourses(rootFpsId);
    setLastRecourseStatus(
      rapoDTO.recourses.length > 0
        ? rapoDTO.recourses[rapoDTO.recourses.length - 1].status
        : undefined
    );
    setLastRapoId(
      rapoDTO.recourses.length > 0
        ? rapoDTO.recourses[rapoDTO.recourses.length - 1].rapoId
        : undefined
    );
  }

  async function fetchRefund() {
    try {
      const foundRefund = await getRefund(refundId);
      void fetchLastRapoId(foundRefund.rootFpsId);
      setRefund(foundRefund);
    } catch (e) {
      setMessage((e as Error).message);
    }
  }

  useEffect(() => {
    void fetchRefund();
  }, [refundId]);

  function onUpdate(shouldReload: boolean, message: string) {
    setMessage(message);
    if (shouldReload) {
      void fetchRefund(); // possible race condition
    }
  }

  async function sendRefundRequest(
    refundPayment: RefundPayment,
    price: number,
    rootFpsId: string
  ) {
    const body = [
      {
        op: 'add',
        path: '/payments/-',
        value: {
          ...refundPayment,
          paymentAmount: -price,
          agent: buildControlAgent(userInfo),
        },
      },
    ];
    try {
      await patchFps(rootFpsId, body);
      setMessage(
        _tg('tefps.dashboard.refund.refundDone', {
          price: formatCtsPrice(price),
        })
      );
      setOpenRefundModal(false);
      void fetchRefund();
    } catch (err) {
      setMessage((err as Error).message);
    }
  }

  function executeRefund(refundPayment: RefundPayment, price?: number) {
    if (price && refund) {
      void sendRefundRequest(refundPayment, price, refund.rootFpsId);
    }
  }

  if (!refund) {
    return <div />;
  }

  const actions = [
    <BoButton
      style={{ marginRight: 10 }}
      key={1}
      label={_tg('action.back')}
      onClick={onClose}
    />,
    <BoButton
      style={{ marginRight: 10 }}
      key={2}
      primary
      label={_tg('tefps.dashboard.refund.doARefund')}
      onClick={() => setOpenRefundModal(true)}
      disabled={refund.fpsRefundStatus === 'REFUND_DONE'}
    />,
  ];

  const fpsState = STATUSES_FILTER_OPTIONS().find(
    state => state.value === refund.state
  ) || {
    value: undefined,
    label: undefined,
  };

  /* Need to add a decomposed Item to make the status button aligned with the
   * other components
   */
  return (
    <Dialog
      open={refund !== null}
      actions={actions}
      contentStyle={{ width: '50%', maxWidth: 'none' }}
      bodyStyle={{ overflowY: 'auto' }}
      title={_tg('tefps.dashboard.refund.refundDetails')}
      titleStyle={STYLE_MODAL_TITLE}
    >
      <RefreshContext.Provider value={{ onUpdate }}>
        <div
          style={{
            display: 'flex',
            paddingTop: '10px',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Item
            name={_tg('tefps.dashboard.payment.columns.fpsNumber')}
            value={<FpsNumber fpsId={refund.rootFpsId} />}
            style={{ display: 'flex' }}
            styleName={STYLE_ITEM_NAME_PERSO}
          />
          <div style={{ flex: '5 5 auto' }} />
          <div style={{ display: 'flex', padding: '5px' }}>
            <BoButton
              label={_tg('tefps.dashboard.refund.changeStatus')}
              primary
              onClick={() => {
                setIsOpen(true);
              }}
              disabled={refund.fpsRefundStatus === 'REFUND_DONE'}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Item
            name={_tg('tefps.filters.fps.fpsStatus.label')}
            value={fpsState.label}
            styleName={STYLE_ITEM_NAME_PERSO}
            style={STYLE_ITEM_PERSO}
          />
          <Item
            name={_tg('tefps.dashboard.payment.statementDatetimeSimple')}
            value={<Date datetime={refund.statementDatetime} />}
            styleName={STYLE_ITEM_NAME_PERSO}
            style={STYLE_ITEM_PERSO}
          />
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Item
            name={_tg('tefps.dashboard.refund.refundMotive')}
            value={FPS_REFUND_REASON_LABEL()[refund.fpsRefundReason]}
            styleName={STYLE_ITEM_NAME_PERSO}
            style={STYLE_ITEM_PERSO}
          />
          <Item
            name={_tg('tefps.dashboard.refund.lastPayment')}
            value={<Date datetime={refund.lastPaymentDate} />}
            styleName={STYLE_ITEM_NAME_PERSO}
            style={STYLE_ITEM_PERSO}
          />
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Item
            name={_tg('tefps.dashboard.refund.refundStatus')}
            value={STATUS_OPTIONS()[refund.fpsRefundStatus] || ''}
            styleName={STYLE_ITEM_NAME_PERSO}
            style={STYLE_ITEM_PERSO}
          />
          <Item
            name={_tg('field.date.lastModificationDateSimple')}
            value={<Date datetime={refund.lastModificationDate} />}
            styleName={STYLE_ITEM_NAME_PERSO}
            style={STYLE_ITEM_PERSO}
          />
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <Item
            name={_tg('tefps.dashboard.refund.refundPrice')}
            value={formatCtsPrice(refund.amount)}
            styleName={STYLE_ITEM_NAME_PERSO}
            style={STYLE_ITEM_PERSO}
          />
          <Item
            name={_tg('tefps.dashboard.refund.ribInputDate')}
            value={<Date datetime={refund.receivedDocumentDatetime} />}
            styleName={STYLE_ITEM_NAME_PERSO}
            style={STYLE_ITEM_PERSO}
          />
        </div>
        {organizationFilterEnabled && (
          <div style={{ display: 'flex' }}>
            <Item
              name={_tg('tefps.dashboard.payment.regie')}
              value={refund.regieName}
              styleName={STYLE_ITEM_NAME_PERSO}
              style={STYLE_ITEM_PERSO}
            />
            <Item
              name={_tg('tefps.filters.fps.initialOrganizationShortId')}
              value={refund.organizationName}
              styleName={STYLE_ITEM_NAME_PERSO}
              style={STYLE_ITEM_PERSO}
            />
          </div>
        )}
        <SeparatorWithTitle
          style={STYLE_SEPARATOR}
          title={_tg('field.comment_plural')}
          color={BKG_PINK}
          titleSize={20}
        />
        <CommentBlock refund={refund} statusOption={STATUS_OPTIONS()} />
        <ContactBlock refund={refund} history={history} />
        <BankDetailBlock
          refundId={refundId}
          detail={{
            name: refund.name,
            surname: refund.surname,
            iban: refund.iban,
            bic: refund.bic,
          }}
          messageDisplay={setMessage}
          onUpdate={onUpdate}
        />
        <DocumentBlock
          refund={refund}
          lastRapoId={lastRapoId}
          lastRecourseStatus={lastRecourseStatus}
          onUpdate={onUpdate}
        />
        {refundId && (
          <StatusModal
            refund={refund}
            refundId={refundId}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        )}
      </RefreshContext.Provider>
      <RefundInfoModal
        amount={refund.amount}
        isOpen={openRefundModal}
        onClose={() => setOpenRefundModal(false)}
        onSubmit={executeRefund}
      />
    </Dialog>
  );
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  const { organizationFilterEnabled } = getConfigState(state);
  return {
    userInfo,
    organizationFilterEnabled,
  };
})(EditModal);
