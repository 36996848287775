import React, { CSSProperties } from 'react';

import { DataBox, DataBoxContent } from 'commons/DataBox';
import FakeInputBlock from 'commons/FakeInputBlock';
import Date from 'commons/Date';
import Separator from 'commons/Separator';
import { RefundDetailDTO } from 'api/refund/types';
import { BKG_CYAN } from 'theme';

const { _tg } = window.loadTranslations(__filename);

const STYLE_CONTAINER: CSSProperties = {
  display: 'flex',
  flex: 1,
  justifyContent: 'space-around',
  width: '100%',
};

const STYLE_CONTAINER_COMMENT: CSSProperties = {
  justifyContent: 'left',
  width: '100%',
};

const CONTAINER_STYLE: CSSProperties = {
  width: 300,
};

const STYLE_HEADER: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  color: 'white',
  fontSize: 20,
  borderRadius: '5px',
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%) translateY(-50%)',
  height: 30,
  padding: '0px 50px',
  backgroundColor: BKG_CYAN,
};

const STYLE_TEXT: CSSProperties = {
  textAlign: 'center',
  marginTop: '20px',
  padding: '10px',
};

type Props = {
  refund: RefundDetailDTO;
  statusOption: {
    [key: string]: string;
  };
};

type BoxHeaderProps = {
  children?: React.ReactNode;
};

const DataBoxHeader = ({ children }: BoxHeaderProps) => {
  return <div style={STYLE_HEADER}>{children}</div>;
};

export default function CommentBlock({
  refund,
  statusOption,
}: Props): JSX.Element {
  if (refund.refundCommentList.length === 0)
    return (
      <div style={STYLE_TEXT}>{_tg('tefps.dashboard.refund.noComment')}</div>
    );
  return (
    <div>
      {refund.refundCommentList.map((obj, index) => (
        <div key={index} style={{ marginTop: '40px' }}>
          <DataBox>
            {refund.fpsRefundStatus && (
              <DataBoxHeader>{statusOption[obj.status]}</DataBoxHeader>
            )}
            <DataBoxContent>
              <div style={STYLE_CONTAINER}>
                <FakeInputBlock
                  containerStyle={CONTAINER_STYLE}
                  title={_tg('field.date.date')}
                  value={<Date datetime={obj.date} />}
                />
                <Separator />
                <FakeInputBlock
                  containerStyle={CONTAINER_STYLE}
                  title={_tg('field.agent.agent')}
                  value={obj.agent.name}
                />
              </div>
              <div style={STYLE_CONTAINER_COMMENT}>
                <FakeInputBlock
                  title={_tg('tefps.filters.rapo.agentComment.title')}
                  value={obj.content}
                  containerStyle={{ display: 'block' }}
                  inputStyle={{
                    maxWidth: 'auto',
                    width: 'calc(100% - 10px)',
                    minHeight: 50,
                    marginLeft: 0,
                    marginTop: 10,
                  }}
                  labelStyle={{ flex: 'none' }}
                />
              </div>
            </DataBoxContent>
          </DataBox>
        </div>
      ))}
    </div>
  );
}
