import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { GpvReportDTO } from 'api/tepv/stats/types';
import { FilterDate } from 'commons/SidebarV2/Components/Dates';
import GpvReport from 'Dashboard/pv/GpvReports/gpvReport';
import { getGpvOrganizationReport } from 'api/tepv/stats';

const GpvOrganizationReport = () => {
  const [report, setReport] = useState<GpvReportDTO>();
  const [period, setPeriod] = useState<FilterDate>({
    from: undefined,
    to: undefined,
  });
  const [error, setError] = useState<Error | null>(null);

  const loadReport = async () => {
    const filter = {
      from: period.from ? moment(period.from).toISOString() : null,
      to: period.to ? moment(period.to).toISOString() : null,
    };
    try {
      const fetchedReport = await getGpvOrganizationReport(filter);
      setError(null);
      setReport(fetchedReport);
    } catch (e) {
      setError(e);
    }
  };

  useEffect(() => {
    loadReport();
  }, []);

  useEffect(() => {
    loadReport();
  }, [period]);

  const onChangeDate = (newPeriod: FilterDate) => {
    setPeriod(newPeriod);
  };

  return (
    <GpvReport
      type="org"
      report={report}
      error={error}
      period={period}
      onChangeDate={onChangeDate}
    />
  );
};

export default GpvOrganizationReport;
