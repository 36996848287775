import moment from 'moment';

export const monthNameMapper = (month: string) =>
  moment(month, 'YYYY-MM').format('MMM YYYY');
const monthSorter = (monthA: string, monthB: string) =>
  moment(monthA, 'YYYY-MM').isAfter(moment(monthB, 'YYYY-MM')) ? 1 : -1;

export const COMPARISON_COLLAPSED = 25;
export const COMPARISON_EXTENDED = 90;
export const COMPARISON_PADDING = 10;

export const EXPORT_COLUMNS = [
  { label: 'Code NATINF', key: 'CODE_LABEL', checked: true },
  {
    label: 'Catégorie NATINF',
    key: 'CATEGORY_LABEL',
    checked: true,
  },
  { label: 'Renseignement prédéfini', key: 'PRESET_LABEL', checked: true },
  {
    label: 'Catégorie de véhicule',
    key: 'VEHICLE_CATEGORY_LABEL',
    checked: true,
  },
  {
    label: "Pays d'immatriculation",
    key: 'VEHICLE_COUNTRY_LABEL',
    checked: true,
  },
  {
    label: 'MIF par mois',
    key: 'MONTH_LABEL',
    checked: true,
  },
  { label: 'Groupes de NATINF', key: 'NATINF_GROUP_LABEL', checked: true },
];

export const sortByMonth = (stats: { [k: string]: number }) =>
  Object.keys(stats)
    .sort(monthSorter)
    .reduce((map, key) => {
      map[key] = stats[key];
      return map;
    }, {});

export const sortByCount = (stats: { [k: string]: number }) =>
  Object.keys(stats)
    .sort((keyA, keyB) => stats[keyA] - stats[keyB])
    .reduce((map, key) => {
      map[key] = stats[key];
      return map;
    }, {});
