import * as React from 'react';
import { connect } from 'react-redux';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import Dates, { FilterDate } from 'commons/SidebarV2/Components/Dates';
import Select from 'commons/SidebarV2/Components/Select';
import Times from 'commons/SidebarV2/Components/Times';
import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';
import { fetchMatchingAgents } from 'api/accounts';
import { fetchTeams } from 'api/cvfm-core-directory/team';
import { buildZoneSelect } from 'commons/Utils/zoneUtils';
import { getConfigState, InitialOrganization } from 'config/duck';
import { FilterTime } from 'tefps/Tickets/List/types';
import { AgentIdAndNameDTO, ZoningDTO } from '@cvfm-front/tefps-types';

import { getStatsAgentsState, initialFilters, setFilters } from './duck';
import { StatsAgentsSearchFilters } from './types';

const { _tg } = window.loadTranslations(__filename);

type FieldValueType =
  | FilterDate
  | FilterTime
  | Set<any>
  | string
  | string[]
  | null
  | undefined;

type State = {
  teamOptions: Array<{ value: string; label: string }>;
  agentSearchOptions: Array<{ id: string; name: string }>;
  zoneOptions: Array<{ key: string | undefined; label: string }>;
};

type Props = {
  filters: StatsAgentsSearchFilters;
  updateFilters: (newFilters: StatsAgentsSearchFilters) => void;
  initialOrganizationsConfigurations: Array<InitialOrganization>;
  zoning: ZoningDTO | null | undefined;
};

class StatsFilters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      teamOptions: [],
      agentSearchOptions: [],
      zoneOptions: [],
    };
  }

  componentDidMount() {
    const { zoning } = this.props;
    void this.fetchTeamsAndGenerateOptions();
    void this.fetchAgentsAndGenerateOptions(null);
    this.buildZoneOptions(zoning);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: Props) {
    this.buildZoneOptions(newProps.zoning);
  }

  onChange = (id: string, value: FieldValueType) => {
    const { filters, updateFilters } = this.props;
    updateFilters({
      ...filters,
      [id]: value === '' ? null : value,
    });
  };

  buildZoneOptions = (zoning: ZoningDTO | null | undefined) => {
    if (!zoning) return;
    this.setState({ zoneOptions: buildZoneSelect(zoning.zones) });
  };

  fetchAgentsAndGenerateOptions = async (
    agentFilter: string | null | undefined
  ) => {
    let agents: Array<AgentIdAndNameDTO> = [];
    if (agentFilter && agentFilter.length >= 3) {
      agents = (await fetchMatchingAgents(agentFilter)).matchingAgents;
    }

    const agentSearchOptions = agents.map(({ id, fullName }) => ({
      id,
      name: fullName,
    }));
    this.setState({ agentSearchOptions });
  };

  fetchTeamsAndGenerateOptions = async () => {
    try {
      const teamOptions = (await fetchTeams()).map(team => ({
        value: team.teamId,
        label: team.name,
      }));
      this.setState({ teamOptions });
    } catch (error) {
      /* we can't load teams, maybe core-planner is not started?
       * In any cases, we should resume and display no teams */
    }
  };

  resetFilters = () => {
    const { updateFilters } = this.props;
    updateFilters({ ...initialFilters() });
  };

  render() {
    const { initialOrganizationsConfigurations, filters } = this.props;
    const { teamOptions, agentSearchOptions, zoneOptions } = this.state;

    return (
      <Sidebar>
        <BarTitle resetFilters={this.resetFilters} />
        <Dates
          id="statementDatetime"
          title={_tg('field.date.date_plural')}
          dates={filters.statementDatetime}
          onChange={this.onChange}
        />
        <br />
        <Times
          id="times"
          title={_tg('field.date.hours')}
          times={filters.times}
          onChange={this.onChange}
        />
        <br />
        {teamOptions && teamOptions.length > 0 && (
          <Checkboxes
            id="teamIds"
            title={_tg('field.organisation.team_plural')}
            options={teamOptions}
            filters={filters.teamIds}
            onChange={this.onChange}
            /* so far we have no way of knowing in advance the faceting of the team */
            faceting={null}
          />
        )}
        <Autocomplete
          id="agentId"
          title={_tg('tefps.filters.fps.agentId')}
          options={agentSearchOptions}
          onChange={this.onChange}
          onAutocomplete={this.fetchAgentsAndGenerateOptions}
          search={filters.agentId}
        />
        {initialOrganizationsConfigurations &&
          initialOrganizationsConfigurations.length > 0 && (
            <Select
              id="organizationShortId"
              title={_tg('tefps.filters.fps.initialOrganizationShortId')}
              onChange={this.onChange}
              selected={filters.organizationShortId}
              options={[
                {
                  key: undefined,
                  label: _tg('tefps.filters.fps.allInitialOrganizationShortId'),
                },
                ...initialOrganizationsConfigurations,
              ]}
            />
          )}
        <Select
          id="zoneId"
          title={_tg('field.address.zone')}
          onChange={this.onChange}
          selected={filters.zoneId}
          options={zoneOptions}
        />
      </Sidebar>
    );
  }
}

export default connect(
  state => {
    const { filters } = getStatsAgentsState(state);
    const { initialOrganizationsConfigurations } = getConfigState(state);

    return {
      filters,
      initialOrganizationsConfigurations,
    };
  },
  dispatch => ({
    updateFilters: (filters: StatsAgentsSearchFilters) =>
      dispatch(setFilters(filters)),
  })
)(StatsFilters);
