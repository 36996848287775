import { Pager, SortParameters } from 'api/commonTypes';

import { PaymentFiltersParam, PaymentSearchCriterias } from './types';

const { _tg } = window.loadTranslations(__filename);

export const STATUSES_OPTIONS = (): Array<{
  value: string;
  label: string;
}> => [
  { value: 'PENDING', label: _tg('field.awaitingPayment') },
  {
    value: 'INCOMPLETE',
    label: _tg('field.incompletePayment'),
  },
  { value: 'OVERPAID', label: _tg('field.overpaid') },
  { value: 'OVERPAID_REDUCED', label: _tg('field.overpaidReduced') },
  {
    value: 'PAID',
    label: _tg('field.paid'),
  },
  { value: 'PAID_REDUCED', label: _tg('field.paidReduced') },
  { value: 'RECOVERED', label: _tg('field.recovered') },
  {
    value: 'ABANDONED',
    label: _tg('field.fpsAbandoned'),
  },
];

export const CHANNELS_OPTIONS = (): Array<{
  value: string;
  label: string;
}> => [
  { value: 'DESK', label: _tg('field.payment.origins.DESK') },
  { value: 'MAIL', label: _tg('field.payment.origins.MAIL') },
  {
    value: 'DGFIP',
    label: _tg('tefps.dashboard.payment.DGFIP'),
  },
  { value: 'PARKMETER', label: _tg('field.payment.origins.parkingMeter') },
  { value: 'MOBILE', label: _tg('field.payment.origins.phone') },
  {
    value: 'INTERNET',
    label: _tg('field.payment.origins.INTERNET'),
  },
  { value: 'VP', label: _tg('field.payment.origins.vp') },
  { value: 'ANTAI', label: _tg('field.payment.origins.ANTAI') },
  {
    value: 'COMMUNITY',
    label: _tg('field.payment.origins.community'),
  },
];

export const MODES_OPTIONS = (): Array<{
  value: string;
  label: string;
}> => [
  { value: 'CASH', label: _tg('field.payment.paymentMode.CASH') },
  { value: 'CHECK', label: _tg('field.payment.paymentMode.CHECK') },
  {
    value: 'CARD',
    label: _tg('field.payment.paymentMode.CB'),
  },
  { value: 'ANTAI', label: _tg('field.payment.paymentMode.ANTAI') },
  { value: 'TRANSFERT', label: _tg('field.payment.paymentMode.TRANSFERT') },
  {
    value: 'RECURRING_DEBIT',
    label: _tg('field.payment.paymentMode.RECURRING_DEBIT'),
  },
  {
    value: 'DIRECT_DEBIT',
    label: _tg('field.payment.paymentMode.DIRECT_DEBIT'),
  },
];

// The key should be the same as the id in PaymentExportColumnsConfig in the back.
export const EXPORT_COL = (): Array<{
  key: string;
  label: string;
  checked?: boolean;
}> => [
  { key: 'fpsId', label: _tg('tefps.fpsNumber_short'), checked: true },
  {
    key: 'finePrice',
    label: _tg('tefps.dashboard.payment.fpsPrice'),
    checked: true,
  },
  {
    key: 'operationId',
    label: _tg('tefps.dashboard.payment.columns.operationId'),
    checked: true,
  },
  {
    key: 'paymentAmount',
    label: _tg('tefps.dashboard.payment.columns.paymentAmount'),
    checked: true,
  },
  {
    key: 'paymentDate',
    label: _tg('tefps.dashboard.payment.transactionDateAlternative'),
    checked: true,
  },
  {
    key: 'paymentReference',
    label: _tg('tefps.dashboard.payment.paymentReference'),
  },
  {
    key: 'status',
    label: _tg('tefps.filters.fps.paymentStatuses'),
    checked: true,
  },
  {
    key: 'channel',
    label: _tg('tefps.dashboard.payment.paymentChannel'),
  },
  { key: 'mode', label: _tg('tefps.dashboard.payment.paymentModeAlternate') },
  { key: 'agent', label: _tg('tefps.dashboard.payment.columns.agentName') },
  {
    key: 'reportDatetime',
    label: _tg('tefps.dashboard.payment.paymentRecuperationDate'),
  },
  {
    key: 'statementDatetime',
    label: _tg('tefps.dashboard.payment.statementDatetime'),
  },
  {
    key: 'rootFpsId',
    label: _tg('tefps.dashboard.payment.fpsNumberAlternate'),
  },
  {
    key: 'responsibleOfPeriodReduced',
    label: _tg('tefps.dashboard.payment.responsibleOfPeriodReduced'),
  },
  { key: 'statDate', label: _tg('tefps.dashboard.payment.statDate') },
  {
    key: 'esStatPaymentState',
    label: _tg('tefps.dashboard.payment.esStatPaymentState'),
  },
];

export const EXPORT_COL_MULTI_CITIES = (): Array<{
  key: string;
  label: string;
  checked?: boolean;
}> => [
  ...EXPORT_COL(),
  { key: 'regie', label: _tg('tefps.dashboard.payment.concernedRegie') },
];

export const PAYMENT_TABLE_COLUMNS_WITH_SORT = {
  date: 2,
  mode: 5,
  agentName: 6,
};

function getSortFieldText(sortField: number): string {
  switch (sortField) {
    case PAYMENT_TABLE_COLUMNS_WITH_SORT.date:
      return 'date';
    case PAYMENT_TABLE_COLUMNS_WITH_SORT.mode:
      return 'mode';
    case PAYMENT_TABLE_COLUMNS_WITH_SORT.agentName:
      return 'agentName';
    default:
      return 'date';
  }
}

export const filtersToRequest = (
  filters: PaymentSearchCriterias,
  sort: SortParameters,
  pager: Pager
): PaymentFiltersParam => {
  const paymentDates = filters.paymentDates || null;
  const collectionDates = filters.collectionDates || null;
  return {
    statuses: Array.from(filters.statuses),
    channels: Array.from(filters.channels),
    paymentModes: Array.from(filters.paymentModes),
    regieIds: Array.from(filters.regieIds),
    clientAppIds: Array.from(filters.clientAppIds),
    startPaymentDate:
      paymentDates && paymentDates.from != null
        ? paymentDates.from.toISOString()
        : null,
    endPaymentDate:
      paymentDates && paymentDates.to != null
        ? paymentDates.to.toISOString()
        : null,
    startCollectionDate:
      collectionDates && collectionDates.from != null
        ? collectionDates.from.toISOString()
        : null,
    endCollectionDate:
      collectionDates && collectionDates.to != null
        ? collectionDates.to.toISOString()
        : null,
    operationId: filters.operationId,
    fpsId: filters.fpsId,
    page: pager.page,
    maxRecords: pager.maxRecords,
    sortOrder: sort.increasingOrder ? 'ASC' : 'DESC',
    sortField: getSortFieldText(sort.sortField),
  };
};
