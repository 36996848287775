import React, { CSSProperties } from 'react';
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import AddIcon from 'material-ui/svg-icons/content/add-circle';

import { BKG_CYAN } from 'theme';

const { _tg } = window.loadTranslations(__filename);

const STYLE_FIELD: CSSProperties = {
  width: 150,
  marginLeft: 10,
};

type AddTagProps = {
  tags: Array<string>;
  onAdd: (arg0: string) => Promise<void>;
  hintText?: string;
  textFieldStyle?: CSSProperties;
  disabled?: boolean;
};

type AddTagState = {
  value: string;
  canAdd: boolean;
};

class AddTag extends React.Component<AddTagProps, AddTagState> {
  constructor(props: AddTagProps) {
    super(props);
    this.state = {
      value: '',
      canAdd: false,
    };
  }

  handleChange = (e: never, value: string): void => {
    const { tags } = this.props;
    this.setState({ value, canAdd: !!value && !tags.includes(value) });
  };

  handleEnter = (e: React.KeyboardEvent<KeyboardEvent>): void => {
    if (e.key === 'Enter') {
      this.addTag();
      e.preventDefault();
    }
  };

  addTag = (): void => {
    const { onAdd } = this.props;
    const { value } = this.state;

    void onAdd(value);
    this.setState({ value: '', canAdd: false });
  };

  render(): React.ReactNode {
    const { value, canAdd } = this.state;
    const { hintText, textFieldStyle, disabled } = this.props;

    return (
      <div style={{ marginTop: 15 }}>
        <TextField
          hintText={
            hintText ||
            _tg(
              'Configuration.commons.AdminTags.AddTag.element.addLabel.hintText'
            )
          }
          style={textFieldStyle || STYLE_FIELD}
          value={value}
          onKeyPress={this.handleEnter}
          disabled={disabled}
          onChange={this.handleChange}
        />
        <IconButton onClick={this.addTag} disabled={disabled || !canAdd}>
          <AddIcon color={BKG_CYAN} />
        </IconButton>
      </div>
    );
  }
}

export default AddTag;
