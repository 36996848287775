import React, { CSSProperties, useContext, useState } from 'react';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import { InternalAgent } from 'api/auth/types';
import { RefundComment, RefundOverviewDTO } from 'api/refund/types';
import { addComment, sendContact } from 'api/refund';
import { RefundPayment } from 'api/commonTypes';
import { patchFps } from 'api/fps/';
import { getApiState } from 'api/duck';
import { buildControlAgent } from 'commons/Utils/agentUtil';

import {
  BoolContext,
  BoolContextType,
  RefundMap,
  RefundMapContext,
  RefundMapContextType,
} from '../Context';
import RefundInfoModal from '../RefundInfoModal';

import ProgressionModal from './ProgressionModal';
import UpdateMultipleStatusModal from './UpdateMultipleStatusModal';
import ConfirmSendMailModal from './ConfirmSendMailModal';

const { _tg } = window.loadTranslations(__filename);

const STYLE_BUTTON: CSSProperties = { marginLeft: '5pt' };

type Props = {
  items: Array<RefundOverviewDTO>;
  reload: () => void;
  onSelectAll: () => void;
  userInfo: InternalAgent;
  totalHits: number;
};

function MultipleRefundAction({
  items,
  reload,
  userInfo,
  onSelectAll,
  totalHits,
}: Props) {
  const { selectedRefunds, setSelectedRefunds } = useContext(
    RefundMapContext
  ) as RefundMapContextType;
  const { multipleMode, setMultipleMode } = useContext(
    BoolContext
  ) as BoolContextType;
  const [openProgressMailModal, setOpenProgressMailModal] = useState<boolean>(
    false
  );
  const [openMassRefundModal, setOpenMassRefundModal] = useState<boolean>(
    false
  );
  const [refundPayment, setRefundPayment] = React.useState<
    RefundPayment | null | undefined
  >(null);
  const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState<boolean>(
    false
  );
  const [openConfirmMailModal, setOpenConfirmMailModal] = useState<boolean>(
    false
  );
  const [comment, setComment] = React.useState<
    RefundComment | null | undefined
  >(null);

  function switchMode() {
    if (multipleMode) {
      // if we cancel, we clear the checked RefundList
      setSelectedRefunds(() => ({} as RefundMap));
    }
    setMultipleMode(() => !multipleMode);
  }

  async function sendSingleMail(refundId: string) {
    return sendContact(null, refundId);
  }

  async function doSingleRefund(refundId: string) {
    const body = [
      {
        op: 'add',
        path: '/payments/-',
        value: {
          ...refundPayment,
          paymentAmount: -selectedRefunds[refundId].refundPrice,
          agent: buildControlAgent(userInfo),
        },
      },
    ];
    await patchFps(selectedRefunds[refundId].rootFpsId, body);
  }

  async function updateSingleRefund(refundId: string) {
    if (comment) {
      await addComment(comment, refundId);
    }
  }

  function closeProgressModalAndRefresh() {
    setOpenProgressMailModal(false);
    setRefundPayment(null);
    setComment(null);
    reload();
  }

  function onReceivePayment(newRefundPayment: RefundPayment) {
    setRefundPayment(newRefundPayment);
    setOpenMassRefundModal(false);
  }

  function onReceiveComment(newComment: RefundComment) {
    setComment(newComment);
    setOpenUpdateStatusModal(false);
  }

  function onConfirmSendMail() {
    setOpenConfirmMailModal(false);
    setOpenProgressMailModal(true);
  }

  function unSelectAll() {
    setSelectedRefunds(() => ({} as RefundMap));
  }

  const selectionLength = Object.keys(selectedRefunds).length;

  const button = multipleMode ? (
    [
      <BoButton
        key="annuler"
        label={_tg('action.cancel')}
        style={STYLE_BUTTON}
        onClick={switchMode}
      />,
      <BoButton
        key="selectAll"
        label={
          items.length === selectionLength || selectionLength === totalHits
            ? _tg('action.unselect_all')
            : _tg('action.select_all')
        }
        style={STYLE_BUTTON}
        onClick={
          items.length === selectionLength || selectionLength === totalHits
            ? unSelectAll
            : onSelectAll
        }
      />,
      <BoButton
        key="mail"
        label={_tg('tefps.dashboard.refund.sendMailAndPost')}
        primary
        onClick={() => setOpenConfirmMailModal(true)}
        style={STYLE_BUTTON}
        disabled={selectionLength === 0}
      />,
      <BoButton
        key="status"
        label={_tg('tefps.dashboard.refund.statusChange')}
        primary
        onClick={() => setOpenUpdateStatusModal(true)}
        style={STYLE_BUTTON}
        disabled={selectionLength === 0}
      />,
      <BoButton
        key="refund"
        label={_tg('field.payment.refund')}
        primary
        onClick={() => setOpenMassRefundModal(true)}
        style={STYLE_BUTTON}
        disabled={selectionLength === 0}
      />,
    ]
  ) : (
    <BoButton
      label={_tg('action.multipleAction')}
      secondary
      onClick={switchMode}
    />
  );

  return (
    <div
      style={{
        marginLeft: 'auto',
      }}
    >
      {button}
      <RefundInfoModal
        isOpen={openMassRefundModal}
        onSubmit={onReceivePayment}
        onClose={() => setOpenMassRefundModal(false)}
        amount={null}
      />
      <UpdateMultipleStatusModal
        isOpen={openUpdateStatusModal}
        onSubmit={onReceiveComment}
        onClose={() => setOpenUpdateStatusModal(false)}
      />
      <ConfirmSendMailModal
        isOpen={openConfirmMailModal}
        onSubmit={onConfirmSendMail}
        onClose={() => setOpenConfirmMailModal(false)}
      />
      {refundPayment && (
        <ProgressionModal
          massAction={doSingleRefund}
          title={_tg('tefps.dashboard.refund.refundStatuses')}
          onClose={closeProgressModalAndRefresh}
        />
      )}
      {openProgressMailModal && (
        <ProgressionModal
          massAction={sendSingleMail}
          title={_tg('tefps.dashboard.refund.mailSendingStatus')}
          onClose={closeProgressModalAndRefresh}
        />
      )}
      {comment && (
        <ProgressionModal
          massAction={updateSingleRefund}
          title={_tg('tefps.dashboard.refund.commentUpdateStatus')}
          onClose={closeProgressModalAndRefresh}
        />
      )}
    </div>
  );
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(MultipleRefundAction);
