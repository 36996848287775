import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import Input from 'commons/SidebarV2/Components/Input';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import DurationRange from 'commons/SidebarV2/Components/DurationRange';
import PriceRange from 'commons/SidebarV2/Components/PriceRange';
import Dates from 'commons/SidebarV2/Components/Dates';
import Times from 'commons/SidebarV2/Components/Times';
import Select from 'commons/SidebarV2/Components/Select';
import { Zone } from 'commons/Utils/zoneUtils';
import { fetchClientAppTV } from 'api/clientapp';
import { buildClientAppOptions } from 'commons/Utils/clientAppUtils';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import { TicketChartCriteria } from 'Dashboard/fps/Graphics/types';
import { translateVehicleTypeFilterOptions } from 'commons/Utils/vehicleTypeUtils';
import { AuthorizedVehicleCategory, getConfigState } from 'config/duck';

import ChartFilterService from '../service';

const { _t } = window.loadTranslations(__filename);

type Props = {
  totalHits?: number;
  filters: TicketChartCriteria;
  zoneOptions: Array<Zone>;
  resetFilters: () => void;
  authorizedVehicleCategories: Array<AuthorizedVehicleCategory>;
};

function TicketFiltersBar({
  totalHits,
  filters,
  resetFilters,
  zoneOptions,
  authorizedVehicleCategories,
}: Props): JSX.Element {
  const [clientAppOptions, setClientAppOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);

  function onChange(id: string, value: unknown) {
    ChartFilterService.setFilters((prevFilters: TicketChartCriteria) => {
      return { ...prevFilters, [id]: value };
    });
  }

  async function fetchClientAppAndBuildOptions() {
    try {
      const clientApp = await fetchClientAppTV();
      setClientAppOptions(buildClientAppOptions(clientApp));
    } catch (e) {
      setClientAppOptions([]);
    }
  }

  useEffect(() => {
    void fetchClientAppAndBuildOptions();
  }, []);

  return (
    <Sidebar>
      <BarTitle resetFilters={resetFilters} />
      <HitsCounter hits={totalHits || 0} />
      <Input
        id="plate"
        title={_t('element.title.plate')}
        placeholder={_t('element.placeholder.plate')}
        onChange={onChange}
        value={filters.plate || ''}
      />
      {authorizedVehicleCategories.length > 0 && (
        <Checkboxes
          id="vehicleCategories"
          title={_t('element.title.vehicleCategory')}
          options={translateVehicleTypeFilterOptions(
            authorizedVehicleCategories
          )}
          filters={filters.vehicleCategories}
          onChange={onChange}
        />
      )}
      <Checkboxes
        id="clientAppIds"
        title={_t('element.title.clientAppIds')}
        options={clientAppOptions}
        filters={filters.clientAppIds}
        onChange={onChange}
      />
      <PriceRange
        id="price"
        title={_t('element.title.price')}
        price={filters.price}
        onChange={onChange}
      />
      <DurationRange
        id="duration"
        title={_t('element.title.duration')}
        durations={filters.duration}
        defaultDurations={{ from: undefined, to: 12 * 60 }}
        onChange={onChange}
      />
      <Dates
        id="creationDate"
        title={_t('element.title.creationDate')}
        dates={filters.creationDate}
        onChange={onChange}
      />
      <Times
        id="creationTime"
        title={_t('element.title.creationHour')}
        times={filters.creationTime}
        onChange={onChange}
      />
      <Dates
        id="startDate"
        title={_t('element.title.startDate')}
        dates={filters.startDate}
        onChange={onChange}
      />
      <Times
        id="startTime"
        title={_t('element.title.startHour')}
        times={filters.startTime}
        onChange={onChange}
      />
      <Dates
        id="endDate"
        title={_t('element.title.endDate')}
        dates={filters.endDate}
        onChange={onChange}
      />
      <Times
        id="endTime"
        title={_t('element.title.endHour')}
        times={filters.endTime}
        onChange={onChange}
      />
      <Select
        id="zoneId"
        title={_t('element.title.zoneId')}
        onChange={onChange}
        selected={filters.zoneId}
        options={zoneOptions}
      />
      <Input
        id="ticketId"
        title={_t('element.title.ticketId')}
        placeholder={_t('element.placeholder.ticketId')}
        onChange={onChange}
        value={filters.ticketId || ''}
      />
    </Sidebar>
  );
}

export default connect(state => {
  const { authorizedVehicleCategories } = getConfigState(state);
  return {
    authorizedVehicleCategories,
  };
})(TicketFiltersBar);
