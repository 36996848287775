import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';

import AdvertisingModal from 'commons/AdvertisingModal';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import Exporter from 'commons/Exporter';
import FlexCenter from 'commons/FlexCenter';
import { AgentsDashboardStatisticsDTO } from 'api/dashboard/types';
import { SortParameters } from 'api/commonTypes';
import { ZoningDTO } from '@cvfm-front/tefps-types';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import {
  askNumberOfFpsAndControlsByAgentExport,
  fetchNumberOfFpsAndControlsByAgent,
} from 'api/dashboard';
import { fetchZoning } from 'api/pricing';
import { FETCH_LIGHT_ZONING_CONFIG } from 'commons/FetchZoningConfigs';

import StatsFilters from './StatsFilters';
import StatsTable from './StatsTable';
import {
  getStatsAgentsState,
  setSortParameters,
  StateStatsAgentsList,
} from './duck';
import { EXPORT_COL, filtersToRequest } from './utils';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  height: '100%',
};

type State = {
  zoning: ZoningDTO | null | undefined;
  stats: AgentsDashboardStatisticsDTO | null | undefined;
  error: Error | null | undefined;
};

type Props = {
  statsAgentsList: StateStatsAgentsList;
  updateSortParameters: (sort: SortParameters) => void;
};

class StatsAgentsList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      zoning: null,
      stats: null,
      error: null,
    };
  }

  componentDidMount() {
    const { statsAgentsList } = this.props;
    void this.fetchZones();
    void this.fetchStatsAgents(statsAgentsList);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: Props) {
    void this.fetchStatsAgents(newProps.statsAgentsList);
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  onUpdateSort = async (colNumber: number, increasingOrder: boolean) => {
    const { updateSortParameters } = this.props;
    void updateSortParameters({ sortField: colNumber, increasingOrder });
  };

  fetchZones = async () => {
    try {
      const zoning = await fetchZoning(FETCH_LIGHT_ZONING_CONFIG);
      this.setState({ zoning });
    } catch (e) {
      this.setState({
        error: new Error(_t('error.zones')),
      });
    }
  };

  fetchStatsAgents = async (stateStatsAgentsList: StateStatsAgentsList) => {
    const { filters, sort } = stateStatsAgentsList;

    try {
      const stats = await fetchNumberOfFpsAndControlsByAgent(
        filtersToRequest(filters, sort)
      );
      this.setState({ stats });
    } catch (error) {
      this.setState({ error: error as Error, stats: null });
    }
  };

  render() {
    const {
      statsAgentsList: { sort, filters },
    } = this.props;
    const { error, stats, zoning } = this.state;
    const items = stats ? stats.countsByAgents : [];

    if (error) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock message={_t('error.statsAgents')} error={error} />
          </FlexCenter>
        </Content>
      );
    }

    return (
      <div style={STYLE_CONTENT}>
        <AdvertisingModal module="fps" />
        <StatsFilters zoning={zoning} />
        <ListWrapper>
          <ListBody loading={false}>
            <StatsTable
              items={items}
              stats={stats}
              onUpdateSort={this.onUpdateSort}
              colSorted={sort.sortField}
              sortOrder={sort.increasingOrder}
            />
          </ListBody>
          <ListBottom style={{ marginTop: '100px' }}>
            <Exporter
              disabled={!items.length}
              columns={EXPORT_COL()}
              type={_tg('tefps.dashboard.agents.suivi.label')}
              filters={filtersToRequest(filters, sort)}
              fileExport={askNumberOfFpsAndControlsByAgentExport}
            />
          </ListBottom>
          <p style={{ fontStyle: 'italic', fontSize: 'small' }}>
            {_t('table.footer')}
          </p>
        </ListWrapper>
      </div>
    );
  }
}

export default connect(
  state => {
    const statsAgentsList = getStatsAgentsState(state);
    return { statsAgentsList };
  },
  dispatch => ({
    updateSortParameters: (sort: SortParameters) =>
      dispatch(setSortParameters(sort)),
  })
)(StatsAgentsList);
