import { combineReducers } from 'redux';

import statsAgentsReducer, {
  StateStatsAgentsList,
} from './fps/StatsAgents/duck';
import subscriptionsReportReducer, {
  State as StateSubscriptionReport,
} from './fps/Subscribers/duck';
import controlStrategyFiltersReducer from './fps/ControlStrategy/duck';
import strategyFiltersReducer from './fps/Strategy/duck';
import reportReducer, { State as ReportState } from './fps/Report/duck';
import paymentReducer, {
  State as StatePaymentList,
} from './fps/Payment/List/duck';
import subscriberPaymentsReducer, {
  State as StateSubscribersPaymentList,
} from './fps/SubscribersPayment/List/duck';
import lapiReviewReportReducer, {
  State as StateLapiReviewReport,
} from './fps/LapiReviewReport/duck';
import { MapFilters as StateControlStrategyMapFilters } from './fps/ControlStrategy/types';
import { MapFilters as StateStrategyMapFilters } from './fps/Strategy/types';

export type ReduxDashboardState = {
  subscriptionsReport: StateSubscriptionReport;
  statsAgentsList: StateStatsAgentsList;
  controlStrategyFilters: StateControlStrategyMapFilters;
  strategyFilters: StateStrategyMapFilters;
  report: ReportState;
  paymentList: StatePaymentList;
  subscriberPayments: StateSubscribersPaymentList;
  lapiReviewReport: StateLapiReviewReport;
};

export default combineReducers({
  subscriptionsReport: subscriptionsReportReducer,
  statsAgentsList: statsAgentsReducer,
  controlStrategyFilters: controlStrategyFiltersReducer,
  strategyFilters: strategyFiltersReducer,
  report: reportReducer,
  paymentList: paymentReducer,
  subscriberPayments: subscriberPaymentsReducer,
  lapiReviewReport: lapiReviewReportReducer,
});
