import React, { CSSProperties } from 'react';
import TextField from 'material-ui/TextField';
import IconButton from 'material-ui/IconButton';
import AddIcon from 'material-ui/svg-icons/content/add-circle';

import { BKG_CYAN, TXT_ERROR_RED } from 'theme';
import { ControlTag } from 'api/lapiReviewConfiguration/types';
import NumberField from 'commons/NumberField';

const { _t } = window.loadTranslations(__filename);

const STYLE_FIELD: CSSProperties = {
  width: 150,
  marginLeft: 10,
};

type AddTagProps = {
  tags: Array<ControlTag>;
  onAdd: (arg0: ControlTag) => void;
};

type AddTagState = {
  priority: number;
  text: string;
  canAdd: boolean;
  errorMessage: string | null;
};

class AddTag extends React.Component<AddTagProps, AddTagState> {
  constructor(props: AddTagProps) {
    super(props);
    this.state = {
      priority: 10,
      text: '',
      canAdd: false,
      errorMessage: null,
    };
  }

  computeErrorMessage = (
    tags: ControlTag[],
    priority: number | null,
    text: string
  ): string | null => {
    if (priority === null) {
      return _t('error.priority.null');
    }
    if (!text) {
      return '';
    }
    if (text.includes(',') || text.includes(':')) {
      return _t('error.tag.illegalChar');
    }
    if (tags.find(tag => tag.text === text)) {
      return _t('error.tag.alreadyPresent');
    }
    return null;
  };

  handlePriorityChange = (priority: number): void => {
    const { tags } = this.props;
    const { text } = this.state;
    const errorMessage = this.computeErrorMessage(tags, priority, text);
    this.setState({
      priority,
      canAdd: errorMessage === null,
      errorMessage,
    });
  };

  handleTextChange = (e: never, text: string): void => {
    const { tags } = this.props;
    const { priority } = this.state;
    const errorMessage = this.computeErrorMessage(tags, priority, text);
    this.setState({
      text,
      canAdd: errorMessage === null,
      errorMessage,
    });
  };

  handleEnter = (e: React.KeyboardEvent<KeyboardEvent>): void => {
    if (e.key === 'Enter') {
      this.addTag();
      e.preventDefault();
    }
  };

  addTag = (): void => {
    const { tags, onAdd } = this.props;
    const { priority, text } = this.state;
    if (this.computeErrorMessage(tags, priority, text) === null) {
      void onAdd({ priority, text });
      this.setState({ text: '', canAdd: false });
    }
  };

  render(): React.ReactNode {
    const { priority, text, canAdd, errorMessage } = this.state;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div style={{ marginTop: 15 }}>
          <NumberField
            name={_t('element.priority.hintText')}
            hintText={_t('element.priority.hintText')}
            style={STYLE_FIELD}
            value={priority}
            onKeyPress={this.handleEnter}
            onChange={this.handlePriorityChange}
          />
          <TextField
            hintText={_t('element.addTag.hintText')}
            style={STYLE_FIELD}
            value={text}
            onKeyPress={this.handleEnter}
            onChange={this.handleTextChange}
          />
          <IconButton onClick={this.addTag} disabled={!canAdd}>
            <AddIcon color={BKG_CYAN} />
          </IconButton>
        </div>
        <div style={{ color: TXT_ERROR_RED }}>{errorMessage}</div>
      </div>
    );
  }
}

export default AddTag;
