import React, { CSSProperties } from 'react';

import SimpleTable from 'commons/SimpleTable';
import {
  AgentReportDTO,
  AgentsDashboardStatisticsDTO,
} from 'api/dashboard/types';

const { _tg } = window.loadTranslations(__filename);

const STYLE_STATS_ROW: CSSProperties = { textAlign: 'center' };

const FOOTER_EMPTY_CELL = {
  label: '',
  width: 130,
};

const FOOTER_EMPTY_STYLE = {
  width: 170,
  style: { textAlign: 'center' },
};

type Props = {
  items: Array<AgentReportDTO>;
  onUpdateSort: (colNumber: number, increasingOrder: boolean) => Promise<void>;
  stats: AgentsDashboardStatisticsDTO | null | undefined;
  colSorted: number;
  sortOrder: boolean;
};

function meanFormat(mean: number | null | undefined, asterisk = false) {
  if (mean == null || isNaN(mean)) {
    return '-';
  }
  const res = mean.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return asterisk ? `${res} **` : res;
}

class StatsTable extends React.Component<Props> {
  STATS_TABLE_SUPER_COLUMNS = [
    {
      label: '',
      width: 130,
      headerStyle: {
        minHeight: 25,
        borderRight: '2px solid rgb(200, 200, 200)',
      },
    },
    {
      label: _tg('commons.history'),
      width: 522,
      headerStyle: {
        justifyContent: 'center',
        minHeight: 25,
        borderRight: '2px solid rgb(200, 200, 200)',
      },
    },
    {
      label: _tg('field.date.today'),
      width: 522,
      headerStyle: {
        minHeight: 25,
        justifyContent: 'center',
      },
    },
  ];

  STATS_TABLE_COLUMNS = [
    {
      label: _tg('field.agent.agent'),
      width: 130,
      onSort: true,
      headerStyle: {
        borderRight: '2px solid rgb(200, 200, 200)',
      },
    },
    {
      label: _tg('commons.fps'),
      width: 170,
      onSort: true,
      headerStyle: { justifyContent: 'center' },
    },
    {
      label: _tg('tefps.controls'),
      width: 170,
      onSort: true,
      headerStyle: { justifyContent: 'center' },
    },
    {
      label: _tg('tefps.dashboard.stats.agentStats.hourlyRate'),
      width: 170,
      onSort: true,
      headerStyle: {
        justifyContent: 'center',
        borderRight: '2px solid rgb(200, 200, 200)',
      },
    },
    {
      label: _tg('commons.fps'),
      width: 170,
      onSort: true,
      headerStyle: { justifyContent: 'center' },
    },
    {
      label: _tg('tefps.controls'),
      width: 170,
      onSort: true,
      headerStyle: { justifyContent: 'center' },
    },
    {
      label: _tg('tefps.dashboard.stats.agentStats.hourlyRate'),
      width: 170,
      onSort: true,
      headerStyle: { justifyContent: 'center' },
    },
  ];

  render(): React.ReactNode {
    const { colSorted, items, stats, onUpdateSort, sortOrder } = this.props;

    const footer = !stats
      ? undefined
      : [
          FOOTER_EMPTY_CELL,
          {
            label: stats.fpsTotal.toLocaleString(),
            ...FOOTER_EMPTY_STYLE,
          },
          {
            label: stats.controlTotal.toLocaleString(),
            ...FOOTER_EMPTY_STYLE,
          },
          {
            label: meanFormat(stats.averageOfControlsMeanPerHour, true),
            ...FOOTER_EMPTY_STYLE,
          },
          {
            label: stats.fpsTotalToday.toLocaleString(),
            ...FOOTER_EMPTY_STYLE,
          },
          {
            label: stats.controlTotalToday.toLocaleString(),
            ...FOOTER_EMPTY_STYLE,
          },
          {
            label: meanFormat(stats.averageOfControlsMeanPerHourToday, true),
            ...FOOTER_EMPTY_STYLE,
          },
        ];

    return (
      <SimpleTable
        style={{ marginTop: 25 }}
        superCols={this.STATS_TABLE_SUPER_COLUMNS}
        cols={this.STATS_TABLE_COLUMNS}
        footer={footer}
        rowHeight={60}
        onSort={onUpdateSort}
        colSorted={colSorted}
        sortOrder={sortOrder}
        itemsRenderer={(agentReport: AgentReportDTO) => {
          return [
            <span>{agentReport.agentName}</span>,
            <div style={STYLE_STATS_ROW}>
              {agentReport.nbrOfFpses.toLocaleString()}
            </div>,
            <div style={STYLE_STATS_ROW}>
              {agentReport.nbrOfControls.toLocaleString()}
            </div>,
            <div style={STYLE_STATS_ROW}>
              {meanFormat(agentReport.controlsMeanPerHour)}
            </div>,
            <div style={STYLE_STATS_ROW}>
              {agentReport.nbrOfFpsesToday.toLocaleString()}
            </div>,
            <div style={STYLE_STATS_ROW}>
              {agentReport.nbrOfControlsToday.toLocaleString()}
            </div>,
            <div style={STYLE_STATS_ROW}>
              {meanFormat(agentReport.controlsMeanPerHourToday)}
            </div>,
          ];
        }}
        items={items}
      />
    );
  }
}

export default StatsTable;
