import { IndexRange } from 'react-virtualized';

import { Watcher } from '@cvfm-front/commons-utils';
import { apiPost } from 'api/helpers';
import {
  DatetimeFilterType,
  Filters,
  InputFilterType,
} from 'commons/types/filterbar';
import { RangeDTO } from '@cvfm-front/tefps-types';

import {
  ParkingRightPaymentSearchRequestDTO,
  ParkingRightPaymentSearchResultDTO,
  TvPaymentExportDTO,
} from './types';
import TvPaymentsFilters from './TvPaymentsFilters';

const TvPaymentsService = () => {
  let currentPage = 0;

  const {
    getValue: getFilters,
    watchValue: watchFilters,
    setValue: setFilters,
  } = Watcher<Filters>(TvPaymentsFilters());

  const {
    getValue: getItems,
    watchValue: watchItems,
    setValue: setItems,
  } = Watcher<TvPaymentExportDTO[]>([]);

  const { watchValue: watchTotalHits, setValue: setTotalHits } = Watcher(0);

  function convertFilterToSearchRequest(): ParkingRightPaymentSearchRequestDTO {
    const filters = getFilters();

    const paymentDatetimeRange: RangeDTO<string | null> = {
      from: null,
      to: null,
    };
    const paymentDateTimeRangeFilter = filters.paymentDatetimeRange as DatetimeFilterType;

    if (paymentDateTimeRangeFilter.datetimeValue != null) {
      paymentDatetimeRange.from = paymentDateTimeRangeFilter.datetimeValue.from?.toISOString();
      paymentDatetimeRange.to = paymentDateTimeRangeFilter.datetimeValue.to?.toISOString();
    }

    return {
      parkingRightId:
        (filters.parkingRightId as InputFilterType).inputValue || '',
      paymentId: (filters.paymentId as InputFilterType).inputValue || '',
      paymentDatetimeRange,
    };
  }

  // Index range not used, but needed to respect signature of SimpleTable
  async function fetchRowsFromAPI(_: IndexRange) {
    const currentItems = getItems();
    const url = '/api/proxy/tv/api/v1/city/{cityId}/payment/search';
    const searchRequest = convertFilterToSearchRequest();

    const body: PagedQuery<ParkingRightPaymentSearchRequestDTO> = {
      page: currentPage,
      maxRecords: 20,
      query: searchRequest,
    };

    const response = await apiPost<ParkingRightPaymentSearchResultDTO>(
      url,
      body
    );

    setTotalHits(response.totalHits);
    setItems([...currentItems, ...response.tvPaymentList]);
  }

  async function loadMoreRows(range: IndexRange) {
    currentPage += 1;
    await fetchRowsFromAPI(range);
  }

  async function fileExport(_: Filters, columns: { columns: Array<string> }) {
    const searchRequest = convertFilterToSearchRequest();

    const url = '/api/prv/v0/city/{cityId}/tickets/payments/export';
    const body = {
      ...columns,
      ...searchRequest,
    };

    return apiPost<void>(url, body);
  }

  const setFiltersAndRefetch = (newFilters: Filters) => {
    currentPage = 0;
    setItems([]);
    setFilters(newFilters);
    fetchRowsFromAPI({ startIndex: 0, stopIndex: 0 });
  };

  const init = () => {
    currentPage = 0;
    setItems([]);
    setFilters(TvPaymentsFilters());
    fetchRowsFromAPI({ startIndex: 0, stopIndex: 0 });
  };

  return {
    fileExport,
    init,
    loadMoreRows,
    setFilters,
    setFiltersAndRefetch,
    watchFilters,
    watchItems,
    watchTotalHits,
  };
};

export default TvPaymentsService();
