import React, { CSSProperties, useEffect, useState } from 'react';

import { CHART_SELECTOR_TREE } from 'Dashboard/fps/Graphics/Charts';
import {
  ChartCriteria,
  ChartDataSource,
  ChartDecomposition,
  ChartSelector,
} from '@cvfm-front/tefps-types';
import {
  ControlChartCriteria,
  FpsChartCriteria,
  RapoChartCriteria,
  TicketChartCriteria,
} from 'Dashboard/fps/Graphics/types';
import {
  translateChartCriteria,
  translateChartDataSource,
  translateChartDecomposition,
} from 'Dashboard/fps/Graphics/Utils';

import ChartFilterService from '../service';

import SelectChartField from './SelectChartField';

const { _t } = window.loadTranslations(__filename);

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '15%',
  width: '90%',
};

const defaultCharSelector: ChartSelector = {
  dataSource: undefined,
  criteria: undefined,
  firstDecomposition: undefined,
  secondDecomposition: undefined,
};

function SelectChartsFields(): JSX.Element {
  const [chartSelector, setChartSelector] = useState(defaultCharSelector);

  const [criteriaSelector, setCriteriaSelector] = useState<
    Array<CHART_SELECTOR_TREE>
  >([]);
  const [firstDecompositionSelector, setFirstDecompositionSelector] = useState<
    Array<CHART_SELECTOR_TREE>
  >([]);
  const [
    secondDecompositionSelector,
    setSecondDecompositionSelector,
  ] = useState<Array<CHART_SELECTOR_TREE>>([]);

  function handleChangeDataSource(chartDataSource: ChartDataSource) {
    const typedObj = ChartFilterService.chartSelectorTree.find(
      hierarchyType => hierarchyType.type === chartDataSource
    );
    setCriteriaSelector(typedObj && typedObj.children ? typedObj.children : []);
    ChartFilterService.setDataSource(chartDataSource);
    setChartSelector({
      dataSource: chartDataSource,
      criteria: undefined,
      firstDecomposition: undefined,
      secondDecomposition: undefined,
    });
    // check if there is only 1 criteria and if so, select it
    if (typedObj && typedObj.children && typedObj.children.length === 1) {
      const chartCriteria = typedObj.children[0].type;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleChangeCriteria(chartCriteria as ChartCriteria);
    }
  }

  function handleChangeCriteria(chartCriteria: ChartCriteria) {
    const typedObj = criteriaSelector.find(
      hierarchyCriteria => hierarchyCriteria.type === chartCriteria
    );
    setFirstDecompositionSelector(
      typedObj && typedObj.children ? typedObj.children : []
    );
    setChartSelector(req => {
      return {
        ...req,
        criteria: chartCriteria,
        firstDecomposition: undefined,
        secondDecomposition: undefined,
      };
    });
    // check if there is only 1 firstDecomposition and if so, select it
    if (typedObj && typedObj.children && typedObj.children.length === 1) {
      const firstDecomposition = typedObj.children[0].type;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleChangeFirstDecomposition(firstDecomposition as ChartDecomposition);
    }
  }

  function handleChangeFirstDecomposition(
    chartDecomposition: ChartDecomposition
  ) {
    const typedObj = firstDecompositionSelector.find(
      hierarchyDecomposition =>
        hierarchyDecomposition.type === chartDecomposition
    );
    setSecondDecompositionSelector(
      typedObj && typedObj.children ? typedObj.children : []
    );
    if (typedObj?.chart) {
      ChartFilterService.setFilters(
        (
          prevState:
            | TicketChartCriteria
            | ControlChartCriteria
            | FpsChartCriteria
            | RapoChartCriteria
        ) => {
          return {
            ...prevState,
            aggregationPipeline: typedObj.chart?.chartPipeline,
            chartName: typedObj.chart?.graphName(),
            chartType: typedObj.chart?.chartType,
            forceSingleDataset: typedObj.chart?.forceSingleDataset,
          };
        }
      );
    }
    setChartSelector(req => {
      return {
        ...req,
        firstDecomposition: chartDecomposition,
        secondDecomposition: undefined,
      };
    });
    // check if there is only 1 secondDecomposition and there isn't already a chart,
    // if so, select it
    if (
      typedObj &&
      typedObj.children &&
      typedObj.children.length === 1 &&
      !typedObj?.chart
    ) {
      const secondDecomposition = typedObj.children[0].type;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      handleChangeSecondDecomposition(
        secondDecomposition as ChartDecomposition
      );
    }
  }

  function handleChangeSecondDecomposition(
    chartDecomposition: ChartDecomposition
  ) {
    const typedObj = secondDecompositionSelector.find(
      hierarchyDecomposition =>
        hierarchyDecomposition.type === chartDecomposition
    );
    if (typedObj?.chart) {
      ChartFilterService.setFilters(
        (
          prevState:
            | TicketChartCriteria
            | ControlChartCriteria
            | FpsChartCriteria
            | RapoChartCriteria
        ) => {
          return {
            ...prevState,
            aggregationPipeline: typedObj.chart?.chartPipeline,
            chartName: typedObj.chart?.graphName(),
            chartType: typedObj.chart?.chartType,
            forceSingleDataset: typedObj.chart?.forceSingleDataset,
          };
        }
      );
    }
    setChartSelector(req => {
      return { ...req, secondDecomposition: chartDecomposition };
    });
  }

  useEffect(() => {
    handleChangeDataSource(ChartFilterService.getDataSource());
  }, []);

  return (
    <div style={STYLE_CONTENT}>
      <SelectChartField
        floatingLabelText={_t('element.label.type')}
        disabled={false}
        value={chartSelector.dataSource}
        onChange={handleChangeDataSource}
        items={ChartFilterService.chartSelectorTree.map(hierarchyType => {
          return {
            id: hierarchyType.type,
            name: translateChartDataSource(
              hierarchyType.type as ChartDataSource
            ),
          };
        })}
      />
      <SelectChartField
        floatingLabelText={_t('element.label.criteria')}
        disabled={!chartSelector.dataSource}
        value={chartSelector.criteria}
        onChange={handleChangeCriteria}
        items={criteriaSelector.map(hierarchyCriteria => {
          return {
            id: hierarchyCriteria.type,
            name: translateChartCriteria(
              hierarchyCriteria.type as ChartCriteria
            ),
          };
        })}
      />
      <SelectChartField
        floatingLabelText={_t('element.label.firstDecomposition')}
        disabled={!chartSelector.dataSource || !chartSelector.criteria}
        value={chartSelector.firstDecomposition}
        onChange={handleChangeFirstDecomposition}
        items={firstDecompositionSelector.map(hierarchyDecomposition => {
          return {
            id: hierarchyDecomposition.type,
            name: translateChartDecomposition(
              hierarchyDecomposition.type as ChartDecomposition
            ),
          };
        })}
      />
      {!!chartSelector.dataSource &&
        !!chartSelector.criteria &&
        !!chartSelector.firstDecomposition &&
        secondDecompositionSelector.length > 0 && (
          <SelectChartField
            floatingLabelText={_t('element.label.secondDecomposition')}
            disabled={
              !chartSelector.dataSource ||
              !chartSelector.criteria ||
              !chartSelector.firstDecomposition
            }
            value={chartSelector.secondDecomposition}
            onChange={handleChangeSecondDecomposition}
            items={secondDecompositionSelector.map(hierarchyDecomposition => {
              return {
                id: hierarchyDecomposition.type,
                name: translateChartDecomposition(
                  hierarchyDecomposition.type as ChartDecomposition
                ),
              };
            })}
          />
        )}
    </div>
  );
}

export default SelectChartsFields;
