import { computeSubscriptionsStats } from 'api/dashboard';
import {
  DashboardSubscriptionStatisticsDTO,
  StatByMonthDTO,
} from 'api/dashboard/types';

export interface FormattedSubStatistics {
  apiData: DashboardSubscriptionStatisticsDTO | null | undefined;
  subscriptionByMonthStatItems: string[][];
  ticketByMonthStatItems: string[][];
}

export const DEFAULT_STATISTICS: FormattedSubStatistics = {
  apiData: null,
  subscriptionByMonthStatItems: [],
  ticketByMonthStatItems: [],
};

const StatisticService = () => {
  const formatMonthlyApiData = (
    id: string,
    statByMonth: StatByMonthDTO,
    idToName: Record<string, string>
  ): string[] => {
    const sortedKeys: string[] = Object.keys(statByMonth).sort();
    const statNodes =
      sortedKeys.length > 0
        ? // Convert API data to string
          sortedKeys.map(key => statByMonth[key].toString())
        : // Or create arrays of "0" if api doesn't have data for this id
          new Array(12).fill('0');
    return [idToName[id], ...statNodes];
  };

  const fetchDataFromApiAndFormat = async (): Promise<FormattedSubStatistics> => {
    const apiData = await computeSubscriptionsStats();

    const subscriptionByMonthStatItems = Object.entries(
      apiData.monthlySubscriptions
    ).map((obj: [string, StatByMonthDTO]) =>
      formatMonthlyApiData(obj[0], obj[1], apiData.subscriptionIdToName)
    );

    const ticketByMonthStatItems = Object.entries(
      apiData.monthlyTickets
    ).map((obj: [string, StatByMonthDTO]) =>
      formatMonthlyApiData(obj[0], obj[1], apiData.parkingRightIdToName)
    );

    return {
      apiData,
      subscriptionByMonthStatItems,
      ticketByMonthStatItems,
    };
  };

  return {
    fetchDataFromApiAndFormat,
  };
};

export default StatisticService();
