import React, { CSSProperties } from 'react';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import {
  ChartCriteria,
  ChartDecomposition,
  ChartDataSource,
} from '@cvfm-front/tefps-types';

const STYLE_MENU_ITEM: CSSProperties = {
  whiteSpace: 'normal',
  lineHeight: '1.2em',
  padding: '0.6em 0',
};

type Props = {
  floatingLabelText?: string;
  items:
    | Array<{
        id: ChartDataSource | ChartCriteria | ChartDecomposition;
        name: string;
      }>
    | undefined;
  disabled: boolean;
  value?: ChartDataSource | ChartCriteria | ChartDecomposition;
  onChange: (e: ChartDataSource | ChartCriteria | ChartDecomposition) => void;
};

function SelectChartField({
  floatingLabelText,
  items,
  disabled,
  onChange,
  value,
}: Props): JSX.Element {
  function handleChange(
    e: React.SyntheticEvent<Element, Event>,
    index: number,
    menuItemValue: ChartDataSource | ChartCriteria | ChartDecomposition
  ) {
    onChange(menuItemValue);
  }

  return (
    <SelectField
      floatingLabelText={floatingLabelText}
      value={value}
      disabled={disabled}
      onChange={handleChange}
    >
      {items &&
        items.map(item => (
          <MenuItem
            key={item.id}
            value={item.id}
            primaryText={item.name ? item.name : item.id}
            style={STYLE_MENU_ITEM}
          />
        ))}
    </SelectField>
  );
}

export default SelectChartField;
