import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Table, TableBody, TableRow, TableRowColumn } from 'material-ui/Table';
import CircularProgress from 'material-ui/CircularProgress';

import { TXT_BLACK } from 'theme';
import { getConfigState, GlobalConfigDTO } from 'config/duck';
import { FinanceStatisticDTO } from 'api/dashboard/types';
import Price from 'commons/Price';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  fontSize: 13,
  fontWeight: 'bold',
  margin: '10px 0',
  textAlign: 'center',
  color: TXT_BLACK,
};

const STYLE_STAT: CSSProperties = { width: 450 };

type ReduxStateProps = {
  antaiApaPrices: GlobalConfigDTO | null | undefined;
};
type Props = ReduxStateProps & {
  stats: FinanceStatisticDTO | null | undefined;
};
type State = {
  payments: number | null | undefined;
  apaForCurrentMonth: number | null | undefined;
  eApaForCurrentMonth: number | null | undefined;
  apaForCurrentYear: number | null | undefined;
  eApaForCurrentYear: number | null | undefined;
  receiptForCurrentMonth: number | null | undefined;
  receiptForCurrentYear: number | null | undefined;
};

class AntaiCurrentMonthStats extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      payments: null,
      apaForCurrentMonth: null,
      eApaForCurrentMonth: null,
      apaForCurrentYear: null,
      eApaForCurrentYear: null,
      receiptForCurrentMonth: null,
      receiptForCurrentYear: null,
    };
  }

  componentDidMount() {
    const { stats } = this.props;
    this.computeStats(stats);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps({ stats }: Props) {
    this.computeStats(stats);
  }

  computeStats = (stats: FinanceStatisticDTO | null | undefined) => {
    if (stats) {
      const currentMonth = moment().format('YYYY-MM');
      const { ANTAI, ANTAI_ENFORCEABLE } = stats.monthlyPaymentAmounts[
        currentMonth
      ] || { ANTAI: 0, ANTAI_ENFORCEABLE: 0 };
      const { APA, E_APA, RECEIPT } = stats.monthlyMailsReceived[
        currentMonth
      ] || {
        APA: 0,
        E_APA: 0,
        RECEIPT: 0,
      };

      let apaForCurrentYear = 0;
      let eApaForCurrentYear = 0;
      let receiptForCurrentYear = 0;
      const currentYear = moment().format('YYYY');
      const currentYearApa = Object.entries(
        stats.monthlyMailsReceived
      ).filter(([key]) => key.includes(currentYear));

      currentYearApa
        .map(month => month[1])
        .forEach(value => {
          apaForCurrentYear += value.APA;
          eApaForCurrentYear += value.E_APA;
          receiptForCurrentYear += value.RECEIPT;
        });

      this.setState({
        apaForCurrentMonth: APA,
        eApaForCurrentMonth: E_APA,
        receiptForCurrentMonth: RECEIPT,
        apaForCurrentYear,
        eApaForCurrentYear,
        receiptForCurrentYear,
        payments: ANTAI + ANTAI_ENFORCEABLE,
      });
    }
  };

  render() {
    const {
      payments,
      apaForCurrentMonth,
      eApaForCurrentMonth,
      apaForCurrentYear,
      eApaForCurrentYear,
      receiptForCurrentYear,
      receiptForCurrentMonth,
    } = this.state;
    const { antaiApaPrices } = this.props;

    return (
      <div style={{ marginBottom: 30 }}>
        <div style={STYLE_TITLE}>
          {_tg('tefps.dashboard.finance.antaiStats.monthlyInformation')}
        </div>
        <Table selectable={false} wrapperStyle={{ width: 900 }}>
          <TableBody displayRowCheckbox={false}>
            <TableRow>
              <TableRowColumn>
                {_tg('tefps.dashboard.finance.antaiStats.monthlyPayment')}
              </TableRowColumn>
              <TableRowColumn style={STYLE_STAT}>
                {payments || payments === 0 ? (
                  <Price price={payments} />
                ) : (
                  <CircularProgress />
                )}
              </TableRowColumn>
            </TableRow>
            <TableRow>
              <TableRowColumn>
                {_tg('tefps.dashboard.finance.antaiStats.mailTariff')}
              </TableRowColumn>
              <TableRowColumn style={STYLE_STAT}>
                {antaiApaPrices ? (
                  <span>
                    <span>
                      {_tg('tefps.dashboard.finance.antaiStats.papers')}
                    </span>
                    <Price price={antaiApaPrices.apaMailPrice.initialCost} />
                    <span>
                      {_tg('tefps.dashboard.finance.antaiStats.treatment')}
                    </span>
                    <Price price={antaiApaPrices.sendingMailCost} />
                    <span>
                      {_tg('tefps.dashboard.finance.antaiStats.mailingCost')}
                    </span>
                    <br />
                    <span>
                      {_tg('tefps.dashboard.finance.antaiStats.electronicMail')}
                    </span>
                    <Price
                      price={antaiApaPrices.dematerializedApaPrice.initialCost}
                    />
                    <span>
                      {_tg('tefps.dashboard.finance.antaiStats.forTreatment')}
                    </span>
                  </span>
                ) : (
                  <CircularProgress />
                )}
              </TableRowColumn>
            </TableRow>
            <TableRow>
              <TableRowColumn>
                {_tg('tefps.dashboard.finance.antaiStats.numberSentPerYear')}
              </TableRowColumn>
              <TableRowColumn style={STYLE_STAT}>
                {apaForCurrentYear != null &&
                eApaForCurrentYear != null &&
                receiptForCurrentYear != null &&
                antaiApaPrices ? (
                  <span>
                    <span>
                      {_tg(
                        'tefps.dashboard.finance.antaiStats.numberSentPerYear'
                      )}
                      {apaForCurrentYear.toLocaleString()}
                      {_tg('tefps.dashboard.finance.antaiStats.approximately')}
                    </span>
                    <Price
                      price={
                        apaForCurrentYear *
                        (antaiApaPrices.apaMailPrice.initialCost +
                          antaiApaPrices.sendingMailCost)
                      }
                    />
                    <br />
                    <span>
                      {_tg('tefps.dashboard.finance.antaiStats.electronicMail')}
                      {eApaForCurrentYear.toLocaleString()}
                      {_tg('tefps.dashboard.finance.antaiStats.approximately')}
                    </span>
                    <Price
                      price={
                        eApaForCurrentYear *
                        antaiApaPrices.dematerializedApaPrice.initialCost
                      }
                    />
                    <br />
                    <span>
                      {_tg('tefps.dashboard.finance.antaiStats.priceProof')}
                      {receiptForCurrentYear.toLocaleString()}
                      {_tg('tefps.dashboard.finance.antaiStats.approximately')}
                    </span>
                    <Price
                      price={
                        receiptForCurrentYear *
                        antaiApaPrices.dematerializedApaPrice.initialCost
                      }
                    />
                  </span>
                ) : (
                  <CircularProgress />
                )}
              </TableRowColumn>
            </TableRow>
            <TableRow>
              <TableRowColumn>
                {_tg('tefps.dashboard.finance.antaiStats.numberSentPerMonth')}
              </TableRowColumn>
              <TableRowColumn style={STYLE_STAT}>
                {apaForCurrentMonth != null &&
                eApaForCurrentMonth != null &&
                receiptForCurrentMonth != null &&
                antaiApaPrices ? (
                  <span>
                    <span>
                      {_tg('tefps.dashboard.finance.antaiStats.papers')}
                      {apaForCurrentMonth.toLocaleString()}
                      {_tg('tefps.dashboard.finance.antaiStats.approximately')}
                    </span>
                    <Price
                      price={
                        apaForCurrentMonth *
                        (antaiApaPrices.apaMailPrice.initialCost +
                          antaiApaPrices.sendingMailCost)
                      }
                    />
                    <br />
                    <span>
                      {_tg('tefps.dashboard.finance.antaiStats.electronicMail')}
                      {eApaForCurrentMonth.toLocaleString()}
                      {_tg('tefps.dashboard.finance.antaiStats.approximately')}
                    </span>
                    <Price
                      price={
                        eApaForCurrentMonth *
                        antaiApaPrices.dematerializedApaPrice.initialCost
                      }
                    />
                    <br />
                    <span>
                      {_tg('tefps.dashboard.finance.antaiStats.priceProof')}
                      {receiptForCurrentMonth.toLocaleString()}
                      {_tg('tefps.dashboard.finance.antaiStats.approximately')}
                    </span>
                    <Price
                      price={
                        receiptForCurrentMonth *
                        antaiApaPrices.dematerializedApaPrice.initialCost
                      }
                    />
                  </span>
                ) : (
                  <CircularProgress />
                )}
              </TableRowColumn>
            </TableRow>
          </TableBody>
        </Table>
      </div>
    );
  }
}

export default connect(
  (state): ReduxStateProps => {
    const { globalConfig } = getConfigState(state);
    return {
      antaiApaPrices: globalConfig,
    };
  }
)(AntaiCurrentMonthStats);
