import React, { CSSProperties } from 'react';
import moment, { Moment } from 'moment';
import CircularProgress from 'material-ui/CircularProgress';
import Card from 'material-ui/Card';

import BoButton from 'facade/BoButton';
import Exporter from 'commons/Exporter';
import MonthYearPicker from 'commons/MonthYearPicker';
import ErrorBlock from 'commons/ErrorBlock';
import Content from 'commons/Content';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import ChartJSWrapper from 'commons/ChartJSWrapper';
import AdvertisingModal from 'commons/AdvertisingModal';
import { FinanceStatisticDTO } from 'api/dashboard/types';
import { computeFinanceStats, exportFinanceStats } from 'api/dashboard';
import { BKG_PINK } from 'theme';
import { ColumnConfig } from 'api/commonTypes';
import { ApiError } from 'api/ApiError';

import { buildChartDataFromMap, buildOptions } from '../commons/helper';
import { buildPaidPercentages } from '../commons/utils';
import MonthlyAmountHistogram, {
  MonthlyAmountHistogramData,
} from '../commons/MonthlyAmountHistogram';
import { EUR_UNIT, PERCENT_UNIT } from '../commons/types';

import AntaiCurrentMonthStats from './AntaiCurrentMonthStats';
import {
  ABANDONNED_CONFIG,
  ANTAI_CONFIG,
  ANTAI_ENFORCEABLE_CONFIG,
  CITY_CONFIG,
  CITY_REDUCED_CONFIG,
  E_MAIL_SENT_CONFIG,
  MAIL_SENT_CONFIG,
  RECEIPT_SENT_CONFIG,
  MAIL_CORRECTION_CONFIG,
  NOT_PAID_CONFIG,
  PAID_CONFIG,
} from './config';

const { _tg } = window.loadTranslations(__filename);

type FinanceState = {
  error: Error | null | undefined;
  stats: FinanceStatisticDTO | null | undefined;
  start: Moment;
  antaiApaPrices: null;
};

const INITIAL_STATE: FinanceState = {
  error: null,
  stats: null,
  start: moment().subtract(12, 'month'),
  antaiApaPrices: null,
};

const STYLE_EXPORT: CSSProperties = {
  marginTop: 10,
  textAlign: 'center',
};

const STYLE_TXT: CSSProperties = {
  marginRight: 30,
};

const STYLE_CONTENT: CSSProperties = {
  width: '100%',
  height: '100%',
  margin: '0 auto',
};

const STYLE_CHART_LABEL: CSSProperties = {
  marginTop: '5px',
  textAlign: 'center',
  color: BKG_PINK,
  fontWeight: 'bold',
};

const GraphCard = ({
  children,
}: {
  children?: React.ReactElement<any> | Array<React.ReactElement<any>>;
}) => <Card style={{ padding: 20 }}>{children}</Card>;

class Finance extends React.Component<Record<string, never>, FinanceState> {
  APA_EXPORT_COL = [
    {
      key: 'APA',
      label: _tg('tefps.dashboard.finance.finance.columns.APA_EXPORT_COL'),
      checked: true,
    },
  ];
  INCOME_EXPORT_COL = [
    {
      key: 'INCOME',
      label: _tg('tefps.dashboard.finance.finance.columns.INCOME_EXPORT_COL'),
      checked: true,
    },
  ];
  PAYEMENTS_EXPORT_COL = [
    {
      key: 'PAYMENTS',
      label: _tg(
        'tefps.dashboard.finance.finance.columns.PAYEMENTS_EXPORT_COL'
      ),
      checked: true,
    },
  ];
  PAID_REDUCED_EXPORT_COL = [
    {
      key: 'PAID_REDUCED',
      label: _tg(
        'tefps.dashboard.finance.finance.columns.PAID_REDUCED_EXPORT_COL'
      ),
      checked: true,
    },
  ];
  PAID_PENALTY_EXPORT_COL = [
    {
      key: 'PAID_PENALTY',
      label: _tg(
        'tefps.dashboard.finance.finance.columns.PAID_PENALTY_EXPORT_COL'
      ),
      checked: true,
    },
  ];

  constructor(props: Record<string, never>) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount(): void {
    void this.fetchStat();
  }

  fetchStat = async (): Promise<void> => {
    this.setState({ stats: null, error: null });
    const { start } = this.state;
    try {
      const stats = await computeFinanceStats({
        from: start.valueOf(),
        to: start
          .clone()
          .add(13, 'month')
          .valueOf(),
      });
      this.setState({ stats });
    } catch (error) {
      this.setState({ error: error as ApiError });
    }
  };

  exportStats = async (
    filters: Record<string, any> | undefined,
    exportParam: ColumnConfig
  ): Promise<void> => {
    const { start } = this.state;

    try {
      await exportFinanceStats(exportParam.columns[0], {
        from: start.valueOf(),
        to: start
          .clone()
          .add(13, 'month')
          .valueOf(),
      });
    } catch (error) {
      this.setState({ error: error as ApiError });
    }
  };

  onChangeStart = (start: Moment): void => {
    this.setState({ start: start.startOf('month') });
  };

  render(): React.ReactNode {
    const { error, stats, start } = this.state;

    if (error) {
      return (
        <Content>
          <ErrorBlock
            message={_tg(
              'tefps.dashboard.finance.finance.errorWhileLoadingGraphs'
            )}
            error={error}
          />
        </Content>
      );
    }
    return (
      <Content>
        <AdvertisingModal module="fps" />
        <SeparatorWithTitle
          title={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {_tg('tefps.dashboard.finance.finance.financeData')}
            </div>
          }
          style={{ marginTop: 20 }}
          color="#dd5269"
          titleSize={22}
        />
        <div
          style={{
            padding: '50px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <AntaiCurrentMonthStats stats={stats} />
          <div
            style={{
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
              marginTop: 20,
            }}
          >
            <div style={{ ...STYLE_TXT, marginLeft: 30 }}>
              {_tg('field.date.from')}
            </div>
            <MonthYearPicker date={start} changeDate={this.onChangeStart} />
            <BoButton
              onClick={this.fetchStat}
              label={_tg('commons.toFilter')}
            />
          </div>
          {stats ? (
            <div style={STYLE_CONTENT}>
              <div style={{ marginBottom: 50 }}>
                <GraphCard>
                  <MonthlyAmountHistogram
                    title={_tg(
                      'tefps.dashboard.finance.finance.mailsSentToAntai'
                    )}
                    configs={[
                      MAIL_SENT_CONFIG(),
                      E_MAIL_SENT_CONFIG(),
                      RECEIPT_SENT_CONFIG(),
                      MAIL_CORRECTION_CONFIG(),
                    ]}
                    data={
                      stats.monthlyMailsReceived as MonthlyAmountHistogramData
                    }
                    unit={undefined}
                  />
                </GraphCard>
                <Exporter
                  style={STYLE_EXPORT}
                  disabled={false}
                  columns={this.APA_EXPORT_COL}
                  type={_tg('tefps.dashboard.finance.finance.labelPlural')}
                  filters={{}}
                  fileExport={this.exportStats}
                />
              </div>
              <div style={{ marginBottom: 50 }}>
                <GraphCard>
                  <MonthlyAmountHistogram
                    title={_tg('tefps.dashboard.finance.finance.monthlyIncome')}
                    configs={[
                      CITY_CONFIG(),
                      CITY_REDUCED_CONFIG(),
                      ANTAI_CONFIG(),
                      ANTAI_ENFORCEABLE_CONFIG(),
                      ABANDONNED_CONFIG(),
                    ]}
                    data={
                      stats.monthlyPaymentAmounts as MonthlyAmountHistogramData
                    }
                    unit={EUR_UNIT}
                  />
                  <div style={STYLE_CHART_LABEL}>
                    {_tg(
                      'tefps.dashboard.finance.finance.paymentAfterTwoMonths'
                    )}
                  </div>
                </GraphCard>
                <Exporter
                  style={STYLE_EXPORT}
                  disabled={false}
                  columns={this.INCOME_EXPORT_COL}
                  type={_tg('tefps.dashboard.finance.finance.labelPlural')}
                  filters={{}}
                  fileExport={this.exportStats}
                />
              </div>
              {/* Add this back when the EsFPS PaymentState works again
              <div style={{ marginBottom: 50 }}>
                <GraphCard>
                  <MonthlyAmountHistogram
                    title="Sommes en cours de récupération"
                    configs={[
                      CITY_SUM_CONFIG,
                      CITY_SUM_REDUCED_CONFIG,
                      ANTAI_SUM_CONFIG,
                      ANTAI_SUM_ENFORCEABLE_CONFIG,
                    ]}
                    data={
                      stats.monthlyNotPaidAmounts as MonthlyAmountHistogramData
                    }
                    unit={EUR_UNIT}
                  />
                </GraphCard>
              </div>
               */}
              <div style={{ marginBottom: 50 }}>
                <GraphCard>
                  <MonthlyAmountHistogram
                    title={_tg(
                      'tefps.dashboard.finance.finance.paidPercentages'
                    )}
                    configs={[PAID_CONFIG(), NOT_PAID_CONFIG()]}
                    data={buildPaidPercentages(stats.paidPercentages, start)}
                    unit={PERCENT_UNIT}
                  />
                </GraphCard>
              </div>

              <div style={{ marginBottom: 50 }}>
                <GraphCard>
                  <ChartJSWrapper
                    update
                    type="line"
                    width={400}
                    options={buildOptions(
                      _tg('tefps.dashboard.finance.finance.paymentPerMonth'),
                      0,
                      ''
                    )}
                    data={buildChartDataFromMap(
                      stats.paymentsPerMonth,
                      'PAYMENTS',
                      _tg('tefps.dashboard.finance.finance.numberOfPayments')
                    )}
                    height={150}
                  />
                </GraphCard>
                <Exporter
                  style={STYLE_EXPORT}
                  disabled={false}
                  columns={this.PAYEMENTS_EXPORT_COL}
                  type={_tg('tefps.dashboard.finance.finance.labelPlural')}
                  filters={{}}
                  fileExport={this.exportStats}
                />
              </div>

              <div style={{ marginBottom: 50 }}>
                <GraphCard>
                  <ChartJSWrapper
                    update
                    type="line"
                    width={400}
                    options={buildOptions(
                      _tg(
                        'tefps.dashboard.finance.finance.percentageReducedPayments'
                      ),
                      0,
                      '%'
                    )}
                    data={buildChartDataFromMap(
                      stats.percentageReducedPayments,
                      'PAID_REDUCED',
                      _tg('tefps.dashboard.finance.finance.fpsPercentage')
                    )}
                    height={150}
                  />
                </GraphCard>
                <Exporter
                  style={STYLE_EXPORT}
                  disabled={false}
                  columns={this.PAID_REDUCED_EXPORT_COL}
                  type={_tg('tefps.dashboard.finance.finance.labelPlural')}
                  filters={{}}
                  fileExport={this.exportStats}
                />
              </div>

              <div style={{ marginBottom: 50 }}>
                <GraphCard>
                  <ChartJSWrapper
                    update
                    type="line"
                    width={400}
                    options={buildOptions(
                      _tg(
                        'tefps.dashboard.finance.finance.percentagePenaltyPayments'
                      ),
                      0,
                      '%'
                    )}
                    data={buildChartDataFromMap(
                      stats.percentagePenaltyPayments,
                      'PAID_PENALTY',
                      _tg('tefps.dashboard.finance.finance.fpsPercentage')
                    )}
                    height={150}
                  />
                </GraphCard>
                <Exporter
                  style={STYLE_EXPORT}
                  disabled={false}
                  columns={this.PAID_PENALTY_EXPORT_COL}
                  type={_tg('tefps.dashboard.finance.finance.labelPlural')}
                  filters={{}}
                  fileExport={this.exportStats}
                />
              </div>
            </div>
          ) : (
            <CircularProgress />
          )}
        </div>
      </Content>
    );
  }
}

export default Finance;
