import React, { CSSProperties, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { FlatButton } from 'material-ui';
import ReturnIcon from 'material-ui/svg-icons/navigation/arrow-back';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import MailEditor from 'commons/Mail/MailTemplateEditor/MailEditor/MailEditor';
import ErrorModal from 'commons/ErrorModal/ErrorModal';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import { getHintList } from 'commons/Mail/utils';
import Content from 'commons/Content';
import { fetchMailMessage, updateRenderedMail } from 'api/refund';
import SectionTitle from 'commons/SectionTitle';
import MailConfiguration from 'commons/Mail/MailTemplateEditor/MailConfiguration/MailConfiguration';
import SaveAndContinueModal from 'commons/SaveAndContinueModal';
import { MailDTO } from 'api/mail/type';
import {
  MailConfigurationDTO,
  MailTemplateDTO,
  MailTypeRefund,
} from '@cvfm-front/tefps-types';
import ConfirmModal from 'commons/ConfirmModal';
import { Config, getConfigState } from 'config/duck';

const { _tg } = window.loadTranslations(__filename);

const HEADER_STYLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  paddingLeft: 20,
  paddingRight: 20,
};

type RefundMailEditorPros = {
  config: Config;
};

const RefundMailEditor = ({ config }: RefundMailEditorPros): JSX.Element => {
  const [renderedMail, setRenderedMail] = useState<MailDTO | null | undefined>(
    null
  );
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [reset, setReset] = useState<boolean>(false);
  const [modified, setModified] = useState<boolean>(false);
  const setMessage = useSnackbar();
  const history = useHistory();
  const { refundId } = useParams<{ refundId: string }>();

  const initMailContent = async () => {
    try {
      const response = await fetchMailMessage(refundId);
      setRenderedMail({ ...response });
    } catch (e) {
      setError(true);
      setErrorMessage((e as Error).message);
    }
  };

  useEffect(() => {
    void initMailContent();
  }, []);

  const onContentChange = (newTemplate: MailTemplateDTO) => {
    setRenderedMail({ ...renderedMail, mail: newTemplate.mail } as MailDTO);
    setModified(true);
  };

  const onConfigurationChange = (
    mailConfiguration: MailConfigurationDTO | null | undefined
  ) => {
    setRenderedMail({ ...renderedMail, mailConfiguration } as MailDTO);
    setModified(true);
  };

  const onSave = async () => {
    if (renderedMail) {
      try {
        await updateRenderedMail(refundId, renderedMail);
        setMessage(_tg('tefps.dashboard.refund.mailSaved'));
        setModified(false);
      } catch (e) {
        setError(true);
        setErrorMessage((e as Error).message);
      }
    }
  };

  const onErrorClose = () => {
    setError(false);
    setErrorMessage('');
  };

  const onQuit = () => {
    history.push(`/dashboard/refund/${refundId}`);
  };

  const handleResetMail = async () => {
    try {
      await updateRenderedMail(refundId, {
        mail: null,
        mailConfiguration: null,
      });
      await initMailContent();
      setReset(false);
    } catch (e) {
      setError(true);
      setErrorMessage((e as Error).message);
    }
  };

  const onReturn = () => {
    if (modified) {
      setOpen(true);
    } else {
      onQuit();
    }
  };

  return (
    <Content>
      {renderedMail && (
        <>
          <div style={HEADER_STYLE}>
            <FlatButton
              label={_tg('tefps.dashboard.refund.backToRefund')}
              onClick={onReturn}
              style={{ marginTop: 20, marginLeft: 20 }}
              icon={<ReturnIcon />}
            />
            <BoButton
              onClick={() => setReset(true)}
              label={_tg('tefps.dashboard.refund.resetMailContent')}
              secondary
            />
          </div>
          <SectionTitle title={_tg('tefps.dashboard.refund.refundMail')} />
          <MailEditor
            mailTemplate={{
              ...renderedMail,
              type: MailTypeRefund.REFUND,
            }}
            onContentChange={onContentChange}
            onSave={onSave}
            hintList={getHintList(config, MailTypeRefund.REFUND) || []}
          />
          <SectionTitle
            title={_tg('tefps.dashboard.refund.objectConfiguration')}
          />
          <MailConfiguration
            mailConfiguration={renderedMail.mailConfiguration}
            onChange={onConfigurationChange}
            onSave={onSave}
            editable={false}
            mailType={MailTypeRefund.REFUND}
          />
        </>
      )}
      <ErrorModal open={error} onClose={onErrorClose} message={errorMessage} />
      <SaveAndContinueModal
        open={open}
        onQuit={onQuit}
        onSave={onSave}
        onCancel={() => setOpen(false)}
      />
      <ConfirmModal
        open={reset}
        message={_tg('tefps.dashboard.refund.nonReversibleAction')}
        onCancel={() => setReset(false)}
        onConfirm={handleResetMail}
      />
    </Content>
  );
};

export default connect(state => {
  const config = getConfigState(state);
  return {
    config,
  };
})(RefundMailEditor);
