import React, { CSSProperties, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { FilterDate } from 'commons/SidebarV2/Components/Dates';
import useSnackbar from 'commons/CustomHooks/SnackBar/useSnackBar';
import ComparisonHeader from 'Dashboard/pv/Graphs/ComparisonHeader';
import Content from 'commons/Content';
import {
  COMPARISON_COLLAPSED,
  COMPARISON_EXTENDED,
  COMPARISON_PADDING,
} from 'Dashboard/pv/Graphs/utils';
import { GpvFilter, GpvStatsDTO } from 'api/tepv/stats/types';
import { getApiState } from 'api/duck';
import {
  filterToSearchParams,
  initGpvFilters,
  noFilterDate,
} from 'Dashboard/pv/GpvGraphs/utils';
import { getPresetNotes } from 'api/tepv/presetNote';
import { fetchGpvStatsExport, getGpvStats } from 'api/tepv/stats';
import GpvSideBar from 'Dashboard/pv/GpvGraphs/sidebar';

import ChartContainerSingle from './ChartContainerSingle';
import ChartContainerComparison from './ChartContainerComparison';

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  width: '100%',
  margin: '0 auto',
  height: '100%',
};

type Props = {
  canExport: boolean;
};

type Comparison = {
  enabled: boolean;
  firstPeriod: FilterDate;
  secondPeriod: FilterDate;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};

const GpvReports = ({ canExport }: Props) => {
  const [filters, setFilters] = useState<GpvFilter>(initGpvFilters());
  const [stats, setStats] = useState<GpvStatsDTO>();
  const [statsSecond, setStatsSecond] = useState<GpvStatsDTO>();
  const [presetNotes, setPresetNotes] = useState<
    Array<{ key: string; label: string }>
  >([]);
  const [comparison, setComparison] = useState<Comparison>({
    enabled: false,
    firstPeriod: noFilterDate,
    secondPeriod: noFilterDate,
  });
  const setMessage = useSnackbar();

  async function fetchStatsFirst(fil: GpvFilter) {
    const rep = await getGpvStats(filterToSearchParams(fil));
    setStats(rep);
  }
  async function fetchStatsSecond(fil: GpvFilter) {
    const rep = await getGpvStats(filterToSearchParams(fil));
    setStatsSecond(rep);
  }

  async function fetchStats(
    dateFilterFirst?: FilterDate,
    dateFilterSecond?: FilterDate
  ) {
    const updatedFilterFirst = {
      ...filters,
      signatureDate: dateFilterFirst || filters.signatureDate,
    };
    try {
      await fetchStatsFirst(updatedFilterFirst);

      if (dateFilterSecond) {
        const updatedFilterSecond = {
          ...filters,
          signatureDate: dateFilterSecond,
        };
        await fetchStatsSecond(updatedFilterSecond);
      }
    } catch (e) {
      setMessage(e);
    }
  }

  const fetchPresetNotes = async () => {
    const notes = await getPresetNotes();
    const presetNoteKeys = notes.map(preset => ({
      key: preset.preset,
      label: preset.preset,
    }));
    setPresetNotes(presetNoteKeys);
  };

  const exportStats = () => {
    if (!canExport) {
      return;
    }

    if (comparison.enabled) {
      const updatedFilterFirst = {
        ...filters,
        signatureDate: comparison.firstPeriod,
      };
      const updatedFilterSecond = {
        ...filters,
        signatureDate: comparison.secondPeriod,
      };
      fetchGpvStatsExport(
        filterToSearchParams(updatedFilterFirst),
        'stats_Period1'
      );
      fetchGpvStatsExport(
        filterToSearchParams(updatedFilterSecond),
        'stats_Period2'
      );
    } else {
      fetchGpvStatsExport(filterToSearchParams(filters));
    }
  };

  useEffect(() => {
    // componentDidmount
    fetchStats();
    fetchPresetNotes();
  }, []);

  useEffect(() => {
    if (comparison.enabled) {
      fetchStats(comparison.firstPeriod, comparison.secondPeriod);
    } else {
      fetchStats();
    }
  }, [filters]);

  const onCheckComparison = (
    event: React.MouseEvent<HTMLInputElement>,
    enabled: boolean
  ) => {
    const { signatureDate } = filters;

    // If the signature date filter is set, we use its dates, otherwise we use a default filter
    const from = new Date();
    from.setFullYear(from.getFullYear() - 1);
    const enabledDate =
      signatureDate.from || signatureDate.to
        ? signatureDate
        : { from, to: undefined };

    const dateToSet = enabled ? enabledDate : noFilterDate;

    setComparison({ enabled, firstPeriod: dateToSet, secondPeriod: dateToSet });

    // When we enable the comparison, it's using the same filters as the sidebar was using (for both periods), except if it wasn't using any
    // When we disable it we fall back to the filters we had before
    if (enabled) {
      fetchStats(dateToSet, dateToSet);
    } else {
      fetchStats();
    }
  };

  const onChangeFirstPeriod = (dates: FilterDate) => {
    setComparison({ ...comparison, firstPeriod: dates });
    const updatedFilter = { ...filters, signatureDate: dates };
    fetchStatsFirst(updatedFilter);
  };

  const onChangeSecondPeriod = (dates: FilterDate) => {
    setComparison({ ...comparison, secondPeriod: dates });
    const updatedFilter = { ...filters, signatureDate: dates };
    fetchStatsSecond(updatedFilter);
  };

  const filtersData = {
    vehicleRemovalStatus: [],
    saisineStatus: [],
    photosStatus: [],
  };

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'row' }}>
      <GpvSideBar
        filters={filters}
        filtersData={filtersData}
        updateFilters={setFilters}
        presetNotes={presetNotes}
      />
      <div style={STYLE_CONTENT_WRAPPER}>
        <ComparisonHeader
          comparison={comparison}
          onCheck={onCheckComparison}
          onChangeFirstPeriod={onChangeFirstPeriod}
          onChangeSecondPeriod={onChangeSecondPeriod}
        />
        <Content
          style={{
            height: `calc(100% - ${COMPARISON_PADDING * 2 +
              (comparison.enabled
                ? COMPARISON_EXTENDED
                : COMPARISON_COLLAPSED)}px)`,
            maxWidth: comparison.enabled ? 1600 : 1200,
          }}
        >
          {stats &&
            (comparison.enabled && statsSecond ? (
              <ChartContainerComparison
                statsFirst={stats}
                statsSecond={statsSecond}
                exportStats={canExport ? exportStats : noop}
              />
            ) : (
              <ChartContainerSingle
                stats={stats}
                exportStats={canExport ? exportStats : noop}
              />
            ))}
        </Content>
      </div>
    </div>
  );
};
export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canExport: userInfo
      ? userInfo.rights.includes('PV_DASHBOARD_REPORTS_EXPORT')
      : false,
  };
})(GpvReports);
