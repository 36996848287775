import moment from 'moment';
import { Dispatch } from 'redux';

import { LOGOUT, ApiAction } from 'api/duck';
import { SortParameters } from 'api/commonTypes';
import { ReduxDashboardState } from 'Dashboard/duck';

import { PaymentSearchCriterias } from './types';
import { PAYMENT_TABLE_COLUMNS_WITH_SORT } from './utils';

export const SET_FILTERS = 'Dashboard/Payment/List/SET_FILTERS';
export const SET_SORT_PARAMETERS = 'Dashboard/Payment/List/SET_SORT_PARAMETERS';

export type State = {
  filters: PaymentSearchCriterias | null | undefined;
  sort: SortParameters | null | undefined;
};

type SetFiltersAction = {
  type: typeof SET_FILTERS;
  filters?: PaymentSearchCriterias;
};

type SetSortParametersAction = {
  type: typeof SET_SORT_PARAMETERS;
  sort?: SortParameters;
};

type Action = SetFiltersAction | SetSortParametersAction | ApiAction;

export const initialFilters = (): PaymentSearchCriterias => ({
  statuses: new Set(),
  channels: new Set(),
  paymentModes: new Set(),
  regieIds: new Set(),
  clientAppIds: new Set(),
  paymentDates: {
    from: moment()
      .subtract(6, 'months')
      .toDate(),
    to: undefined,
  },
  collectionDates: {
    from: undefined,
    to: undefined,
  },
  operationId: null,
  fpsId: null,
  comment: null,
  agentComment: null,
});

const initialState = (): State => ({
  filters: initialFilters(),
  sort: {
    sortField: PAYMENT_TABLE_COLUMNS_WITH_SORT.date,
    increasingOrder: false,
  },
});

export default function paymentReducer(
  state: State = initialState(),
  action: Action
): State {
  switch (action.type) {
    case SET_SORT_PARAMETERS:
      return {
        ...state,
        sort: action.sort,
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
      };
    case LOGOUT:
      return initialState();
    default:
      return state;
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getPaymentListState(state: any): State {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return (state.dashboard as ReduxDashboardState).paymentList;
}

export function setFilters(filters: PaymentSearchCriterias): any {
  return (dispatch: Dispatch<any>) => {
    dispatch({
      type: SET_FILTERS,
      filters,
    });
  };
}

export function setSortParameters(sort: SortParameters): any {
  return {
    type: SET_SORT_PARAMETERS,
    sort,
  };
}
