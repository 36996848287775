import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import Content from 'commons/Content';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { getConfigState } from 'config/duck';
import { isDisplayed } from 'commons/helpers/ModulesConfiguration';
import { getApiState, InternalApiState } from 'api/duck';

import { getReportState, reportPageChanged } from './duck';
import Pages from './Pages';
import PageMenuBar from './MenuBar';
import { pageList } from './Pages/constants';

const STYLE_CONTENT: CSSProperties = {
  width: '100%',
  height: '100%',
  margin: '0 auto',
};

type ReduxStateProps = {
  pageId: number;
  isCvfmRapoPartner: boolean;
  isCvfmFpsPartner: boolean;
};

type ReduxDispatchProps = {
  onPageIdChange: (pageId: number) => void;
};

type Props = ReduxStateProps & ReduxDispatchProps;

class Report extends React.Component<Props> {
  changePage = (e: React.ChangeEvent<HTMLSelectElement>, i: number) => {
    const { onPageIdChange } = this.props;
    onPageIdChange(i);
  };

  render() {
    const { pageId, isCvfmRapoPartner, isCvfmFpsPartner } = this.props;
    const currentPage = pageList(isCvfmRapoPartner, isCvfmFpsPartner)[pageId];

    return (
      <Content>
        <div
          style={{
            padding: '50px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <PageMenuBar
            changePage={this.changePage}
            pageId={pageId}
            isCvfmRapoPartner={isCvfmRapoPartner}
            isCvfmFpsPartner={isCvfmFpsPartner}
          />
          <div style={STYLE_CONTENT}>
            <div>
              <span>
                <SeparatorWithTitle
                  title={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {currentPage.title}
                    </div>
                  }
                  color="#dd5269"
                  titleSize={22}
                />
                <Pages pageId={currentPage.id} />
              </span>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}

const mapStateToProps = (state: InternalApiState): ReduxStateProps => {
  const { pageId } = getReportState(state);
  const {
    modulesConfiguration: { fps, rapo },
  } = getConfigState(state);
  const { userInfo } = getApiState(state);
  return {
    pageId: !rapo.enabled && rapo.displayed && pageId === 0 ? 2 : pageId,
    isCvfmRapoPartner:
      isDisplayed(rapo) &&
      !!userInfo?.rights?.includes('BACKOFFICE_DASHBOARD_RAPO'),
    isCvfmFpsPartner:
      isDisplayed(fps) &&
      !!userInfo?.rights.includes('BACKOFFICE_DASHBOARD_FPS'),
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): any => ({
  onPageIdChange: (pageId: number) => dispatch(reportPageChanged(pageId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Report);
