import moment from 'moment';

import { MapSearchRequest } from 'api/tepv/mif/types';

import { MapFilters } from './types';

export const filtersToRequest = (filters: MapFilters): MapSearchRequest => {
  const {
    boundingBoxNorthEast,
    boundingSouthWest,
    zoom,
    agentId,
    dates,
    plate,
    signatureDaysOfWeek,
    times,
    natinfCategories,
    groupIds,
    presetNotes,
    organizationIds,
  } = filters;

  let periods;
  if (dates && (dates.to || dates.from)) {
    periods = [
      {
        type: 'STATEMENT',
        startDatetime: dates.from
          ? moment(dates.from).toISOString()
          : undefined,
        endDatetime: dates.to ? moment(dates.to).toISOString() : undefined,
      },
    ];
  }

  return {
    agentId,
    plate,
    periods,
    startTime: times ? times.from : undefined,
    endTime: times ? times.to : undefined,
    signatureDaysOfWeek: signatureDaysOfWeek
      ? Array.from(signatureDaysOfWeek)
      : null,
    natinfCategories,
    groupIds,
    presetNotes,
    organizationIds,
    infractionLocations: [], // TODO lieu verbalisation v2
    boundingBoxNorthEast,
    boundingSouthWest,
    zoom,
  };
};

const natinfCategories = [
  'Atteintes diverses',
  'Espaces naturels',
  'Alcoolémie',
  'Animaux',
  'Armes',
  'Vitesse',
  'Route',
  'Bruit',
  'Surcharge',
  'Chasse et pêche',
  'Stationnement',
];

export const natinfCategoriesOptions: Array<{
  key: string;
  label: string;
}> = natinfCategories.map(categorie => ({
  key: categorie,
  label: categorie,
}));
