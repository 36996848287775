import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { fetchControlForMap } from 'api/control';
import { MapClusterDTO } from 'api/commonTypes';
import { getConfigState } from 'config/duck';
import ControlDetail from 'tefps/Control/List/ControlDetail';
import AdvertisingModal from 'commons/AdvertisingModal';
import { InternalApiState } from 'api/duck';
import { ControlMapSearchResultDTO } from 'api/control/types';
import useZoning from 'commons/hooks/useZoning';
import services from 'commons/services';
import { DrawingType, MapId } from 'commons/services/MapService';
import { Point } from '@cvfm-front/tefps-types';

import ControlMapFilters from './ControlMapFilters';
import { MapFilters, StrategyProps } from './types';
import { getStrategyFiltersState, strategyFiltersChanged } from './duck';
import { filtersToRequest } from './utils';

const { _tg } = window.loadTranslations(__filename);

const STYLE_CONTENT: CSSProperties = {
  display: 'flex',
  width: '100%',
  height: '100%',
};

const STYLE_LIST_WRAPPER: CSSProperties = {
  backgroundColor: 'white',
  width: '100%',
  margin: '0 auto',
};

const iconMap = new Map([
  ['OK', '/static/img/map/circles/circle_ok.png'],
  ['KO', '/static/img/map/circles/circle_ko.png'],
  ['EXEMPTION', '/static/img/map/circles/circle_exemption.png'],
  ['EMPTY', '/static/img/map/circles/circle_place_vide.png'],
  ['UNKNOWN', '/static/img/map/circles/circle_place_vide.png'],
]);

export const DISPLAY_ZONES = 'displayZones';
export const DISPLAY_PATROL_ZONES = 'displayPatrolZones';

export type Marker = {
  status?: string | null | undefined;
  position: Point;
  count?: number;
  id?: string;
  title?: string;
};

const titleFormatter = (count: number) =>
  count > 1
    ? `${count} ${_tg(
        'tefps.RecoursesV2.pages.Proposal.ControlMap.control_plural'
      )}`
    : `${count} ${_tg('tefps.RecoursesV2.pages.Proposal.ControlMap.control')}`;

const ControlStrategy = ({
  config,
  filters,
  onFilterChange,
  history,
}: StrategyProps): JSX.Element => {
  const { exemptionReasonsConfigurations } = config;

  const refMap = useRef<HTMLDivElement>(null);

  const zoning = useZoning();

  const [controlId, setControlId] = useState<string | null>(null);
  const [markers, setMarkers] = useState<Array<Marker>>([]);
  const [result, setResult] = useState<ControlMapSearchResultDTO | null>(null);
  const [displayZones, setDisplayZones] = useState<boolean>(false);
  const [displayPatrolZones, setDisplayPatrolZones] = useState<boolean>(false);

  const handleClickOnControl = useCallback(
    (newControlId: string) => setControlId(newControlId),
    []
  );

  const handleControlDetailClose = useCallback(() => setControlId(null), []);

  const totalHits = useMemo(() => result?.totalHits || 0, [result]);

  const exemptionReasons = useMemo(
    () =>
      new Map(
        (exemptionReasonsConfigurations || []).map(r => [r.key, r.label])
      ),
    [exemptionReasonsConfigurations]
  );

  const zones = useMemo(() => {
    if (zoning) {
      return new Map(zoning.zones.map(z => [z.id, z.name]));
    }
    return null;
  }, [zoning]);

  useEffect(() => {
    if (refMap?.current) {
      void services.mapService.init(MapId.CONTROL_STRATEGY, config, refMap);
    }
  }, [refMap]);

  useEffect(() => {
    const map = services.mapService.get(MapId.CONTROL_STRATEGY);
    map?.mapListener?.add('bounds_changed', () => {
      const [north, west, south, east] = map.viewPort.get();
      const zoom = map.zoom.get();
      onFilterChange({
        ...filters,
        boundingBoxNorthEast: {
          latitude: north,
          longitude: east,
        },
        boundingSouthWest: {
          latitude: south,
          longitude: west,
        },
        zoom,
      });
    });
    return () => map?.mapListener?.clear('bounds_changed');
  }, [filters]);

  useEffect(() => {
    const map = services.mapService.get(MapId.CONTROL_STRATEGY);
    map?.markers?.add(
      markers.map(marker => {
        const listeners = new Map();
        listeners.set('click', () => {
          handleClickOnControl(marker.id || '');
        });
        return {
          id: marker.id || '',
          type: DrawingType.CONTROL_STRATEGY,
          point: marker.position,
          title: titleFormatter(marker.count || 1),
          count: marker.count || 1,
          symbol:
            iconMap.get(marker.status || 'KO') ||
            '/static/img/map/circles/circle_ko.png',
          listeners,
        };
      })
    );
    map?.markers?.addClusterer({
      type: DrawingType.CONTROL_STRATEGY,
      titleFormatter,
    });
    return () => map?.clear?.drawingType(DrawingType.CONTROL_STRATEGY);
  }, [markers]);

  const fetchControls = async () => {
    try {
      const newResult = await fetchControlForMap(filtersToRequest(filters));
      let newMarkers: Array<Marker> = [];
      if (newResult && newResult.controls) {
        newMarkers = newResult.controls.map(control => ({
          status: control.controlStatus,
          id: control.id,
          position: {
            latitude: control.latitude,
            longitude: control.longitude,
          },
          count: 1,
        }));
      } else if (newResult && newResult.clusters) {
        newMarkers = newResult.clusters.map((cluster: MapClusterDTO) => ({
          position: {
            latitude: cluster.latitude,
            longitude: cluster.longitude,
          },
          id: cluster.key,
          count: cluster.number,
        }));
      }
      setResult(newResult);
      setMarkers(newMarkers);
    } catch (error) {
      // ignored
    }
  };

  useEffect(() => {
    void fetchControls();
  }, [filters]);

  const toggleDisplayZones = (_id: string, newFilter: Set<string>): void => {
    // zones
    const newDisplayZones = newFilter.has(DISPLAY_ZONES);
    if (displayZones !== newDisplayZones) {
      setDisplayZones(newDisplayZones);
      if (newDisplayZones) {
        services.zoning.drawZonesOnMap(MapId.CONTROL_STRATEGY);
      } else {
        services.zoning.clearZonesOnMap(MapId.CONTROL_STRATEGY);
      }
    }
    // patrol zones
    const newDisplayPatrolZones = newFilter.has(DISPLAY_PATROL_ZONES);
    if (displayPatrolZones !== newDisplayPatrolZones) {
      setDisplayPatrolZones(newDisplayPatrolZones);
      if (newDisplayPatrolZones) {
        services.patrolZoneService.drawPatrolZonesOnMap(MapId.CONTROL_STRATEGY);
      } else {
        services.patrolZoneService.clearPatrolZonesOnMap(
          MapId.CONTROL_STRATEGY
        );
      }
    }
  };

  return (
    <div style={STYLE_CONTENT}>
      <AdvertisingModal module="control" />
      <ControlMapFilters
        totalHits={totalHits}
        facetings={(result || {}).checkboxFaceting}
        displayZones={displayZones}
        displayPatrolZones={displayPatrolZones}
        toggleDisplayZone={toggleDisplayZones}
      />
      <div style={STYLE_LIST_WRAPPER}>
        <div
          ref={refMap}
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        />
      </div>
      {controlId && (
        <ControlDetail
          exemptionReasons={exemptionReasons}
          controlId={controlId}
          zones={zones}
          onClose={handleControlDetailClose}
          history={history}
          controlMapDisabled
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: InternalApiState) => {
  const config = getConfigState(state);
  const filters = getStrategyFiltersState(state);
  return {
    filters,
    config,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<unknown>) => ({
  onFilterChange: (filters: MapFilters) =>
    dispatch(strategyFiltersChanged(filters)),
});

export default connect<unknown, unknown, StrategyProps>(
  mapStateToProps,
  mapDispatchToProps
)(ControlStrategy);
