import moment from 'moment';

import { DatetimeFilterType, InputFilterType } from 'commons/types/filterbar';

const { _tg } = window.loadTranslations(__filename);

const paymentDatetimeRange = (): DatetimeFilterType => ({
  type: 'DATETIME',
  id: 'paymentDatetimeRange',
  label: _tg('tefps.dashboard.tvPayments.paymentDate'),
  datetimeDefaultValue: {
    from: moment()
      .subtract(3, 'month')
      .toDate(),
    to: moment().toDate(),
  },
  datetimeValue: {
    from: moment()
      .subtract(3, 'month')
      .toDate(),
    to: moment().toDate(),
  },
});

const paymentId = (): InputFilterType => ({
  type: 'INPUT',
  id: 'paymentId',
  label: _tg('tefps.dashboard.tvPayments.paymentId'),
});

const parkingRightId = (): InputFilterType => ({
  type: 'INPUT',
  id: 'parkingRightId',
  label: _tg('tefps.dashboard.tvPayments.ticketId'),
});

export default (): {
  paymentDatetimeRange: DatetimeFilterType;
  parkingRightId: InputFilterType;
  paymentId: InputFilterType;
} => ({
  paymentDatetimeRange: paymentDatetimeRange(),
  parkingRightId: parkingRightId(),
  paymentId: paymentId(),
});
