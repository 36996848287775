import React from 'react';
import Dialog from 'material-ui/Dialog';

import BoButton from 'facade/BoButton';
import { deleteWhiteListedPlate } from 'api/whitelist';

const { _t, _tg } = window.loadTranslations(__filename);

type Props = {
  plate: string;
  open: boolean;
  onClose: (message: string, reload: boolean) => any;
};

class DeletePlate extends React.Component<Props> {
  handleCancel = (): void => {
    const { onClose } = this.props;
    onClose('', false);
  };

  handleDelete = async (): Promise<void> => {
    const { onClose, plate } = this.props;
    try {
      await deleteWhiteListedPlate(plate);
      onClose(_t('feedback.success'), true);
    } catch (e) {
      onClose(_t('feedback.error'), true);
    }
  };

  render(): React.ReactNode {
    const { plate, open } = this.props;

    return (
      <div style={{ marginTop: 20 }}>
        <Dialog
          title={_t('element.dialog.title', { plate })}
          actions={[
            <BoButton
              label={_tg('action.cancel')}
              onClick={this.handleCancel}
            />,
            <BoButton
              label={_t('element.remove.label')}
              style={{ marginLeft: 10 }}
              secondary
              onClick={this.handleDelete}
            />,
          ]}
          open={open}
          autoScrollBodyContent
          onRequestClose={this.handleCancel}
        >
          {_t('element.dialog.content', { plate })}
        </Dialog>
      </div>
    );
  }
}

export default DeletePlate;
