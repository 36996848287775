import { CSSProperties } from 'react';

export const CHART_TYPE = {
  PIE: 'pie',
  BAR: 'bar',
};

export const CHART_KEY_LABEL = {
  pie: 'Diagramme en secteurs',
  bar: 'Diagramme en barres',
};

export const STYLE_GRAPHS_WRAPPER: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  flex: '1 0 auto',
};

const displayPercent = (tooltipItem: any, data: any) => {
  const dataset = data.datasets[tooltipItem.datasetIndex];
  const currentValue: number = dataset.data[tooltipItem.index];
  const total: number = dataset.data.reduce(
    (sum: number, current: number) => sum + current,
    0
  );
  const percentage = ((currentValue * 100.0) / total).toFixed(1);
  return `${currentValue} (${percentage}%)`;
};

const displayPercentWithCount = (
  tooltipItem: any,
  data: any,
  total: Array<() => number>
) => {
  const dataset = data.datasets[tooltipItem.datasetIndex];
  const currentValue = dataset.data[tooltipItem.index];
  const percentage = (
    (currentValue * 100) /
    total[tooltipItem.datasetIndex]()
  ).toFixed(1);
  return `${currentValue} (${percentage}%)`;
};

export const chartPieOptions = (
  title: string,
  totalCount?: Array<() => number>
) => ({
  plugins: {
    title: {
      display: true,
      text: title,
      font: {
        size: 18,
      },
    },
    legend: {
      position: 'bottom',
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: Record<string, any>, data: Record<string, any>) =>
          totalCount
            ? displayPercentWithCount(tooltipItem, data, totalCount)
            : displayPercent(tooltipItem, data),
      },
    },
  },
  animation: false,
  responsive: true,
  aspectRatio: 2,
  maintainAspectRatio: true,
});

export const chartBarOptions = (
  title: string,
  totalCount?: Array<() => number>
) => ({
  plugins: {
    title: {
      display: true,
      text: title,
      font: {
        size: 18,
      },
    },
    legend: { display: false },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem: Record<string, any>, data: Record<string, any>) =>
          totalCount
            ? displayPercentWithCount(tooltipItem, data, totalCount)
            : displayPercent(tooltipItem, data),
      },
    },
  },
  animation: false,
  responsive: true,
  maintainAspectRatio: true,
});
