import React, { CSSProperties } from 'react';

import SimpleTable from 'commons/SimpleTable';
import Price from 'commons/Price';
import FpsNumber from 'commons/FpsNumber';
import Date from 'commons/Date';
import { PaymentOverviewDTO } from 'api/fps/types';
import { translatePaymentMode } from 'commons/Utils/paymentUtil';

const { _tg } = window.loadTranslations(__filename);

const STYLE_LINK: CSSProperties = {
  height: 50,
  width: '100%',
};

const STYLE_HOVERED: CSSProperties = {
  ...STYLE_LINK,
  color: 'white',
};

type Props = {
  items: Array<PaymentOverviewDTO>;
  loadMoreRows?: (arg0: { startIndex: number }) => Promise<void>;
  remoteRowCount?: number;
  onUpdateSort?: (sortFieldColNumber: number, increasingOrder: boolean) => void;
  colSorted?: number;
  sortOrder?: boolean;
};

type State = {
  hovered: string | null | undefined;
};

const getStyle = (id: string, hovered: string | null | undefined) =>
  id === hovered ? STYLE_HOVERED : STYLE_LINK;

class PaymentTable extends React.Component<Props, State> {
  tableCols: Array<{
    label?: string;
    width: number;
    grow?: number;
    style?: CSSProperties;
    headerStyle?: CSSProperties;
    onSort?: boolean;
  }> = [
    { label: _tg('tefps.dashboard.payment.columns.fpsNumber'), width: 300 },
    {
      label: _tg('tefps.dashboard.payment.columns.operationId'),
      width: 270,
    },
    {
      label: _tg('tefps.dashboard.payment.columns.operationDate'),
      width: 150,
      onSort: true,
    },
    {
      label: _tg('tefps.dashboard.payment.columns.fpsPrice'),
      width: 100,
      style: { textAlign: 'center' },
    },
    {
      label: _tg('tefps.dashboard.payment.columns.paymentAmount'),
      width: 100,
      style: { textAlign: 'center' },
    },
    {
      label: _tg('field.payment.mode'),
      width: 130,
      grow: 1,
      onSort: true,
    },
    {
      label: _tg('tefps.dashboard.payment.columns.agentName'),
      width: 150,
      grow: 1,
      onSort: true,
    },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      hovered: null,
    };
  }

  handleMouseLeave = (): void => this.setState({ hovered: null });
  handleMouseEnter = ({ currentTarget }: React.MouseEvent<HTMLElement>): void =>
    this.setState({ hovered: currentTarget.dataset.paymentId });

  render(): React.ReactNode {
    const { hovered } = this.state;
    const {
      loadMoreRows,
      remoteRowCount,
      colSorted,
      items,
      sortOrder,
      onUpdateSort,
    } = this.props;

    return (
      <SimpleTable
        style={{ marginTop: 25 }}
        cols={this.tableCols}
        rowHeight={50}
        onSort={onUpdateSort}
        loadMoreRows={loadMoreRows}
        remoteRowCount={remoteRowCount}
        colSorted={colSorted}
        sortOrder={sortOrder}
        items={items}
        itemsRenderer={(payment: PaymentOverviewDTO) => [
          <a
            style={getStyle(payment.paymentId, hovered)}
            data-payment-id={payment.paymentId}
            onMouseLeave={this.handleMouseLeave}
            onMouseEnter={this.handleMouseEnter}
            href={`#/fps/detail/${payment.rootFpsId}`}
          >
            <FpsNumber fpsId={payment.fpsVersionId} />
          </a>,
          <span>{payment.paymentId}</span>,
          <Date datetime={payment.date} />,
          <Price price={payment.fpsPrice} />,
          <Price price={payment.amount} />,
          <span>{translatePaymentMode()[payment.paymentMode]}</span>,
          <span>{payment.agentName}</span>,
        ]}
      />
    );
  }
}

export default PaymentTable;
