import React, { useEffect, useState } from 'react';

import { GpvMapError, GpvMapFilters } from 'Dashboard/pv/GpvMaps/types';
import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import { translateDayOptions } from 'commons/Days';
import Dates from 'commons/SidebarV2/Components/Dates';
import Times from 'commons/SidebarV2/Components/Times';
import Select, { SelectOption } from 'commons/SidebarV2/Components/Select';
import { motifsCategoriesOptions } from 'Dashboard/pv/GpvMaps/utils';
import Input from 'commons/SidebarV2/Components/Input';
import { getPresetNotes } from 'api/tepv/presetNote';
import { fetchOrganizations } from 'api/organizations';

import CheckboxV1 from '../../../commons/SidebarV2/Components/CheckboxV1';

type Props = {
  filters: GpvMapFilters;
  updateFilters: (f: GpvMapFilters) => void;
  totalHits: number;
  setError: (f: GpvMapError) => void;
  displayZones: boolean;
  toggleDisplayZone: (id: string, filters: any) => void;
};

const GpvMapFilterBar = ({
  filters,
  updateFilters,
  totalHits,
  setError,
  displayZones,
  toggleDisplayZone,
}: Props) => {
  const [presetNoteKeys, setPresetNotes] = useState<SelectOption[]>([]);
  const [organizationIds, setOrganizationIds] = useState<SelectOption[]>([]);

  const onChange = (id: string, value: any) => {
    updateFilters({ ...filters, [id]: value });
  };

  const fetchData = async () => {
    try {
      const notes = await getPresetNotes();
      setPresetNotes(
        notes.map(preset => ({
          key: preset.preset,
          label: preset.preset,
        }))
      );
      const ids = await fetchOrganizations(false, 'pv');
      setOrganizationIds(
        ids.map(org => ({ key: org.organizationId, label: org.name }))
      );
    } catch (e) {
      setError(e);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Sidebar>
      <BarTitle />
      <HitsCounter hits={totalHits} />
      <CheckboxV1
        id="display"
        title="Afficher les zones"
        filter={displayZones}
        options={{ value: 'zones', label: 'Zones' }}
        onChange={toggleDisplayZone}
      />
      <Checkboxes
        id="signatureDaysOfWeek"
        title="Jour de la semaine"
        options={translateDayOptions()}
        filters={filters.signatureDaysOfWeek}
        onChange={onChange}
        faceting={null}
      />
      <Dates
        id="dates"
        title="Dates"
        onChange={onChange}
        dates={filters.dates}
        periodSelectionEnabled
      />
      <Times
        id="times"
        title="Horaires"
        onChange={onChange}
        times={filters.times}
      />
      <Select
        id="organizationIds"
        title="Organisations"
        options={organizationIds}
        selected={filters.organizationIds}
        onChange={onChange}
        multiple
      />
      <Select
        id="motifsCategories"
        title="Catégories de Motifs"
        options={motifsCategoriesOptions}
        selected={filters.motifsCategories}
        onChange={onChange}
        multiple
      />
      <Select
        id="presetNote"
        title="Renseignements prédéfinis"
        onChange={onChange}
        selected={filters.presetNotes}
        options={presetNoteKeys}
        multiple
      />
      <Input
        id="plate"
        title="Plaque d'immatriculation"
        placeholder="Rechercher par plaque d'immatriculation"
        onChange={onChange}
        value={filters.plate || ''}
      />
    </Sidebar>
  );
};

export default GpvMapFilterBar;
