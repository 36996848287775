import React, { CSSProperties, useState } from 'react';
import Dialog from 'material-ui/Dialog';
import FlatButton from 'material-ui/FlatButton';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';
import { connect } from 'react-redux';

import { InternalApiState } from 'api/duck';
import { getConfigState } from 'config/duck';
import InputFile from 'commons/InputFile';
import { BKG_LIGHT_BLUE, TXT_RED } from 'theme';
import { uploadFileV2 } from 'api/mediaupload';
import { RefundDetailDTO, RefundDocuments } from 'api/refund/types';
import { updateDocuments, getDirectUploadLinkRefund } from 'api/refund';
import { validateFileSize } from 'commons/Validators';
import { DOCUMENT_ENTITLED } from 'api/fpsConfiguration/utils';
import {
  RefundConfigurationDTO,
  RefundDocumentType,
} from 'api/fpsConfiguration/types';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_LIGHT_BLUE,
  color: '#ffffff',
  fontWeight: 'bold',
};

const STYLE_WARNING: CSSProperties = {
  color: TXT_RED,
};

const STYLE_ERROR: CSSProperties = {
  color: 'red',
  fontSize: 12,
  height: 30,
  textAlign: 'center',
  marginTop: 5,
};

type Props = {
  refund: RefundDetailDTO;
  open: boolean;
  refundConfigurationDTO: RefundConfigurationDTO;
  handleClose: () => void;
  onUpdate: (shouldReload: boolean, message: string) => void;
  watermarkText: string | null;
};

function AddDocumentModal({
  refund,
  open,
  refundConfigurationDTO,
  handleClose,
  onUpdate,
  watermarkText,
}: Props) {
  const [selectValue, setSelectValue] = useState<
    RefundDocumentType | null | undefined
  >(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadError, setUploadError] = useState<string | null | undefined>(
    null
  );

  const warningText =
    selectValue && refund.documents[selectValue]
      ? `${_tg('tefps.dashboard.refund.byAddingANewEvidence')} <${
          DOCUMENT_ENTITLED[selectValue]
        }> ${_tg('tefps.dashboard.refund.willBeDeleted')}`
      : '';

  function onClose() {
    handleClose();
  }

  async function onDrop(files: Array<File>) {
    if (!selectValue) {
      return;
    }
    const file = files[0];
    setUploadError(null);
    setLoading(true);

    // checking file size
    const fileSizeError = validateFileSize(file);
    if (fileSizeError) {
      setUploadError(fileSizeError);
      setLoading(false);
      return;
    }

    const documentsCopy: RefundDocuments = { ...refund.documents };
    try {
      // Get pre-signed upload url and urn of the file for a given resource type
      const directUploadDTO = await getDirectUploadLinkRefund(
        refund.refundId,
        file.type
      );
      let hasError = false;
      try {
        await uploadFileV2(
          directUploadDTO.signedUploadUrl,
          file,
          watermarkText
        );
        documentsCopy[selectValue] = {
          url: directUploadDTO.fileUrn,
          name: file.name,
          size: file.size,
          mime: file.type,
          dateCreated: new Date().toISOString(),
        };
      } catch (e) {
        const error = e as Error;
        setUploadError(
          `${_tg('tefps.dashboard.refund.invalidFile')} ${error.message}`
        );
        setLoading(false);
        hasError = true;
      }
      if (hasError) {
        return;
      }
      await updateDocuments(refund.refundId, documentsCopy);
      onUpdate(true, _tg('tefps.dashboard.refund.uploadFinished'));
    } catch (err) {
      onUpdate(false, _tg('feedback.error.whileSavingFile'));
    }
    onClose();
    setLoading(false);
  }

  function handleSelectChange(
    _event: React.ChangeEvent<any>,
    _index: number,
    value: RefundDocumentType
  ) {
    setSelectValue(value);
  }

  return (
    <Dialog
      title={_tg('tefps.dashboard.refund.addEvidence')}
      open={open}
      titleStyle={STYLE_TITLE}
      modal
      autoScrollBodyContent
      actions={[
        <FlatButton
          label={_tg('action.close')}
          onClick={onClose}
          disabled={loading}
        />,
      ]}
    >
      <SelectField
        value={selectValue}
        onChange={handleSelectChange}
        floatingLabelText={_tg('tefps.dashboard.refund.selectEvidenceToAdd')}
      >
        {refund.documents &&
          Array.from(refundConfigurationDTO.refundDocuments).map(
            (key: RefundDocumentType) => (
              <MenuItem
                key={key}
                value={key}
                primaryText={DOCUMENT_ENTITLED[key]}
                style={{
                  whiteSpace: 'normal',
                  lineHeight: '1.2em',
                  padding: '0.6em 0',
                }}
              />
            )
          )}
      </SelectField>
      {selectValue && (
        <div>
          <div style={{ display: 'block', padding: 10 }}>
            {refund.documents[selectValue] && (
              <span style={STYLE_WARNING}> {warningText} </span>
            )}
          </div>
          <div style={{ textAlign: 'center' }}>
            <InputFile
              onDrop={onDrop}
              accept="application/pdf, image/*"
              filename=""
              loading={loading}
            />
            <div style={STYLE_ERROR}>{uploadError}</div>
          </div>
        </div>
      )}
    </Dialog>
  );
}

function mapStateToProps(state: InternalApiState) {
  const { refundConfigurationDTO, watermarkText } = getConfigState(state);
  return {
    refundConfigurationDTO,
    watermarkText,
  };
}

export default connect(mapStateToProps)(AddDocumentModal);
