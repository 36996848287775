import React from 'react';
import moment from 'moment';
import { ChartDataset } from 'chart.js';

import ChartJSWrapper from 'commons/ChartJSWrapper';
import { StatsKey } from 'api/dashboard/types';

import { DatasetConfig, EUR_UNIT, UNIT_TYPE } from './types';
import { buildEmptyDataset, buildGraphDatas } from './utils';

export type MonthlyAmountHistogramData = {
  [k: string]: { [k in StatsKey]: number };
};

type Props = {
  title: string;
  configs: Array<DatasetConfig>;
  data: MonthlyAmountHistogramData;
  unit?: UNIT_TYPE;
};

const MonthlyAmountHistogram = ({
  title,
  configs,
  data,
  unit,
}: Props): JSX.Element => {
  const graphDatas = buildGraphDatas(title, unit);
  const datasets: ChartDataset[] = [];

  // build a map with the name of the field as key and the dataset as value
  configs.forEach(config => {
    datasets[config.field] = buildEmptyDataset(config);
  });

  // Iterate on each month and add each values in the good dataset (sort month)

  Object.keys(data)
    .sort()
    .forEach(month => {
      const value = data[month];
      graphDatas.data.labels?.push(moment(month, 'YYYY-MM').format('MMM YYYY'));
      Object.entries(value).forEach(([type, amount]) => {
        if (datasets[type]) {
          (datasets[type] as ChartDataset).data.push(
            unit === EUR_UNIT ? Number(amount) / 100 : Number(amount)
          );
        }
      });
    });

  // Add dataset to the chart with the good order to stack bar correctly
  configs
    .sort((configA, configB) => {
      return Number(configA.order) - Number(configB.order);
    })
    .forEach(config => {
      graphDatas.data.datasets.push(datasets[config.field]);
    });

  return (
    <ChartJSWrapper
      update
      type={graphDatas.type}
      data={graphDatas.data}
      options={graphDatas.options}
      isLastDatasetLighter
      width={400}
      height={150}
    />
  );
};

export default MonthlyAmountHistogram;
