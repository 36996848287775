import React, { CSSProperties } from 'react';
import _cloneDeep from 'lodash.clonedeep';
import { connect } from 'react-redux';
import Snackbar from 'material-ui/Snackbar';
import CircularProgress from 'material-ui/CircularProgress';
import TextField from 'material-ui/TextField';
import Checkbox from 'material-ui/Checkbox';

import BoButton from 'facade/BoButton';
import { BKG_PINK } from 'theme';
import TimeInterval from 'tefps/Pricing/Policies/components/TimeInterval';
import { getApiState } from 'api/duck';
import Content from 'commons/Content';
import ErrorBlock from 'commons/ErrorBlock';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import MultiSelect from 'commons/MultiSelect';
import { PlanningConfigurationDTO } from 'api/planner/types';
import { translateDaysOfWeek } from 'commons/Days';
import AdvertisingModal from 'commons/AdvertisingModal';
import {
  fetchPlanningConfiguration,
  upsertPlanningConfiguration,
} from 'api/planner';
import {
  DataBox,
  DataBoxContent,
  DataBoxHeader,
  DataBoxItemWrapper,
  DataBoxWithName,
} from 'commons/DataBox';
import FlexCenter from 'commons/FlexCenter';
import { DayOfWeek } from 'api/commonTypes';

const { _t, _tg } = window.loadTranslations(__filename);

const STYLE_HEADER_LEFT: CSSProperties = {
  fontWeight: 'bold',
  marginLeft: 30,
};

const STYLE_LOGS_TITLE: CSSProperties = {
  width: '100%',
  margin: '65px 0px 20px 0px',
};

const STYLE_HEADER_RIGHT: CSSProperties = {
  marginRight: 30,
};

const STYLE_WRAPPER: CSSProperties = {
  marginTop: 40,
  justifyContent: 'space-around',
};

const STYLE_DATABOX: CSSProperties = { width: '95%', margin: '20px auto 0' };

type PlanningConfigurationState = {
  configuration: PlanningConfigurationDTO | null | undefined;
  backupConfiguration: PlanningConfigurationDTO | null | undefined;
  hasChanges: boolean;
  updating: boolean;
  message: string;
  error: Error | null | undefined;
};

type PlanningConfigurationProps = {
  canWrite: boolean;
};

class PlanningConfiguration extends React.Component<
  PlanningConfigurationProps,
  PlanningConfigurationState
> {
  constructor(props: PlanningConfigurationProps) {
    super(props);
    this.state = {
      configuration: null,
      backupConfiguration: null,
      hasChanges: false,
      updating: false,
      message: '',
      error: null,
    };
  }

  componentDidMount(): void {
    void this.fetchConfig();
  }

  onAlertConfigurationChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const {
      value,
      dataset: { field },
    } = ev.currentTarget;
    const { configuration } = this.state;

    this.setState({
      configuration: {
        ...configuration,
        [field as string]: value,
      } as PlanningConfigurationDTO,
      hasChanges: true,
    });
  };

  handleAlertCheck = () => {
    const { configuration } = this.state;

    if (!configuration) {
      return;
    }

    this.setState({
      configuration: {
        ...configuration,
        alertsEnabled: !configuration.alertsEnabled,
      },
      hasChanges: true,
    });
  };

  fetchConfig = async (): Promise<void> => {
    try {
      const configuration = await fetchPlanningConfiguration();
      this.setState({ configuration, backupConfiguration: configuration });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  saveDaysOfWeek = (checkedIds: Array<DayOfWeek>): void => {
    const { configuration } = this.state;
    this.setState({
      configuration: {
        ...configuration,
        controlDays: checkedIds,
      } as PlanningConfigurationDTO,
      hasChanges: true,
    });
  };

  changeDailyPeriod = (from: string, to: string): void => {
    const { configuration } = this.state;
    this.setState({
      configuration: {
        ...configuration,
        startOfDay: from,
        endOfDay: to,
      } as PlanningConfigurationDTO,
      hasChanges: true,
    });
  };

  closeErrorSnackBar = (): void => this.setState({ message: '' });

  submit = async (): Promise<void> => {
    const { configuration } = this.state;
    if (!configuration) {
      return;
    }

    this.setState({ updating: true });
    try {
      await upsertPlanningConfiguration(configuration);
      this.setState({
        hasChanges: false,
        updating: false,
        message: _t('feedback.success'),
      });
    } catch (error) {
      this.setState({ error: error as Error, updating: false });
    }
  };

  cancel = (): void => {
    const { backupConfiguration } = this.state;
    this.setState({
      configuration: _cloneDeep(backupConfiguration),
      hasChanges: false,
    });
  };

  render(): React.ReactNode {
    const { canWrite } = this.props;
    const { configuration, hasChanges, updating, message, error } = this.state;

    if (error) {
      return (
        <FlexCenter>
          <ErrorBlock error={error} />
        </FlexCenter>
      );
    }

    if (!configuration) {
      return (
        <FlexCenter>
          <CircularProgress />
        </FlexCenter>
      );
    }

    return (
      <Content>
        <AdvertisingModal module="planner" />
        <DataBox panel style={STYLE_DATABOX}>
          <DataBoxHeader>
            <div style={STYLE_HEADER_LEFT}>{_t('element.header')}</div>
            <div style={STYLE_HEADER_RIGHT} />
          </DataBoxHeader>
          <DataBoxContent>
            {DataBoxWithName({
              name: _t('element.dataBoxDayControl.name'),
              node: (
                <MultiSelect
                  items={translateDaysOfWeek()}
                  checkedIds={configuration.controlDays}
                  title={_t('element.dataBoxDayControl.title')}
                  itemNameSingular={_t('element.dataBoxDayControl.itemName')}
                  itemNamePlural={_t(
                    'element.dataBoxDayControl.itemName_plural'
                  )}
                  save={this.saveDaysOfWeek}
                  disabled={!canWrite}
                />
              ),
            })}
            {DataBoxWithName({
              name: _t('element.dataBoxHours.name'),
              node: (
                <TimeInterval
                  from={configuration.startOfDay}
                  to={configuration.endOfDay}
                  onChange={this.changeDailyPeriod}
                  disabled={false}
                />
              ),
            })}
            {DataBoxWithName({
              name: _t('element.dataBoxAlert.name'),
              node: (
                <Checkbox
                  id="alertsEnabled"
                  checked={configuration.alertsEnabled}
                  onCheck={this.handleAlertCheck}
                />
              ),
            })}{' '}
            <SeparatorWithTitle
              style={STYLE_LOGS_TITLE}
              title={_t('element.separatorAlert.title')}
              color={BKG_PINK}
              titleSize={20}
            />
            {DataBoxWithName({
              name: _t('element.dataBoxDistance.name'),
              node: (
                <TextField
                  id="agentMinimumDistance"
                  min={0}
                  type="number"
                  value={configuration.agentMinimumDistance}
                  onChange={this.onAlertConfigurationChange}
                  data-field="agentMinimumDistance"
                />
              ),
            })}
            {DataBoxWithName({
              name: _t('element.dataBoxInactivity.name'),
              node: (
                <TextField
                  id="agentInactivityTime"
                  min={0}
                  type="number"
                  value={configuration.agentInactivityTime}
                  onChange={this.onAlertConfigurationChange}
                  data-field="agentInactivityTime"
                />
              ),
            })}
            {DataBoxWithName({
              name: _t('element.dataBoxActivation.name'),
              node: (
                <TextField
                  id="agentThreshold"
                  min={0}
                  type="number"
                  value={configuration.agentThreshold}
                  onChange={this.onAlertConfigurationChange}
                  data-field="agentThreshold"
                />
              ),
            })}
            <SeparatorWithTitle
              style={STYLE_LOGS_TITLE}
              title={_t('element.separatorAlertLapi.title')}
              color={BKG_PINK}
              titleSize={20}
            />
            {DataBoxWithName({
              name: _t('element.dataBoxDistance.name'),
              node: (
                <TextField
                  id="lapiMinimumDistance"
                  min={0}
                  type="number"
                  value={configuration.lapiMinimumDistance}
                  onChange={this.onAlertConfigurationChange}
                  data-field="lapiMinimumDistance"
                />
              ),
            })}
            {DataBoxWithName({
              name: _t('element.dataBoxInactivity.name'),
              node: (
                <TextField
                  id="lapiInactivityTime"
                  min={0}
                  type="number"
                  value={configuration.lapiInactivityTime}
                  onChange={this.onAlertConfigurationChange}
                  data-field="lapiInactivityTime"
                />
              ),
            })}
            {DataBoxWithName({
              name: _t('element.dataBoxActivation.name'),
              node: (
                <TextField
                  id="lapiThreshold"
                  min={0}
                  type="number"
                  value={configuration.lapiThreshold}
                  onChange={this.onAlertConfigurationChange}
                  data-field="lapiThreshold"
                />
              ),
            })}
            <DataBoxItemWrapper style={STYLE_WRAPPER}>
              <BoButton
                label={_tg('action.save_1')}
                primary
                disabled={!hasChanges || updating}
                onClick={this.submit}
              />
              <BoButton
                label="Annuler"
                disabled={!hasChanges || updating}
                onClick={this.cancel}
              />
            </DataBoxItemWrapper>
            {updating && (
              <DataBoxItemWrapper>
                <CircularProgress />
              </DataBoxItemWrapper>
            )}
          </DataBoxContent>
        </DataBox>
        <Snackbar
          open={!!message}
          message={message}
          autoHideDuration={4000}
          onRequestClose={this.closeErrorSnackBar}
        />
      </Content>
    );
  }
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    canWrite:
      userInfo && userInfo.rights.includes('PLANNING_CONFIGURATION_WRITE'),
  };
})(PlanningConfiguration);
