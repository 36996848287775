import React, { CSSProperties, useState } from 'react';
import { connect } from 'react-redux';

import { MailGroup } from 'api/mail/type';
import {
  MailType,
  MailTypeRAPO,
  MailTypeRefund,
  MailTypeSubscriber,
  MailTypeSubscriberMassAction,
} from '@cvfm-front/tefps-types';
import Content from 'commons/Content';
import SectionTitle from 'commons/SectionTitle';
import DropDownList from 'commons/DropDownList';
import MailTemplateEditor from 'commons/Mail/MailTemplateEditor/MailTemplateEditor';
import { getConfigState } from 'config/duck';
import OrganizationSelector from 'commons/OrganizationSelector';
import { translateMailGroup, translateMailType } from 'commons/Mail/utils';
import { getApiState } from 'api/duck';

const { _t } = window.loadTranslations(__filename);

const CONTENT_DISPLAY_STYLE: CSSProperties = {
  padding: 20,
};

const SELECTION_COL_STYLE: CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '10%',
  marginTop: 20,
  marginBottom: 20,
};

type MailTemplateConfigurationProps = {
  organizationRapoAnswerEnabled: boolean;
  availableMailConfigs: Array<{
    key: MailGroup;
    value: Record<string, string>;
  }>;
};

const MailTemplateConfiguration = ({
  organizationRapoAnswerEnabled,
  availableMailConfigs,
}: MailTemplateConfigurationProps): JSX.Element => {
  const [selectedGroup, setSelectedGroup] = useState<{
    key: MailGroup;
    value: Record<string, string>;
  }>();
  const [selectedMailType, setSelectedMailType] = useState<MailType>();

  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>(
    ''
  );

  const handleMailGroupChange = (group: {
    key: MailGroup;
    value: Record<string, string>;
  }) => {
    setSelectedGroup(group);
    setSelectedMailType(undefined);
  };

  const handleMailTypeChange = (mailType: MailType) => {
    setSelectedMailType(mailType);
  };

  return (
    <Content style={CONTENT_DISPLAY_STYLE}>
      {organizationRapoAnswerEnabled && (
        <>
          <SectionTitle title={_t('element.section.title')} />
          <OrganizationSelector
            organizationId={selectedOrganizationId}
            onChange={(
              _e: React.SyntheticEvent<unknown>,
              _index: number,
              menuItemValue: any
            ) => setSelectedOrganizationId(menuItemValue)}
          />
        </>
      )}
      <SectionTitle title={_t('element.section.title')} />
      <div style={SELECTION_COL_STYLE}>
        <DropDownList
          valueName={selectedGroup && translateMailGroup(selectedGroup.key)}
          onItemClick={handleMailGroupChange}
          hintText={_t('element.dropdownType.hint')}
          floatingLabel={_t('element.dropdownType.label')}
          list={availableMailConfigs}
          renderer={({ key }) => translateMailGroup(key)}
        />
        {selectedGroup && (
          <>
            <DropDownList
              hintText={_t('element.downdownLetter.hint')}
              floatingLabel={_t('element.downdownLetter.label')}
              list={Object.values(selectedGroup.value)}
              onItemClick={handleMailTypeChange}
              valueName={
                selectedMailType && translateMailType(selectedMailType)
              }
              renderer={(mailType: MailType) => translateMailType(mailType)}
            />
          </>
        )}
      </div>
      {selectedMailType && (
        <MailTemplateEditor
          organizationId={selectedOrganizationId}
          mailType={selectedMailType}
          useHeaderFooter={
            selectedMailType !== MailTypeRAPO.RAPO_COMPLEMENT_NOTIFICATION &&
            selectedMailType !== MailTypeRefund.REFUND_NOTIFICATION
          }
          noPreview={
            selectedMailType === MailTypeRAPO.RAPO_COMPLEMENT_NOTIFICATION ||
            selectedMailType === MailTypeRefund.REFUND_NOTIFICATION ||
            selectedGroup?.key === MailGroup.SUBSCRIBER ||
            selectedGroup?.key === MailGroup.SUBSCRIBER_MASS_ACTION
          }
          useSubject={
            selectedGroup?.key === MailGroup.SUBSCRIBER ||
            selectedGroup?.key === MailGroup.SUBSCRIBER_MASS_ACTION
          }
        />
      )}
    </Content>
  );
};
export default connect(state => {
  const {
    organizationRapoAnswerEnabled,
    fpsRefundEnabled,
    modulesConfiguration,
  } = getConfigState(state);
  const { userInfo } = getApiState(state);

  const availableMailConfigs = [];
  if (modulesConfiguration.fps && fpsRefundEnabled) {
    availableMailConfigs.push({ key: MailGroup.REFUND, value: MailTypeRefund });
  }

  if (modulesConfiguration.rapo) {
    availableMailConfigs.push({ key: MailGroup.RAPO, value: MailTypeRAPO });
  }

  if (modulesConfiguration.subscribers) {
    availableMailConfigs.push({
      key: MailGroup.SUBSCRIBER,
      value: MailTypeSubscriber,
    });
    if (userInfo && userInfo.rights.includes('ORDER_MASS_ACTION')) {
      availableMailConfigs.push({
        key: MailGroup.SUBSCRIBER_MASS_ACTION,
        value: MailTypeSubscriberMassAction,
      });
    }
  }

  return {
    organizationRapoAnswerEnabled,
    availableMailConfigs,
  };
})(MailTemplateConfiguration);
