import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';

import { getConfigState } from 'config/duck';
import { FpsContactDTO, FpsContactType } from 'api/fps/types';
import { Address } from '@cvfm-front/tefps-types';

import ContactItem from './ContactItem';
import { buildDisplayName, getContactValue } from './utils';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  fpsContacts: Array<FpsContactDTO>;
  onSend: (arg0: FpsContactDTO) => Promise<void>;
  onDelete: (arg0: FpsContactDTO) => Promise<void>;
  updateMainContact: (arg0: FpsContactDTO) => Promise<void>;
  mainContactId: string | null | undefined;
  style: CSSProperties | undefined;
  sendingRefundLetterEnabled: boolean;
};

const ContactList = ({
  fpsContacts,
  onSend,
  onDelete,
  updateMainContact,
  mainContactId,
  style,
  sendingRefundLetterEnabled,
}: Props): JSX.Element => {
  const setMainContact = async (contact: FpsContactDTO) => {
    await updateMainContact(contact);
  };

  const isValidAddress = (address: Address): boolean => {
    return (
      !address.streetName || !address.postalCode || !address.addressLocality
    );
  };

  const renderByContactType = (
    contact: FpsContactDTO,
    index: number,
    contactType: FpsContactType
  ) => {
    if (contactType === 'EMAIL') {
      return (
        <ContactItem
          key={index}
          value={getContactValue(contact)}
          name={_tg('tefps.dashboard.refund.lastMail')}
          label={_tg('tefps.dashboard.refund.seldMail')}
          sentDatetime={contact.sentDatetime}
          category={buildDisplayName(contact)}
          sendNow={async () => onSend(contact)}
          deleteContact={async () => onDelete(contact)}
        />
      );
    }
    return (
      <ContactItem
        key={index}
        value={getContactValue(contact)}
        name={_tg('tefps.dashboard.refund.lastPost')}
        label={
          sendingRefundLetterEnabled
            ? _tg('tefps.dashboard.refund.sendPosting')
            : _tg('tefps.dashboard.refund.signalSentPosting')
        }
        sentDatetime={contact.sentDatetime}
        category={buildDisplayName(contact)}
        sendNow={async () => onSend(contact)}
        deleteContact={async () => onDelete(contact)}
        isMainContact={mainContactId === contact.id}
        setMainContact={async () => setMainContact(contact)}
        illegibleAddress={contact.illegibleAddress}
        validAddress={
          contact.contactType === 'POSTAL_ADDRESS' &&
          isValidAddress(contact.contact as Address)
        }
      />
    );
  };

  return (
    <div style={style}>
      {fpsContacts.map((contact, index) =>
        renderByContactType(contact, index, contact.contactType)
      )}
    </div>
  );
};

export default connect(state => {
  const { sendingRefundLetterEnabled } = getConfigState(state);
  return {
    sendingRefundLetterEnabled,
  };
})(ContactList);
