import moment from 'moment';

export function buildYearColumns(
  firstColum: string
): { label: string; width: number }[] {
  const oneYearBefore = moment().subtract(11, 'months');
  const columns = [{ label: firstColum, width: 140 }];

  for (let i = 0; i < 12; i += 1) {
    columns.push({ label: oneYearBefore.format('MMM YY'), width: 70 });
    oneYearBefore.add(1, 'M');
  }

  return columns;
}
