import React, { CSSProperties } from 'react';
import { Moment } from 'moment';
import { connect } from 'react-redux';
import CircularProgress from 'material-ui/CircularProgress';
import { Dispatch } from 'redux';

import BoButton from 'facade/BoButton';
import ErrorBlock from 'commons/ErrorBlock';
import SimpleTable from 'commons/SimpleTable';
import {
  computeOfficialStats,
  generateDownloadFileUrlForOfficialReport,
} from 'api/dashboard';
import { getConfigState } from 'config/duck';
import {
  OfficialReportCellDTO,
  OfficialReportRowDTO,
} from 'api/dashboard/types';
import MonthYearPicker from 'commons/MonthYearPicker';
import { InternalApiState } from 'api/duck';
import { ApiError } from 'api/ApiError';
import { openNewAuthentifiedTab } from 'api/helpers';

import { PeriodFilter } from '../types';
import { getReportState, officialReportPeriodChanged } from '../duck';

import {
  FIRST_TAB_ROW_KEYS,
  FIRST_TAB_ROW_KEY_TYPE,
  ROW_TITLES,
  TABLE_COLUMNS,
} from './constants';

const { _tg } = window.loadTranslations(__filename);

const TABLE_STYLE: CSSProperties = {
  padding: 10,
};

const ROW_STYLE: CSSProperties = {
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};

const ROW_ITEM_STYLE: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
};

const ROW_ITEM_WITH_LEFT_BORDER: CSSProperties = {
  ...ROW_ITEM_STYLE,
  borderLeft: '1px solid #777',
  paddingLeft: 10,
  marginLeft: 10,
};

const TITLE_STYLE: CSSProperties = {
  fontFamily: 'roboto',
  fontSize: 14,
};

const SEPARATOR_TITLE_STYLE: CSSProperties = {
  fontFamily: 'roboto',
  fontWeight: 'bold',
};

const STYLE_TXT: CSSProperties = {
  marginRight: 30,
};

const STYLE_PADDING_8: CSSProperties = { padding: 8 };

type State = {
  rapoStats: Map<string, OfficialReportRowDTO> | null | undefined;
  error: Error | null | undefined;
  filterError: string | null | undefined;
};

type ReduxStateProps = {
  period: PeriodFilter;
  isCvfmFpsPartner: boolean;
};

type ReduxDispatchProps = {
  onPeriodChange: (period: PeriodFilter) => void;
};

type Props = ReduxStateProps & ReduxDispatchProps;

type TitleProps = {
  title: string;
  style?: CSSProperties;
};

type CellProps = {
  cellDTO: OfficialReportCellDTO;
  displayFpsCorrelation: boolean;
  legend: string;
};

const INITIAL_STATE: State = {
  rapoStats: null,
  error: null,
  filterError: null,
};

const Legend = ({ isCvfmFpsPartner }: { isCvfmFpsPartner: boolean }) => (
  <div style={{ textAlign: 'center', marginTop: 30 }}>
    <div>
      <span style={{ fontWeight: 'bold' }}>
        {_tg('tefps.dashboard.report.legend')}
      </span>
      <span style={STYLE_PADDING_8}>
        {_tg('tefps.dashboard.report.absoluteValue')}
      </span>
      |
      <span style={STYLE_PADDING_8}>
        {_tg('tefps.dashboard.report.evolutionByYear')}
      </span>
      {isCvfmFpsPartner && (
        <span>
          |
          <span style={STYLE_PADDING_8}>
            {_tg('tefps.dashboard.report.fpsPercentageWithRapo')}
          </span>
        </span>
      )}
    </div>
  </div>
);

const tooltipLegend = (isCvfmFpsPartner: boolean) => {
  let legend = `${_tg('tefps.dashboard.report.absoluteValue')} | ${_tg(
    'tefps.dashboard.report.evolutionByYear'
  )}`;
  if (isCvfmFpsPartner) {
    legend += ` | ${_tg('tefps.dashboard.report.fpsPercentageWithRapo')}`;
  }
  return legend;
};

const Title = ({ title, style = TITLE_STYLE }: TitleProps) => (
  <span style={style}>{title}</span>
);

const percentage = (
  value: number | null | undefined,
  withSign = false
): string => {
  if (value != null && isFinite(value)) {
    const sign = withSign && value > 0 ? '+' : '';
    return `${sign +
      value.toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
      })}%`;
  }
  return '-';
};

const formatValue = (value: number | null | undefined) => {
  if (value != null && isFinite(value)) {
    return value.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    });
  }
  return '-';
};

const formatCorrelation = (correlation: number | null | undefined) => {
  if (correlation != null && isFinite(correlation)) {
    return percentage(100 * correlation);
  }
  return '-';
};

const Cell = ({
  cellDTO: { value, evolution, correlation },
  displayFpsCorrelation,
  legend,
}: CellProps) => (
  <div style={ROW_STYLE} title={legend}>
    <div style={ROW_ITEM_STYLE}>{formatValue(value)}</div>
    <div style={ROW_ITEM_WITH_LEFT_BORDER}>{percentage(evolution, true)}</div>
    {displayFpsCorrelation && (
      <div style={ROW_ITEM_WITH_LEFT_BORDER}>
        {formatCorrelation(correlation)}
      </div>
    )}
  </div>
);

const Loading = ({ loading }: { loading: boolean }) =>
  loading ? (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress />
    </div>
  ) : (
    <div />
  );

class OfficialPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    void this.fetchStat();
  }

  onChangeStart = (start: Moment) => {
    const {
      period: { end },
    } = this.props;
    this.updatePeriodInStore({ start: start.startOf('month'), end });
  };
  onChangeEnd = (end: Moment) => {
    const {
      period: { start },
    } = this.props;
    this.updatePeriodInStore({ start, end: end.endOf('month') });
  };

  fetchStat = async () => {
    const { period } = this.props;
    const { start, end } = period;
    if (!this.isPeriodValid(period)) {
      return;
    }
    const startDate = start.toISOString();
    const endDate = end.toISOString();
    this.setState(INITIAL_STATE);
    try {
      const rapoStats = await computeOfficialStats(startDate, endDate);
      this.setState({ rapoStats, filterError: null });
    } catch (error) {
      this.setState({ error: error as ApiError, filterError: null });
    }
  };

  isPeriodValid = (period: PeriodFilter) => {
    const { start, end } = period;
    const filterError = start.isAfter(end)
      ? _tg('feedback.error.startDateShouldBeBeforeEndDate')
      : null;
    this.setState({ filterError });
    return !filterError;
  };

  updatePeriodInStore = (period: PeriodFilter) => {
    const { onPeriodChange } = this.props;
    this.isPeriodValid(period);
    onPeriodChange(period);
  };

  itemRenderer = (key: FIRST_TAB_ROW_KEY_TYPE) => {
    const { isCvfmFpsPartner } = this.props;
    const { rapoStats } = this.state;
    const title = ROW_TITLES()[key];
    // when there is no title trad for a row, it means that it's a separator line
    if (!title) {
      return ['', '', '', ''];
    }
    const row = rapoStats ? (rapoStats[key] as OfficialReportRowDTO) : null;
    if (row) {
      const legend = tooltipLegend(isCvfmFpsPartner);
      return [
        <Title title={title} />,
        <Cell
          cellDTO={row.global}
          displayFpsCorrelation={isCvfmFpsPartner}
          legend={legend}
        />,
        <Cell
          cellDTO={row.resident}
          displayFpsCorrelation={isCvfmFpsPartner}
          legend={legend}
        />,
        <Cell
          cellDTO={row.nonResident}
          displayFpsCorrelation={isCvfmFpsPartner}
          legend={legend}
        />,
      ];
    }
    return [<Title title={title} style={SEPARATOR_TITLE_STYLE} />, '', '', ''];
  };

  openPdfUrl = () => {
    const {
      period: { start, end },
    } = this.props;

    const url = generateDownloadFileUrlForOfficialReport(
      start ? start.toISOString() : '',
      end ? end.toISOString() : ''
    );
    openNewAuthentifiedTab(url);
  };

  render() {
    const { rapoStats, error, filterError } = this.state;
    const {
      isCvfmFpsPartner,
      period: { start, end },
    } = this.props;

    if (error) {
      return (
        <ErrorBlock
          message={_tg('feedback.error.errorFetchingReportData')}
          error={error}
        />
      );
    }

    return (
      <div>
        <div
          style={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            justifyItems: 'center',
            marginTop: 20,
          }}
        >
          <BoButton
            onClick={this.openPdfUrl}
            label={_tg('tefps.dashboard.report.printPdf')}
            disabled={filterError != null}
          />
          <div style={{ ...STYLE_TXT, marginLeft: 30 }}>
            {_tg('field.date.fromCapitalized')}
          </div>
          <MonthYearPicker date={start} changeDate={this.onChangeStart} />
          <div style={STYLE_TXT}>{_tg('field.date.to')}</div>
          <MonthYearPicker date={end} changeDate={this.onChangeEnd} />
          <BoButton
            onClick={this.fetchStat}
            label={_tg('commons.toFilter')}
            disabled={filterError != null}
          />
        </div>
        <div style={{ color: '#C00', textAlign: 'center' }}>{filterError}</div>
        {rapoStats ? (
          <div style={TABLE_STYLE}>
            <SimpleTable
              maxHeight={2500}
              cols={TABLE_COLUMNS()}
              rowHeight={65}
              header
              itemsRenderer={this.itemRenderer}
              items={FIRST_TAB_ROW_KEYS}
            />
            <Legend isCvfmFpsPartner={isCvfmFpsPartner} />
          </div>
        ) : (
          <Loading loading={filterError == null} />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: InternalApiState): ReduxStateProps => {
  const period = getReportState(state);
  const { cvfmFpsPartner: isCvfmFpsPartner } = getConfigState(state);
  return {
    period,
    isCvfmFpsPartner,
  };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): any => ({
  onPeriodChange: (period: PeriodFilter) =>
    dispatch(officialReportPeriodChanged(period)),
});

export default connect<ReduxStateProps, ReduxDispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(OfficialPage);
