import React, { CSSProperties, useState } from 'react';
import moment, { Moment } from 'moment';
import Dialog from 'material-ui/Dialog';
import Checkbox from 'material-ui/Checkbox';
import TextField from 'material-ui/TextField';
import SelectField from 'material-ui/SelectField';
import MenuItem from 'material-ui/MenuItem';

import { PaymentChannel, PaymentMode, RefundPayment } from 'api/commonTypes';
import { getActions } from 'commons/ModalUtils';
import DateAndTimeSelector from 'commons/DateAndTimeSelector';
import {
  formatCentsToEuro,
  translatePaymentMode,
  translatePaymentOriginAntai,
  translateRefundOrigin,
} from 'commons/Utils/paymentUtil';
import { BKG_CYAN, TXT_RED } from 'theme';

const { _tg } = window.loadTranslations(__filename);

const STYLE_TITLE: CSSProperties = {
  backgroundColor: BKG_CYAN,
  color: '#ffffff',
  fontWeight: 'bold',
};

const STYLE_CONTENT_WRAPPER: CSSProperties = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignContent: 'center',
};

const STYLE_PRICE_INPUT: CSSProperties = {
  color: BKG_CYAN,
  fontWeight: 'bold',
  fontSize: 40,
};

const STYLE_INPUTS: CSSProperties = {
  borderColor: BKG_CYAN,
  color: BKG_CYAN,
};

const STYLE_WARNING: CSSProperties = {
  borderColor: BKG_CYAN,
  color: TXT_RED,
  width: '60%',
};

const origins = translateRefundOrigin().map(value => (
  <MenuItem key={value.key} value={value} primaryText={value.label} />
));

const originsAntai = translatePaymentOriginAntai().map(value => (
  <MenuItem key={value.key} value={value} primaryText={value.label} />
));

type Props = {
  onSubmit: (
    refundPayment: RefundPayment,
    amount: number | null | undefined
  ) => void;
  isOpen: boolean;
  onClose: () => void;
  amount: number | null | undefined;
};

function RefundInfoModal({
  onSubmit,
  onClose,
  isOpen,
  amount,
}: Props): JSX.Element {
  const [origin, setOrigin] = useState(translateRefundOrigin()[0]);
  const [mode, setMode] = useState<PaymentMode>('TRANSFERT');
  const [date, setDate] = useState<Moment>(moment());
  const [price, setPrice] = useState<number | null | undefined>(amount);
  const [comment, setComment] = useState('');
  const [isAntaiRefund, setIsAntaiRefund] = useState(false);

  function onClickValidate() {
    onSubmit(
      {
        paymentDatetime: date,
        paymentChannel: origin.key,
        paymentMode: mode,
        comment,
      },
      price
    );
  }

  function onChangeComment(
    e: React.ChangeEvent<HTMLInputElement>,
    newComment: string
  ) {
    setComment(newComment);
  }

  function changeOrigin(
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    update: {
      key: PaymentChannel;
      label: string;
      paymentModes: Array<PaymentMode>;
    }
  ) {
    setOrigin(update);
    setMode(update.paymentModes[0]);
  }

  function changeMode(
    e: React.ChangeEvent<HTMLInputElement>,
    i: number,
    updateMode: PaymentMode
  ) {
    setMode(updateMode);
  }

  function handleAntaiCheck(e: any, isInputChecked: boolean) {
    const updatedOrigin = (isInputChecked
      ? translatePaymentOriginAntai()
      : translateRefundOrigin())[0];
    setIsAntaiRefund(isInputChecked);
    setOrigin(updatedOrigin);
    setMode(updatedOrigin.paymentModes[0]);
  }

  function onUpdateDate(newDate: Moment) {
    setDate(newDate);
  }

  function onChangePrice(event: React.FormEvent<any>, value: string) {
    setPrice(Number(value) * 100);
  }

  const actions = getActions(onClickValidate, onClose);
  const warningText = _tg(
    'tefps.dashboard.refund.eachFPSWillBeMarkedAsRefunded'
  );

  return (
    <Dialog
      title={_tg('tefps.dashboard.refund.fpsRefund')}
      actions={actions}
      modal
      autoScrollBodyContent
      open={isOpen}
      titleStyle={STYLE_TITLE}
    >
      <div style={STYLE_CONTENT_WRAPPER}>
        <TextField
          floatingLabelText={_tg('tefps.dashboard.refund.refundPriceAlternate')}
          value={(price && formatCentsToEuro(price)) || undefined}
          type="number"
          min={0}
          step={0.01}
          onChange={onChangePrice}
          inputStyle={STYLE_PRICE_INPUT}
          style={{ height: 100 }}
          disabled={amount === null}
        />
        {amount === null && <span style={STYLE_WARNING}>{warningText}</span>}
      </div>
      <div style={STYLE_CONTENT_WRAPPER}>
        <Checkbox
          label={_tg('tefps.dashboard.refund.refundForAntai')}
          checked={isAntaiRefund}
          onCheck={handleAntaiCheck}
          style={{ margin: '10px 0' }}
        />
      </div>
      <div style={STYLE_CONTENT_WRAPPER}>
        <SelectField
          underlineFocusStyle={STYLE_INPUTS}
          value={origin}
          onChange={changeOrigin}
          floatingLabelText={_tg('field.payment.origin')}
          disabled={isAntaiRefund}
        >
          {isAntaiRefund ? originsAntai : origins}
        </SelectField>
        <DateAndTimeSelector
          date={date}
          onUpdate={onUpdateDate}
          style={STYLE_INPUTS}
        />
      </div>
      <div style={STYLE_CONTENT_WRAPPER}>
        <SelectField
          underlineFocusStyle={STYLE_INPUTS}
          value={mode}
          onChange={changeMode}
          floatingLabelText={_tg('field.payment.mode')}
        >
          {origin.paymentModes.map((key: PaymentMode) => (
            <MenuItem
              key={key}
              value={key}
              primaryText={translatePaymentMode()[key]}
            />
          ))}
        </SelectField>
      </div>
      <TextField
        underlineFocusStyle={STYLE_INPUTS}
        floatingLabelFocusStyle={STYLE_INPUTS}
        value={comment}
        onChange={onChangeComment}
        floatingLabelText={_tg('field.comment')}
        fullWidth
        multiLine
        rows={3}
        rowsMax={3}
      />
    </Dialog>
  );
}

export default RefundInfoModal;
