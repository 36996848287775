import React, { CSSProperties, useState } from 'react';
import { connect } from 'react-redux';

import Content from 'commons/Content';
import AdminTags from 'Configuration/Rapo/AdminTags';
import SeparatorWithTitle from 'commons/SeparatorWithTitle';
import { BKG_PINK } from 'theme';
import { getApiState } from 'api/duck';
import { TagsTypes } from 'api/tags';

const { _t } = window.loadTranslations(__filename);

const STYLE_LOGS_TITLE: CSSProperties = {
  padding: '0 30px',
  margin: '30px 0px 0px',
};

interface IProps {
  organizationId: string | null | undefined;
}

const SubscriptionConfiguration = ({ organizationId }: IProps) => {
  const [alert, setAlert] = useState<string | null | undefined>('');

  const openErrorSnackBar = (alert: string) => setAlert(alert);

  return (
    <Content>
      <SeparatorWithTitle
        style={STYLE_LOGS_TITLE}
        title={_t('element.separatorLabel.title')}
        color={BKG_PINK}
        titleSize={20}
      />
      <AdminTags
        organizationId={organizationId}
        type={TagsTypes.order}
        showMessage={openErrorSnackBar}
      />
    </Content>
  );
};

export default connect(state => {
  const { userInfo } = getApiState(state);

  return {
    organizationId: userInfo?.worksFor?.organizationId,
  };
})(SubscriptionConfiguration);
