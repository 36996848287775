import React, { useState, useEffect } from 'react';
import moment from 'moment';

import BoButton from 'facade/BoButton';
import { TimeSlot } from 'api/lapiReviewConfiguration/types';
import { DataBoxWithName } from 'commons/DataBox';
import TimeInterval from 'tefps/Pricing/Policies/components/TimeInterval';
import { TXT_RED } from 'theme';

const { _tg } = window.loadTranslations(__filename);

const ERROR_TEXT_STYLE = {
  fontSize: 12,
  fontFamily: 'Roboto',
  marginTop: 10,
  color: TXT_RED,
};

const COLUMN_STYLE: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'column',
};

const INTERVAL_WRAPPER_STYLE = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 20,
};

const BUTTON_WRAPPER_STYLE = {
  display: 'flex',
  justifyContent: 'center',
};

type Props = {
  timeSlots: Array<TimeSlot>;
  deleteSlot: (index: number) => void;
  addNewSlot: (newSlot: TimeSlot) => void;
};

const TimeSlots = ({
  timeSlots,
  addNewSlot,
  deleteSlot,
}: Props): JSX.Element => {
  const [creationEnabled, setCreationEnabled] = useState<boolean>(false);
  const [newSlot, setNewSlot] = useState<TimeSlot>({ start: '', end: '' });
  const [valid, setValid] = useState<boolean>(true);

  const enableCreation = () => {
    setNewSlot({ start: '00:00', end: '23:59' });
    setCreationEnabled(true);
  };

  useEffect(() => {
    let newValid = true;
    const newSlotStartMoment = moment(newSlot.start, 'HH:mm');
    const newSlotEndMoment = moment(newSlot.end, 'HH:mm');
    if (
      moment.duration(newSlotEndMoment.diff(newSlotStartMoment)).asMinutes() <
      15
    ) {
      newValid = false;
    }
    newValid =
      newValid &&
      !timeSlots.some(timeSlot => {
        const slotStartMoment = moment(timeSlot.start, 'HH:mm');
        const slotEndMoment = moment(timeSlot.end, 'HH:mm');
        if (
          newSlotStartMoment.isBetween(
            slotStartMoment,
            slotEndMoment,
            'minute'
          ) ||
          newSlotEndMoment.isBetween(
            slotStartMoment,
            slotEndMoment,
            'minute'
          ) ||
          (newSlotStartMoment.isBefore(slotStartMoment, 'minute') &&
            newSlotEndMoment.isAfter(slotEndMoment, 'minute'))
        ) {
          return true;
        }
        return false;
      });
    setValid(newValid);
  }, [newSlot]);

  const addSlot = () => {
    if (valid) {
      addNewSlot(newSlot);
      setCreationEnabled(false);
    }
  };

  const onNewSlotChange = (from: string, to: string) => {
    setNewSlot({ start: from, end: to });
  };

  return (
    <div style={{ width: '100%' }}>
      {DataBoxWithName({
        name: 'Liste des créneaux horaires: ',
        node: (
          <>
            {timeSlots.map((timeSlot, index) => {
              return (
                <div style={INTERVAL_WRAPPER_STYLE}>
                  <TimeInterval
                    from={timeSlot.start}
                    to={timeSlot.end}
                    disabled
                  />
                  <BoButton
                    label="Supprimer"
                    secondary
                    onClick={() => deleteSlot(index)}
                  />
                </div>
              );
            })}
            {!creationEnabled && (
              <div style={BUTTON_WRAPPER_STYLE}>
                <BoButton
                  label="Créer un nouveau créneau horaire"
                  onClick={enableCreation}
                />
              </div>
            )}
            {creationEnabled && (
              <div style={INTERVAL_WRAPPER_STYLE}>
                <div style={COLUMN_STYLE}>
                  <TimeInterval
                    from={newSlot.start}
                    to={newSlot.end}
                    onChange={onNewSlotChange}
                    disabled={false}
                  />
                  {!valid && (
                    <span style={ERROR_TEXT_STYLE}>
                      {_tg('feedback.error.slotIntervalError')}
                    </span>
                  )}
                </div>
                <BoButton
                  label="Ajouter"
                  primary
                  onClick={addSlot}
                  disabled={!valid}
                />
              </div>
            )}
          </>
        ),
      })}
    </div>
  );
};

export default TimeSlots;
