import moment from 'moment';

import { FilterDate } from 'commons/SidebarV2/Components/Dates';
import { SortParameters } from 'api/commonTypes';

import { StatsAgentsTepvSearchFiltersParam } from './types';

export const EXPORT_COL = [
  { key: 'name', label: "Nom de l'agent", checked: true },
  { key: 'count', label: 'Nombre de MIF', checked: true },
  { key: 'lastMif', label: 'Date du dernier MIF' },
];

export const EXPORT_COL_ORG = [
  { key: 'name', label: "Nom de l'organisation", checked: true },
  { key: 'count', label: 'Nombre de MIF', checked: true },
  { key: 'lastMif', label: 'Date du dernier MIF' },
];

const mifColumns = ['agentName', 'nbrOfMif', 'lastMif'];
const mifColumnsOrg = ['orgName', 'nbrOfMif', 'lastMif'];

function getSortFieldText(sortFieldNumber: number, type: string) {
  return type === 'agent'
    ? mifColumns[sortFieldNumber]
    : mifColumnsOrg[sortFieldNumber];
}

export const filtersToRequest = (
  period: FilterDate,
  sort: SortParameters,
  type: string
): StatsAgentsTepvSearchFiltersParam => {
  const { from, to } = period;
  return {
    statementStartDatetime: from ? moment(from).toISOString() : undefined,
    statementEndDatetime: to ? moment(to).toISOString() : undefined,
    sortOrder: sort.increasingOrder ? 'ASC' : 'DESC',
    sortField: getSortFieldText(sort.sortField, type),
  };
};
