import React from 'react';
import moment from 'moment';

import { FilterDate } from 'commons/SidebarV2/Components/Dates';
import { PvReportDTO } from 'api/tepv/stats/types';
import { getAgentReport } from 'api/tepv/stats';

import Report from './report';

type State = {
  report: PvReportDTO | null | undefined;
  period: FilterDate;
  error: Error | null | undefined;
};

// eslint-disable-next-line @typescript-eslint/ban-types
class AgentReport extends React.Component<{}, State> {
  // eslint-disable-next-line @typescript-eslint/ban-types
  constructor(props = {}) {
    super(props);
    this.state = {
      report: null,
      period: {
        from: undefined,
        to: undefined,
      },
      error: null,
    };
  }

  componentDidMount(): void {
    const { period } = this.state;
    void this.loadReport(period);
  }

  onChangeDate = (period: FilterDate): void => {
    this.setState({ period });
    void this.loadReport(period);
  };

  loadReport = async ({ from, to }: FilterDate): Promise<void> => {
    const filter = {
      statementStartDatetime: from ? moment(from).toISOString() : null,
      statementEndDatetime: to ? moment(to).toISOString() : null,
    };
    try {
      const report = await getAgentReport(filter);
      this.setState({ report, error: null });
    } catch (error) {
      this.setState({ error: error as Error });
    }
  };

  render(): React.ReactNode {
    const { report, period, error } = this.state;
    return (
      <Report
        type="agent"
        report={report}
        error={error}
        period={period}
        onChangeDate={this.onChangeDate}
      />
    );
  }
}

export default AgentReport;
