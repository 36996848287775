import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import {
  Link,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import MissNotFound from 'commons/MissNotFound';
import ErrorBlock from 'commons/ErrorBlock';
import FlexCenter from 'commons/FlexCenter';
import { BKG_CYAN, TXT_BLACK } from 'theme';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import { getConfigState } from 'config/duck';
import { isDisplayed } from 'commons/helpers/ModulesConfiguration';
import { RIGHT_GROUPS } from 'Administration/Profiles/ProfileModal/helpers';
import { AgentRight } from '@cvfm-front/tefps-types';

import ControlStrategy from './ControlStrategy';
import Finance from './Finance';
import Report from './Report';
import EnqueteLapi from './EnqueteLapi';
import Strategy from './Strategy';
import StatsAgentsList from './StatsAgents';
import LapiDriverStats from './LapiDriverStats';
import Subscribers from './Subscribers';
import PaymentList from './Payment/List';
import RefundList from './Refund/List';
import SubscribersPaymentList from './SubscribersPayment/List';
import SubscriptionsStats from './SubscriptionsStats';
import LapiReviewReport from './LapiReviewReport';
import RefundMailEditor from './Refund/List/RefundMailEditor/RefundMailEditor';
import Graphics from './Graphics';
import TvPayments from './TvPayments';
import LapiReviewStats from './LapiReviewStats';

const { _tg } = window.loadTranslations(__filename);

const STYLE_MENU: CSSProperties = {
  display: 'flex',
  height: '100%',
  fontFamily: 'Roboto',
  width: '60%',
  margin: '0 auto',
};

const STYLE_CONTAINER: CSSProperties = {
  flex: 1,
  flexWrap: 'wrap',
  color: TXT_BLACK,
  alignItems: 'center',
  margin: 20,
};

const STYLE_LINKS: CSSProperties = {
  borderRadius: 10,
  borderTop: `1px solid ${BKG_CYAN}`,
  borderLeft: `1px solid ${BKG_CYAN}`,
  borderRight: `1px solid ${BKG_CYAN}`,
  backgroundColor: 'white',
  overflow: 'hidden',
};

const STYLE_TITLE: CSSProperties = {
  fontSize: 25,
  margin: '20px 0',
  textAlign: 'center',
};

const STYLE_MODULE: CSSProperties = {
  height: 100,
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  borderBottom: `1px solid ${BKG_CYAN}`,
  fontWeight: 'bold',
  fontSize: 25,
  flex: 1,
};

type DashboardHomeProps = {
  userInfo: InternalAgent;
  withRapos: boolean;
  withFpses: boolean;
  withSubscriptions: boolean;
  withControls: boolean;
  withLapiReview: boolean;
  withRefund: boolean;
  withTV: boolean;
};

const LINKS: {
  [key: string]: {
    to: string;
    label: () => string;
    rightsNeeded: Array<AgentRight>;
    oneRightNeeded?: Array<AgentRight>;
  };
} = {
  // stats
  reports: {
    to: '/dashboard/reports',
    label: () => _tg('tefps.dashboard.stats.reports.label'),
    rightsNeeded: [],
    oneRightNeeded: ['BACKOFFICE_DASHBOARD_FPS', 'BACKOFFICE_DASHBOARD_RAPO'],
  },
  suiviSubs: {
    to: '/dashboard/subscriptions',
    label: () => _tg('tefps.dashboard.stats.suiviSubs.label'),
    rightsNeeded: [
      'BACKOFFICE_DASHBOARD_SUBSCRIBERS',
      'SUBSCRIBER_READ',
      'SUBSCRIPTION_PLAN_READ',
    ],
  },
  graphics: {
    to: '/dashboard/graphics',
    label: () => _tg('tefps.dashboard.stats.graphics.label'),
    rightsNeeded: ['BACKOFFICE_DASHBOARD_GRAPHICS'],
    oneRightNeeded: ['CONTROL_READ', 'FPS_READ', 'RAPO_READ', 'TV_READ'],
  },
  // agents
  suivi: {
    to: '/dashboard/statsAgents',
    label: () => _tg('tefps.dashboard.agents.suivi.label'),
    rightsNeeded: ['BACKOFFICE_DASHBOARD_FPS'],
  },
  subscribers: {
    to: '/dashboard/subscribers',
    label: () => _tg('tefps.dashboard.agents.subscribers.label'),
    rightsNeeded: ['BACKOFFICE_DASHBOARD_SUBSCRIBERS'],
  },
  lapiReviewReport: {
    to: '/dashboard/lapiReviewReport',
    label: () => _tg('tefps.dashboard.agents.lapiReviewReport.label'),
    rightsNeeded: ['BACKOFFICE_DASHBOARD_LAPI'],
  },
  lapiDriverStats: {
    to: '/dashboard/lapiDriverStats',
    label: () => _tg('tefps.dashboard.agents.lapiDriverStats.label'),
    rightsNeeded: ['BACKOFFICE_DASHBOARD_LAPI', 'CONTROL_READ'],
  },
  lapiReviewStats: {
    to: '/dashboard/lapiReviewStats',
    label: () => _tg('tefps.dashboard.agents.lapiReviewStats.label'),
    rightsNeeded: ['BACKOFFICE_DASHBOARD_LAPI'],
  },
  // finance
  finance: {
    to: '/dashboard/finance',
    label: () => _tg('tefps.dashboard.finance.finance.label'),
    rightsNeeded: ['BACKOFFICE_DASHBOARD_FINANCE_FPS'],
  },
  payment: {
    to: '/dashboard/payment',
    label: () => _tg('tefps.dashboard.finance.payment.label'),
    rightsNeeded: ['BACKOFFICE_DASHBOARD_FINANCE_FPS'],
  },
  tvPayment: {
    to: '/dashboard/tvPayment',
    label: () => _tg('tefps.dashboard.finance.tvPayment.label'),
    rightsNeeded: ['BACKOFFICE_TV_EXPORT'],
  },
  refund: {
    to: '/dashboard/refund',
    label: () => _tg('tefps.dashboard.finance.refund.label'),
    rightsNeeded: ['BACKOFFICE_DASHBOARD_REFUND', 'FPS_REFUND'],
  },
  subscribersPayment: {
    to: '/dashboard/subscribersPayment',
    label: () => _tg('tefps.dashboard.finance.subscribersPayment.label'),
    rightsNeeded: [
      'BACKOFFICE_DASHBOARD_FINANCE_SUBSCRIBERS',
      'SUBSCRIBER_ACTIVE_READ',
    ],
  },
  // maps
  fpsStrategy: {
    to: '/dashboard/strategy',
    label: () => _tg('tefps.dashboard.maps.fpsStrategy.label'),
    rightsNeeded: ['BACKOFFICE_DASHBOARD_FPS', 'FPS_READ'],
  },
  controlStrategy: {
    to: '/dashboard/controlStrategy',
    label: () => _tg('tefps.dashboard.maps.controlStrategy.label'),
    rightsNeeded: ['BACKOFFICE_DASHBOARD_CONTROL', 'CONTROL_READ'],
  },
};

const hasRightsNeeded = (userInfo: InternalAgent, link: string) => {
  if (
    LINKS[link].oneRightNeeded?.every(
      (right: AgentRight) => !userInfo.rights.includes(right)
    )
  ) {
    return false;
  }
  return LINKS[link].rightsNeeded.every((right: AgentRight) =>
    userInfo.rights.includes(right)
  );
};

const ModuleLink = ({
  link,
  displayed,
  userInfo,
}: {
  link: string;
  displayed: boolean;
  userInfo: InternalAgent;
}) => {
  if (!displayed) {
    return null;
  }
  if (!hasRightsNeeded(userInfo, link)) {
    return null;
  }

  return (
    <Link key={link} to={LINKS[link].to} style={STYLE_MODULE}>
      {LINKS[link].label()}
    </Link>
  );
};

const hasStatistics = ({
  withControls,
  withFpses,
  withRapos,
  withSubscriptions,
  withTV,
  userInfo,
}: {
  withControls: boolean;
  withFpses: boolean;
  withRapos: boolean;
  withSubscriptions: boolean;
  withTV: boolean;
  userInfo: InternalAgent;
}) =>
  ((withFpses || withRapos) && hasRightsNeeded(userInfo, 'reports')) ||
  (withSubscriptions && hasRightsNeeded(userInfo, 'suiviSubs')) ||
  ((withControls || withFpses || withRapos || withTV) &&
    hasRightsNeeded(userInfo, 'graphics'));

const hasAgents = ({
  withFpses,
  withSubscriptions,
  withControls,
  withLapiReview,
  userInfo,
}: {
  withFpses: boolean;
  withSubscriptions: boolean;
  withControls: boolean;
  withLapiReview: boolean;
  userInfo: InternalAgent;
}) =>
  ((withFpses || withControls) && hasRightsNeeded(userInfo, 'suivi')) ||
  (withSubscriptions && hasRightsNeeded(userInfo, 'subscribers')) ||
  (withLapiReview && hasRightsNeeded(userInfo, 'lapiReviewReport')) ||
  (withControls && hasRightsNeeded(userInfo, 'lapiDriverStats')) ||
  (withControls && hasRightsNeeded(userInfo, 'lapiReviewStats'));

const hasFinance = ({
  withFpses,
  withSubscriptions,
  userInfo,
}: {
  withFpses: boolean;
  withSubscriptions: boolean;
  userInfo: InternalAgent;
}) =>
  (withFpses && hasRightsNeeded(userInfo, 'finance')) ||
  (withFpses && hasRightsNeeded(userInfo, 'payment')) ||
  (withFpses && hasRightsNeeded(userInfo, 'refund')) ||
  (withSubscriptions && hasRightsNeeded(userInfo, 'subscribersPayment'));

const hasCartography = ({
  withFpses,
  withControls,
  userInfo,
}: {
  withFpses: boolean;
  withControls: boolean;
  userInfo: InternalAgent;
}) =>
  (withFpses && hasRightsNeeded(userInfo, 'fpsStrategy')) ||
  (withControls && hasRightsNeeded(userInfo, 'controlStrategy'));

const DashboardHome = ({
  userInfo,
  withSubscriptions,
  withFpses,
  withControls,
  withRapos,
  withLapiReview,
  withRefund,
  withTV,
}: DashboardHomeProps) => {
  if (
    !RIGHT_GROUPS(true, false)
      .find(group => group.value === 'BACKOFFICE_DASHBOARD')
      ?.rights?.some(right => userInfo.rights.includes(right))
  ) {
    return (
      <FlexCenter>
        <ErrorBlock
          error={{
            message: _tg('tefps.dashboard.noAccess'),
          }}
        />
      </FlexCenter>
    );
  }

  return (
    <div>
      <div style={STYLE_MENU}>
        {hasStatistics({
          withControls,
          withFpses,
          withRapos,
          withSubscriptions,
          withTV,
          userInfo,
        }) && (
          <div style={STYLE_CONTAINER}>
            <div style={STYLE_TITLE}>
              {_tg('tefps.dashboard.stats.agentStats.label')}
            </div>
            <div style={STYLE_LINKS}>
              <ModuleLink
                link="reports"
                displayed={withFpses || withRapos}
                userInfo={userInfo}
              />
              <ModuleLink
                link="suiviSubs"
                displayed={withSubscriptions}
                userInfo={userInfo}
              />
              <ModuleLink
                link="graphics"
                displayed={withControls || withFpses || withRapos || withTV}
                userInfo={userInfo}
              />
            </div>
          </div>
        )}
        {hasAgents({
          withFpses,
          withSubscriptions,
          withControls,
          withLapiReview,
          userInfo,
        }) && (
          <div style={STYLE_CONTAINER}>
            <div style={STYLE_TITLE}>{_tg('tefps.dashboard.agents.label')}</div>
            <div style={STYLE_LINKS}>
              <ModuleLink
                link="suivi"
                displayed={withFpses || withControls}
                userInfo={userInfo}
              />
              <ModuleLink
                link="subscribers"
                displayed={withSubscriptions}
                userInfo={userInfo}
              />
              <ModuleLink
                link="lapiReviewReport"
                displayed={withLapiReview}
                userInfo={userInfo}
              />
              <ModuleLink
                link="lapiDriverStats"
                displayed={withControls}
                userInfo={userInfo}
              />
              <ModuleLink
                link="lapiReviewStats"
                displayed={withLapiReview}
                userInfo={userInfo}
              />
            </div>
          </div>
        )}
      </div>
      <div style={STYLE_MENU}>
        {hasFinance({ withFpses, withSubscriptions, userInfo }) && (
          <div style={STYLE_CONTAINER}>
            <div style={STYLE_TITLE}>
              {_tg('tefps.dashboard.finance.label')}
            </div>
            <div style={STYLE_LINKS}>
              <ModuleLink
                link="finance"
                displayed={withFpses}
                userInfo={userInfo}
              />
              <ModuleLink
                link="payment"
                displayed={withFpses}
                userInfo={userInfo}
              />
              <ModuleLink
                link="tvPayment"
                displayed={withTV}
                userInfo={userInfo}
              />
              {withRefund && (
                <ModuleLink
                  link="refund"
                  displayed={withFpses}
                  userInfo={userInfo}
                />
              )}
              <ModuleLink
                link="subscribersPayment"
                displayed={withSubscriptions}
                userInfo={userInfo}
              />
            </div>
          </div>
        )}
        {hasCartography({ withFpses, withControls, userInfo }) && (
          <div style={STYLE_CONTAINER}>
            <div style={STYLE_TITLE}>{_tg('tefps.dashboard.maps.label')}</div>
            <div style={STYLE_LINKS}>
              <ModuleLink
                link="fpsStrategy"
                displayed={withFpses}
                userInfo={userInfo}
              />
              <ModuleLink
                link="controlStrategy"
                displayed={withControls}
                userInfo={userInfo}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ConnectedHome = connect(state => {
  const { userInfo } = getApiState(state);
  const {
    modulesConfiguration: { subscribers, fps, control, rapo, lapiReview, tv },
    fpsRefundEnabled,
  } = getConfigState(state);
  return {
    userInfo,
    withSubscriptions: isDisplayed(subscribers),
    withFpses: isDisplayed(fps),
    withControls: isDisplayed(control),
    withRapos: isDisplayed(rapo),
    withLapiReview: isDisplayed(lapiReview),
    withRefund: fpsRefundEnabled,
    withTV: isDisplayed(tv),
  };
})(DashboardHome);

const FpsDashboard = ({
  match,
  withFpses,
  withRapos,
  withSubscriptions,
  withControls,
  withLapiReview,
  withRefund,
  withTV,
  userInfo,
}: RouteComponentProps & {
  withFpses: boolean;
  withRapos: boolean;
  withSubscriptions: boolean;
  withControls: boolean;
  withLapiReview: boolean;
  withRefund: boolean;
  withTV: boolean;
  userInfo: InternalAgent;
}) => (
  <div style={{ height: '100%', margin: 'auto' }}>
    <Switch>
      <Route path={`${match.url}`} exact component={ConnectedHome} />
      {(withFpses || withControls) && hasRightsNeeded(userInfo, 'suivi') && (
        <Route
          path={`${match.url}/statsAgents`}
          exact
          component={StatsAgentsList}
        />
      )}
      {withControls && hasRightsNeeded(userInfo, 'lapiDriverStats') && (
        <Route
          path={`${match.url}/lapiDriverStats`}
          exact
          component={LapiDriverStats}
        />
      )}
      {withFpses && hasRightsNeeded(userInfo, 'finance') && (
        <Route path={`${match.url}/finance`} exact component={Finance} />
      )}
      {withFpses && hasRightsNeeded(userInfo, 'payment') && (
        <Route path={`${match.url}/payment`} exact component={PaymentList} />
      )}
      {withFpses && hasRightsNeeded(userInfo, 'payment') && (
        <Route path="/dashboard/tvPayment" exact component={TvPayments} />
      )}
      {withFpses && hasRightsNeeded(userInfo, 'refund') && (
        <Route path={`${match.url}/refund`} exact component={RefundList} />
      )}
      {withFpses && hasRightsNeeded(userInfo, 'refund') && (
        <Route
          path={`${match.url}/refund/:refundId/mail`}
          exact
          component={RefundMailEditor}
        />
      )}
      {withFpses && hasRightsNeeded(userInfo, 'refund') && (
        <Route path={`${match.url}/refund/:id`} exact component={RefundList} />
      )}
      {withFpses && withRefund && hasRightsNeeded(userInfo, 'refund') && (
        <Route
          path={`${match.url}/refund/:refundId/mail`}
          exact
          component={RefundMailEditor}
        />
      )}
      {withSubscriptions && hasRightsNeeded(userInfo, 'suiviSubs') && (
        <Route
          path={`${match.url}/subscriptions`}
          exact
          component={SubscriptionsStats}
        />
      )}
      {withSubscriptions && hasRightsNeeded(userInfo, 'subscribersPayment') && (
        <Route
          path={`${match.url}/subscribersPayment`}
          exact
          component={SubscribersPaymentList}
        />
      )}
      {withSubscriptions && hasRightsNeeded(userInfo, 'subscribers') && (
        <Route
          path={`${match.url}/subscribers`}
          exact
          component={Subscribers}
        />
      )}
      {(withFpses || withRapos) && hasRightsNeeded(userInfo, 'reports') && (
        <Route path={`${match.url}/reports`} exact component={Report} />
      )}
      {withFpses && hasRightsNeeded(userInfo, 'fpsStrategy') && (
        <Route path={`${match.url}/strategy`} exact component={Strategy} />
      )}
      {withControls && hasRightsNeeded(userInfo, 'controlStrategy') && (
        <Route
          path={`${match.url}/controlStrategy`}
          exact
          component={ControlStrategy}
        />
      )}
      {withLapiReview && hasRightsNeeded(userInfo, 'lapiReviewReport') && (
        <Route
          path={`${match.url}/lapiReviewReport`}
          exact
          component={LapiReviewReport}
        />
      )}
      {withLapiReview && hasRightsNeeded(userInfo, 'lapiReviewStats') && (
        <Route
          path={`${match.url}/lapiReviewStats`}
          exact
          component={LapiReviewStats}
        />
      )}
      {(withControls || withFpses || withRapos || withTV) &&
        hasRightsNeeded(userInfo, 'graphics') && (
          <Route path={`${match.url}/graphics`} exact component={Graphics} />
        )}
      <Route path={`${match.url}/enqueteLAPI`} exact component={EnqueteLapi} />
      <MissNotFound />
    </Switch>
  </div>
);

const ConnectedFpsDashboard = connect(state => {
  const { userInfo } = getApiState(state);
  const {
    modulesConfiguration: { fps, rapo, subscribers, control, lapiReview, tv },
    fpsRefundEnabled,
  } = getConfigState(state);

  return {
    withFpses: isDisplayed(fps),
    withRapos: isDisplayed(rapo),
    withSubscriptions: isDisplayed(subscribers),
    withControls: isDisplayed(control),
    withLapiReview: isDisplayed(lapiReview),
    withRefund: fpsRefundEnabled,
    withTV: isDisplayed(tv),
    userInfo,
  };
})(FpsDashboard);

export default withRouter(ConnectedFpsDashboard);
