import { CSSProperties } from 'react';

const { _tg } = window.loadTranslations(__filename);

export type PageType = {
  id: string;
  label: string;
  title: string;
};

export const pageList = (
  showRapoPages?: boolean,
  showFpsPage?: boolean
): PageType[] => {
  const availablePages = [];

  if (showRapoPages) {
    availablePages.push({
      id: 'official',
      label: _tg('tefps.dashboard.report.officialReport'),
      title: _tg('tefps.dashboard.report.officialReport'),
    });
    availablePages.push({
      id: 'rapo',
      label: 'RAPO',
      title: _tg('tefps.dashboard.report.rapoMonthlyStats'),
    });
  }
  if (showFpsPage) {
    availablePages.push({
      id: 'fps',
      label: 'FPS',
      title: _tg('tefps.dashboard.report.fpsStats'),
    });
  }

  return availablePages;
};

export const TABLE_COLUMNS = (): Array<{
  label?: string;
  width: number;
  grow?: number;
  style?: CSSProperties;
  headerStyle?: CSSProperties;
  onSort?: boolean;
}> => [
  { label: '', width: 200, grow: 1 },
  {
    label: _tg('field.totalAmount'),
    width: 200,
    headerStyle: { justifyContent: 'center' },
  },
  {
    label: _tg('tefps.dashboard.report.numbersForResidents'),
    width: 200,
    headerStyle: { textAlign: 'center' },
  },
  {
    label: _tg('tefps.dashboard.report.numberForNonResidents'),
    width: 200,
    headerStyle: { textAlign: 'center' },
  },
];

export type FIRST_TAB_ROW_KEY_TYPE =
  | 'TOTAL'
  | 'AVERAGE_PROCESSING_DELAY'
  | 'EXPLICITE'
  | 'IMPLICITE'
  | 'INCORRECT'
  | 'REFUSED'
  | 'ACCEPTED'
  | 'SAISINE_REJETEE'
  | 'SAISINE_ACCEPTEE'
  | 'SEPARATOR'
  | 'TITLE_REASON'
  | 'PAYE'
  | 'DESTRUCTION'
  | 'VOL'
  | 'AVANCE'
  | 'AUTRE'
  | 'CITY_INADMISSIBILITY_TITLE'
  | 'I_NOT_RECOMMENDED'
  | 'I_WRONG_FORM'
  | 'I_NO_CLAIM_REASON'
  | 'I_DELAY_OVER'
  | 'I_COMPLETION_PERIOD_OVER'
  | 'I_OTHERS'
  | 'CITY_REJECTION_TITLE'
  | 'R_NON_CONVINCING'
  | 'R_JUSTIFIED_FINE'
  | 'R_OTHERS'
  | 'CITY_CANCELED_TITLE'
  | 'A_VALID_PAYMENT'
  | 'A_VEHICLE_SOLD'
  | 'A_PLATE_FRAUD'
  | 'A_WRONG_PRICE'
  | 'A_INVALID_TIME'
  | 'A_WAS_FREE'
  | 'A_ERRONEOUS'
  | 'A_INCOMPLET'
  | 'A_OTHERS_GOOD_WILL'
  | 'A_OTHERS';

export const FIRST_TAB_ROW_KEYS: FIRST_TAB_ROW_KEY_TYPE[] = [
  'TOTAL',
  'AVERAGE_PROCESSING_DELAY',
  'EXPLICITE',
  'IMPLICITE',
  'INCORRECT',
  'REFUSED',
  'ACCEPTED',
  'SAISINE_REJETEE',
  'SAISINE_ACCEPTEE',
  'SEPARATOR',
  'TITLE_REASON',
  'PAYE',
  'DESTRUCTION',
  'VOL',
  'AVANCE',
  'AUTRE',
  'CITY_INADMISSIBILITY_TITLE',
  'I_NOT_RECOMMENDED',
  'I_WRONG_FORM',
  'I_NO_CLAIM_REASON',
  'I_DELAY_OVER',
  'I_COMPLETION_PERIOD_OVER',
  'I_OTHERS',
  'CITY_REJECTION_TITLE',
  'R_NON_CONVINCING',
  'R_JUSTIFIED_FINE',
  'R_OTHERS',
  'CITY_CANCELED_TITLE',
  'A_VALID_PAYMENT',
  'A_VEHICLE_SOLD',
  'A_PLATE_FRAUD',
  'A_WRONG_PRICE',
  'A_INVALID_TIME',
  'A_WAS_FREE',
  'A_ERRONEOUS',
  'A_INCOMPLET',
  'A_OTHERS_GOOD_WILL',
  'A_OTHERS',
];

export const ROW_TITLES = (): Record<FIRST_TAB_ROW_KEY_TYPE, string> => ({
  TOTAL: _tg('tefps.dashboard.report.rows.TOTAL'),
  AVERAGE_PROCESSING_DELAY: _tg(
    'tefps.dashboard.report.rows.AVERAGE_PROCESSING_DELAY'
  ),
  EXPLICITE: _tg('tefps.dashboard.report.rows.EXPLICITE'),
  IMPLICITE: _tg('tefps.dashboard.report.rows.IMPLICITE'),
  INCORRECT: _tg('tefps.dashboard.report.rows.INCORRECT'),
  REFUSED: _tg('tefps.dashboard.report.rows.REFUSED'),
  ACCEPTED: _tg('tefps.dashboard.report.rows.ACCEPTED'),
  SAISINE_REJETEE: _tg('tefps.dashboard.report.rows.SAISINE_REJETEE'),
  SAISINE_ACCEPTEE: _tg('tefps.dashboard.report.rows.SAISINE_ACCEPTEE'),
  SEPARATOR: '',
  TITLE_REASON: _tg('tefps.dashboard.report.rows.TITLE_REASON'),
  PAYE: _tg('tefps.dashboard.report.rows.PAYE'),
  DESTRUCTION: _tg('tefps.dashboard.report.rows.DESTRUCTION'),
  VOL: _tg('tefps.dashboard.report.rows.VOL'),
  AVANCE: _tg('tefps.dashboard.report.rows.AVANCE'),
  AUTRE: _tg('tefps.dashboard.report.rows.AUTRE'),
  CITY_INADMISSIBILITY_TITLE: _tg(
    'tefps.dashboard.report.rows.CITY_INADMISSIBILITY_TITLE'
  ),
  I_NOT_RECOMMENDED: _tg('tefps.dashboard.report.rows.I_NOT_RECOMMENDED'),
  I_WRONG_FORM: _tg('tefps.dashboard.report.rows.I_WRONG_FORM'),
  I_NO_CLAIM_REASON: _tg('tefps.dashboard.report.rows.I_NO_CLAIM_REASON'),
  I_DELAY_OVER: _tg('tefps.dashboard.report.rows.I_DELAY_OVER'),
  I_COMPLETION_PERIOD_OVER: _tg(
    'tefps.dashboard.report.rows.I_COMPLETION_PERIOD_OVER'
  ),
  I_OTHERS: _tg('tefps.dashboard.report.rows.I_OTHERS'),
  CITY_REJECTION_TITLE: _tg('tefps.dashboard.report.rows.CITY_REJECTION_TITLE'),
  R_NON_CONVINCING: _tg('tefps.dashboard.report.rows.R_NON_CONVINCING'),
  R_JUSTIFIED_FINE: _tg('tefps.dashboard.report.rows.R_JUSTIFIED_FINE'),
  R_OTHERS: _tg('tefps.dashboard.report.rows.R_OTHERS'),
  CITY_CANCELED_TITLE: _tg('tefps.dashboard.report.rows.CITY_CANCELED_TITLE'),
  A_VALID_PAYMENT: _tg('tefps.dashboard.report.rows.A_VALID_PAYMENT'),
  A_VEHICLE_SOLD: _tg('tefps.dashboard.report.rows.A_VEHICLE_SOLD'),
  A_PLATE_FRAUD: _tg('tefps.dashboard.report.rows.A_PLATE_FRAUD'),
  A_WRONG_PRICE: _tg('tefps.dashboard.report.rows.A_WRONG_PRICE'),
  A_INVALID_TIME: _tg('tefps.dashboard.report.rows.A_INVALID_TIME'),
  A_WAS_FREE: _tg('tefps.dashboard.report.rows.A_WAS_FREE'),
  A_ERRONEOUS: _tg('tefps.dashboard.report.rows.A_ERRONEOUS'),
  A_INCOMPLET: _tg('tefps.dashboard.report.rows.A_INCOMPLET'),
  A_OTHERS_GOOD_WILL: _tg('tefps.dashboard.report.rows.A_OTHERS_GOOD_WILL'),
  A_OTHERS: _tg('tefps.dashboard.report.rows.A_OTHERS'),
});
