import React from 'react';
import Toggle from 'material-ui/Toggle';

import BoButton from 'facade/BoButton';
import { ControlViewPrivateDTO, DeviceType } from '@cvfm-front/tefps-types';
import SimpleTable from 'commons/SimpleTable';
import './ControlViewTable.css';

const { _t, _tg } = window.loadTranslations(__filename);

type ControlViewTableProps = {
  canEdit: boolean;
  canDelete: boolean;
  controlViews: Array<ControlViewPrivateDTO>;
  onClick: (controlViewId: string) => void;
  onToggle: (controlViewId: string, enabled: boolean) => void;
  onDelete: (controlViewId: string) => void;
  exemptionReasons: Array<{ id: string; name: string }>;
};

const ControlViewTable = ({
  canEdit,
  canDelete,
  controlViews,
  onClick,
  onToggle,
  onDelete,
  exemptionReasons,
}: ControlViewTableProps): JSX.Element => {
  const translateTableCols = () => [
    { label: _t('table.header.name'), width: 200, grow: 1 },
    { label: _t('table.header.status'), width: 100, grow: 1 },
    { label: _t('table.header.deviceType'), width: 50, grow: 1 },
    { label: _t('table.header.exemption'), width: 175, grow: 3 },
    { label: _t('table.header.activity'), width: 85, grow: 1 },
    { label: _t('table.header.enabled'), width: 75, grow: 1 },
    { label: '', width: 75, grow: 1 },
  ];

  const translateUserSwitchText = (userCanSwitch: boolean): string => {
    if (userCanSwitch) {
      return _t('table.switch.userCanSwitch');
    }

    return _t('table.switch.default');
  };

  const translateDeviceType = (deviceType: DeviceType): string => {
    if (deviceType === 'PDA') {
      return 'PDA';
    }
    if (deviceType === 'AUTOMATED_CONTROL_VEHICLE') {
      return 'LAPI';
    }

    return '-';
  };

  const translateExemption = (exemptionReasonId: string): string => {
    let text = exemptionReasonId;
    const exemptionProp = exemptionReasons.find(
      e => e.id === exemptionReasonId
    );

    if (exemptionProp) {
      text = exemptionProp.name;
    }

    return text;
  };

  const handleClick = ({ currentTarget }: React.MouseEvent<any>) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    onClick(currentTarget.dataset.id as string);
  const handleToggle = ({ currentTarget }: React.MouseEvent<any>) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    onToggle(currentTarget.dataset.id as string, currentTarget.checked);
  const handleDelete = ({ currentTarget }: React.MouseEvent<any>) =>
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    onDelete(currentTarget.dataset.id as string);

  const renderControlViewRow = (controlView: ControlViewPrivateDTO) => {
    const statusRowTest = controlView.controlStatuses
      .map(status => status)
      .join(', ');
    const exemptionRowText = controlView.exemptionReasons
      .map(reason => translateExemption(reason))
      .join(', ');
    const deviceRowText = controlView.deviceTypes
      .map(device => translateDeviceType(device))
      .join(', ');
    const userSwitchText = translateUserSwitchText(controlView.userCanSwitch);

    const row = [
      <span
        id={`${controlView.id}-name`}
        onClick={handleClick}
        className="control-view-row_id"
        data-id={controlView.id}
      >
        {controlView.name}
      </span>,
      <span id={`${controlView.id}-status`}>{statusRowTest || '-'}</span>,
      <span id={`${controlView.id}-device`}>{deviceRowText || '-'}</span>,
      <span id={`${controlView.id}-exempt`}>{exemptionRowText || '-'}</span>,
      <span id={`${controlView.id}-user`}>{userSwitchText || '-'}</span>,
      <span id={`${controlView.id}-toggle`}>
        <Toggle
          toggled={controlView.enabled}
          label={
            controlView.enabled ? _tg('status.active') : _tg('status.inactive')
          }
          labelPosition="right"
          data-id={controlView.id}
          onToggle={handleToggle}
          color="primary"
          disabled={!canEdit}
        />
      </span>,
      <span id={`${controlView.id}-delete`}>
        <BoButton
          disabled={!canDelete}
          label={_tg('action.delete')}
          data-id={controlView.id}
          onClick={handleDelete}
          primary
        />
      </span>,
    ];
    return row;
  };

  return (
    <SimpleTable
      style={{ marginTop: 25 }}
      cols={translateTableCols()}
      rowHeight={50}
      // loadMoreRows={loadMoreRows}
      // remoteRowCount={remoteRowCount}
      itemsRenderer={renderControlViewRow}
      items={controlViews}
    />
  );
};

export default ControlViewTable;
