import moment from 'moment';

import { Pager, SortParameters } from 'api/commonTypes';
import { GpvFilter, GpvSearchParams } from 'api/tepv/stats/types';
import { FilterDate } from 'commons/SidebarV2/Components/Dates';
import { BoolOptions } from 'api/tepv/mif/types';

export const monthNameMapper = (month: string) =>
  moment(month, 'YYYY-MM').format('MMM YYYY');
const monthSorter = (monthA: string, monthB: string) =>
  moment(monthA, 'YYYY-MM').isAfter(moment(monthB, 'YYYY-MM')) ? 1 : -1;

export const noFilterDate: FilterDate = { from: undefined, to: undefined };

export const initGpvFilters = (): GpvFilter => {
  return {
    motifText: '',
    category: [],
    presetNotes: [],
    infractionLocations: [],
    signatureDate: noFilterDate,
    signatureTime: {},
    address: '',
    vehicleRemovalStatus: new Set<BoolOptions>(),
    saisineStatus: new Set<BoolOptions>(),
    photosStatus: new Set<BoolOptions>(),
    agentSearchText: '',
    organizationIds: [],
    profiles: [],
    vehicleCategory: [],
    immatriculationFulltext: '',
    infractionCases: [],
  };
};

function getSortFieldText(sortField: number) {
  switch (sortField) {
    case 0:
      return 'natinfCode';
    case 1:
      return 'signatureDatetime';
    case 2:
      return 'agentName';
    case 3:
      return 'agentMatricule';
    case 4:
      return 'streetName';
    case 5:
      return 'cityIdentifier';
    case 6:
      return 'locality';
    case 7:
      return 'presetNotes';
    default:
      return 'signatureDatetime';
  }
}

export const filterToSearchParams = (
  filters: GpvFilter,
  sort: SortParameters = {
    sortField: 1,
    increasingOrder: true,
  },
  pager: Pager = { page: 0, maxRecords: 0 }
): GpvSearchParams => {
  const {
    motifText,
    category,
    signatureDate,
    signatureTime,
    address,
    vehicleRemovalStatus,
    saisineStatus,
    photosStatus,
    agentSearchText,
    organizationIds,
    profiles,
    vehicleCategory,
    immatriculationFulltext,
    presetNotes,
    infractionLocations,
    infractionCases,
  } = filters;
  const { page, maxRecords } = pager;

  return {
    motifText,
    category: Array.from(category.map(v => encodeURIComponent(v))),
    signatureDateStart: signatureDate.from
      ? moment(signatureDate.from).toISOString()
      : undefined,
    signatureDateEnd: signatureDate.to
      ? moment(signatureDate.to).toISOString()
      : undefined,
    signatureTimeStart: signatureTime.from,
    signatureTimeEnd: signatureTime.to,
    address,
    vehicleRemovalStatus: Array.from(vehicleRemovalStatus),
    saisineStatus: Array.from(saisineStatus),
    photosStatus: Array.from(photosStatus),
    agentSearchText,
    organizationIds,
    profiles,
    vehicleCategory,
    immatriculationFulltext,
    presetNotes,
    infractionLocations,
    infractionCases,
    page,
    maxRecords,
    sortOrder: sort.increasingOrder ? 'ASC' : 'DESC',
    sortField: getSortFieldText(sort.sortField),
  };
};

export const vehicleCategories = [
  'CYCLE',
  'CYCLO',
  'VP',
  'PL',
  'TCP',
  'TAXI',
  'MOTO',
  'CTTE',
  'PIETON',
  'AUTRE',
];

export const EXPORT_COLUMNS = [
  {
    label: 'Catégorie motif',
    key: 'CATEGORY_LABEL',
    checked: true,
  },
  { label: 'Renseignement prédéfini', key: 'PRESET_LABEL', checked: true },
  {
    label: 'Catégorie de véhicule',
    key: 'VEHICLE_CATEGORY_LABEL',
    checked: true,
  },
  {
    label: "Pays d'immatriculation",
    key: 'VEHICLE_COUNTRY_LABEL',
    checked: true,
  },
  {
    label: 'Gpv par mois',
    key: 'MONTH_LABEL',
    checked: true,
  },
];

export const sortByMonth = (stats: { [k: string]: number }) =>
  Object.keys(stats)
    .sort(monthSorter)
    .reduce((map, key) => {
      map[key] = stats[key];
      return map;
    }, {});

export const sortByCount = (stats: { [k: string]: number }) =>
  Object.keys(stats)
    .sort((keyA, keyB) => stats[keyA] - stats[keyB])
    .reduce((map, key) => {
      map[key] = stats[key];
      return map;
    }, {});
