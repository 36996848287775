import { fetchRefundFilteredList } from 'api/refund';
import { RefundOverviewDTO } from 'api/refund/types';
import PageFetcherFactory, {
  PageFetcher,
} from 'commons/Generics/PageFetcherFactory';
import { Filters } from 'commons/types/filterbar';

const useRefundPageFetcher = (
  filters: Filters
): PageFetcher<Filters, RefundOverviewDTO> =>
  PageFetcherFactory<Filters, RefundOverviewDTO>(
    fetchRefundFilteredList,
    filters
  )();

export default useRefundPageFetcher;
