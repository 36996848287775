import { NotificationService } from '@cvfm-front/commons-services';
import { Watcher } from '@cvfm-front/commons-utils';
import { ApiError } from 'api/ApiError';
import { SortParameters } from 'api/commonTypes';

import { fetchData } from './api';
import {
  LapiDriverStatsFilters,
  LapiDriverStatsItemType,
  LapiDriverStatsSearchRequestDTO,
} from './types';

function getSortFieldText(sortField?: number) {
  switch (sortField) {
    case 1:
      return 'numberOfControl';
    case 2:
      return 'numberOfReviewedControl';
    case 3:
      return 'numberOfFpsByReviewedControl';
    case 4:
      return 'numberOfFpsCanceled';
    case 0:
    default:
      return 'agentName';
  }
}

const LapiDriverStatsService = () => {
  const { setValue: setData, watchValue: watchData } = Watcher(
    [] as LapiDriverStatsItemType[]
  );

  const fetchDataFromAPI = async (
    filters: LapiDriverStatsFilters,
    sortParameters?: SortParameters
  ): Promise<void> => {
    try {
      if (filters.rangeDatetime.datetimeValue === undefined) {
        // No-op
        return;
      }

      const { from, to } = filters.rangeDatetime.datetimeValue;
      if (!from || !to) {
        // No-op
        return;
      }
      const searchRequest: LapiDriverStatsSearchRequestDTO = {
        start: from,
        end: to,
      };
      if (sortParameters) {
        searchRequest.sortOrder = sortParameters.increasingOrder
          ? 'ASC'
          : 'DESC';
        searchRequest.sortField = getSortFieldText(sortParameters?.sortField);
      }

      setData(await fetchData(searchRequest));
    } catch (e) {
      NotificationService.pushNotification({
        id: 'LapiDriverStats',
        type: 'error',
        message: (e as ApiError).message,
      });
    }
  };

  return {
    fetchDataFromAPI,
    watchData,
  };
};

export default LapiDriverStatsService();
