import React, { useEffect, useState } from 'react';
import moment from 'moment';

import SimpleTable from 'commons/SimpleTable';
import GenericFilterBar from 'commons/Generics/FilterBar';
import { DatetimeFilterType, Filters } from 'commons/types/filterbar';
import { SortParameters } from 'api/commonTypes';

import { LapiDriverStatsFilters, LapiDriverStatsItemType } from './types';
import LapiDriverStatService from './service';

import './index.css';

const { _t } = window.loadTranslations(__filename);

const rangeDatetimeFilter: DatetimeFilterType = {
  type: 'DATETIME',
  id: 'rangeDatetime',
  label: 'Date',
  datetimeDefaultValue: {
    from: moment()
      .subtract(1, 'month')
      .toDate(),
    to: moment().toDate(),
  },
  datetimeValue: {
    from: moment()
      .subtract(1, 'month')
      .toDate(),
    to: moment().toDate(),
  },
};

const initialFilters: LapiDriverStatsFilters = {
  rangeDatetime: rangeDatetimeFilter,
};

const itemsRenderer = ({
  agentName,
  numberOfControl,
  rateOfFpsByReviewedControl,
  rateOfFpsCanceledByFps,
  numberOfReviewedControl,
}: LapiDriverStatsItemType) => {
  return [
    agentName,
    numberOfControl.toLocaleString(),
    numberOfReviewedControl.toLocaleString(),
    `${(rateOfFpsByReviewedControl * 100).toFixed(2)}%`,
    `${(rateOfFpsCanceledByFps * 100).toFixed(2)}%`,
  ];
};

const LapiDriverStats = (): JSX.Element => {
  const [filters, setFilters] = useState<Filters>(initialFilters);
  const [sortParameters, setSortParameters] = useState<
    SortParameters | undefined
  >(undefined);
  const [data, setData] = useState([] as LapiDriverStatsItemType[]);

  const cols = [
    { label: _t('table.agent'), width: 256, onSort: true, grow: 1 },
    { label: _t('table.numberOfControl'), width: 256, onSort: true },
    { label: _t('table.numberOfReviewedControl'), width: 256, onSort: true },
    { label: _t('table.fpsByReviewedControl'), width: 256, onSort: true },
    { label: _t('table.fpsCancelled'), width: 256, onSort: true },
  ];

  async function onUpdateSort(
    colNumber: number,
    increasingOrder: boolean
  ): Promise<void> {
    setSortParameters({
      increasingOrder,
      sortField: colNumber,
    });
    return Promise.resolve();
  }

  // Fetch data from API when filter changes
  useEffect(() => {
    void LapiDriverStatService.fetchDataFromAPI(
      filters as LapiDriverStatsFilters,
      sortParameters
    );
  }, [filters, sortParameters]);

  // Update component when new data is fetched from API
  useEffect(() => LapiDriverStatService.watchData(setData), []);

  return (
    <div className="lapi-driver-stats">
      <GenericFilterBar
        initialFilters={filters}
        totalHits={data.length}
        onChangeFilters={setFilters}
        facetings={{}}
      />
      <div className="lapi-driver-stats__table">
        <h1 className="lapi-driver-stats__title">{_t('title')}</h1>
        <SimpleTable
          cols={cols}
          items={data}
          itemsRenderer={itemsRenderer}
          onSort={onUpdateSort}
          colSorted={sortParameters?.sortField}
          sortOrder={sortParameters?.increasingOrder}
        />
      </div>
    </div>
  );
};

export default LapiDriverStats;
