import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import Dialog from 'material-ui/Dialog';
import SuccessIcon from 'material-ui/svg-icons/action/check-circle';
import ErrorIcon from 'material-ui/svg-icons/action/highlight-off';
import WaitIcon from 'material-ui/svg-icons/action/schedule';

import BoButton from 'facade/BoButton';
import SimpleTable from 'commons/SimpleTable';
import { BKG_GREEN, BKG_PINK, TXT_BLACK } from 'theme';

import { RefundMapContext, RefundMapContextType } from '../Context';

const { _tg } = window.loadTranslations(__filename);

const STYLE_ICON: CSSProperties = {
  width: 25,
  height: 25,
  margin: '2px 10px 2px 0',
};

const SUCCESS = 'Réussite';
const FAILURE = 'Échec';

type Props = {
  title: string;
  massAction: (arg0: string) => Promise<unknown>;
  onClose: () => void;
};

type actionStatus = {
  fpsId: string;
  status: string | null | undefined;
  comment: string | null | undefined;
};

function renderIcon(status: string | null | undefined) {
  let icon = <WaitIcon style={STYLE_ICON} color={TXT_BLACK} />;
  if (status === SUCCESS) {
    icon = <SuccessIcon style={STYLE_ICON} color={BKG_GREEN} />;
  } else if (status === FAILURE) {
    icon = <ErrorIcon style={STYLE_ICON} color={BKG_PINK} />;
  }
  return icon;
}

function ProgressionModal({ title, massAction, onClose }: Props): JSX.Element {
  const TABLE_COLS = [
    { label: '', width: 30 },
    { label: _tg('tefps.dashboard.payment.columns.fpsNumber'), width: 250 },
    {
      label: _tg('field.status'),
      width: 100,
    },
    { label: _tg('field.comment'), width: 300 },
  ];

  const { selectedRefunds } = useContext(
    RefundMapContext
  ) as RefundMapContextType;
  const [refundsStatus, setRefundsStatus] = useState<{
    [key: string]: actionStatus;
  }>({});

  useEffect(() => {
    async function process() {
      const values = Object.keys(selectedRefunds);
      const refundMap = {};
      for (let i = 0; i < values.length; i += 1) {
        const tuple = values[i];
        try {
          // Les requêtes doivent être synchrones et exécutées les unes à la suite des autres
          // eslint-disable-next-line no-await-in-loop
          const response = await massAction(tuple);
          refundMap[tuple] = {
            fpsId: selectedRefunds[tuple].rootFpsId,
            status: SUCCESS,
            comment: response,
          };
        } catch (err) {
          refundMap[tuple] = {
            fpsId: selectedRefunds[tuple].rootFpsId,
            status: FAILURE,
            comment: (err as Error).message,
          };
        } finally {
          setRefundsStatus({ ...refundsStatus, ...refundMap });
        }
      }
    }

    void process();
  }, []);

  function renderRow(refundId: string) {
    const state = refundsStatus[refundId];

    if (state) {
      return [
        renderIcon(state.status),
        state.fpsId,
        state.status === SUCCESS
          ? _tg('commons.success')
          : _tg('commons.failure'),
        state.comment,
      ];
    }
    return [
      renderIcon(null),
      selectedRefunds[refundId].rootFpsId,
      _tg('tefps.filters.rapo.statuses.isWaiting').toLocaleLowerCase(),
      '',
    ];
  }

  const action = (
    <BoButton
      style={{ marginRight: 10 }}
      key={1}
      label={_tg('action.close')}
      onClick={onClose}
      disabled={
        Object.keys(selectedRefunds).length !==
        Object.keys(refundsStatus).length
      }
    />
  );

  return (
    <Dialog title={title} modal autoScrollBodyContent open actions={[action]}>
      <SimpleTable
        maxHeight={300}
        cols={TABLE_COLS}
        rowHeight={50}
        header
        itemsRenderer={renderRow}
        style={{ marginTop: 10 }}
        items={Object.keys(selectedRefunds)}
      />
    </Dialog>
  );
}

export default ProgressionModal;
