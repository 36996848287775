import React, { CSSProperties } from 'react';
import CircularProgress from 'material-ui/CircularProgress';
import { connect } from 'react-redux';
import moment from 'moment';

import { fetchLapiReviewByAgent } from 'api/dashboard';
import { AgentLapiReviewCounterDTO } from 'api/dashboard/types';
import Content from 'commons/Content';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';
import AdvertisingModal from 'commons/AdvertisingModal';
import DateFilter, { DatepickerClass } from 'commons/DateFilter';
import SimpleTable from 'commons/SimpleTable';
import { TXT_BLACK } from 'theme';
import { ReduxDashboardState } from 'Dashboard/duck';
import { ApiError } from 'api/ApiError';

import { FiltersDTO, Period, SortParameters } from './types';
import {
  SET_PERIOD,
  SET_SORT_PARAMETERS,
  setPeriod,
  setSortParameters,
} from './duck';

const { _tg } = window.loadTranslations(__filename);

const footerCols = (
  totalAgents: number,
  totalFps: number,
  totalExemption: number,
  totalComplex: number,
  totalOkPlate: number,
  totalReviewed: number,
  totalTreated: number
) => {
  const headStyle = { marginTop: 14 };
  return [
    {
      label: `${totalAgents.toLocaleString()} ${_tg('commons.activeAgents')}`,
      width: 300,
      headerStyle: headStyle,
    },
    {
      label: `${totalFps.toLocaleString()}`,
      width: 120,
      headerStyle: headStyle,
    },
    {
      label: `${totalExemption.toLocaleString()}`,
      width: 120,
      headerStyle: headStyle,
    },
    {
      label: `${totalComplex.toLocaleString()}`,
      width: 120,
      headerStyle: headStyle,
    },
    {
      label: `${totalOkPlate.toLocaleString()}`,
      width: 120,
      headerStyle: headStyle,
    },
    {
      label: `${totalReviewed.toLocaleString()}`,
      width: 120,
      headerStyle: headStyle,
    },
    {
      label: `${totalTreated.toLocaleString()}`,
      width: 120,
      headerStyle: headStyle,
    },
  ];
};

const STYLE_TITLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  flexWrap: 'wrap',
  color: TXT_BLACK,
  margin: '25px auto 40px',
  fontSize: 20,
  textAlign: 'center',
};

const STYLE_SUBTITLE: CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  flex: 1,
  flexWrap: 'wrap',
  margin: '0 auto',
  fontSize: 15,
  textAlign: 'center',
  fontWeight: 'bold',
};

const STYLE_TEXT: CSSProperties = {
  fontSize: 12,
  fontWeight: 'bold',
  marginRight: 10,
};

type State = {
  reportInfo: Array<AgentLapiReviewCounterDTO> | null | undefined;
  totalFps: number;
  totalExemption: number;
  totalComplex: number;
  totalOkPlate: number;
  totalReviewed: number;
  totalTreated: number;
  error: Error | null | undefined;
};

type Props = {
  lapiReviewReport: {
    sort: SortParameters;
    period: Period;
  };
  updateSortParameters: (
    sort: SortParameters
  ) => { type: typeof SET_SORT_PARAMETERS; sort: SortParameters };
  updatePeriod: (period: Period) => { type: typeof SET_PERIOD; period: Period };
};

function getSortFieldText(colNumber: number) {
  switch (colNumber) {
    case 0:
      return 'nameAgent';
    case 1:
    default:
      return 'KO';
    case 2:
      return 'EXEMPTION';
    case 3:
      return 'COMPLEX';
    case 4:
      return 'PLATE_OK';
    case 5:
      return 'TOTAL';
  }
}

const filtersToRequest = (period: Period, sort: SortParameters): FiltersDTO => {
  const { from, to } = period;
  return {
    from: from ? moment(from).toISOString() : undefined,
    to: to ? moment(to).toISOString() : undefined,
    sortOrder: sort.increasingOrder ? 'ASC' : 'DESC',
    sortField: getSortFieldText(sort.colSorted),
  };
};

class LapiReviewReport extends React.Component<Props, State> {
  tableCols = [
    { label: _tg('field.agent.name'), width: 300, onSort: true },
    {
      label: _tg('tefps.dashboard.lapiReviewReport.numberOfFps'),
      width: 120,
      onSort: true,
    },
    {
      label: _tg('tefps.dashboard.lapiReviewReport.numberOfExemptions'),
      width: 120,
      onSort: true,
    },
    {
      label: _tg('tefps.dashboard.lapiReviewReport.numberOfComplexCases'),
      width: 120,
      onSort: true,
    },
    {
      label: _tg(
        'tefps.dashboard.lapiReviewReport.numberOfValidPlateCorrection'
      ),
      width: 120,
      onSort: true,
    },
    {
      label: _tg('tefps.dashboard.lapiReviewReport.numberOfReviewedControls'),
      width: 120,
      onSort: true,
    },
    {
      label: _tg('tefps.dashboard.lapiReviewReport.numberOfProcessedControls'),
      width: 120,
      onSort: true,
    },
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      reportInfo: null,
      error: null,
      totalFps: 0,
      totalExemption: 0,
      totalComplex: 0,
      totalOkPlate: 0,
      totalReviewed: 0,
      totalTreated: 0,
    };
  }

  componentDidMount() {
    void this.getLapiReviewInfo(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(newProps: Props) {
    void this.getLapiReviewInfo(newProps);
  }

  onUpdateSort = (colSorted: number, increasingOrder: boolean) => {
    const { updateSortParameters } = this.props;
    updateSortParameters({ colSorted, increasingOrder });
  };

  onChangeDate = (newDate: Period) => {
    const { updatePeriod } = this.props;
    updatePeriod(newDate);
  };

  getLapiReviewInfo = async (props: Props) => {
    try {
      const {
        lapiReviewReport: { sort, period },
      } = props;
      const newReportInfo = await fetchLapiReviewByAgent(
        filtersToRequest(period, sort)
      );
      let newTotalFps = 0;
      let newTotalExemption = 0;
      let newTotalComplex = 0;
      let newTotalOkPlate = 0;
      let newTotalTreated = 0;
      let newTotalReviewed = 0;
      newReportInfo.forEach((ri: AgentLapiReviewCounterDTO) => {
        newTotalFps += ri.nbFps;
        newTotalExemption += ri.nbExemption;
        newTotalComplex += ri.nbComplex;
        newTotalOkPlate += ri.nbOkPlate;
        newTotalReviewed += ri.nbReviewed;
        newTotalTreated += ri.nbTreated;
      });
      this.setState({
        reportInfo: newReportInfo,
        error: null,
        totalFps: newTotalFps,
        totalExemption: newTotalExemption,
        totalOkPlate: newTotalOkPlate,
        totalComplex: newTotalComplex,
        totalReviewed: newTotalReviewed,
        totalTreated: newTotalTreated - newTotalComplex,
      });
    } catch (fetchError) {
      this.setState({ error: fetchError as ApiError, reportInfo: null });
    }
  };

  itemsRenderer = (line: AgentLapiReviewCounterDTO) => {
    return [
      <span>{line.agentName}</span>,
      <span>{line.nbFps || 0}</span>,
      <span>{line.nbExemption || 0}</span>,
      <span>{line.nbComplex || 0}</span>,
      <span>{line.nbOkPlate || 0}</span>,
      <span>{line.nbReviewed || 0}</span>,
      <span>{line.nbTreated || 0}</span>,
    ];
  };

  computeIfAbsent = (value: number | null | undefined): number => {
    return value || 0;
  };

  render() {
    const {
      reportInfo,
      error,
      totalFps,
      totalExemption,
      totalComplex,
      totalOkPlate,
      totalReviewed,
      totalTreated,
    } = this.state;
    const {
      lapiReviewReport: { period, sort },
    } = this.props;

    if (error) {
      return (
        <Content>
          <FlexCenter>
            <ErrorBlock
              message={_tg(
                'tefps.dashboard.lapiReviewReport.errorWhileFetchingData'
              )}
              error={error}
            />
          </FlexCenter>
        </Content>
      );
    }

    return (
      <Content>
        <AdvertisingModal module="control" />
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div style={{ margin: '2em auto', maxWidth: '90%' }}>
            <span style={STYLE_TITLE}>
              {_tg('tefps.dashboard.lapiReviewReport.numberOfControlsOnPeriod')}
            </span>
            <div style={{ margin: '40px auto 30px', maxWidth: 400 }}>
              <span style={STYLE_SUBTITLE}>
                {_tg('tefps.dashboard.lapiReviewReport.reviewDate')}
              </span>
              <DateFilter
                styleText={STYLE_TEXT}
                datepickerClass={DatepickerClass.filter}
                colorResetButton={TXT_BLACK}
                dates={period}
                onChange={this.onChangeDate}
              />
            </div>
            {!reportInfo ? (
              <FlexCenter>
                <CircularProgress />
              </FlexCenter>
            ) : (
              <div style={{ margin: 'auto', width: '930px' }}>
                <SimpleTable
                  maxHeight={500}
                  cols={this.tableCols}
                  rowHeight={50}
                  header
                  footer={footerCols(
                    reportInfo.length,
                    totalFps,
                    totalExemption,
                    totalComplex,
                    totalOkPlate,
                    totalReviewed,
                    totalTreated
                  )}
                  itemsRenderer={this.itemsRenderer}
                  items={reportInfo}
                  colSorted={sort.colSorted}
                  sortOrder={sort.increasingOrder}
                  onSort={this.onUpdateSort}
                />
              </div>
            )}
          </div>
        </div>
      </Content>
    );
  }
}

export default connect(
  (state: any) => ({
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    lapiReviewReport: (state.dashboard as ReduxDashboardState).lapiReviewReport,
  }),
  dispatch => ({
    updatePeriod: (period: Period) => dispatch(setPeriod(period)),
    updateSortParameters: (sort: SortParameters) =>
      dispatch(setSortParameters(sort)),
  })
)(LapiReviewReport);
