import moment from 'moment';

import { FpsMapSearchRequest, PeriodType } from 'api/fps/types';

import { MapFilters } from './types';

export const filtersToRequest = (filters: MapFilters): FpsMapSearchRequest => {
  const {
    dates,
    times,
    fineLegalId,
    agentId,
    organizationIds,
    plate,
    statementDaysOfWeek,
    boundingBoxNorthEast,
    boundingSouthWest,
    zoom,
  } = filters;

  let periods;
  if (dates && (dates.to || dates.from)) {
    periods = [
      {
        type: 'STATEMENT' as PeriodType,
        startDatetime: dates.from
          ? moment(dates.from).toISOString()
          : undefined,
        endDatetime: dates.to ? moment(dates.to).toISOString() : undefined,
      },
    ];
  }

  return {
    agentIds: agentId ? [agentId] : undefined,
    fineLegalId,
    plate,
    periods,
    startTime: times ? times.from : undefined,
    endTime: times ? times.to : undefined,
    statementDaysOfWeek: statementDaysOfWeek
      ? Array.from(statementDaysOfWeek)
      : undefined,
    organizationIds: organizationIds.size
      ? Array.from(organizationIds)
      : undefined,
    boundingBoxNorthEast,
    boundingSouthWest,
    zoom,
  };
};
