import * as React from 'react';
import { connect } from 'react-redux';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import Dates, { FilterDate } from 'commons/SidebarV2/Components/Dates';
import Input from 'commons/SidebarV2/Components/Input';
import { KeyBucketDTO } from 'api/commonTypes';
import { getConfigState, RegieProperties } from 'config/duck';
import { buildClientAppOptions } from 'commons/Utils/clientAppUtils';
import { fetchClientAppPayments } from 'api/clientapp';

import { PaymentSearchCriterias } from './types';
import { CHANNELS_OPTIONS, MODES_OPTIONS, STATUSES_OPTIONS } from './utils';
import { getPaymentListState, initialFilters, setFilters } from './duck';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  totalHits: number;
  filters: PaymentSearchCriterias;
  facetings:
    | {
        [key: string]: Array<KeyBucketDTO>;
      }
    | null
    | undefined;
  updateFilters: (newFilters: PaymentSearchCriterias) => void;
  regiesProperties: RegieProperties;
};

type State = {
  regieOptions: Array<{ value: string; label: string }>;
  clientAppOptions: Array<{ value: string; label: string }>;
};

class PaymentFilters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      regieOptions: [],
      clientAppOptions: [],
    };
  }

  componentDidMount() {
    const { regiesProperties } = this.props;
    this.buildRegieOptions(regiesProperties);
    void this.fetchClientAppAndBuildOptions();
  }

  onChange = (id: string, value: FilterDate | Set<any> | string) => {
    const { filters, updateFilters } = this.props;
    updateFilters({ ...filters, [id]: value });
  };

  buildRegieOptions = (regiesProperties: RegieProperties) => {
    // Do not build regie options if not regie properties or if only one regie
    if (!regiesProperties || regiesProperties.properties.length <= 1) {
      return;
    }

    const regieOptions = regiesProperties.properties.map(({ id, name }) => ({
      value: id,
      label: name,
    }));

    this.setState({ regieOptions });
  };

  fetchClientAppAndBuildOptions = async () => {
    const clientApp = await fetchClientAppPayments();
    this.setState({ clientAppOptions: buildClientAppOptions(clientApp) });
  };

  resetFilters = () => {
    const { updateFilters } = this.props;
    updateFilters(initialFilters());
  };

  render() {
    const { totalHits, filters, facetings } = this.props;
    const { regieOptions, clientAppOptions } = this.state;

    return (
      <Sidebar>
        <BarTitle resetFilters={this.resetFilters} />
        <HitsCounter hits={totalHits} />
        <Dates
          id="paymentDates"
          title={_tg('tefps.dashboard.payment.transactionDate')}
          dates={filters.paymentDates}
          onChange={this.onChange}
        />
        <Dates
          id="collectionDates"
          title={_tg('tefps.dashboard.payment.collectionDate')}
          dates={filters.collectionDates}
          onChange={this.onChange}
        />
        <Checkboxes
          id="statuses"
          title={_tg('tefps.filters.fps.paymentStatuses')}
          options={STATUSES_OPTIONS()}
          filters={filters.statuses}
          onChange={this.onChange}
          faceting={facetings ? facetings.statuses : null}
        />
        <Checkboxes
          id="channels"
          title={_tg('tefps.dashboard.payment.paymentChannel')}
          options={CHANNELS_OPTIONS()}
          filters={filters.channels}
          onChange={this.onChange}
          faceting={facetings ? facetings.channels : null}
        />
        <Checkboxes
          id="paymentModes"
          title={_tg('field.payment.mode')}
          options={MODES_OPTIONS()}
          filters={filters.paymentModes}
          onChange={this.onChange}
          faceting={facetings ? facetings.paymentModes : null}
        />
        <Checkboxes
          id="clientAppIds"
          title={_tg('tefps.dashboard.payment.clientAppIds')}
          options={clientAppOptions}
          filters={filters.clientAppIds}
          onChange={this.onChange}
          faceting={facetings ? facetings.clientAppIds : null}
        />
        {regieOptions.length > 0 && (
          <Checkboxes
            id="regieIds"
            title={_tg('tefps.dashboard.payment.regie')}
            options={regieOptions}
            filters={filters.regieIds}
            onChange={this.onChange}
            faceting={facetings ? facetings.regieIds : null}
          />
        )}
        <Input
          id="operationId"
          title={_tg('tefps.dashboard.payment.operationNumber')}
          placeholder={_tg('tefps.dashboard.payment.operationNumberFilter')}
          onChange={this.onChange}
          value={filters.operationId || ''}
        />
        <Input
          id="fpsId"
          title={_tg('tefps.dashboard.payment.fpsNumber')}
          placeholder={_tg('tefps.dashboard.payment.fpsNumberFilter')}
          onChange={this.onChange}
          value={filters.fpsId || ''}
        />
      </Sidebar>
    );
  }
}

export default connect(
  state => {
    const { filters } = getPaymentListState(state);
    const { regiesProperties } = getConfigState(state);
    return {
      filters,
      regiesProperties,
    };
  },
  dispatch => ({
    updateFilters: (filters: PaymentSearchCriterias) =>
      dispatch(setFilters(filters)),
  })
)(PaymentFilters);
