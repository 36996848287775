import React from 'react';

import ErrorBlock from 'commons/ErrorBlock';
import AdvertisingModal from 'commons/AdvertisingModal';

import DefaultPage from './DefaultPage';
import OfficialPage from './OfficalPage';
import OrganizationsReport from './OrganizationsReport';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  pageId: string;
};

const Pages = ({ pageId }: Props): JSX.Element => {
  switch (pageId) {
    case 'fps':
      return (
        <div>
          <AdvertisingModal module="fps" />
          <OrganizationsReport />
          <DefaultPage pageId={pageId} />
        </div>
      );
    case 'rapo':
      return (
        <div>
          <AdvertisingModal module="rapo" />
          <DefaultPage pageId={pageId} />
        </div>
      );
    case 'official':
      return (
        <div>
          <AdvertisingModal module="rapo" />
          <OfficialPage />
        </div>
      );
    default:
      return (
        <ErrorBlock
          error={{
            message: _tg('tefps.dashboard.report.pageNotHandled', { pageId }),
          }}
        />
      );
  }
};

export default Pages;
