import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import BoButton from 'facade/BoButton';
import { ListBody, ListBottom, ListWrapper } from 'commons/ListWrappers';
import { ControlViewPrivateDTO } from '@cvfm-front/tefps-types';
import { fetchControlTeams } from 'api/cvfm-core-directory/team';
import {
  createControlView,
  deleteControlViewById,
  fetchAllControlViews,
  patchControlViewById,
} from 'api/cvfm-core-control/ControlView';
import { PatchObject } from 'api/commonTypes';
import { getApiState, InternalApiState } from 'api/duck';
import { getConfigState } from 'config/duck';
import FlexCenter from 'commons/FlexCenter';
import ErrorBlock from 'commons/ErrorBlock';

import ControlViewTable from './ControlViewTable';
import ControlViewDetail from './ControlViewDetail';
import { computeControlViewPatchDiff } from './ControlViewDiffPatch';

import './ControlViewConfiguration.css';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { _t, _tg } = window.loadTranslations(__filename);

type ControlViewConfigurationProps = {
  canEdit: boolean;
  canDelete: boolean;
  exemptionReasons: Array<{ id: string; name: string }>;
  withTeams: boolean;
};

const ControlViewConfiguration = ({
  canEdit,
  canDelete,
  exemptionReasons,
  withTeams,
}: ControlViewConfigurationProps) => {
  // Load stuff first
  const [loading, setLoading] = useState<boolean>(true);
  const [controlTeams, setControlTeams] = useState<
    Array<{ id: string; name: string }>
  >([]);
  const [controlViews, setControlViews] = useState<
    Array<ControlViewPrivateDTO>
  >([]);
  // Store edited view in state
  const [controlViewDetailEdit, setControlViewDetailEdit] = useState<
    ControlViewPrivateDTO
  >();
  const [controlViewDetailCreate, setControlViewDetailCreate] = useState<
    ControlViewPrivateDTO
  >();
  // Error reference
  const [error, setError] = useState<Error>();

  function sortControlViews(views: Array<ControlViewPrivateDTO>) {
    views.sort((a, b) => (a.name + a.id).localeCompare(b.name + b.id));
  }

  async function loadControlViews(): Promise<void> {
    setControlViewDetailEdit(undefined);
    setControlViewDetailCreate(undefined);

    const fetchedControlViews = await fetchAllControlViews();
    sortControlViews(fetchedControlViews);
    setControlViews(fetchedControlViews);
    setLoading(false);
  }

  function onClickControlView(controlViewId: string): void {
    const controlViewToEdit = controlViews.find(cv => cv.id === controlViewId);
    if (controlViewToEdit) {
      setControlViewDetailEdit(controlViewToEdit);
    }
  }

  function onClickCreateControlView(): void {
    const newControlView = {
      id: 'tmp',
      enabled: true,
      name: '',
      refreshDelayMinutes: 10,
      displayColorHex: '#00FF00',
      userCanSwitch: true,
      userDefaultSwitchState: true,
      useReliabilityThreshold: true,
      reliabilityThreshold: 1.0,
      fpsFilters: [],
      exemptionReasons: [],
      controlStatuses: [],
      deviceTypes: [],
      teamIds: [],
      vehicleTypes: [],
      presumedVehicleTypes: [],
      reviewReasons: [],
      relevantParkingRightPricingCategories: [],
    } as ControlViewPrivateDTO;
    setControlViewDetailCreate(newControlView);
  }

  function handleControlViewDelete(controlViewId: string): void {
    deleteControlViewById(controlViewId)
      .then(() => {
        const idx = controlViews.findIndex(v => v.id === controlViewId);
        if (idx !== -1) {
          const updatedViews = [...controlViews];
          updatedViews.splice(idx, 1);
          setControlViews(updatedViews);
        }
        return Promise.resolve();
      })
      .catch(err => {
        setError(err as Error);
      });
  }

  function handleControlViewPatchList(
    controlViewId: string,
    patches: Array<PatchObject<unknown>>
  ) {
    if (patches) {
      patchControlViewById(controlViewId, patches)
        .then(controlViewPatched => {
          const idx = controlViews.findIndex(
            v => v.id === controlViewPatched.id
          );
          if (idx !== -1) {
            const updatedViews = [...controlViews];
            updatedViews[idx] = controlViewPatched;
            sortControlViews(updatedViews);
            setControlViews(updatedViews);
          }
          return Promise.resolve();
        })
        .catch(err => {
          setError(err as Error);
        })
        .finally(() => {
          setControlViewDetailEdit(undefined);
        });
    }
  }

  function handleControlViewToggle(
    controlViewId: string,
    enabled: boolean
  ): void {
    const patches = [
      {
        op: 'replace',
        path: '/enable',
        value: enabled,
      },
    ];
    handleControlViewPatchList(controlViewId, patches);
  }

  function handleControlViewEdit(editedControlView: ControlViewPrivateDTO) {
    if (!controlViewDetailEdit) {
      return;
    }

    const patches = computeControlViewPatchDiff(
      controlViewDetailEdit,
      editedControlView
    );
    handleControlViewPatchList(editedControlView.id, patches);
  }

  function handleControlViewCreate(newControlView: ControlViewPrivateDTO) {
    createControlView(newControlView)
      .then((createdControlView: ControlViewPrivateDTO) => {
        const updatedViews = [...controlViews];
        updatedViews.push(createdControlView);
        sortControlViews(updatedViews);
        setControlViews(updatedViews);
      })
      .catch(err => {
        setError(err as Error);
      })
      .finally(() => {
        setControlViewDetailCreate(undefined);
      });
  }

  useEffect(() => {
    if (withTeams) {
      fetchControlTeams()
        .then(teamsDto => {
          const teams = teamsDto.map(dto => {
            return {
              id: dto.teamId,
              name: dto.name,
            };
          });
          setControlTeams(teams);
        })
        .then(() => {
          return loadControlViews();
        })
        .catch(err => {
          setError(err as Error);
        });
    } else {
      setControlTeams([]);
      void loadControlViews();
    }
  }, []);

  if (error) {
    return (
      <FlexCenter>
        <ErrorBlock
          message={_tg('feedback.error.fetchControl')}
          error={error}
        />
      </FlexCenter>
    );
  }

  return (
    <div className="control-view_content">
      <ListWrapper>
        <ListBody loading={loading}>
          <ControlViewTable
            canEdit={canEdit}
            canDelete={canDelete}
            controlViews={controlViews}
            onClick={onClickControlView}
            onToggle={handleControlViewToggle}
            onDelete={handleControlViewDelete}
            exemptionReasons={exemptionReasons}
          />
        </ListBody>
        <ListBottom>
          <BoButton
            disabled={!canEdit}
            label={_tg('action.create')}
            onClick={onClickCreateControlView}
            primary
          />
        </ListBottom>
      </ListWrapper>
      {controlViewDetailEdit && (
        <ControlViewDetail
          open={controlViewDetailEdit !== null}
          onCancel={() => {
            setControlViewDetailEdit(undefined);
          }}
          onConfirm={handleControlViewEdit}
          controlView={controlViewDetailEdit}
          exemptionReasons={exemptionReasons}
          withTeams={withTeams}
          controlTeams={controlTeams}
        />
      )}
      {controlViewDetailCreate && (
        <ControlViewDetail
          open={controlViewDetailCreate !== null}
          onCancel={() => {
            setControlViewDetailCreate(undefined);
          }}
          onConfirm={handleControlViewCreate}
          controlView={controlViewDetailCreate}
          exemptionReasons={exemptionReasons}
          withTeams={withTeams}
          controlTeams={controlTeams}
        />
      )}
    </div>
  );
};

function mapStateToProps(state: InternalApiState) {
  const { userInfo } = getApiState(state);
  const {
    exemptionReasonsConfigurations,
    modulesConfiguration,
  } = getConfigState(state);

  const exemptionReasons = exemptionReasonsConfigurations?.map(e => {
    return {
      id: e.key,
      name: e.label,
    };
  });
  return {
    canEdit: userInfo?.rights.includes('CONTROL_FOLLOWER_WRITE'),
    canDelete: userInfo?.rights.includes('CONTROL_FOLLOWER_DELETE'),
    exemptionReasons,
    withTeams: modulesConfiguration.planner.enabled,
  };
}

export default connect(mapStateToProps)(ControlViewConfiguration);
