import React from 'react';
import Checkbox from 'material-ui/Checkbox';

import SimpleTable from 'commons/SimpleTable';
import SectionTitle from 'commons/SectionTitle';
import { DOCUMENT_ENTITLED } from 'api/fpsConfiguration/utils';
import { RefundDocumentType } from 'api/fpsConfiguration/types';

const { _t } = window.loadTranslations(__filename);

type Props = {
  documents: Set<RefundDocumentType>;
  setDocuments: (arg0: Set<RefundDocumentType>) => void;
};

const tableCols = [
  { label: '', width: 200, grow: 1 },
  { label: '', width: 100 },
];

function DocumentConfiguration({
  documents,
  setDocuments,
}: Props): JSX.Element {
  function handleClick(
    event: React.MouseEvent<HTMLFormElement>,
    checked: boolean
  ) {
    const { key } = event.currentTarget.dataset;
    const localList: Set<RefundDocumentType> = new Set(documents);
    if (checked) {
      localList.add(key as RefundDocumentType);
    } else {
      localList.delete(key as RefundDocumentType);
    }
    setDocuments(localList);
  }

  return (
    <div style={{ position: 'relative', padding: 20, paddingTop: 0 }}>
      <SectionTitle title={_t('element.title')} />
      <SimpleTable
        maxHeight={1500}
        cols={tableCols}
        header={false}
        rowHeight={50}
        items={Object.entries(DOCUMENT_ENTITLED)}
        itemsRenderer={([key, value]: [RefundDocumentType, string]) => [
          <span>{value}</span>,
          <Checkbox
            checked={documents.has(key)}
            onCheck={handleClick}
            data-key={key.toString()}
          />,
        ]}
      />
    </div>
  );
}

export default DocumentConfiguration;
