import React from 'react';
import Dialog from 'material-ui/Dialog';
import TextField from 'material-ui/TextField';

import BoButton from 'facade/BoButton';
import { addWhiteListedPlate } from 'api/whitelist';

const { _t } = window.loadTranslations(__filename);

type Props = {
  onAdd: (message: string) => any;
};

type State = {
  open: boolean;
  plates: string;
};

class AddPlates extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      open: false,
      plates: '',
    };
  }

  closeModal = (): void => this.setState({ open: false, plates: '' });

  openModal = (): void => this.setState({ open: true });

  handleChangeNewPlates = (
    e: React.ChangeEvent<HTMLInputElement>,
    plates: string
  ): void => {
    this.setState({ plates });
  };

  handleSubmit = async (): Promise<void> => {
    const { onAdd } = this.props;
    const { plates } = this.state;
    try {
      await addWhiteListedPlate(
        plates.split('\n').filter(p => p.trim() !== '')
      );
      onAdd(_t('feedback.success'));
    } catch (e) {
      onAdd(_t('feedback.error'));
    } finally {
      this.closeModal();
    }
  };

  render(): React.ReactNode {
    const { open, plates } = this.state;

    return (
      <div style={{ marginTop: 20 }}>
        <BoButton
          label={_t('element.buttonAdd.label')}
          primary
          onClick={this.openModal}
        />
        <Dialog
          title={_t('element.dialog.title')}
          actions={[
            <BoButton label="Annuler" onClick={this.closeModal} />,
            <BoButton
              label="Ajouter"
              style={{ marginLeft: 10 }}
              primary
              onClick={this.handleSubmit}
              disabled={plates.trim() === ''}
            />,
          ]}
          open={open}
          autoScrollBodyContent
          onRequestClose={this.closeModal}
        >
          <TextField
            floatingLabelText={_t('element.fieldPlates.label')}
            style={{ width: '100%' }}
            value={plates}
            multiLine
            rows={5}
            rowsMax={10}
            onChange={this.handleChangeNewPlates}
          />
        </Dialog>
      </div>
    );
  }
}

export default AddPlates;
