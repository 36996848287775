import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Sidebar from 'commons/SidebarV2';
import BarTitle from 'commons/SidebarV2/Components/BarTitle';
import HitsCounter from 'commons/SidebarV2/Components/HitsCounter';
import Checkboxes from 'commons/SidebarV2/Components/Checkboxes';
import Autocomplete from 'commons/SidebarV2/Components/Autocomplete';
import Dates from 'commons/SidebarV2/Components/Dates';
import Input from 'commons/SidebarV2/Components/Input';
import { fetchTags, TagsTypes } from 'api/tags';
import { fetchMatchingAgents } from 'api/accounts';
import { InternalAgent } from 'api/auth/types';
import { getApiState } from 'api/duck';
import { AgentIdAndNameDTO } from '@cvfm-front/tefps-types';
import ChartFilterService from 'Dashboard/fps/Graphics/service';
import { RapoChartCriteria } from 'Dashboard/fps/Graphics/types';
import { RecourseSearchCriterias } from 'tefps/RecoursesV2/utils/recourseUtils';
import {
  RECOURSE_STATUSES_FILTER_OPTIONS,
  REQUEST_REASONS_FILTER_OPTIONS,
  RESPONSE_REASONS_FILTER_OPTIONS,
  RECOURSE_SOURCES_FILTER_OPTIONS,
} from 'tefps/RecoursesV2/utils/translationUtils';

const { _tg } = window.loadTranslations(__filename);

type Props = {
  totalHits?: number;
  filters: RecourseSearchCriterias;
  resetFilters: () => void;
  userInfo: InternalAgent;
};

function RapoFiltersBar({
  totalHits,
  filters,
  resetFilters,
  userInfo,
}: Props): JSX.Element {
  const [agentSearchOptions, setAgentSearchOptions] = useState<
    Array<{ id: string; name: string }>
  >([]);
  const [tagsOptions, setTagsOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);

  function onChange(id: string, value: unknown) {
    ChartFilterService.setFilters((prevFilters: RapoChartCriteria) => {
      return { ...prevFilters, [id]: value };
    });
  }

  async function fetchOrganizationTags() {
    const orgId = userInfo.worksFor ? userInfo.worksFor.organizationId : null;
    const tags = await fetchTags(TagsTypes.rapo, orgId, true);
    setTagsOptions(tags.map(tag => ({ value: tag, label: tag })));
  }

  async function fetchAgentsAndGenerateOptions(
    agentFilter: string | null | undefined
  ) {
    let agents: Array<AgentIdAndNameDTO> = [];
    if (agentFilter && agentFilter.length >= 3) {
      agents = (await fetchMatchingAgents(agentFilter)).matchingAgents;
    }

    const agentOptions = agents.map(({ id, fullName }) => ({
      id,
      name: fullName,
    }));
    setAgentSearchOptions(agentOptions);
  }

  useEffect(() => {
    void fetchAgentsAndGenerateOptions(null);
    void fetchOrganizationTags();
  }, []);

  return (
    <Sidebar>
      <BarTitle resetFilters={resetFilters} />
      <HitsCounter hits={totalHits || 0} />
      <Dates
        id="creationDate"
        title={_tg('tefps.filters.rapo.creationDate')}
        dates={filters.creationDate}
        onChange={onChange}
      />
      <Dates
        id="receptionDate"
        title={_tg('tefps.filters.rapo.receptionDate')}
        dates={filters.receptionDate}
        onChange={onChange}
      />
      <Autocomplete
        id="agentId"
        title={_tg('tefps.filters.fps.agentId')}
        options={agentSearchOptions}
        onChange={onChange}
        onAutocomplete={fetchAgentsAndGenerateOptions}
        search={filters.agentId}
      />
      <Input
        id="fpsId"
        title={_tg('tefps.filters.rapo.fpsId.title')}
        placeholder={_tg('tefps.filters.rapo.fpsId.placeholder')}
        onChange={onChange}
        value={filters.fpsId || ''}
      />
      <Input
        id="plate"
        title={_tg('tefps.filters.rapo.plate.title')}
        placeholder={_tg('tefps.filters.rapo.plate.placeholder')}
        onChange={onChange}
        value={filters.plate || ''}
      />
      <Checkboxes
        id="statuses"
        title={_tg('tefps.filters.rapo.statuses.label')}
        options={RECOURSE_STATUSES_FILTER_OPTIONS}
        filters={filters.statuses}
        onChange={onChange}
      />
      <Checkboxes
        id="sources"
        title={_tg('tefps.filters.rapo.sources.label')}
        options={RECOURSE_SOURCES_FILTER_OPTIONS}
        filters={filters.sources}
        onChange={onChange}
      />
      <Checkboxes
        id="tags"
        title={_tg('tefps.filters.rapo.tags')}
        options={tagsOptions}
        filters={filters.tags}
        onChange={onChange}
      />
      <Checkboxes
        id="reasons"
        title={_tg('tefps.filters.rapo.reasons.label')}
        options={REQUEST_REASONS_FILTER_OPTIONS}
        filters={filters.reasons}
        onChange={onChange}
      />
      <Checkboxes
        id="cityReasons"
        title={_tg('tefps.filters.rapo.cityReasons.label')}
        options={RESPONSE_REASONS_FILTER_OPTIONS}
        filters={filters.cityReasons}
        onChange={onChange}
      />
      <Input
        id="comment"
        title={_tg('tefps.filters.rapo.comment.title')}
        placeholder={_tg('tefps.filters.rapo.comment.placeholder')}
        onChange={onChange}
        value={filters.comment || ''}
      />
      <Input
        id="agentComment"
        title={_tg('tefps.filters.rapo.agentComment.title')}
        placeholder={_tg('tefps.filters.rapo.agentComment.placeholder')}
        onChange={onChange}
        value={filters.agentComment || ''}
      />
      <Dates
        id="fpsStatementDatetime"
        title={_tg('tefps.filters.rapo.fpsStatementDatetime')}
        dates={filters.fpsStatementDatetime}
        onChange={onChange}
      />
    </Sidebar>
  );
}

export default connect(state => {
  const { userInfo } = getApiState(state);
  return {
    userInfo,
  };
})(RapoFiltersBar);
